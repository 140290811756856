import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import theme from "../theme/theme";
import Logo from "../assets/Logo.svg";
import LogoDark from "../assets/LogoDark.svg";

const AuthCardHeader = ({ title, des, imgSrc, imageW, desColor, mbImg }: any) => {
  const src = useColorModeValue(Logo, LogoDark);
  const color = useColorModeValue(
    '#00332D',
    theme.colors.dark.text.primary
  );
  const parts = des?.split(/({[^}]+})/);
  const partsColor = useColorModeValue('#5A5A5A','#EEE')

  return (
    <Flex
      justify={"center"}
      align={"center"}
      direction={"column"}
      gap="7px"
      mb="48px"
    >
      <Image mb={mbImg || "41px"} src={imgSrc || src} w={imageW} />
      <Heading
        color={color}
        fontFamily={"Audiowide"}
        fontSize={"24px"}
        fontWeight={"400"}
        textAlign={'center'}
      >
        {title}
      </Heading>
      <Text textAlign="center" letterSpacing={'0.035px'} fontSize="14px" fontWeight="400" color={desColor}>
        {parts?.map((part: any, index: number) => {
          if (part.startsWith("{") && part.endsWith("}")) {
            return (
              <Text as="span" display="inline" color={partsColor} fontWeight="700" key={index}>
                {part.substring(1, part.length - 1)}
              </Text>
            );
          }
          return part;
        })}
      </Text>
    </Flex>
  );
};

export default AuthCardHeader;
