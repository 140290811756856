import React, { useEffect, useState } from "react";
import AdminLayout from "../layouts/AdminLayout";
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import FilterDropDown from "../components/FilterDropDown";
import theme from "../theme/theme";
import searchIcon from "../assets/searchIcon.svg";
import cloudDark from "../assets/cloudDark.svg";
import cloud from "../assets/downloadCloud.svg";
import CustomTabs from "../components/CustomTabs";
import CustomTable from "../components/CustomTable";
import shareBg from "../assets/shareWide.svg";
import cloudBg from "../assets/cloudwide.svg";
import cloudBgDark from "../assets/cloudWideDark.svg";
import shareBgDark from "../assets/shareWideDark.svg";
import naira from "../assets/naira_circular.svg";
import nairaDark from "../assets/nairaCircularDark.svg";
import filterIcon from "../assets/filterIcon.svg";
import copyLight from "../assets/copy.svg";
import copyDark from "../assets/copyDark.svg";
import {
  useCsvAirtimeDownload,
  useCsvElectricityDownload,
  useCsvInternetDownload,
  useCsvTvSubscriptionDownload,
  useGetCompletedAirtime,
  useGetCompletedElectricityBill,
  useGetCompletedInternet,
  useGetCompletedTvSubscription,
} from "../utils/service.api";

const ServicesTransactions = () => {
  const { data: csvAirtimeData, isLoading: csvAirtimeLoading } =
    useCsvAirtimeDownload();
  const { data: csvInternetData, isLoading: csvInternetLoading } =
    useCsvInternetDownload();
  const { data: csvElectricityData, isLoading: csvElectricityLoading } =
    useCsvElectricityDownload();
  const { data: csvTvData, isLoading: csvTvLoading } =
    useCsvTvSubscriptionDownload();

  const [airtimeRows, setAirtimeRows] = useState([]);
  const [internetRows, setInternetRows] = useState([]);
  const [electricityRows, setElectricityRows] = useState([]);
  const [tvRows, setTvRows] = useState([]);
  const [filterDropdownShow, setFilterDropDownShow] = useState(false);
  const { data: airtimeData, isLoading } = useGetCompletedAirtime();
  const { data: internetData } = useGetCompletedInternet();
  const { data: electricityData } = useGetCompletedElectricityBill();
  const { data: tvSubscriptionData } = useGetCompletedTvSubscription();

  const toast = useToast();
  const cloudBtn = useColorModeValue(cloud, cloudDark);
  const headColor = useColorModeValue("#00332D", "#C7C7C7");
  const borderColor = useColorModeValue("#C7C7C7", "#00665A");
  const borderColorSecondary = useColorModeValue(
    theme.colors.light.border.secondary,
    theme.colors.dark.border.secondary
  );
  const bgColor = useColorModeValue("#FFF", "#121B18");
  const bg = useColorModeValue("#EEE", "#00332D");
  const color = useColorModeValue(
    theme.colors.light.accent.primary,
    theme.colors.dark.accent.primary
  );
  const copyIcon = useColorModeValue(copyLight, copyDark);
  const borderColor2 = useColorModeValue("#EEE", "#333");
  const cloudBgIcon = useColorModeValue(cloudBg, cloudBgDark);
  const shareBgIcon = useColorModeValue(shareBg, shareBgDark);
  const nairaIcon = useColorModeValue(naira, nairaDark);
  const [selectedTab, setSelectedTab] = useState(0);

  const airtimeColumns = ["Amount", "Paid to", "Network Name", "Date"];
  const internetColumns = [
    "Amount",
    "Phone No",
    "Data Plan",
    "Validity",
    "Network Name",
    "Date",
    // "Action",
  ];

  const electricityColumns = [
    "Amount",
    "Meter Provider",
    "Meter Type",
    "Token",
    "Service Fee",
    "Date",
    // "Action",
  ];
  const tvColumns = [
    "Amount",
    "Service Provider",
    "Smart Card No",
    "Service Fee",
    "Date",
    // "Action",
  ];

  const reArrageData = () => {
    setAirtimeRows(
      generateRows(
        airtimeColumns,
        cloudBgIcon,
        shareBgIcon,
        nairaIcon,
        copyIcon,
        airtimeData,
        toast
      )
    );
    setInternetRows(
      generateRows(
        internetColumns,
        cloudBgIcon,
        shareBgIcon,
        nairaIcon,
        copyIcon,
        internetData,
        toast
      )
    );
    setElectricityRows(
      generateRows(
        electricityColumns,
        cloudBgIcon,
        shareBgIcon,
        nairaIcon,
        copyIcon,
        electricityData,
        toast
      )
    );
    setTvRows(
      generateRows(
        tvColumns,
        cloudBgIcon,
        shareBgIcon,
        nairaIcon,
        copyIcon,
        tvSubscriptionData,
        toast
      )
    );
  };

  const toggleFilter = () => {
    setFilterDropDownShow(!filterDropdownShow);
  };

  useEffect(() => {
    reArrageData();
  }, [
    cloudBgIcon,
    shareBgIcon,
    nairaIcon,
    airtimeData,
    internetData,
    electricityData,
    tvSubscriptionData,
  ]);

  // console.log(internetData);
  return (
    <AdminLayout>
      <Box marginTop="72px" marginRight={"2%"} mb={"50px"} w={"95%"}>
        <Flex
          justify={"space-between"}
          align={["start", "start", "start", "center"]}
          mb={"30px"}
          direction={["column", "column", "column", "row"]}
        >
          <Heading
            color={headColor}
            fontSize={["18px", "24px", "30px", "34px"]}
            fontWeight={"700"}
            letterSpacing={"0.085px"}
          >
            Services Transactions
          </Heading>
        </Flex>
        {filterDropdownShow && (
          <Flex mt="-35px" justify={"end"}>
            <Box
              position={"relative"}
              zIndex={10}
              padding={"24px"}
              borderRadius={"16px"}
              border={`1px solid ${borderColor2}`}
              bg={bgColor}
              boxShadow={"0px 4px 40px 0px rgba(0,0,0,0.10)"}
              w={["100%", "100%", "40%", "40%"]}
            >
              <FilterDropDown toggleFilter={toggleFilter} />
            </Box>
          </Flex>
        )}
        <Box
          mt={filterDropdownShow ? "-414px" : "0px"}
          display={"flex"}
          justifyContent={["start", "start", "end", "end"]}
          mb={["10px", "10px", "-42px", "-42px"]}
        >
          <Button
            display={"flex"}
            padding={"8px 16px"}
            alignItems={"center"}
            gap={"8px"}
            borderRadius={"8px"}
            border={`1px solid ${borderColor}`}
            bg={bg}
            color={color}
            fontSize={["8px", "9px", "11px", "12px"]}
            fontWeight={"400"}
            letterSpacing={"0.048px"}
            mt={["10px", "10px", "10px", "0px"]}
            zIndex={1}
            _hover={{ bg: bg }}
            _active={{ bg: bg }}
            isDisabled={csvAirtimeLoading}
            onClick={() => {
              const blob = new Blob(
                [
                  selectedTab === 0
                    ? csvAirtimeData
                    : selectedTab === 1
                    ? csvInternetData
                    : selectedTab === 2
                    ? csvElectricityData
                    : selectedTab === 3
                    ? csvTvData
                    : "",
                ],
                { type: "text/csv" }
              );
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = "csvData.csv";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
          >
            <Image src={cloudBtn} alt="cloud" />
            Download History
          </Button>
        </Box>

        <CustomTabs
          titles={[
            { sm: "AT", md: "Airtime" },
            { sm: "Internet", md: "Internet" },
            { sm: "E Bill", md: "Electricity Bill" },
            { sm: "TV Subs", md: "TV Subscription" },
          ]}
          // selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        >
          <Box>
            {isLoading ? (
              <Spinner />
            ) : (
              <CustomTable
                rows={airtimeRows}
                columns={airtimeColumns}
                borderB={true}
                pagination={true}
              />
            )}
          </Box>
          <Box>
            <CustomTable
              rows={internetRows}
              columns={internetColumns}
              borderB={true}
              pagination={true}
            />
          </Box>
          <Box>
            <CustomTable
              rows={electricityRows}
              columns={electricityColumns}
              borderB={true}
              pagination={true}
            />
          </Box>
          <Box>
            <CustomTable
              rows={tvRows}
              columns={tvColumns}
              borderB={true}
              pagination={true}
            />
          </Box>
        </CustomTabs>
      </Box>
    </AdminLayout>
  );
};

export default ServicesTransactions;

const generateRows = (
  columns: any,
  cloudBgIcon: any,
  shareBgIcon: any,
  nairaIcon: any,
  copyIcon: any,
  data: any,
  toast: any
): any => {
  let tempArr: any = [];

  data?.map((dat: any) => {
    let row: any = {};
    columns.forEach((column: any) => {
      switch (column) {
        case "Amount":
          row[column.toLowerCase()] = (
            <Flex align={"center"} gap={"8px"}>
              <Text>
                {Number(dat?.amount)?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
              <Image alt="crypto-icon" src={nairaIcon} w={"20px"} h={"20px"} />
            </Flex>
          );
          break;
        case "Paid to":
          row[column.toLowerCase()] = dat?.paidTo;
          break;
        case "Received by":
          row[column.toLowerCase()] = dat?.phone;
          break;
        case "Data Plan":
          row[column.toLowerCase()] = dat?.dataPlan;
          break;
        case "Validity":
          row[column.toLowerCase()] = dat?.validity;
          break;
        case "Meter Provider":
          row[column.toLowerCase()] = dat?.provider;
          break;
        case "Meter Type":
          row[column.toLowerCase()] = dat?.meterType;
          break;
        case "Token":
          row[column.toLowerCase()] = (
            <Flex align={"center"} gap={"8px"}>
              <Text>{dat?.token}</Text>
              <Image
                alt="crypto-icon"
                src={copyIcon}
                w={"20px"}
                h={"20px"}
                cursor="pointer"
                onClick={() => {
                  navigator?.clipboard
                    ?.writeText(dat?.token)
                    .then((result) => {
                      toast({
                        title: "Token copied to clipboard",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                      });
                    })
                    .catch((err) => {
                      toast({
                        title: "Failed to copy token to clipboard",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                      });
                    });
                }}
              />
            </Flex>
          );
          break;
        case "Service Provider":
          row[column.toLowerCase()] = dat?.provider;
          break;
        case "Smart Card No":
          row[column.toLowerCase()] = dat?.smartCardNumber;
          break;
        case "Service Fee":
          row[column.toLowerCase()] = dat?.serviceFee || "0.00 NGN";
          break;
        case "Network Name":
          row[column.toLowerCase()] = dat?.networkName;
          break;
        case "Phone No":
          row[column.toLowerCase()] = dat?.phoneNumber;
          break;
        case "Date":
          row[column.toLowerCase()] = dat?.updatedAt?.slice(0, 10);
          break;
        case "Action":
          row[column.toLowerCase()] = (
            <Flex align={"center"} gap={"16px"}>
              <Image src={cloudBgIcon} />
              <Image src={shareBgIcon} />
            </Flex>
          );
          break;
        default:
          row[column.toLowerCase()] = "";
          break;
      }
    });
    tempArr?.push(row);
  });

  return tempArr;
};
