import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import ThemeToggle from "./ThemeToggle";
import theme from "../theme/theme";
import crownLight from "../assets/icons/light/crown.svg";
import crownDark from "../assets/icons/dark/crown.svg";
import chevronDownLight from "../assets/icons/light/chevronDown.svg";
import chevronDownDark from "../assets/icons/dark/chevronDown.svg";
import ProfilePlaceholder from "../assets/Profile-Placeholder.jpg";
import NotificationDropDown from "./NotificationDropDown";
import Logo from "../assets/Logo.svg";
import LogoDark from "../assets/LogoDark.svg";
import bellLight from "../assets/icons/light/bell.svg";
import bellDark from "../assets/icons/dark/bell.svg";
import { useAddDeviceToken, useGetLoggedInUser } from "../utils/auth.api";
import { requestForToken } from "../utils/firebase";
import { useNavigate } from "react-router-dom";

const Header = ({ noSidebar, isOpen }: any) => {
  const navigate = useNavigate();
  const AddDeviceToken = useAddDeviceToken();
  const { data: user } = useGetLoggedInUser();
  const headerBG = useColorModeValue(theme.colors.light.bg, "#181818");

  const borderColor = useColorModeValue(
    theme.colors.light.border.secondary,
    theme.colors.dark.border.secondary
  );
  const color = useColorModeValue("#00332D", theme.colors.dark.text.primary);

  const badgeColor = useColorModeValue(
    theme.colors.light.badge,
    theme.colors.dark.badge
  );

  const crownIcon = useColorModeValue(crownLight, crownDark);
  const chevronDownIcon = useColorModeValue(chevronDownLight, chevronDownDark);
  const headerImage = useColorModeValue(Logo, LogoDark);
  const bellIcon = useColorModeValue(bellLight, bellDark);

  const fetchToken = async () => {
    try {
      const token = await requestForToken();
      if (token) {
        await AddDeviceToken.mutateAsync({
          deviceTokens: token,
          deviceModel: "web",
        });
      }
    } catch (error: any) {
      // toast.warn(error?.message);
      console.error("Error fetching device token:", error);
    }
  };
  useEffect(() => {
    fetchToken();
  }, []);

  return (
    <Box>
      <Flex
        justify={"flex-end"}
        align={"center"}
        gap={["10px", "10px", "20px"]}
        position={"fixed"}
        right={isOpen ? "0px" : "0"}
        top="0"
        p={noSidebar ? ["30px 20px", "30px 50px"] : ["30px 10px", "30px 10px"]}
        bg={headerBG}
        zIndex={1}
        w={noSidebar ? "100%" : "95%"}
      >
        {noSidebar && (
          <Flex align={"center"} gap={"15px"} mr="auto">
            <Image h={["36px", "36px", "36px", "56px"]} src={headerImage} />
            <Heading
              display={{ base: "none", md: "block" }}
              color={color}
              fontSize={["20px", "20px", "20px", "24px"]}
              fontFamily={"Audiowide"}
              fontWeight={400}
            >
              payremit
            </Heading>
          </Flex>
        )}
        <ThemeToggle />
        <Flex
          p="8px 18px 8px 8px"
          // h="56px"
          // w="301px"
          borderRadius={"60px"}
          border="1px solid"
          borderColor={borderColor}
          align={"center"}
          gap="32px"
          display={{ base: "none", sm: "flex" }}
        >
          <Image
            src={user?.image || ProfilePlaceholder}
            boxSize={"40px"}
            borderRadius={"20px"}
            mr="-20px"
          />
          <Box display={{ base: "none", lg: "block" }}>
            <Flex align={"center"} gap="8px">
              <Text color={color} fontSize={"20px"}>
                {user?.fullname}
              </Text>
              <Flex
                p="3px 8.2px"
                borderRadius={"6px"}
                bg={badgeColor.bg}
                color={badgeColor.text}
                gap="4px"
                align="center"
                cursor={"pointer"}
                onClick={() =>
                  navigate("/dashboard/settings", {
                    state: "EditProfile",
                  })
                }
              >
                <Image src={crownIcon} />
                <Text fontSize={"10px"} fontWeight={600}>
                  {user?.kycTier === "1"
                    ? "Level 01"
                    : user?.kycTier === "2"
                    ? "Level 02"
                    : user?.kycTier === "3"
                    ? "Pro"
                    : "Level 01"}
                </Text>
              </Flex>
            </Flex>
            <Text fontSize={"12px"}>{user?.email}</Text>
          </Box>
          {/* <Button
            variant={"unstyled"}
            bg={btnBG}
            h="40px"
            borderRadius={"100px"}
          >
            <Image src={chevronDownIcon} mx="auto" />
          </Button> */}
        </Flex>
        {/* <Button color={btnColor} bg={btnBG} w="30px" borderRadius={"14px"}>
          <i className="fa-regular fa-bell"></i>
        </Button> */}
        <NotificationDropDown bellIcon={bellIcon} />
      </Flex>
    </Box>
  );
};

export default Header;
