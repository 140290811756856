import React, { useEffect, useState } from "react";
import AdminLayout from "../layouts/AdminLayout";
import {
  Box,
  Divider,
  Flex,
  Heading,
  Image,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import theme from "../theme/theme";
import PrimaryButton from "../components/PrimaryButton";
import CustomModal from "../components/CustomModal";
import verifyIcon from "../assets/verified.svg";
import AuthCardHeader from "../components/AuthCardHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { useDepositAmount } from "../utils/wallet.api";
import { useGetLoggedInUser } from "../utils/auth.api";
import { useGetAccountdetails } from "../utils/service.api";
import failedIcon from "../assets/failedIcon.svg";
import arrow1 from "../assets/backArrow.svg";
import arrow2 from "../assets/backArrowDark.svg";

const DepositSummary = () => {
  const { data: user } = useGetLoggedInUser();
  const { data: payremitBankDetails, isLoading } = useGetAccountdetails();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  const [errorMsg, setErrorMsg] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const data = location?.state;
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => {
    setIsOpen(!isOpen);
  };
  const primaryText = useColorModeValue(
    theme.colors.light.text.primary,
    theme.colors.dark.text.primary
  );
  const paraColor2 = useColorModeValue("#5A5A5A", "#EEE");
  const arrowIcon = useColorModeValue(arrow1, arrow2);

  const CustomFlex = ({ title, value }: any) => {
    return (
      <Flex
        justify={"space-between"}
        my="10px"
        direction={{ base: "column", md: "row" }}
        gap="20px"
      >
        <Text>{title}</Text>
        <Text color={primaryText} fontSize={{ base: "14px", md: "18px" }}>
          {value}
        </Text>
      </Flex>
    );
  };
  const depositAmountMutation = useDepositAmount();
  useEffect(() => {
    if (!data?.bank || !data?.depositAmount) {
      navigate("/dashboard/deposit");
    }
  }, [data]);

  return (
    <AdminLayout>
      <Box marginTop="30px" marginRight={"2%"} mb={"50px"} w={"95%"} p="30px">
        <Flex gap={"10px"} align={"center"}>
          <Image
            src={arrowIcon}
            alt="arrow"
            transform={"rotate(270deg)"}
            onClick={() => navigate("/dashboard/deposit")}
            cursor={"pointer"}
          />
          <Heading fontSize={["20px", "34px"]} color={primaryText}>
            Deposit Summary
          </Heading>
        </Flex>
        <Text fontWeight={700} mt="30px">
          Payremit Bank Details
        </Text>
        <CustomFlex
          title="Bank"
          value={payremitBankDetails?.bank_name || "N/A"}
        />
        <CustomFlex
          title="Reference ID"
          value={localStorage.getItem("referenceCode")}
        />
        <CustomFlex
          title="Account Name"
          value={payremitBankDetails?.name || "N/A"}
        />
        <CustomFlex
          title="Account Number"
          value={payremitBankDetails?.account_number || "N/A"}
        />
        <Divider my="0px" />
        <Text fontWeight={700} mt="20px">
          Your Deposit Account Details
        </Text>
        <CustomFlex title="Bank" value={data?.bank?.bankName} />
        <CustomFlex title="Account Name" value={data?.bank?.accountName} />
        <CustomFlex title="Account Number" value={data?.bank?.accountNumber} />
        <Divider my="0px" />
        <Flex
          justify={"space-between"}
          my="12px"
          direction={{ base: "column", md: "row" }}
          gap="20px"
        >
          <Text fontWeight={700}>Deposit Amount</Text>
          <Text fontWeight={700} color={primaryText}>
            {Number(data?.depositAmount || 0)?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            <Text
              color={"#909090"}
              fontSize={"14px"}
              ml="4px"
              display={"inline"}
              letterSpacing={"0.056px"}
            >
              NGN
            </Text>{" "}
          </Text>
        </Flex>
        <Divider my="0px" />
        <Box w="220px">
          <PrimaryButton
            text={depositAmountMutation.isLoading ? <Spinner /> : "Submit"}
            onClick={async () => {
              if (Number(user?.kycTier) === 1) {
                onErrorOpen();
                setErrorMsg("Upgrade your level to enable Fiat deposit.");
              } else if (
                Number(user?.kycTier) === 2 &&
                Number(data?.depositAmount) > 5000000
              ) {
                onErrorOpen();
                setErrorMsg(
                  "While you are on Level 2, you can only deposit upto 5M Naira. Please upgrade your level."
                );
              } else {
                try {
                  await depositAmountMutation.mutateAsync({
                    bankAccount: data?.bank?.accountNumber,
                    depositAmount: data?.depositAmount,
                    referenceId: localStorage.getItem("referenceCode"),
                  });
                  onClose();
                } catch (error: any) {
                  onErrorOpen();
                  setErrorMsg(error?.message);
                }
              }
            }}
            disabled={
              depositAmountMutation.isLoading ||
              !data?.bank ||
              !data?.depositAmount
            }
          />
        </Box>
        <CustomModal
          isOpen={isOpen}
          onClose={() => {
            onClose();
            navigate("/dashboard/deposit");
          }}
          onSubmit={() => {
            onClose();
            navigate("/dashboard/deposit");
          }}
          headerText="Completed"
        >
          <Box py="20px">
            <AuthCardHeader
              imgSrc={verifyIcon}
              imageW="56px"
              title="Deposit Request Completed"
              des="Your request has been received, and is being processed. You will receive a notification once your deposit has been completed."
              desColor={useColorModeValue("#5A5A5A", "#C7C7C7")}
            />
          </Box>
        </CustomModal>
      </Box>
      <CustomModal
        isOpen={isErrorOpen}
        onClose={() => {
          onErrorClose();
          // navigate("/dashboard/index");
        }}
        noFooter={true}
        onSubmit={() => {
          onErrorClose();
        }}
      >
        <Flex
          justify={"center"}
          align={"center"}
          direction={"column"}
          gap="24px"
          mb="32px"
          mt={"32px"}
        >
          <Image src={failedIcon} w={"56px"} h={"56px"} />
          <Heading
            fontFamily={"Audiowide"}
            fontSize={["15px", "18px", "21px", "24px"]}
            fontWeight={"400"}
          >
            Transaction Failed!
          </Heading>
          <Text
            textAlign={"center"}
            fontSize={["9px", "11px", "13px", "14px"]}
            fontWeight={"400"}
            lineHeight={"20px"}
            letterSpacing={"0.035px"}
            color={paraColor2}
          >
            {errorMsg}
          </Text>
        </Flex>
      </CustomModal>
    </AdminLayout>
  );
};

export default DepositSummary;
