import { Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import selectedIcon from "../assets/selectedIcon.svg";
import radioSelectedDark from "../assets/radioButtonDark.svg";
import selectedDark from "../assets/selectedIconDark.svg";
import notSelected from "../assets/notSelectedTokenIcon.svg";
import notSelectedDark from "../assets/notSelectedDark.svg";

const NetworkCard = ({
  // selected,
  icon,
  symbol,
  networkInfo,
  // minWithdraw,
  // currency,
  // time,
  onClick,
  selected,
  id,
  idx,
  fee,
}: any) => {
  const icon2 = useColorModeValue(selectedIcon, selectedDark);
  const icon3 = useColorModeValue(selectedIcon, radioSelectedDark);
  const icon1 = useColorModeValue(notSelected, notSelectedDark);

  const borderColor = useColorModeValue("#EEE", " #333");
  const borderColorSelected = useColorModeValue("#009986", "#009986");
  const bg = useColorModeValue("#FFF", "#121B18");
  const headingColor = useColorModeValue("#333", "#FFF");
  const subHeadingColor = useColorModeValue("#909090", "#EEE");
  return (
    <Flex
      p={"16px"}
      justifyContent={"space-between"}
      alignItems={"center"}
      borderRadius={"12px"}
      border={`2px solid `}
      borderColor={selected?.id === id ? borderColorSelected : borderColor}
      bg={bg}
      maxH={"98px"}
      cursor={"pointer"}
      onClick={() => onClick()}
    >
      <Flex direction={"column"} gap={"16px"} justifyContent={"flex-start"}>
        <Flex gap={"8px"}>
          <Image
            src={networkInfo?.logoUrl}
            alt={"icon"}
            width={"20px"}
            height={"20px"}
          />

          <Flex gap={"4px"}>
            <Text
              textColor={headingColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"16px"}
              fontWeight={700}
              lineHeight={"22px"}
              letterSpacing={"1.2px"}
            >
              {selected?.symbol?.slice(0, 4)}
            </Text>

            <Text
              textColor={subHeadingColor}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"12px"}
              fontWeight={400}
              lineHeight={"22px"}
              letterSpacing={"0.048px"}
              mt="2px"
            >
              {networkInfo?.tokenStandard}
            </Text>
          </Flex>
        </Flex>

        <Flex direction={"column"} gap={"12px"} alignContent={"flex-start"}>
          <Text
            textColor={subHeadingColor}
            fontFamily={"Plus Jakarta Sans"}
            fontSize={"12px"}
            fontWeight={400}
            lineHeight={"normal"}
            letterSpacing={"0.048px"}
          >
            {`Min withdrawal ${"25"} ${"USD"}`}
          </Text>

          <Text
            textColor={subHeadingColor}
            fontFamily={"Plus Jakarta Sans"}
            fontSize={"12px"}
            fontWeight={400}
            lineHeight={"normal"}
            letterSpacing={"0.048px"}
          >
            {` Fee: $${fee || 0}`}
          </Text>
        </Flex>
      </Flex>

      <Flex>
        <Image src={selected?.id === id ? icon2 : icon1} alt={"radiobtn"} />
      </Flex>
    </Flex>
  );
};

export default NetworkCard;
