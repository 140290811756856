import { QueryCache } from "@tanstack/react-query";

const apiURL = "https://live-api.payremit.io";
// const apiURL = "https://stg-api.payremit.io";

const queryCache = new QueryCache({
  onError: (error) => {
    // console.log(error);
  },
  onSuccess: (data) => {
    // console.log(data);
  },
});

async function client(
  endpoint,
  { data, headers: customHeaders, params, ...customConfig } = {}
) {
  const token = localStorage.getItem("token");
  const queryString = new URLSearchParams(params).toString();
  const endpointWithParams = queryString
    ? `${endpoint}?${queryString}`
    : endpoint;

  const config = {
    method: data ? "POST" : "GET",
    body: data?.access_token
      ? undefined
      : data instanceof FormData
      ? data
      : JSON.stringify(data),
    headers: {
      Authorization: data?.access_token
        ? `${data?.access_token}`
        : token
        ? `${token}`
        : "",
      ...(!data || data instanceof FormData
        ? {}
        : { "Content-Type": "application/json" }),
      ...customHeaders,
    },
    ...customConfig,
  };

  return await window
    .fetch(`${apiURL}/${endpointWithParams}`, config)
    .then(async (response) => {
      if (response.status === 401) {
        queryCache.clear();
        const errorData = await response.json();
        const error = new Error(errorData.message || "Unauthorized");
        return Promise.reject(error);
      }

      const contentType = response.headers.get("content-type");

      if (contentType && contentType.includes("application/json")) {
        if (response.ok) {
          const jsonData = await response.json();
          return jsonData;
        } else {
          const jsonData = await response.json();
          return Promise.reject(jsonData);
        }
      } else if (contentType && contentType.includes("text/csv")) {
        if (response.ok) {
          const csvData = await response.text();
          return csvData;
        } else {
          const csvErrorData = await response.text();
          return Promise.reject(csvErrorData);
        }
      } else {
        // Handle other content types as needed
        console.warn(`Unhandled content type: ${contentType}`);
        return Promise.reject(
          new Error(`Unhandled content type: ${contentType}`)
        );
      }
    });
}

export { client };
