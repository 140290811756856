import React, { useEffect, useState } from "react";
import AdminLayout from "../layouts/AdminLayout";
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  PinInput,
  PinInputField,
  Slider,
  SliderFilledTrack,
  SliderTrack,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import ProfileItem from "../components/ProfileItem";
import editProfile from "../assets/editProfileIcon.svg";
import referral from "../assets/referral.svg";
import addressBook from "../assets/addressBookSettings.svg";
import bankAccount from "../assets/bankAccount.svg";
import twoFa from "../assets/2fa.svg";
import authorize from "../assets/authorize.svg";
import changePass from "../assets/changePassword.svg";
import editProfileSelected from "../assets/editProfileSelected.svg";
import editProfileSelectedDark from "../assets/editProfileSelectedDark.svg";
import referralSelected from "../assets/refferralSelected.svg";
import addressBookSelected from "../assets/addressBookSelected.svg";
import bankAccountSelected from "../assets/bankAccountSelected.svg";
import twoFaSelected from "../assets/twoFaSelected.svg";
import changePassSelected from "../assets/changePasswordSelected.svg";
import referralSelectedDark from "../assets/referralSelectedDark.svg";
import addressBookSelectedDark from "../assets/addressBookSelectedDark.svg";
import bankAccountSelectedDark from "../assets/bankAccountSelectedDark.svg";
import twoFaSelectedDark from "../assets/2faSelectedDark.svg";
import changePassSelectedDark from "../assets/changePasswordSelectedDark.svg";
import helpCenter from "../assets/help.svg";
import switchOff from "../assets/switchOff.svg";
import switchOn from "../assets/switchOn.svg";
import switchOffDark from "../assets/switchOffDark.svg";
import switchOnDark from "../assets/switchOnDark.svg";
import editProfileDark from "../assets/editProfileDark.svg";
import referralDark from "../assets/referralDark.svg";
import addressBookDark from "../assets/addressBookSettingsDark.svg";
import bankAccountDark from "../assets/bankAccountDark.svg";
import twoFaDark from "../assets/2faDark.svg";
import authorizeDark from "../assets/authorizeDark.svg";
import changePassDark from "../assets/changePasswordDark.svg";
import helpCenterDark from "../assets/helpCenterDark.svg";
import MyProfile from "../components/MyProfile";
import EditProfile from "../components/EditProfile";
import ReferralCode from "../components/ReferralCode";
import AddressBook from "../components/AddressBook";
import BankAccounts from "../components/BankAccounts";
import TwoFa from "../components/TwoFa";
import CustomModal from "../components/CustomModal";
import twoFaModalPic from "../assets/twoFaModalPic.svg";
import twoFaModalPicDark from "../assets/twoFaModalPicDark.svg";
import ChangePassword from "../components/ChangePassword";
import { useLocation, useNavigate } from "react-router-dom";
import levelIco from "../assets/icons/level.svg";
import helpIcon from "../assets/icons/help.svg";

import {
  useAuthorizeTransaction,
  useEnable2FA,
  useGetLoggedInUser,
  useSendOtpAuthorizeTransaction,
  useVerifyOtpAuthorizeTransaction,
} from "../utils/auth.api";
import AuthCardHeader from "../components/AuthCardHeader";

const Settings = () => {
  const location = useLocation();
  const dat = location.state;
  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isPendingKYC,
    onClose: onPendingKYCClose,
    onOpen: onPendingKYCOpen,
  } = useDisclosure();
  const {
    isOpen: isOpenOtp,
    onClose: onCloseOtp,
    onOpen: onOpenOtp,
  } = useDisclosure();
  const enable2FAQuery = useEnable2FA();
  const [authorizeSwitch, setAuthorizeSwitch] = useState(true);
  const { data, isLoading } = useGetLoggedInUser();
  const [pinInput, setPinInput] = useState("");
  const [isVerified, setIsVerified] = useState(3);
  const [timer, setTimer] = useState(60);
  const [twoFaSwitch, setTwoFaSwitch] = useState(
    data?.is2FAEnabled
    // enable2FAQuery?.data?.isAuthEnabled
  );
  const authorizeTransactionMutation = useAuthorizeTransaction();
  const sendAuthorizeTransactionQuery = useSendOtpAuthorizeTransaction();
  const verifyOtpAuthorizeTransaction = useVerifyOtpAuthorizeTransaction();
  const toast = useToast();

  const [selectedItem, setSelectedItem] = useState("myProfile");
  const headColor = useColorModeValue("#00332D", "#EEE");
  const paraColor1 = useColorModeValue("#5A5A5A", "#C7C7C7");
  const paraColor2 = useColorModeValue("#909090", "#C7C7C7");
  const borderColor = useColorModeValue("#EEE", "#333");
  const editProfileIcon = useColorModeValue(
    selectedItem === "EditProfile" ? editProfileSelected : editProfile,
    selectedItem === "EditProfile" ? editProfileSelectedDark : editProfileDark
  );
  const referralIcon = useColorModeValue(
    selectedItem === "ReferralCode" ? referralSelected : referral,
    selectedItem === "ReferralCode" ? referralSelectedDark : referralDark
  );
  const addressBookIcon = useColorModeValue(
    selectedItem === "AddressBook" ? addressBookSelected : addressBook,
    selectedItem === "AddressBook" ? addressBookSelectedDark : addressBookDark
  );
  const bankAccountIcon = useColorModeValue(
    selectedItem === "BankAccounts" ? bankAccountSelected : bankAccount,
    selectedItem === "BankAccounts" ? bankAccountSelectedDark : bankAccountDark
  );
  const twoFaIcon = useColorModeValue(
    selectedItem === "TwofactorAuthentication" && twoFaSwitch
      ? twoFaSelected
      : twoFa,
    selectedItem === "TwofactorAuthentication" && twoFaSwitch
      ? twoFaSelectedDark
      : twoFaDark
  );
  const authorizeIcon = useColorModeValue(authorize, authorizeDark);
  const changePassIcon = useColorModeValue(
    selectedItem === "ChangePassword" ? changePassSelected : changePass,
    selectedItem === "ChangePassword" ? changePassSelectedDark : changePassDark
  );
  const helpCenterIcon = useColorModeValue(helpCenter, helpCenterDark);
  const switchOffIcon = useColorModeValue(switchOff, switchOffDark);
  const switchOnIcon = useColorModeValue(switchOn, switchOnDark);
  const modalPicIcon = useColorModeValue(twoFaModalPic, twoFaModalPicDark);

  const handleSelectionChange = (newSelectedItem: string) => {
    setSelectedItem(newSelectedItem);
  };

  const des = `Verification code has been sent to {${data?.email}}`;

  const profileItemContent: any = {
    myProfile: (
      <MyProfile
        setSelected={handleSelectionChange}
        data={data}
        isLoading={isLoading}
      />
    ),
    EditProfile: (
      <EditProfile
        setSelected={handleSelectionChange}
        data={data}
        isLoading={isLoading}
      />
    ),
    ReferralCode: <ReferralCode data={data} isLoading={isLoading} />,
    AddressBook: <AddressBook />,
    BankAccounts: <BankAccounts />,
    TwofactorAuthentication: twoFaSwitch ? (
      <TwoFa fetch={twoFaSwitch} loggedInUser={data} />
    ) : (
      <MyProfile
        data={data}
        isLoading={isLoading}
        setSelected={handleSelectionChange}
      />
    ),
    ChangePassword: <ChangePassword />,
  };

  const handleItemClick = (item: any) => {
    setSelectedItem(item);
  };

  useEffect(() => {
    if (dat) {
      setSelectedItem(dat);
    }
  }, []);

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;
  const displayTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  useEffect(() => {
    if (isOpenOtp) {
      const countdown = setInterval(() => {
        if (timer > 0) {
          setTimer((prevTimer) => prevTimer - 1);
        } else {
          clearInterval(countdown);
        }
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [timer, isOpenOtp]);

  useEffect(() => {
    // if (selectedItem !== "TwofactorAuthentication") {
    setTwoFaSwitch(data?.is2FAEnabled);
    // }
  }, [selectedItem, data]);

  return (
    <AdminLayout>
      <Box marginTop="72px" marginRight={"2%"} mb={"50px"} w={"95%"}>
        <Flex direction={["column", "column", "row", "row"]}>
          <Box
            borderRight={[
              ``,
              ``,
              `1px solid ${borderColor}`,
              `1px solid ${borderColor}`,
            ]}
            w={["100%", "100%", "45%", "45%"]}
            paddingRight={["1%", "1%", "5%", "5%"]}
          >
            <Heading
              color={headColor}
              fontSize={["18px", "24px", "30px", "34px"]}
              fontWeight={"700"}
              letterSpacing={"0.085px"}
              mb="40px"
            >
              Profile & Settings
            </Heading>
            <Flex
              direction={"column"}
              gap={"24px"}
              align={"flex-start"}
              alignSelf={"stretch"}
            >
              <Text
                color={paraColor1}
                fontSize={"16px"}
                lineHeight={"22px"}
                fontWeight={"700"}
                letterSpacing={"0.08px"}
              >
                General
              </Text>
              <Box w="100%">
                {Number(data?.kycTier) !== 3 && (
                  <ProfileItem
                    iconSrc={levelIco}
                    text={
                      Number(data?.kycTier) === 1
                        ? "Upgrade to Level 2"
                        : "Upgrade to Level 3"
                    }
                    onItemClick={() => {
                      if (data?.kycStatus === "Pending") {
                        onPendingKYCOpen();
                      } else {
                        handleItemClick("EditProfile");
                      }
                    }}
                    selected={selectedItem}
                  />
                )}
                <ProfileItem
                  iconSrc={editProfileIcon}
                  text="Edit Profile"
                  onItemClick={handleItemClick}
                  selected={selectedItem}
                />
                <ProfileItem
                  iconSrc={referralIcon}
                  text="Referral Code"
                  onItemClick={handleItemClick}
                  selected={selectedItem}
                />
                <ProfileItem
                  iconSrc={addressBookIcon}
                  text="Address Book"
                  onItemClick={handleItemClick}
                  selected={selectedItem}
                />
                <ProfileItem
                  iconSrc={bankAccountIcon}
                  text="Bank Accounts"
                  onItemClick={handleItemClick}
                  selected={selectedItem}
                />

                <ProfileItem
                  iconSrc={twoFaIcon}
                  text="Two-factor Authentication"
                  switchIcon={twoFaSwitch ? switchOnIcon : switchOffIcon}
                  onSwitchChange={() => {
                    if (!twoFaSwitch) {
                      onOpen();
                    } else {
                      setTwoFaSwitch(!twoFaSwitch);
                    }
                  }}
                  onItemClick={handleItemClick}
                  selected={selectedItem}
                />
                <ProfileItem
                  iconSrc={authorizeIcon}
                  text="Authorize Transaction"
                  switchIcon={
                    data?.authTransaction === true
                      ? switchOnIcon
                      : switchOffIcon
                  }
                  onSwitchChange={() => {
                    onOpenOtp();
                    sendAuthorizeTransactionQuery.refetch();
                  }}
                  isLoading={authorizeTransactionMutation.isLoading}
                  // setAuthorizeSwitch(!authorizeSwitch)}
                  onItemClick={handleItemClick}
                  selected={selectedItem}
                />
              </Box>
              <Box border={`1px solid ${borderColor}`} w="100%"></Box>
              <Text
                color={paraColor1}
                fontSize={"16px"}
                lineHeight={"22px"}
                fontWeight={"700"}
                letterSpacing={"0.08px"}
              >
                Other
              </Text>
              <Box w="100%">
                <ProfileItem
                  iconSrc={changePassIcon}
                  text="Change Password"
                  onItemClick={handleItemClick}
                  selected={selectedItem}
                />
                {/* <ProfileItem
                  iconSrc={helpIcon}
                  text=" Help Center"
                  onItemClick={() => {}}
                  selected={selectedItem}
                /> */}
              </Box>
            </Flex>
          </Box>
          <Box
            w={["100%", "100%", "55%", "55%"]}
            paddingLeft={["1%", "1%", "5%", "5%"]}
          >
            {profileItemContent[selectedItem]}
          </Box>
        </Flex>
      </Box>
      <CustomModal
        isOpen={isOpenOtp}
        onClose={() => {
          onCloseOtp();
          setTimer(60);
          setPinInput("");
        }}
        headerText="Otp"
        isLoading={verifyOtpAuthorizeTransaction?.isLoading}
        onSubmit={async () => {
          try {
            await verifyOtpAuthorizeTransaction.mutateAsync(pinInput);
            await authorizeTransactionMutation.mutateAsync();
            setIsVerified(1);
            setPinInput("");
            toast({
              title: "Email verified successfully.",
              status: "success",
            });
            onCloseOtp();
          } catch (error: any) {
            toast({
              title: error.message,
              status: "error",
            });
            setIsVerified(0);
          }

          // setTwoFaSwitch(true);
        }}
        isDisabled={verifyOtpAuthorizeTransaction?.isLoading}
        footerText={"Submit"}
        closeText={"Cancel"}
      >
        <AuthCardHeader
          title="Verification Code"
          des={des}
          desColor={useColorModeValue("#5A5A5A", "#C7C7C7")}
        />

        <Flex justify={"space-between"} gap="10px" fontFamily={"Roboto"}>
          <PinInput
            errorBorderColor="red.300"
            focusBorderColor="teal.300"
            size={"lg"}
            variant={"flushed"}
            placeholder="0"
            value={pinInput}
            isInvalid={isVerified === 0 && pinInput !== ""}
            onChange={(value) => {
              setPinInput(value);
              setIsVerified(1);
            }}
            autoFocus
          >
            <PinInputField
              _placeholder={{ color: "#C7C7C7" }}
              fontWeight={700}
              color={useColorModeValue("#5A5A5A", "#EEEEEE")}
              fontFamily={"Roboto"}
              fontSize={"20px"}
            />
            <PinInputField
              _placeholder={{ color: "#C7C7C7" }}
              fontWeight={700}
              color={useColorModeValue("#5A5A5A", "#EEEEEE")}
              fontFamily={"Roboto"}
              fontSize={"20px"}
            />
            <PinInputField
              _placeholder={{ color: "#C7C7C7" }}
              fontWeight={700}
              color={useColorModeValue("#5A5A5A", "#EEEEEE")}
              fontFamily={"Roboto"}
              fontSize={"20px"}
            />
            <PinInputField
              _placeholder={{ color: "#C7C7C7" }}
              fontWeight={700}
              color={useColorModeValue("#5A5A5A", "#EEEEEE")}
              fontFamily={"Roboto"}
              fontSize={"20px"}
            />
            <PinInputField
              _placeholder={{ color: "#C7C7C7" }}
              fontWeight={700}
              color={useColorModeValue("#5A5A5A", "#EEEEEE")}
              fontFamily={"Roboto"}
              fontSize={"20px"}
            />
            <PinInputField
              _placeholder={{ color: "#C7C7C7" }}
              fontWeight={700}
              color={useColorModeValue("#5A5A5A", "#EEEEEE")}
              fontFamily={"Roboto"}
              fontSize={"20px"}
            />
          </PinInput>
        </Flex>
        <Flex justify={"space-between"} mt="20px" align={"center"}>
          {isVerified === 0 && pinInput?.length === 6 && (
            <Text color="red.300">Incorrect verification code.</Text>
          )}
          <Flex
            gap="5px"
            align="center"
            fontSize={"12px"}
            justify={"end"}
            ml="auto"
          >
            <Text color={useColorModeValue("#5A5A5A", "#C7C7C7")}>
              {displayTime === "00:00"
                ? "Didn’t received code?"
                : "Resend within"}
            </Text>
            <Text
              color={useColorModeValue("#00332D", "#00FFE0")}
              fontWeight={700}
              cursor={displayTime === "00:00" ? "pointer" : ""}
              onClick={async () => {
                if (displayTime === "00:00") {
                  try {
                    await sendAuthorizeTransactionQuery.refetch();
                    toast({
                      title: "Otp sent again.",
                      status: "success",
                    });
                    setTimer(60);
                  } catch (error: any) {
                    toast({
                      title: error.message,
                      status: "error",
                    });
                  }
                }
              }}
            >
              {displayTime === "00:00" ? "Send Again" : displayTime}
            </Text>
          </Flex>
        </Flex>
        {/* <PrimaryButton
          text={verifyEmailMutation.isLoading ? <Spinner /> : "Verify"}
          disabled={verifyEmailMutation.isLoading}
          type="submit"
        /> */}
      </CustomModal>

      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        headerText="Two Factor Authentication"
        onSubmit={() => {
          setTwoFaSwitch(true);
          onClose();
        }}
        footerText={"Enable Now"}
        closeText={"Cancel"}
      >
        <Flex
          my={"32px"}
          direction={"column"}
          justify={"center"}
          align={"center"}
          gap={"16px"}
          alignSelf={"stretch"}
        >
          <Image src={modalPicIcon} />
          <Text
            color={paraColor2}
            fontSize={"14px"}
            fontWeight={"400"}
            letterSpacing={"0.056px"}
          >
            Enable two factor authentication.
          </Text>
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isPendingKYC}
        onClose={() => {
          onPendingKYCClose();
        }}
        headerText="KYC Upgrade Pending"
        // noFooter={true}
        footerText="Done"
        noCloseFooter={true}
        onSubmit={() => {
          // navigate("/dashboard/index");
          onPendingKYCClose();
        }}
      >
        <Flex
          // justify={"center"}
          // align={"center"}
          // direction={"column"}
          gap="24px"
          mb="32px"
          mt={"32px"}
        >
          <Text
            textAlign={"left"}
            fontSize={["9px", "11px", "13px", "14px"]}
            fontWeight={"400"}
            lineHeight={"20px"}
            letterSpacing={"0.035px"}
            color={paraColor2}
          >
            Your KYC has been submitted and is pending for approval.
          </Text>
        </Flex>
      </CustomModal>
    </AdminLayout>
  );
};

export default Settings;
