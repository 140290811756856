import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Box, Spinner, useColorModeValue } from "@chakra-ui/react";

import Signup from "./pages/Signup";
import Verification from "./pages/Verification";
import Signin from "./pages/Signin";
import ForgotPassword from "./pages/ForgotPassword";
import AddNewPassword from "./pages/AddNewPassword";
import PasswordUpdated from "./pages/PasswordUpdated";
import Home from "./pages/Home";
import theme from "./theme/theme";
import Deposit from "./pages/Deposit";
import "./App.css";
import Wallet from "./pages/Wallet";
import Withdraw from "./pages/Withdraw";
import ConfirmWithdraw from "./pages/ConfirmWithdraw";
import AddressBook from "./pages/AddressBook";
import DepositSummary from "./pages/DepositSummary";
import ConfirmNairaWithdrawal from "./pages/ConfirmNairaWithdrawal";
import WalletTransactions from "./pages/WalletTransactions";
import AirTime from "./pages/AirTime";
import Internet from "./pages/Internet";
import TvSubscription from "./pages/TvSubscription";
import Electricity from "./pages/Electricity";
import ServicesTransactions from "./pages/ServicesTransactions";
import Settings from "./pages/Settings";
import KYC from "./pages/KYC";
import HelpCenter from "./components/HelpCenter";
import { useQuery } from "@tanstack/react-query";
import KycLevel3 from "./pages/KycLevel3";

function App() {
  const { data: token } = useQuery(["token"], () =>
    localStorage.getItem("token")
  );
  const [address, setAddress] = useState<any>();
  const bgColor = useColorModeValue(theme.colors.light.bg, "#181818");
  const color = useColorModeValue(
    theme.colors.light.text.secondary,
    theme.colors.dark.text.secondary
  );
  document.body.style.backgroundColor = bgColor;
  if (token === undefined) {
    return <Spinner />;
  }
  // useEffect(() => {
  //   if (location?.pathname !== "/help-center") {
  //     const script = document.querySelector(
  //       'script[src="https://desk.zoho.com/portal/api/web/inapp/986193000000351491?orgId=852760906"]'
  //     );
  //     if (script) {
  //       script.remove();
  //     }
  //   }
  // }, [location]);
  return (
    <Box
      bgColor={bgColor}
      transition={"background-color 1s ease"}
      color={color}
      bgSize={"cover"}
      minH={"100vh"}
    >
      <Router>
        {!token ? (
          <Routes>
            <Route path="/auth/signin" element={<Signin />} />
            <Route path="/auth/signup" element={<Signup />} />
            <Route path="/auth/verification" element={<Verification />} />
            <Route path="/auth/forgot-password" element={<ForgotPassword />} />
            <Route path="/auth/add-new-password" element={<AddNewPassword />} />
            <Route
              path="/auth/password-updated"
              element={<PasswordUpdated />}
            />
            <Route
              path="*"
              element={
                token ? (
                  <Navigate to="/dashboard/index" replace />
                ) : (
                  <Navigate to="/auth/signin" replace />
                )
              }
            />
          </Routes>
        ) : (
          <Routes>
            <Route path="/dashboard/*" element={<Home />} />
            <Route path="/dashboard/index" element={<Home />} />
            <Route path="/dashboard/deposit" element={<Deposit />} />
            <Route
              path="/dashboard/deposit/summary"
              element={<DepositSummary />}
            />
            <Route path="/dashboard/wallet" element={<Wallet />} />
            <Route
              path="/dashboard/withdraw"
              element={<Withdraw setAddress={setAddress} address={address} />}
            />
            <Route
              path="/dashboard/confirm-withdraw"
              element={<ConfirmWithdraw />}
            />
            <Route
              path="/dashboard/confirm-naira-withdraw"
              element={<ConfirmNairaWithdrawal />}
            />
            <Route
              path="/dashboard/address-book"
              element={
                <AddressBook setAddress={setAddress} address={address} />
              }
            />
            <Route
              path="/dashboard/transactions"
              element={<WalletTransactions />}
            />
            <Route path="/dashboard/services/airtime" element={<AirTime />} />
            <Route path="/dashboard/services/internet" element={<Internet />} />
            <Route
              path="/dashboard/services/tv-sbscription"
              element={<TvSubscription />}
            />
            <Route
              path="/dashboard/services/electricity-bill"
              element={<Electricity />}
            />
            <Route
              path="/dashboard/services-transactions"
              element={<ServicesTransactions />}
            />
            <Route path="/dashboard/settings" element={<Settings />} />
            <Route path="/dashboard/kyc" element={<KYC />} />
            <Route path="/dashboard/kyc-level3" element={<KycLevel3 />} />
            <Route path="/dashboard/help-center" element={<HelpCenter />} />
            <Route
              path="*"
              element={
                token ? (
                  <Navigate to="/dashboard/index" replace />
                ) : (
                  <Navigate to="/auth/signin" replace />
                )
              }
            />
          </Routes>
        )}
      </Router>
    </Box>
  );
}

export default App;
