import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    body: "'Plus Jakarta Sans', sans-serif",
    heading: "'Plus Jakarta Sans', sans-serif",
  },
  colors: {
    light: {
      primary:'#00ffe0',
      text: {
        primary: "#000000",
        secondary: "#909090",
      },
      border: {
        primary: "#00CCB3",
        secondary: "#EEE",
      },
      accent: {
        primary: "#00332D",
        secondary: "#EEE", // Secondary accent color
      },
      link: {
        primary: "#009986", // Primary accent color
        secondary: "#EEE", // Secondary accent color
      },
      badge : {
        bg: '#00665A',
        text: '#FFC960'
      },
      bg: "white",
      bgSecondary: '#F9F9F9'
    },
    dark: {
      primary:'#00ffe0',
      text: {
        primary: "#FFFFFF",
        secondary: "#C7C7C7",
      },
      border: {
        primary: "#00FFE0",
        secondary: "#333",
      },
      accent: {
        primary: "#00FFE0",
        secondary: "#333",
      },
      link: {
        primary: "#00FFE0",
        secondary: "#333",
      },
      badge : {
        bg: '#FFC960',
        text:'#181818'
      },
      bg: "black",
      bgSecondary: '#1f1f1f'
    },
  },
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
});

export default theme;
