import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import PrimaryButton from "./PrimaryButton";
import TokenCard from "./TokenCard";
import bitcoin from "../assets/bitcoin.svg";
import CustomModal from "./CustomModal";
import UserInfoInput from "./UserInfoInput";
import userLight from "../assets/icons/light/userIcon.svg";
import userDark from "../assets/icons/dark/user.svg";
import bankLight from "../assets/icons/light/bank.svg";
import bankDark from "../assets/icons/dark/bank.svg";
import numberLight from "../assets/icons/light/number.svg";
import numberDark from "../assets/icons/dark/number.svg";
import userActive from "../assets/icons/light/userActive.svg";
import bankActive from "../assets/icons/light/bankActive.svg";
import numberActive from "../assets/icons/light/numberActive.svg";
import verifyIcon from "../assets/verified.svg";
import {
  useAddBankAccount,
  useDeleteBankAccount,
  useGetBankAccounts,
} from "../utils/auth.api";
import BankSelectionCard from "./BankSelectionCard";
import AuthCardHeader from "./AuthCardHeader";
import SelectWithSearch from "./SelectWithSearch";

const BankAccounts = () => {
  const addBankAccountMutation = useAddBankAccount();
  const deleteBankAccountMutation = useDeleteBankAccount();
  const [seletcedAccountNumber, setSelectedAccountNumber] = useState<any>(null);
  const [isDeletedOpen, setIsDeletedOpen] = useState(false);
  const [selectedBank, setSelectedBank] = useState(1);
  const onDeletedClose = () => {
    setIsDeletedOpen(!isDeletedOpen);
  };
  const [isAddOpen, setIsAddOpen] = useState(false);
  const onAddClose = () => {
    setIsAddOpen(!isAddOpen);
  };
  const toast = useToast();
  const [bankForm, setBankForm] = useState({
    bankName: "",
    accountNumber: "",
    accountName: "",
  });
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setBankForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBankChange = (selectedBank: any) => {
    setBankForm({ ...bankForm, bankName: selectedBank });
  };

  const [tokenCardSelected, setTokenCardSelected] = useState("Divid Mark");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedInput, setSelectedInput] = useState("");
  const paraColor = useColorModeValue("#5A5A5A", "#C7C7C7");
  const paraColor2 = useColorModeValue("#909090", "#C7C7C7");
  const { data: banksData, isLoading } = useGetBankAccounts();
  useEffect(() => {
    setSelectedAccountNumber(banksData?.bankDetails?.[0]);
  }, [banksData]);

  return (
    <div>
      <Flex pt={"65px"} direction={"column"} gap={"56px"} flexShrink={0}>
        <Flex direction={"column"} gap={"24px"} alignSelf={"stretch"}>
          <Text
            color={paraColor}
            fontSize={"16px"}
            fontWeight={"700"}
            lineHeight={"22px"}
            letterSpacing={"0.08px"}
          >
            Bank Accounts
          </Text>
          <Text
            color={paraColor2}
            fontSize={"16px"}
            fontWeight={"400"}
            lineHeight={"22px"}
            letterSpacing={"0.08px"}
          >
            Choose a default bank account that you intend to use for
            transactions. Note: You can add maximum 3 bank accounts.
          </Text>
          {banksData?.bankDetails?.map((item: any, idx: number) => (
            <>
              {item.accountNumber && (
                <Box mb="-40px">
                  <BankSelectionCard
                    onClick={() => setSelectedBank(idx + 1)}
                    isActive={selectedBank === idx + 1}
                    bankName={item.bankName}
                    accountNumber={item.accountNumber}
                    accountName={item.accountName}
                  />
                </Box>
              )}
            </>
          ))}
        </Flex>
        <PrimaryButton
          text="Add New Bank Account"
          mt="0px"
          type="submit"
          onClick={() => {
            banksData?.bankDetails?.length === 3 ? onOpen() : onAddClose();
          }}
        />
        <CustomModal
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={async () => {
            try {
              await deleteBankAccountMutation.mutateAsync(
                seletcedAccountNumber?.id
              );
              setIsDeletedOpen(true);
              onClose();
            } catch (error: any) {
              toast({
                title: error.message,
                status: "error",
              });
            }
          }}
          headerText="Delete Bank Account"
          footerText="Delete Account"
          closeText="Cancel"
          isLoading={deleteBankAccountMutation.isLoading}
        >
          <Box py="20px" fontSize={"14px"}>
            <Text color={useColorModeValue("#909090", "#C7C7C7")}>
              You can add only{" "}
              <Text
                color={useColorModeValue("#5A5A5A", "#EEE")}
                display={"inline"}
                fontWeight={700}
              >
                3 Bank Accounts.
              </Text>{" "}
              If you want to add a new one, please select & delete from the
              already existing banks and add a new one.
            </Text>
            <Text mt="54px">Select Account</Text>
            {banksData?.bankDetails?.map((item: any, idx: number) => (
              <BankSelectionCard
                onClick={() => {
                  setSelectedBank(idx + 1);
                  setSelectedAccountNumber(item);
                }}
                isActive={selectedBank === idx + 1}
                bankName={item.bankName}
                accountNumber={item.accountNumber}
                accountName={item.accountName}
              />
            ))}
          </Box>
        </CustomModal>
        <CustomModal
          isOpen={isDeletedOpen}
          onClose={onDeletedClose}
          onSubmit={() => {
            onDeletedClose();
            onAddClose();
          }}
          headerText="Successful"
          footerText="Add new Account"
        >
          <Box py="20px" fontSize={"14px"}>
            <AuthCardHeader
              title="Bank Account Deleted"
              des={`The bank account with account number, “${seletcedAccountNumber?.accountNumber}” has been deleted.`}
              desColor={useColorModeValue("#909090", "#C7C7C7")}
              imgSrc={verifyIcon}
              imageW="56px"
            />
          </Box>
        </CustomModal>
        <CustomModal
          isOpen={isAddOpen}
          onClose={onAddClose}
          onSubmit={async () => {
            try {
              if (
                bankForm?.accountName === "" ||
                bankForm?.accountNumber === "" ||
                bankForm?.bankName === ""
              ) {
                toast({
                  title: "Please fill in all fields",
                  status: "error",
                });
                return;
              }
              await addBankAccountMutation.mutateAsync(bankForm);
              // getBanks();
              setBankForm({
                bankName: "",
                accountNumber: "",
                accountName: "",
              });
              toast({
                title: "Bank account added successfully.",
                status: "success",
              });
              onAddClose();
            } catch (error: any) {
              toast({
                title: error.message,
                status: "error",
              });
            }
          }}
          headerText="Add Bank Account"
          isLoading={addBankAccountMutation.isLoading}
        >
          <Box py="20px" fontSize={"14px"}>
            <Text>
              Enter your account title, bank name and account number for
              withdrawals. You can add a maximum of 3 bank accounts.
            </Text>
            <UserInfoInput
              placeholder="ACCOUNT NAME"
              icon={useColorModeValue(userLight, userDark)}
              activeIcon={useColorModeValue(userActive, userDark)}
              name="accountName"
              value={bankForm.accountName}
              onChange={handleChange}
            />
            {/* <UserInfoInput
            placeholder="Bank Name"
            icon={useColorModeValue(bankLight, bankDark)}
            activeIcon={useColorModeValue(bankActive, bankDark)}
            name="bankName"
            value={bankForm.bankName}
            onChange={handleChange}
          /> */}
            <SelectWithSearch
              icon={useColorModeValue(bankLight, bankDark)}
              activeIcon={useColorModeValue(bankActive, bankDark)}
              onChange={handleBankChange} // Pass the function to handle selected value
            />
            <UserInfoInput
              placeholder="ACCOUNT NUMBER"
              icon={useColorModeValue(numberLight, numberDark)}
              activeIcon={useColorModeValue(numberActive, numberDark)}
              name="accountNumber"
              value={bankForm.accountNumber}
              onChange={handleChange}
            />
          </Box>
        </CustomModal>
      </Flex>
    </div>
  );
};

export default BankAccounts;
