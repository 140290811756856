import React, { useEffect, useRef, useState } from "react";
import AdminLayout from "../layouts/AdminLayout";
import CustomHeader from "../components/CustomHeader";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Heading,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Radio,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import CustomTabs from "../components/CustomTabs";
import PrimaryButton from "../components/PrimaryButton";
import UserInfoInput from "../components/UserInfoInput";
import { useNavigate } from "react-router-dom";
import gloIcon from "../assets/icons/glo.svg";
import airtelIcon from "../assets/icons/airtel.svg";
import mtnIcon from "../assets/icons/mtn.svg";
import nineMobileIcon from "../assets/icons/nineMobile.svg";
import nairaLight from "../assets/icons/light/naira.svg";
import nairaDark from "../assets/icons/dark/naira.svg";
import PhoneInputWithCountry from "../components/PhoneInput";
import RecentPurchase from "../components/RecentPurchase";
import CustomModal from "../components/CustomModal";
import AuthCardHeader from "../components/AuthCardHeader";
import { CustomDetailFlex } from "../components/CustomDeatilFlex";
import verifiedIcon from "../assets/verified.svg";
import SelectCrypto from "../components/SelectCrypto";
import SelectCryptoDropdown from "../components/SelectCryptoDropdown";
import theme from "../theme/theme";
import radioUncheckedLight from "../assets/icons/light/radio-unchecked.svg";
import radioUncheckedDark from "../assets/icons/dark/radio-unchecked.svg";
import radioCheckedLight from "../assets/icons/light/radio-checked.svg";
import radioCheckedDark from "../assets/icons/dark/radio-checked.svg";
import radioLight from "../assets/icons/light/radio-light.svg";
import radioDark from "../assets/icons/dark/radio.svg";
import shopingBagIcon from "../assets/icons/shopping-bag.svg";
import numberLight from "../assets/icons/light/number.svg";
import numberActive from "../assets/icons/light/numberActive.svg";
import numberDark from "../assets/icons/dark/number.svg";
import radionInactiveLight from "../assets/icons/light/radio-inactive.svg";
import radionInactiveDark from "../assets/icons/dark/radio-inactive.svg";
import {
  useGetAllElectricityProvider,
  useGetCompletedElectricityBill,
  useGetProductOfElectricityById,
  usePurchaseElectricityBill,
} from "../utils/service.api";
import { useGetWalletWithBalance } from "../utils/wallet.api";
import failedIcon from "../assets/failedIcon.svg";
import NigeriaFlag from "../assets/icons/nigeria_flag.svg";
import { set } from "date-fns";

const Electricity = () => {
  const { isLoading, data, error } = useGetAllElectricityProvider();
  const menuData = data?.map((item: any, idx: any) => ({
    id: idx + 1,
    symbol: item?.name,
    operatorId: item?.id,
  }));
  const [amount, setAmount] = useState("");
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  const [errorMsg, setErrorMsg] = useState("");

  // let [menuData, setMenuData] : any = useState([]);
  const [menuW, setmenuW] = useState();
  const elementRef: any = useRef(null);
  const purchaseElectricityMutation = usePurchaseElectricityBill();
  const [selectedCoin, setSelectedCoin] = useState(menuData?.[0]);
  const { data: productsData, isLoading: productDataLoading } =
    useGetProductOfElectricityById(selectedCoin?.operatorId);
  const { data: balanceData, isLoading: balanceLoading } =
    useGetWalletWithBalance();
  const productData = productsData?.map((item: any, idx: any) => ({
    id: idx + 1,
    symbol: item?.name,
    productId: item?.id,
    fee: item?.meta?.fee,
  }));

  const [selectedProduct, setSelectedProduct] = useState(selectedCoin?.[0]);
  const [phoneNo, setPhoneNo] = useState("");

  const [selectedBank, setSelectedBank] = useState(1);
  const [selected, setSelected] = useState("NGN");

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuProductOpen, setIsMenuProductOpen] = useState(false);
  const [isActive, setIsActive] = useState("postpaid");
  const toast = useToast();
  const borderColor = useColorModeValue(
    theme.colors.light.border.secondary,
    theme.colors.dark.border.secondary
  );
  const dropIconColor = useColorModeValue("#5A5A5A", "#C7C7C7");
  const selectedTextColor = useColorModeValue("#00332D", "#EEEEEE");
  const paraColor2 = useColorModeValue("#5A5A5A", "#EEE");

  const [isCompleteOpen, setIsCompleteOpen] = useState(false);
  const [token, setToken] = useState("");
  const [meterNo, setMeterNo] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [purchaseResponse, setPurchaseResponse] = useState<any>(null);
  const onCompleteOpen = () => {
    setIsCompleteOpen(true);
  };
  const onCompleteClose = () => {
    setPurchaseResponse(null);
    setIsCompleteOpen(false);
    setMeterNo("");
    setPhoneNo("");
    setSelectedCoin(menuData?.[0]);
    setAmount("");
    setToken("");
    setIsSaved(false);
  };
  const navigate = useNavigate();
  const nairaIcon = useColorModeValue(nairaLight, nairaDark);
  const numberIcon = useColorModeValue(numberLight, numberDark);
  const numberActiveIcon = useColorModeValue(numberActive, numberDark);

  const { data: recentElec, isLoading: recentElecLoading } =
    useGetCompletedElectricityBill(0, 3, isActive, selectedCoin?.operatorId);
  const radioUnCheckedIcon = useColorModeValue(
    radioUncheckedLight,
    radioUncheckedDark
  );
  const radioCheckedIcon = useColorModeValue(
    radioCheckedLight,
    radioCheckedDark
  );

  const inactiveOpacity = useColorModeValue(0.5, 0.1);
  const radio = useColorModeValue(radioLight, radioDark);
  const radioInactive = useColorModeValue("#333333", "#909090");

  const getCurrentDate = () => {
    const now = new Date();
    const options: any = { day: "2-digit", month: "short", year: "numeric" };
    return now.toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    if (elementRef.current) {
      const width = elementRef.current.offsetWidth;
      setmenuW(width);
    }
  }, [menuW, isMenuOpen, isMenuProductOpen]);

  useEffect(() => {
    setSelectedCoin(isLoading ? "Loading..." : menuData?.[0]);
  }, [data]);

  useEffect(() => {
    setSelectedProduct(productDataLoading ? "Loading..." : productData?.[0]);
  }, [selectedCoin]);

  return (
    <AdminLayout>
      <Box marginTop="72px" marginRight={"2%"} mb={"50px"} w={"95%"}>
        <CustomHeader
          para1="Electricity Bill"
          para2="Services /"
          header={"Electricity Bill"}
          amount="38,920.84"
          setSelected={setSelected}
          data={balanceData}
          isLoading={balanceLoading}
        />
        <Box mt={"32px"}>
          <Text
            fontSize={"16px"}
            color={useColorModeValue("#333333", "#C7C7C7")}
            mb="24px"
          >
            Meter Type
          </Text>
          <Flex
            // opacity={isActive === "prepaid" ? 1 : inactiveOpacity}
            color={useColorModeValue(
              isActive === "prepaid" ? "#333333" : "#909090",
              isActive === "prepaid" ? "#EEEEEE" : "#909090"
            )}
            gap={"15px"}
            cursor={"pointer"}
            align="center"
            onClick={() => {
              setIsActive("prepaid");
            }}
          >
            {isActive === "prepaid" ? (
              <Image src={radio} />
            ) : (
              <Box
                height="18px"
                width="18px"
                borderRadius="24px"
                border={`1.5px solid ${radioInactive} `}
              ></Box>
            )}
            {/* <Image src={isActive === "prepaid" ? radio : radioInactive} /> */}
            <Text>Prepaid Meter</Text>
          </Flex>
          <Flex
            // opacity={isActive === "postpaid" ? 1 : inactiveOpacity}
            color={useColorModeValue(
              isActive === "postpaid" ? "#333333" : "#909090",
              isActive === "postpaid" ? "#EEEEEE" : "#909090"
            )}
            gap={"15px"}
            cursor={"pointer"}
            align="center"
            onClick={() => {
              setIsActive("postpaid");
            }}
            mt="16px"
          >
            {isActive === "postpaid" ? (
              <Image src={radio} />
            ) : (
              <Box
                height="18px"
                width="18px"
                borderRadius="24px"
                border={`1.5px solid ${radioInactive} `}
              ></Box>
            )}
            <Text>Postpaid Meter</Text>
          </Flex>
          <Divider my="32px" />
          <Box display={"none"}>
            <Text color={useColorModeValue("#333333", "#C7C7C7")}>
              Service Provider
            </Text>
            <Flex
              direction={"column"}
              justify={"center"}
              align={"center"}
              p="40px 16px"
              border={"1px solid"}
              borderColor={useColorModeValue("#EEE", "#333")}
              mt="24px"
              borderRadius={"12px"}
            >
              <Image mb="24px" src={shopingBagIcon} />
              <Text>No Service Provider Available</Text>
            </Flex>
          </Box>
          <Flex
            direction={{ base: "column", lg: "row" }}
            gap="24px"
            align={{ base: "center", lg: "start" }}
          >
            <Box w={{ base: "100%", lg: "58%" }}>
              <Text
                mt="32px"
                fontWeight={"700"}
                color={useColorModeValue("#5A5A5A", "#C7C7C7")}
              >
                Pay Electricity Bill
              </Text>
              <Text my="24px">
                Select service provider and enter meter number to pay bill
              </Text>
              {isLoading ? (
                <Flex w={"100%"} justify={"center"} align={"center"}>
                  <Spinner />
                </Flex>
              ) : (
                <>
                  <Box ref={elementRef}>
                    <SelectCrypto
                      isMenuOpen={isMenuOpen}
                      setIsMenuOpen={setIsMenuOpen}
                      selectedCoin={selectedCoin}
                      fontWeight={400}
                      dropIconColor={dropIconColor}
                      iconSize="32px"
                      iconBG="transparent"
                    />
                  </Box>
                  {isMenuOpen && (
                    <SelectCryptoDropdown
                      {...{
                        isMenuOpen,
                        setIsMenuOpen,
                        selectedCoin,
                        setSelectedCoin,
                        menuData,
                        menuW,
                        selectedTextColor,
                        h: "245px",
                      }}
                    />
                  )}
                  {productsData?.length > 0 && (
                    <>
                      <Box ref={elementRef} mt={"24px"}>
                        <SelectCrypto
                          isMenuOpen={isMenuProductOpen}
                          setIsMenuOpen={setIsMenuProductOpen}
                          selectedCoin={selectedProduct}
                          fontWeight={400}
                          dropIconColor={dropIconColor}
                          iconSize="32px"
                          iconBG="transparent"
                        />
                      </Box>
                      {isMenuProductOpen && (
                        <SelectCryptoDropdown
                          {...{
                            isMenuOpen: isMenuProductOpen,
                            setIsMenuOpen: setIsMenuProductOpen,
                            selectedCoin: selectedProduct,
                            setSelectedCoin: setSelectedProduct,
                            menuData: productData,
                            menuW,
                            selectedTextColor,
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              )}
              <Box mt="24px">
                <UserInfoInput
                  type="number"
                  placeholder="meter no"
                  icon={numberIcon}
                  activeIcon={numberActiveIcon}
                  value={meterNo}
                  onChange={(e: any) => setMeterNo(e.target.value)}
                />
              </Box>
              <Box mt="24px">
                <UserInfoInput
                  type="number"
                  placeholder="amount"
                  icon={numberIcon}
                  activeIcon={numberActiveIcon}
                  value={amount}
                  onChange={(e: any) => setAmount(e.target.value)}
                />
              </Box>
              <Box mt="24px">
                <UserInfoInput
                  type="tel"
                  activeIcon={NigeriaFlag}
                  placeholder="Enter Phone Number"
                  icon={NigeriaFlag}
                  name="phoneNo"
                  value={phoneNo}
                  onChange={(e: any) => {
                    const pattern = /^\d+$/;
                    if (
                      pattern?.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      setPhoneNo(e.target.value);
                    }
                  }}
                />
                {phoneNo && phoneNo?.length > 11 && (
                  <Text fontSize={"12"} mt="-20px" color="red">
                    Incorrect phone number.
                  </Text>
                )}
              </Box>
              <Checkbox
                colorScheme="teal"
                onChange={(e: any) => {
                  setIsSaved(e.target.checked);
                }}
                isChecked={isSaved}
              >
                Save Address Book
              </Checkbox>
              <PrimaryButton
                mt="0px"
                text={
                  purchaseElectricityMutation?.isLoading
                    ? "Processing..."
                    : "Pay Bill"
                }
                disabled={
                  isLoading ||
                  purchaseElectricityMutation?.isLoading ||
                  !selectedCoin?.operatorId ||
                  !selectedProduct?.productId ||
                  !meterNo ||
                  phoneNo?.length !== 11
                }
                // isLoading={purchaseElectricityMutation?.isLoading}
                onClick={async () => {
                  if (
                    !selectedCoin?.operatorId ||
                    !selectedProduct?.productId ||
                    !meterNo
                  ) {
                    toast({
                      title:
                        "Please select service provider and product and enter meter Number",
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                    });
                    return;
                  }
                  const formData = {
                    operatorId: selectedCoin?.operatorId,
                    productId: selectedProduct?.productId,
                    provider: selectedCoin?.symbol,
                    accountId: "",
                    amount: amount,
                    meterType: isActive,
                    meterNumber: meterNo,
                    phone: phoneNo,
                    addressBook: isSaved ? "true" : "",
                  };
                  await purchaseElectricityMutation
                    ?.mutateAsync(formData)
                    .then((result) => {
                      setToken(result?.token);
                      setPurchaseResponse(result?.dataFromApi);
                      onCompleteOpen();
                    })
                    .catch((error) => {
                      onErrorOpen();
                      setErrorMsg(error?.message);
                    });
                }}
              />
            </Box>
            {recentElecLoading ? (
              <Spinner />
            ) : (
              <RecentPurchase
                data={recentElec}
                // from="electricity"
                empty={
                  recentElec?.length === 0 || recentElec === undefined
                    ? true
                    : false
                }
                onSelect={(item: any) => {
                  setMeterNo(item?.meterNumber || "");
                  setIsActive(item?.meterType || "");
                  setPhoneNo(item?.phoneNumber || "");
                }}
                type="Electricity"
              />
            )}
          </Flex>
        </Box>
      </Box>
      <CustomModal
        isOpen={isCompleteOpen}
        onClose={onCompleteClose}
        headerText="Electricity Bill Paid"
        footerText="Continue Purchasing"
        onSubmit={onCompleteClose}
      >
        <Box pt="20px" mb="-30px">
          <AuthCardHeader
            title={"Bill Paid"}
            imgSrc={verifiedIcon}
            imageW="56px"
            des="Transaction Successful"
          />
        </Box>
        <Divider variant={"dashed"} />
        <CustomDetailFlex title="Date" value={getCurrentDate()} />
        <CustomDetailFlex
          title="Electricity"
          value={purchaseResponse?.electricity || ""}
        />
        <CustomDetailFlex
          title="Meter Number"
          value={purchaseResponse?.meter_number || ""}
        />
        <CustomDetailFlex
          title="Token"
          value={purchaseResponse?.token || ""}
          copy={true}
        />
        <CustomDetailFlex title="Phone" value={purchaseResponse?.phone} />
        <CustomDetailFlex
          title="Amount"
          value={`NGN${Number(purchaseResponse?.amount || 0)?.toLocaleString(
            undefined,
            {
              maximumFractionDigits: 2,
            }
          )}`}
        />
        <CustomDetailFlex
          title="Order ID"
          value={purchaseResponse?.order_id || ""}
        />
      </CustomModal>
      <CustomModal
        isOpen={isErrorOpen}
        onClose={() => {
          onErrorClose();
          // navigate("/dashboard/index");
        }}
        noFooter={true}
        onSubmit={() => {
          // navigate("/dashboard/index");
          onErrorClose();
        }}
      >
        <Flex
          justify={"center"}
          align={"center"}
          direction={"column"}
          gap="24px"
          mb="32px"
          mt={"32px"}
        >
          <Image src={failedIcon} w={"56px"} h={"56px"} />
          <Heading
            fontFamily={"Audiowide"}
            fontSize={["15px", "18px", "21px", "24px"]}
            fontWeight={"400"}
          >
            Transaction Failed!
          </Heading>
          <Text
            textAlign={"center"}
            fontSize={["9px", "11px", "13px", "14px"]}
            fontWeight={"400"}
            lineHeight={"20px"}
            letterSpacing={"0.035px"}
            color={paraColor2}
          >
            {errorMsg}
          </Text>
        </Flex>
      </CustomModal>
    </AdminLayout>
  );
};

export default Electricity;
