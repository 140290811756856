import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import bgPic from "../assets/headerBg.svg";
import naira from "../assets/NairaSymbol.svg";
import nairaDark from "../assets/nairaSettingsDark.svg";
import copy from "../assets/copy.svg";
import copyDark from "../assets/copyDark.svg";
import telegram from "../assets/telegram.svg";
import whatsapp from "../assets/whatsapp.svg";
import facebook from "../assets/facebook.svg";
import linkedln from "../assets/linkedln.svg";
import { useClaimRewards } from "../utils/wallet.api";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  WhatsappShareButton,
} from "react-share";

const ReferralCode = ({ data, isLoading }: any) => {
  const toast = useToast();
  const claimRewardsMutation = useClaimRewards();
  const bgBtn = useColorModeValue("#FFF", "#00332D");
  const bgColor = useColorModeValue("#F5F5F5", "#121B18");
  const bgColor2 = useColorModeValue("#F9F9F9", "");
  const btnText = useColorModeValue("#00332D", "#00FFE0");
  const paraColor = useColorModeValue("#5A5A5A", "#EEE");
  const paraColor3 = useColorModeValue("#5A5A5A", "#C7C7C7");
  const paraColor4 = useColorModeValue("#333", "#C7C7C7");
  const borderColor = useColorModeValue("", "#333");
  const nairaIcon = useColorModeValue(naira, nairaDark);
  const copyIcon = useColorModeValue(copy, copyDark);

  const appUrl = window?.location?.origin;

  const shareUrl = `${appUrl}/auth/signup?referralCode=${data?.referralCode}`;
  return (
    <div>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"32px"}
        alignSelf={"stretch"}
      >
        <Flex
          w={"100%"}
          p={"32px 24px"}
          align={"start"}
          direction={"column"}
          alignSelf={"stretch"}
          gap={["12px", "12px", "12px", "24px"]}
          borderRadius={"16px"}
          background={`url(${bgPic}) no-repeat, linear-gradient(91deg,#00DBFF 0%, #00FFE0 47.92%)`}
          backgroundSize={"cover"}
        >
          <Text
            color={"#00665A"}
            fontSize={"14px"}
            fontWeight={"400"}
            letterSpacing={"0.056px"}
          >
            Total Assets
          </Text>
          <Flex
            justify={"space-between"}
            align={["start", "start", "start", "center"]}
            w="100%"
            gap={["8px", "8px", "8px", "0px"]}
            direction={["column", "column", "column", "row"]}
          >
            <Heading
              display={"flex"}
              gap={"8px"}
              alignItems={"center"}
              color={"#00332D"}
              fontSize={["22px", "26px", "30px", "34px"]}
              fontWeight={"700"}
              letterSpacing={"0.085px"}
            >
              <Image
                src={nairaIcon}
                w={["22px", "24px", "26px", "28px"]}
                h={["24px", "26px", "29px", "32px"]}
              />{" "}
              {data?.claimAble}
            </Heading>
            <Button
              display={"flex"}
              padding={"16px 24px"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"10px"}
              alignSelf={"stretch"}
              borderRadius={"12px"}
              bg={bgBtn}
              fontSize={"16px"}
              color={btnText}
              fontWeight={"600"}
              letterSpacing={"0.32px"}
              textTransform={"capitalize"}
              _hover={{ bg: bgBtn }}
              disabled={claimRewardsMutation.isLoading}
              onClick={async () => {
                try {
                  if (data?.claimAble <= 0) {
                    toast({
                      title: "No Reward to claim.",
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                    });
                    return;
                  }
                  await claimRewardsMutation.mutateAsync();
                  toast({
                    title: "Rewards Claimed Successfully",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  });
                } catch (error: any) {
                  toast({
                    title: error.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                  });
                }
              }}
            >
              {claimRewardsMutation?.isLoading ? <Spinner /> : "Claim Reward"}
            </Button>
          </Flex>
          <Text
            color={"#00665A"}
            fontSize={"10px"}
            fontWeight={"400"}
            letterSpacing={"0.056px"}
          >
            <strong>Note:</strong> <br /> Clicking "Claim Reward" adds the
            amount to your naira balance, available for withdrawal at any time
          </Text>
        </Flex>
        <Flex direction={"column"} gap={"24px"} alignSelf={"stretch"}>
          <Flex
            justify={"space-between"}
            align={"center"}
            alignSelf={"stretch"}
          >
            <Text
              color={paraColor}
              fontSize={"16px"}
              fontWeight={"700"}
              lineHeight={"22px"}
              letterSpacing={"0.08px"}
            >
              Personal info
            </Text>
          </Flex>
          <Text
            color={paraColor3}
            fontSize={["11px", "12px", "13px", "14px"]}
            fontWeight={"400"}
            lineHeight={"20px"}
            letterSpacing={"0.035px"}
          >
            Refer a friend and earn{" "}
            <span style={{ fontWeight: "700" }}>
              {" "}
              {Number(data?.referralPrice || 0)?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              NGN{" "}
            </span>{" "}
            on each transaction through the referral code.
          </Text>
          <Flex
            h="56px"
            p={"16px"}
            justify={"space-between"}
            align={"center"}
            alignSelf={"stretch"}
            borderRadius={"12px"}
            bg={bgColor}
          >
            <Text
              color={paraColor4}
              fontSize={"16px"}
              fontWeight={"400"}
              lineHeight={"22px"}
              letterSpacing={"0.08px"}
            >
              {data?.referralCode}
            </Text>
            <Image
              src={copyIcon}
              alt="copy"
              cursor={"pointer"}
              onClick={() => {
                navigator.clipboard.writeText(data?.referralCode);
                toast({
                  title: "Referral Code copied to clipboard",
                  status: "success",
                  duration: 3000,
                  isClosable: true,
                });
              }}
            />
          </Flex>
          <Divider />
          <Flex
            direction={"column"}
            align={"flex-start"}
            gap={"24px"}
            flex={"1 0 0"}
          >
            <Text
              color={paraColor}
              fontSize={"16px"}
              fontWeight={"700"}
              lineHeight={"22px"}
              letterSpacing={"0.08px"}
            >
              Share, Invite & Earn
            </Text>
            <SimpleGrid
              spacing={"16px"}
              flex={"1 0 0"}
              w={"100%"}
              columns={[1, 2, 3, 4]}
            >
              <TelegramShareButton url={shareUrl}>
                <SocialMediaBox
                  icon={telegram}
                  text="Telegram"
                  textColor={paraColor3}
                  bgColor={bgColor2}
                  borderColor={borderColor}
                />
              </TelegramShareButton>
              <WhatsappShareButton url={shareUrl}>
                <SocialMediaBox
                  icon={whatsapp}
                  text="Whatsapp"
                  textColor={paraColor3}
                  bgColor={bgColor2}
                  borderColor={borderColor}
                />
              </WhatsappShareButton>
              <FacebookShareButton url={shareUrl}>
                <SocialMediaBox
                  icon={facebook}
                  text="Facebook"
                  textColor={paraColor3}
                  bgColor={bgColor2}
                  borderColor={borderColor}
                />
              </FacebookShareButton>

              <LinkedinShareButton url={shareUrl}>
                <SocialMediaBox
                  icon={linkedln}
                  text="Linkedln"
                  textColor={paraColor3}
                  bgColor={bgColor2}
                  borderColor={borderColor}
                />
              </LinkedinShareButton>
            </SimpleGrid>
          </Flex>
        </Flex>
      </Box>
    </div>
  );
};

export default ReferralCode;

const SocialMediaBox = ({
  icon,
  text,
  textColor,
  bgColor,
  borderColor,
}: any) => {
  return (
    <Flex
      p={"16px 8px"}
      h={"200px"}
      direction={"column"}
      justify={"center"}
      align={"center"}
      gap={"24px"}
      flex={"1 0 0"}
      borderRadius={"12px"}
      bg={bgColor}
      border={borderColor ? `1px solid ${borderColor}` : ""}
    >
      <Image src={icon} alt="social" w={"40px"} h={"40px"} />
      <Text
        color={textColor}
        fontSize={"12px"}
        fontWeight={"400"}
        letterSpacing={"0.048px"}
      >
        {text}
      </Text>
    </Flex>
  );
};
