import {
  Avatar,
  Box,
  Button,
  Flex,
  Image,
  Input,
  PinInput,
  PinInputField,
  Slider,
  SliderFilledTrack,
  SliderTrack,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import profileBg from "../assets/profileBg.svg";
import profileBgDark from "../assets/profileBgDark.svg";
import profileImg from "../assets/Profile-Placeholder.jpg";
import crown from "../assets/crown.svg";
import crownDark from "../assets/crownDark.svg";
import CustomModal from "./CustomModal";
import copyIcon from "../assets/copyIcon.svg";
import copyIconDark from "../assets/copyIconDark.svg";
import theme from "../theme/theme";
import {
  useDeleteUser,
  useDeleteUserOtp,
  useGetLoggedInUser,
} from "../utils/auth.api";

const MyProfile = ({ setSelected, data, isLoading }: any) => {
  const { data: user } = useGetLoggedInUser();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isVerify, setIsVerify] = useState(false);
  const [pinInput, setPinInput] = useState("");
  const [isVerified, setIsVerified] = useState(3);

  const color = useColorModeValue(
    theme.colors.light.text.primary,
    theme.colors.dark.text.primary
  );

  const timerColor = useColorModeValue(
    theme.colors.light.text.primary,
    "#00FFE0"
  );

  const headColor = useColorModeValue("#00332D", "#C7C7C7");
  const paraColor1 = useColorModeValue("#5A5A5A", "#C7C7C7");
  const paraColor2 = useColorModeValue("#000", "#EEE");
  const paraColor3 = useColorModeValue("#333", "#C7C7C7");
  const paraColor4 = useColorModeValue("#00332D", "#00FFE0");
  const paraColor5 = useColorModeValue("#FFF", "#FF5959");
  const paraColor6 = useColorModeValue("#C7C7C7", "#909090");
  const modalText = useColorModeValue("#909090", "#C7C7C7");

  const borderColor2 = useColorModeValue("", "#FF5959");
  const borderColor3 = useColorModeValue("#C7C7C7", "#333");

  const bgColor = useColorModeValue("#F9F9F9", "rgba(249,249,249,0.03)");
  const bgColor2 = useColorModeValue("#00332D", "#00FFE0");
  const bgColor3 = useColorModeValue(" #F00C0C", "rgba(240,12,12,0.10)");
  const bgColor4 = useColorModeValue("#EEE", "");
  const btnBg = useColorModeValue("#F00C0C", "rgba(240, 12, 12, 0.10);");
  const btnText = useColorModeValue("#FFF", "#FF5959");
  const btnBorderColor = useColorModeValue("transparent", "#FF5959");
  const borderColor = useColorModeValue("#EEE", "#333");

  const profileBgImg = useColorModeValue(profileBg, profileBgDark);
  const crownIcon = useColorModeValue(crown, crownDark);
  const copyIco = useColorModeValue(copyIcon, copyIconDark);

  const deleteUserOtpQuery = useDeleteUserOtp();
  const [deleteUserOtpLoading, setDeleteUserOtpLoading] = useState(false);
  const deleteUSerMutation = useDeleteUser();
  const [sliderValue, setSliderValue] = useState(50);

  const [timer, setTimer] = useState(60);
  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;
  let displayTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
  useEffect(() => {
    const countdown = setInterval(() => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
      } else {
        clearInterval(countdown);
      }
    }, 1000);
    return () => clearInterval(countdown);
  }, [timer]);

  useEffect(() => {
    if (data?.profileCompeletedPercentage) {
      setSliderValue(data?.profileCompeletedPercentage?.replace("%", ""));
    }
  }, [data]);

  console.log("data we get", data);
  return (
    <div>
      <Flex
        direction={"column"}
        alignItems={"flex-start"}
        gap={"24px"}
        alignSelf={"stretch"}
        mt={"65px"}
      >
        <Text
          color={paraColor1}
          fontSize={"16px"}
          lineHeight={"22px"}
          fontWeight={"700"}
          letterSpacing={"0.08px"}
        >
          My Profile
        </Text>
        <Flex direction={"column"} w={"100%"}>
          <Box
            h={"290px"}
            borderRadius={"18px 18px 0px 0px"}
            background={`url(${profileBgImg}), lightgray 50% / cover no-repeat`}
            backgroundSize={"cover"}
            backgroundRepeat={"no-repeat"}
            border={"none"}
            // background={`linear-gradient(0deg, rgba(0,0,0,0.82) 0%, rgba(0,0,0,0.82) 100%), url(${profileBgImg}), lightgray 50% / cover no-repeat`}
          ></Box>
          <Box
            mt={"-90px"}
            p={"0px 24px 40px 24px"}
            display={"flex"}
            flexDirection={"column"}
            gap={"56px"}
            alignSelf={"stretch"}
            borderRadius={"0px 0px 16px 16px"}
            border={`1px solid ${borderColor}`}
            borderTop={"none"}
          >
            <Flex direction={"column"} align={"center"}>
              <Avatar
                src={data?.image || profileImg}
                mb={"16px"}
                w={"160px"}
                h={"160px"}
              />
              <Text
                mb={"16px"}
                color={paraColor2}
                fontSize={"20px"}
                fontWeight={"700"}
                letterSpacing={"0.03px"}
              >
                {isLoading ? <Spinner /> : data?.fullname}
              </Text>
              <Flex align={"center"} justify={"center"} gap={"5px"}>
                <Text
                  mb={"18px"}
                  color={paraColor3}
                  fontSize={"16px"}
                  fontWeight={"400"}
                  lineHeight={"22px"}
                  letterSpacing={"0.08px"}
                >
                  {isLoading ? (
                    <Spinner />
                  ) : data?.uniqueId ? (
                    data?.uniqueId
                  ) : (
                    "@dummyUser"
                  )}
                </Text>
                <Image
                  mb={"18px"}
                  src={copyIco}
                  cursor={"pointer"}
                  onClick={() => {
                    navigator.clipboard.writeText(data?.uniqueId);
                    toast({
                      title: "Copied to clipboard",
                      status: "success",
                      position: "top-right",
                    });
                  }}
                />
              </Flex>

              <Flex
                // width={"72px"}
                height={"24px"}
                p={"4px 8px"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={"5px"}
                borderRadius={"99px"}
                bg={"#FFC960"}
              >
                <Image src={crownIcon} />
                <Text
                  display={"flex"}
                  alignItems={"center"}
                  fontWeight={"600"}
                  textColor={"#000"}
                  fontSize={"10px"}
                  lineHeight={"14px"}
                >
                  Level {isLoading ? <Spinner /> : data?.kycTier}
                </Text>
              </Flex>

              <Flex
                flexDirection={"column"}
                justify={"center"}
                align={"center"}
                w={["100%", "100%", "60%", "60%"]}
                mt={"12px"}
              >
                <Slider
                  aria-label="slider-ex-1"
                  defaultValue={sliderValue}
                  value={sliderValue}
                  isReadOnly
                >
                  <SliderTrack cursor={"default"} bg={"#EEE"}>
                    <SliderFilledTrack bg={bgColor2} />
                  </SliderTrack>
                </Slider>
                {/* <Flex
                  w={"100%"}
                  align={"center"}
                  justify={"space-between"}
                  color={paraColor4}
                  fontSize={["11px", "11px", "14px", "14px"]}
                  lineHeight={"14px"}
                  letterSpacing={"0.21px"}
                  mt={"16px"}
                >
                  <Text
                    display={"flex"}
                    alignItems={"center"}
                    gap={"8px"}
                    fontWeight={"400"}
                  >
                    <Image src={crownIcon} />
                    Level {isLoading ? <Spinner /> : data?.kycTier}
                  </Text>
                  <Text
                    fontWeight={"500"}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    Completed:{" "}
                    <Text fontWeight={"700"}>{`${sliderValue}%`}</Text>
                  </Text>
                </Flex> */}
              </Flex>
              <Flex
                padding={"16px"}
                direction={["column", "column", "column", "row"]}
                justify={"center"}
                align={"center"}
                gap={"24px"}
                alignSelf={"stretch"}
                color={paraColor3}
                fontSize={["13px", "13px", "14px", "16px"]}
                lineHeight={"22px"}
                fontWeight={"400"}
                letterSpacing={"0.024px"}
                borderRadius={"12px"}
                bg={bgColor}
                mt={"16px"}
              >
                <Text
                  borderRight={["", "", "", "1px solid #C7C7C7"]}
                  paddingRight={["2px", "2px", "2px", "24px"]}
                >
                  {isLoading ? <Spinner /> : data?.email}
                </Text>

                <Text>{isLoading ? <Spinner /> : data?.phone}</Text>
              </Flex>
            </Flex>
          </Box>
        </Flex>
        <Flex
          direction={["column", "column", "row", "row"]}
          gap={"16px"}
          alignSelf={"stretch"}
          fontSize={"16px"}
          fontWeight={"600"}
          letterSpacing={"0.32px"}
          textTransform={"capitalize"}
        >
          <Button
            display={"flex"}
            h={"56px"}
            padding={"16px 32px"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"10px"}
            flex={"1 0 0"}
            border={`1px solid ${borderColor2}`}
            borderRadius={"12px"}
            color={paraColor5}
            bg={bgColor3}
            _hover={{ bg: bgColor3 }}
            _active={{ bg: bgColor3 }}
            onClick={() => {
              onOpen();
            }}
            isDisabled={isLoading}
          >
            Delete Account
          </Button>
          <Button
            color={headColor}
            display={"flex"}
            h={"56px"}
            padding={"16px 32px"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"10px"}
            flex={"1 0 0"}
            borderRadius={"12px"}
            bg={bgColor4}
            border={`1px solid ${borderColor3}`}
            isDisabled={isLoading}
            onClick={() => setSelected("EditProfile")}
          >
            Edit Profile
          </Button>
        </Flex>
      </Flex>
      <CustomModal
        isOpen={isOpen}
        onClose={() => {
          if (isVerify) {
            setIsVerify(false);
            onClose();
          } else {
            onClose();
          }
        }}
        headerText={isVerify ? "Confirm Deleteion" : "Delete Account"}
        onSubmit={async () => {
          if (isVerify) {
            try {
              await deleteUSerMutation.mutateAsync({
                email: user?.email,
                otp: pinInput,
              });
              toast({
                title: "User deleted.",
                status: "success",
              });
              onClose();
              setIsVerify(false);
              localStorage.removeItem("token");
              window.location.reload();
            } catch (error: any) {
              toast({
                title: error.message,
                status: "error",
              });
            }
          } else {
            setDeleteUserOtpLoading(true);
            setTimer(60);
            await deleteUserOtpQuery.refetch();
            setDeleteUserOtpLoading(false);
            setIsVerify(true);
          }
        }}
        footerText={isVerify ? "Verify" : "Yes, Delete Account"}
        btnDirection={true}
        closeText="Cancel"
        btnBgColor={btnBg}
        btnColor={btnText}
        btnBorderColor={btnBorderColor}
        isLoading={deleteUserOtpLoading}
      >
        {isVerify ? (
          <>
            <Text
              my={"32px"}
              color={modalText}
              fontSize={"16px"}
              fontWeight={"400"}
              lineHeight={"22px"}
              letterSpacing={"0.08px"}
              textAlign={"center"}
            >
              Verification code has been sent to{" "}
              {user && (
                <span style={{ fontWeight: "700" }}>{`${user?.email?.slice(
                  0,
                  4
                )}***${user?.email?.slice(-4)}`}</span>
              )}
              . Verify and delete your account permanently.
            </Text>
            <Flex
              justify={"space-between"}
              w={"100%"}
              alignSelf={"stretch"}
              gap={"12px"}
              color={paraColor1}
              fontSize={"20px"}
              fontFamily={"Roboto"}
              fontWeight={"700"}
              lineHeight={"20px"}
              letterSpacing={"0.05px"}
            >
              <PinInput
                errorBorderColor="red.300"
                focusBorderColor="#33FFE6"
                // size={"lg"}
                variant={"flushed"}
                placeholder="0"
                value={pinInput}
                // isInvalid={isVerified === 0 && pinInput !== ""}
                otp={true}
                onChange={(value) => {
                  setPinInput(value);
                }}
                autoFocus
              >
                <PinInputField
                  onChange={() => setPinInput("")}
                  w={"100%"}
                  _placeholder={{ color: paraColor6 }}
                />
                <PinInputField
                  w={"100%"}
                  _placeholder={{ color: paraColor6 }}
                />
                <PinInputField
                  w={"100%"}
                  _placeholder={{ color: paraColor6 }}
                />
                <PinInputField
                  w={"100%"}
                  _placeholder={{ color: paraColor6 }}
                />
                <PinInputField
                  w={"100%"}
                  _placeholder={{ color: paraColor6 }}
                />
                <PinInputField
                  w={"100%"}
                  _placeholder={{ color: paraColor6 }}
                />
              </PinInput>
            </Flex>
            <Flex
              justify={"space-between"}
              mt="20px"
              mb={"32px"}
              align={"center"}
            >
              {isVerified === 0 && pinInput !== "" && (
                <Text color="red.300">Incorrect verification code.</Text>
              )}
              <Flex
                gap="5px"
                align="center"
                fontSize={"12px"}
                justify={"end"}
                ml="auto"
              >
                <Text>
                  {displayTime === "00:00"
                    ? "Didn’t received code?"
                    : "Resend within"}
                </Text>
                <Text
                  color={timerColor}
                  fontWeight={700}
                  cursor={displayTime === "00:00" ? "pointer" : "auto"}
                  onClick={async () => {
                    if (displayTime === "00:00") {
                      setDeleteUserOtpLoading(true);
                      setTimer(60);
                      await deleteUserOtpQuery.refetch();
                      setDeleteUserOtpLoading(false);
                    }
                  }}
                >
                  {displayTime === "00:00" ? "Send Again" : displayTime}
                </Text>
              </Flex>
            </Flex>
          </>
        ) : (
          <Text
            my={"32px"}
            textAlign={"left"}
            fontSize={["9px", "11px", "13px", "14px"]}
            fontWeight={"400"}
            lineHeight={"20px"}
            letterSpacing={"0.035px"}
            color={modalText}
            alignSelf={"stretch"}
          >
            Are you sure you want to delete your account permanently? You will
            no longer be able to use your account. Make sure you have no assets
            before deletion. Otherwise, you will lose your assets.
          </Text>
        )}
      </CustomModal>
    </div>
  );
};

export default MyProfile;
