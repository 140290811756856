import {
  Box,
  Divider,
  Flex,
  Image,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import copyIcon from "../assets/icons/copyIcon.svg";
import theme from "../theme/theme";
import { useGetLoggedInUser } from "../utils/auth.api";
import { useGetAccountdetails } from "../utils/service.api";

const BankDetailsCard = () => {
  const reference = localStorage.getItem("referenceCode");
  const { data: user } = useGetLoggedInUser();
  const { data: payremitBankDetails, isLoading } = useGetAccountdetails();
  const toast = useToast();
  const color = useColorModeValue(
    theme.colors.light.text.primary,
    theme.colors.dark.text.primary
  );
  const darkerColor = useColorModeValue("#5A5A5A", "#C7C7C7");

  return (
    <Box
      w={{ base: "100%", lg: "40%" }}
      p={["10px", "24px"]}
      borderRadius={"12px"}
      border="1px solid"
      borderColor={useColorModeValue("transparent", "#333")}
      bgColor={useColorModeValue("#f9f9f9", "transparent")}
    >
      <Text fontWeight={700} color={darkerColor} mb="48px">
        {payremitBankDetails?.name}
      </Text>
      <Text>Reference</Text>
      {isLoading ? (
        <Spinner />
      ) : (
        <Flex align={"center"} justify={"space-between"}>
          <Text color={color} fontSize={"18px"}>
            {reference}
          </Text>
          <Flex
            bgColor={"#00332D"}
            boxSize={"28px"}
            align={"center"}
            justify={"center"}
            cursor={"pointer"}
            borderRadius={"8px"}
            onClick={() => {
              navigator.clipboard.writeText(reference || "");
              toast({
                title: "Copied to clipboard.",
                status: "success",
              });
            }}
          >
            <Image src={copyIcon} />
          </Flex>
        </Flex>
      )}
      <Divider my="20px" />
      <Text>Bank</Text>
      {isLoading ? (
        <Spinner />
      ) : (
        <Flex align={"center"} justify={"space-between"}>
          <Text color={color} fontSize={"18px"}>
            {payremitBankDetails?.bank_name}
          </Text>
          <Flex
            bgColor={"#00332D"}
            boxSize={"28px"}
            align={"center"}
            justify={"center"}
            cursor={"pointer"}
            borderRadius={"8px"}
            onClick={() => {
              navigator.clipboard.writeText(payremitBankDetails?.bank_name);
              toast({
                title: "Copied to clipboard.",
                status: "success",
              });
            }}
          >
            <Image src={copyIcon} />
          </Flex>
        </Flex>
      )}
      <Divider my="20px" />
      <Text>Account Number</Text>
      {isLoading ? (
        <Spinner />
      ) : (
        <Flex align={"center"} justify={"space-between"}>
          <Text color={color} fontSize={"18px"}>
            {payremitBankDetails?.account_number}
          </Text>
          <Flex
            bgColor={"#00332D"}
            boxSize={"28px"}
            align={"center"}
            justify={"center"}
            cursor={"pointer"}
            borderRadius={"8px"}
            onClick={() => {
              navigator.clipboard.writeText(
                payremitBankDetails?.account_number
              );
              toast({
                title: "Copied to clipboard.",
                status: "success",
              });
            }}
          >
            <Image src={copyIcon} />
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default BankDetailsCard;
