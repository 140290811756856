import React, { useState } from "react";
import Select from "react-select";
import { Box, Image, useColorModeValue } from "@chakra-ui/react";
import theme from "../theme/theme";
import bankActive from "../assets/icons/light/bankActive.svg";
import bankDark from "../assets/icons/dark/bank.svg";
import { useGetListOfNigerianBank } from "../utils/service.api";

interface SelectWithSearchProps {
  activeIcon?: string;
  icon?: string;
  onChange?: (value: string | null) => void; // Add onChange prop
  initialValue?: string | null;
}

const SelectWithSearch: React.FC<SelectWithSearchProps> = ({
  activeIcon,
  icon,
  onChange,
  initialValue,
}) => {
  const { data } = useGetListOfNigerianBank();
  const [isFocused, setIsFocused] = useState(false);
  const iconOpacity = useColorModeValue(1, isFocused ? 1 : 0.8);
  const bgColor = useColorModeValue("#FFF", "#121B18");
  const borderColor3 = useColorModeValue(
    theme.colors.light.border.primary,
    theme.colors.dark.border.primary
  );
  const borderColor = useColorModeValue(
    theme.colors.light.border.primary,
    theme.colors.dark.border.primary
  );
  const borderColorSecondary = useColorModeValue(
    theme.colors.light.border.secondary,
    theme.colors.dark.border.secondary
  );
  const placehold = useColorModeValue(
    isFocused ? "#5A5A5A" : "#C7C7C7",
    "#C7C7C7"
  );
  const textColor = useColorModeValue("#000", "#FFF");
  const bgColor1 = useColorModeValue("#FFF", "#121B18");
  const bColor = useColorModeValue("#EEE", "#5A5A5A");
  const [selectedBank, setSelectedBank] = useState(initialValue || null);

  const bankOptions = data?.map((dat: any) => ({
    value: dat,
    label: dat,
  }));

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      boxShadow: isFocused ? "none" : provided.boxShadow,
      border: "none",
      backgroundColor: bgColor,
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: "none", // Hide the indicator separator
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: placehold, // Change placeholder color
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: textColor, // Change selected value color
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: bgColor, // Change dropdown background color
      width: initialValue ? "100%" : "110%",
      marginLeft: initialValue ? "0px" : "-42px",
      marginTop: "15px",
      border: `1px solid ${bColor}`,
    }),
    input: (provided: any) => ({
      ...provided,
      color: textColor, // Change the color of the search text
    }),
    menuList: (provided: any) => ({
      ...provided,
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#6B7280", // Customize scrollbar thumb color
        borderRadius: "4px",
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? borderColorSecondary // Change background color on focus
        : "none"
        ? "none"
        : "none",
      color: state.isSelected ? "none" : provided.color, // Change text color when selected
      cursor: "pointer", // Add pointer cursor on menu items
      "&:hover": {
        backgroundColor: "borderColorSecondary", // Change background color on hover
        color: "none", // Change text color on hover
      },
      "&:selected": {
        backgroundColor: "none",
      },
    }),
  };

  const handleChange = (option: any) => {
    if (onChange) {
      setSelectedBank(option);
      onChange(option ? option.value : null);
    }
  };

  return (
    <div>
      <Box
        my={"10px"}
        mb={initialValue ? "10px" : "24px"}
        borderRadius={"12px"}
        border={"1px solid"}
        py={"6px"}
        display={"flex"}
        alignItems={"center"}
        gap={"3px"}
        bg={bgColor}
        _focus={{ borderColor, boxShadow: "none" }}
        borderColor={isFocused ? borderColor : bColor}
      >
        <Box pointerEvents="none">
          <Image
            src={isFocused && activeIcon ? activeIcon : icon}
            px={activeIcon ? "10px" : "0px"}
            opacity={iconOpacity}
            borderRight={"1px solid"}
            borderRightColor={borderColorSecondary}
          />
        </Box>
        <Box
          w={"100%"}
          onMouseEnter={() => setIsFocused(true)}
          onMouseLeave={() => setIsFocused(false)}
        >
          <Select
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            isSearchable
            styles={customStyles}
            options={bankOptions}
            value={
              initialValue
                ? { value: initialValue, label: initialValue }
                : selectedBank
            }
            onChange={handleChange}
            placeholder="BANK NAME"
          />
        </Box>
      </Box>
    </div>
  );
};

export default SelectWithSearch;
