import React, { useEffect, useState } from "react";
import AuthLayout from "../layouts/AuthLayout";
import {
  Box,
  Flex,
  Image,
  PinInput,
  PinInputField,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import theme from "../theme/theme";
import AuthCardHeader from "../components/AuthCardHeader";
import PrimaryButton from "../components/PrimaryButton";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useResendOtp,
  useVerifyEmail,
  useVerifyOtpForForgotPassword,
} from "../utils/auth.api";
import arrow1 from "../assets/backArrow.svg";
import arrow2 from "../assets/backArrowDark.svg";

const Verification = () => {
  const navigate = useNavigate();
  const verifyEmailMutation = useVerifyEmail();
  const verifyOtpForForgotPasswordMutation = useVerifyOtpForForgotPassword();
  const resendOtpMutation = useResendOtp();
  const toast = useToast();
  const location = useLocation();
  const [pinInput, setPinInput] = useState("");
  const [isVerified, setIsVerified] = useState(3);
  const [timer, setTimer] = useState(60);
  const signUpData = location?.state?.formData;
  const fromPage = location?.state?.from;
  const userEmail = signUpData?.email;
  const atIndex = userEmail?.indexOf("@");
  const maskedEmail =
    userEmail?.substring(0, 4) + "****" + userEmail?.substring(atIndex);
  const des = `Verification code has been sent to {${maskedEmail}}`;
  const color = useColorModeValue("#00332D", theme.colors.dark.text.primary);
  const arrowIcon = useColorModeValue(arrow1, arrow2);

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;
  const displayTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  useEffect(() => {
    const countdown = setInterval(() => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
      } else {
        clearInterval(countdown);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [timer]);

  return (
    <AuthLayout noAuthNav={true}>
      <Image
        src={arrowIcon}
        alt="arrow"
        transform={"rotate(270deg)"}
        onClick={() => {
          if (fromPage === "forgotPassword") {
            navigate("/auth/forgot-password");
          } else {
            navigate("/auth/signup");
          }
        }}
        cursor={"pointer"}
        mb={"40px"}
      />
      <AuthCardHeader
        title="Verification Code"
        des={des}
        desColor={useColorModeValue("#5A5A5A", "#C7C7C7")}
      />
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          // if (pinInput === "111111") {
          if (fromPage === "forgotPassword") {
            try {
              await verifyOtpForForgotPasswordMutation.mutateAsync({
                email: userEmail,
                otp: pinInput,
              });
              setIsVerified(1);
              window.location.href = "/auth/add-new-password";
              setPinInput("");
              toast({
                title: "Email verified successfully.",
                status: "success",
              });
            } catch (error: any) {
              toast({
                title: error.message,
                status: "error",
              });
              setIsVerified(0);
            }
          }
          if (fromPage === "signup") {
            try {
              await verifyEmailMutation.mutateAsync({
                email: userEmail,
                otp: pinInput,
              });
              setIsVerified(1);
              navigate("/dashboard/index");
              localStorage.setItem("fromPage", "verify");
              setPinInput("");
              toast({
                title: "Email verified successfully.",
                status: "success",
              });
            } catch (error: any) {
              toast({
                title: error.message,
                status: "error",
              });
              setIsVerified(0);
            }
          }

          // } else
        }}
      >
        <Flex justify={"space-between"} gap="10px" fontFamily={"Roboto"}>
          <PinInput
            errorBorderColor="red.300"
            focusBorderColor="teal.300"
            size={"lg"}
            variant={"flushed"}
            placeholder="0"
            value={pinInput}
            isInvalid={isVerified === 0 && pinInput !== ""}
            onChange={(value) => {
              setPinInput(value);
              setIsVerified(1);
            }}
            autoFocus
          >
            <PinInputField
              _placeholder={{ color: "#C7C7C7" }}
              fontWeight={700}
              color={useColorModeValue("#5A5A5A", "#EEEEEE")}
              fontFamily={"Roboto"}
              fontSize={"20px"}
            />
            <PinInputField
              _placeholder={{ color: "#C7C7C7" }}
              fontWeight={700}
              color={useColorModeValue("#5A5A5A", "#EEEEEE")}
              fontFamily={"Roboto"}
              fontSize={"20px"}
            />
            <PinInputField
              _placeholder={{ color: "#C7C7C7" }}
              fontWeight={700}
              color={useColorModeValue("#5A5A5A", "#EEEEEE")}
              fontFamily={"Roboto"}
              fontSize={"20px"}
            />
            <PinInputField
              _placeholder={{ color: "#C7C7C7" }}
              fontWeight={700}
              color={useColorModeValue("#5A5A5A", "#EEEEEE")}
              fontFamily={"Roboto"}
              fontSize={"20px"}
            />
            <PinInputField
              _placeholder={{ color: "#C7C7C7" }}
              fontWeight={700}
              color={useColorModeValue("#5A5A5A", "#EEEEEE")}
              fontFamily={"Roboto"}
              fontSize={"20px"}
            />
            <PinInputField
              _placeholder={{ color: "#C7C7C7" }}
              fontWeight={700}
              color={useColorModeValue("#5A5A5A", "#EEEEEE")}
              fontFamily={"Roboto"}
              fontSize={"20px"}
            />
          </PinInput>
        </Flex>
        <Flex justify={"space-between"} mt="20px" align={"center"}>
          {isVerified === 0 && pinInput?.length === 6 && (
            <Text color="red.300">Incorrect verification code.</Text>
          )}
          <Flex
            gap="5px"
            align="center"
            fontSize={"12px"}
            justify={"end"}
            ml="auto"
          >
            <Text color={useColorModeValue("#5A5A5A", "#C7C7C7")}>
              {displayTime === "00:00"
                ? "Didn’t received code?"
                : "Resend within"}
            </Text>
            <Text
              color={useColorModeValue("#00332D", "#00FFE0")}
              fontWeight={700}
              cursor={displayTime === "00:00" ? "pointer" : ""}
              onClick={async () => {
                if (displayTime === "00:00") {
                  try {
                    await resendOtpMutation.mutateAsync({ email: userEmail });
                    toast({
                      title: "Otp sent again.",
                      status: "success",
                    });
                    setTimer(60);
                  } catch (error: any) {
                    toast({
                      title: error.message,
                      status: "error",
                    });
                  }
                }
              }}
            >
              {displayTime === "00:00" ? "Send Again" : displayTime}
            </Text>
          </Flex>
        </Flex>
        <Box minH="200px" />
        <PrimaryButton
          text={verifyEmailMutation.isLoading ? <Spinner /> : "Verify"}
          disabled={verifyEmailMutation.isLoading}
          type="submit"
        />
      </form>
    </AuthLayout>
  );
};

export default Verification;
