import React, { useState } from "react";
import { Flex, Text } from "@chakra-ui/layout";
import theme from "../theme/theme";
import { useColorModeValue } from "@chakra-ui/color-mode";
import PrimaryButton from "./PrimaryButton";
import { useGetWalletWithBalance } from "../utils/wallet.api";
import { Spinner } from "@chakra-ui/react";

const FilterDropDown = ({
  toggleFilter,
  appliedFilters,
  setAppliedFilters,
  clearFilters,
}: any) => {
  const borderColor = useColorModeValue(
    "#EEE",
    theme.colors.dark.border.primary
  );
  const borderColor2 = useColorModeValue("#00FFE0", "#009986");
  const borderColor3 = useColorModeValue("#EEE", "#333");
  const bgColor2 = useColorModeValue("#FFF", "");
  const paraColor1 = useColorModeValue("#000", "#EEE");
  const paraColor2 = useColorModeValue("#909090", "#C7C7C7");
  const paraColor3 = useColorModeValue("#333", "#C7C7C7");
  const paraColor4 = useColorModeValue("#00332D", "#C7C7C7");
  const paraColor4Active = useColorModeValue("#00332D", "#00FFE0");

  const [localFilters, setLocalFilters] = useState<any>({
    token: appliedFilters?.token,
    wallet: appliedFilters?.wallet,
    symbol: appliedFilters?.symbol,
  });

  const { data, isLoading } = useGetWalletWithBalance();

  return (
    <div>
      <Flex align={"center"} justify={"space-between"} mb="24px">
        <Text
          color={paraColor1}
          fontSize={["11px", "13px", "15px", "16px"]}
          fontWeight={"700"}
          lineHeight={"22px"}
          letterSpacing={"0.08px"}
        >
          Apply Filters
        </Text>
        <Text
          color={"#00CCB3"}
          fontSize={["9px", "11px", "13px", "14px"]}
          fontWeight={"400"}
          letterSpacing={"0.014px"}
          onClick={() => setLocalFilters({ wallet: "", token: "", symbol: "" })}
          cursor={"pointer"}
        >
          Clear Filter
        </Text>
      </Flex>
      <Text
        color={paraColor2}
        fontSize={["9px", "11px", "13px", "14px"]}
        fontWeight={"400"}
        lineHeight={"20px"}
        letterSpacing={"0.035px"}
        mb={"40px"}
      >
        Apply filters for the transaction you want to search.
      </Text>
      <Text
        color={paraColor3}
        fontSize={["11px", "13px", "15px", "16px"]}
        fontWeight={"400"}
        lineHeight={"22px"}
        letterSpacing={"0.024px"}
        mb={"16px"}
      >
        Token
      </Text>
      {isLoading ? (
        <Spinner />
      ) : (
        <Flex
          fontSize={["8px", "9px", "11px", "12px"]}
          color={paraColor4}
          fontWeight={"400"}
          letterSpacing={"0.048px"}
          gap={["5px", "7px", "8px", "8px"]}
          align={"center"}
          mb={"24px"}
          justify={"stretch"}
          wrap={"wrap"}
        >
          {data?.balance?.map((item: any, idx: number) => (
            <Text
              key={idx}
              borderRadius={"30px"}
              bg={bgColor2}
              padding={"10px"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"10px"}
              wordBreak={"keep-all"}
              color={
                localFilters?.token.includes(item?.coinId)
                  ? paraColor4Active
                  : ""
              }
              border={`2px solid ${
                localFilters?.token.includes(item?.coinId)
                  ? borderColor2
                  : borderColor3
              }`}
              onClick={() => {
                setLocalFilters((prev: any) => {
                  const updatedTokens = [item?.coinId];
                  const updatedSymbols = [item?.symbol];
                  return {
                    ...prev,
                    token: updatedTokens,
                    symbol: updatedSymbols,
                  };
                });
              }}
              cursor={"pointer"}
            >
              {item?.symbol?.replace("(Testnet)", "")}
            </Text>
          ))}
        </Flex>
      )}

      <Text
        pt={"24px"}
        borderTop={`1px solid ${borderColor3}`}
        color={paraColor3}
        mb={"16px"}
        fontSize={["11px", "13px", "15px", "16px"]}
        fontWeight={"400"}
        lineHeight={"22px"}
        letterSpacing={"0.024px"}
      >
        Wallet
      </Text>
      <Flex
        mb="0px"
        fontSize={["8px", "9px", "11px", "12px"]}
        color={paraColor4}
        fontWeight={"400"}
        letterSpacing={"0.048px"}
        gap={"8px"}
        align={"center"}
      >
        <Text
          borderRadius={"30px"}
          border={`2px solid ${
            localFilters?.wallet === "Deposit" ? borderColor2 : borderColor3
          }`}
          bg={bgColor2}
          padding={"10px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"10px"}
          color={localFilters?.wallet === "Deposit" ? paraColor4Active : ""}
          onClick={() =>
            setLocalFilters((prev: any) => ({ ...prev, wallet: "Deposit" }))
          }
          cursor={"pointer"}
        >
          Deposit
        </Text>
        <Text
          borderRadius={"30px"}
          border={`2px solid ${
            localFilters?.wallet === "Withdraw" ? borderColor2 : borderColor3
          }`}
          bg={bgColor2}
          padding={"10px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"10px"}
          color={localFilters?.wallet === "Withdraw" ? paraColor4Active : ""}
          onClick={() =>
            setLocalFilters((prev: any) => ({ ...prev, wallet: "Withdraw" }))
          }
          cursor={"pointer"}
        >
          Withdraw
        </Text>
        <Text
          borderRadius={"30px"}
          border={`2px solid ${
            localFilters?.wallet === "Swap" ? borderColor2 : borderColor3
          }`}
          bg={bgColor2}
          padding={"10px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"10px"}
          color={localFilters?.wallet === "Swap" ? paraColor4Active : ""}
          onClick={() =>
            setLocalFilters((prev: any) => ({ ...prev, wallet: "Swap" }))
          }
          cursor={"pointer"}
        >
          Swap
        </Text>
      </Flex>
      <PrimaryButton
        text="Apply Filters"
        disabled={isLoading}
        onClick={() => {
          toggleFilter();
          setAppliedFilters(localFilters);
        }}
      />
    </div>
  );
};

export default FilterDropDown;

{
  /* <Popover
  isOpen={isPopoverOpen}
  onClose={() => setIsPopoverOpen(false)}
  closeOnBlur={false}
  placement="bottom-start"
>
  <PopoverTrigger>
    <Image src={filterIcon} onClick={handlePopoverToggle} />
  </PopoverTrigger>
  <PopoverContent
    position={"relative"}
    zIndex={10}
    padding={"24px"}
    borderRadius={"16px"}
    border={`1px solid ${borderColor}`}
    bg={bgColor}
    boxShadow={"0px 4px 40px 0px rgba(0,0,0,0.10)"}
    mt="10px"
    w={"100%"}
    mr={["-40px", "-30px", "-60px", "-60px"]}
  >
    <PopoverBody>
      <Flex align={"center"} justify={"space-between"} mb="24px">
        <Text
          color={paraColor1}
          fontSize={["11px", "13px", "15px", "16px"]}
          fontWeight={"700"}
          lineHeight={"22px"}
          letterSpacing={"0.08px"}
        >
          Apply Filters
        </Text>
        <Text
          color={"#00CCB3"}
          fontSize={["9px", "11px", "13px", "14px"]}
          fontWeight={"400"}
          letterSpacing={"0.014px"}
        >
          Clear Filter
        </Text>
      </Flex>
      <Text
        color={paraColor2}
        fontSize={["9px", "11px", "13px", "14px"]}
        fontWeight={"400"}
        lineHeight={"20px"}
        letterSpacing={"0.035px"}
        mb={"40px"}
      >
        Apply filters for the transaction you want to search.
      </Text>
      <Text
        color={paraColor3}
        fontSize={["11px", "13px", "15px", "16px"]}
        fontWeight={"400"}
        lineHeight={"22px"}
        letterSpacing={"0.024px"}
        mb={"16px"}
      >
        Token
      </Text>
      <Flex
        fontSize={["8px", "9px", "11px", "12px"]}
        color={paraColor4}
        fontWeight={"400"}
        letterSpacing={"0.048px"}
        gap={"8px"}
        align={"center"}
        mb={"24px"}
        justify={"stretch"}
      >
        <Text
          borderRadius={"30px"}
          border={`1px solid ${borderColor2}`}
          bg={bgColor2}
          padding={"10px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"10px"}
          flex={"1 0 0"}
        >
          BTC
        </Text>
        <Text
          borderRadius={"30px"}
          border={`1px solid ${borderColor3}`}
          bg={bgColor2}
          padding={"10px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"10px"}
          flex={"1 0 0"}
        >
          USDT
        </Text>
        <Text
          borderRadius={"30px"}
          border={`1px solid ${borderColor3}`}
          bg={bgColor2}
          padding={"10px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"10px"}
          flex={"1 0 0"}
        >
          ETH
        </Text>
        <Text
          borderRadius={"30px"}
          border={`1px solid ${borderColor3}`}
          bg={bgColor2}
          padding={"10px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"10px"}
          flex={"1 0 0"}
        >
          TRX
        </Text>
        <Text
          borderRadius={"30px"}
          border={`1px solid ${borderColor3}`}
          bg={bgColor2}
          padding={"10px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"10px"}
          flex={"1 0 0"}
        >
          NGN
        </Text>
      </Flex>

      <Text
        pt={"24px"}
        borderTop={"1px solid #EEE"}
        mb={"16px"}
        color={paraColor3}
        fontSize={["11px", "13px", "15px", "16px"]}
        fontWeight={"400"}
        lineHeight={"22px"}
        letterSpacing={"0.024px"}
      >
        Wallet
      </Text>
      <Flex
        mb="0px"
        fontSize={["8px", "9px", "11px", "12px"]}
        color={paraColor4}
        fontWeight={"400"}
        letterSpacing={"0.048px"}
        gap={"8px"}
        align={"center"}
      >
        <Text
          borderRadius={"30px"}
          border={`1px solid ${borderColor3}`}
          bg={bgColor2}
          padding={"10px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"10px"}
        >
          Deposit
        </Text>
        <Text
          borderRadius={"30px"}
          border={`1px solid ${borderColor2}`}
          bg={bgColor2}
          padding={"10px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"10px"}
        >
          Withdraw
        </Text>
        <Text
          borderRadius={"30px"}
          border={`1px solid ${borderColor3}`}
          bg={bgColor2}
          padding={"10px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"10px"}
        >
          Swap
        </Text>
      </Flex>
      <PrimaryButton text="Apply Filters" />
    </PopoverBody>
  </PopoverContent>
</Popover> */
}
