import React, { useEffect, useState } from "react";
import AdminLayout from "../layouts/AdminLayout";
import {
  Box,
  Divider,
  Flex,
  Heading,
  Image,
  PinInput,
  PinInputField,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import PrimaryButton from "../components/PrimaryButton";
import AuthCardHeader from "../components/AuthCardHeader";
import verifiedIcon from "../assets/verified.svg";
import CongratulationCard from "../components/CongratulationCard";
import AuthCard from "../components/AuthCard";
import CustomModal from "../components/CustomModal";
import { useLocation, useNavigate } from "react-router-dom";
import theme from "../theme/theme";
import { CustomDetailFlex } from "../components/CustomDeatilFlex";
import { useGetLoggedInUser } from "../utils/auth.api";
import {
  useVerifyOtpForTransaction,
  useWithdrawFiat,
} from "../utils/wallet.api";
import failedIcon from "../assets/failedIcon.svg";
import arrow1 from "../assets/backArrow.svg";
import arrow2 from "../assets/backArrowDark.svg";

const ConfirmNairaWithdrawal = () => {
  const { data: user } = useGetLoggedInUser();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCompleteOpen, setIsCompleteOpen] = useState(false);
  const onCompleteOpen = () => {
    setIsCompleteOpen(true);
  };
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  const [errorMsg, setErrorMsg] = useState("");
  const onCompleteClose = () => {
    setIsCompleteOpen(false);
  };
  const location = useLocation();
  const formData = location.state;
  const navigate = useNavigate();
  const [pinInput, setPinInput] = useState("");
  const [isVerified, setIsVerified] = useState(3);
  const [timer, setTimer] = useState(60);

  const headColor = useColorModeValue("#00332D", "#EEE");
  const headColor2 = useColorModeValue("#333", "#EEE");
  const paraColor = useColorModeValue("#909090", "#C7C7C7");
  const paraColor2 = useColorModeValue("#5A5A5A", "#EEE");
  const paraColor3 = useColorModeValue("#000", "#EEE");
  const arrowIcon = useColorModeValue(arrow1, arrow2);

  const color = useColorModeValue(
    theme.colors.light.text.primary,
    theme.colors.dark.text.primary
  );
  let currentDate = new Date().toJSON().slice(0, 10);

  const withdrawFiatMutation = useWithdrawFiat();
  const verifyOtpForTransactionMutation = useVerifyOtpForTransaction();

  const verifyOtp = async () => {
    setPinInput("");
    onClose();
    try {
      await verifyOtpForTransactionMutation.mutateAsync({
        email: user?.email,
        otp: pinInput,
        amount: Number(formData?.amountSelected),
        bankName: formData?.bankSelected?.bankName,
        accountNumber: formData?.bankSelected?.accountNumber,
        accountName: formData?.bankSelected?.accountName,
      });
      toast({
        title: "Withdraw request sent successfully.",
        status: "success",
      });
      onCompleteOpen();
    } catch (error: any) {
      onErrorOpen();
      setErrorMsg(error?.message);
    }
  };
  const withdrawFiat = async () => {
    try {
      await withdrawFiatMutation.mutateAsync({
        amount: formData?.amountSelected,
        bankName: formData?.bankSelected?.bankName,
        accountNumber: formData?.bankSelected?.accountNumber,
        accountName: formData?.bankSelected?.accountName,
      });
      if (user?.authTransaction) {
        onOpen();
        setTimer(60);
      } else {
        toast({
          title: "Withdraw request sent successfully.",
          status: "success",
        });
        onCompleteOpen();
      }
    } catch (error: any) {
      onErrorOpen();
      setErrorMsg(error?.message);
      // toast({
      //   title: error.message,
      //   status: "error",
      // });
    }
  };

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;
  const displayTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  useEffect(() => {
    const countdown = setInterval(() => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
      } else {
        clearInterval(countdown);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [timer, isOpen, onOpen, onClose]);

  useEffect(() => {
    if (!formData?.amountSelected && !formData?.bankSelected) {
      navigate("/dashboard/withdraw");
    }
  }, [formData]);

  return (
    <AdminLayout>
      <Box marginTop="72px" marginRight={"2%"} mb={"50px"} w={"95%"}>
        <Flex gap={"10px"} align={"center"}>
          <Image
            src={arrowIcon}
            alt="arrow"
            transform={"rotate(270deg)"}
            onClick={() => navigate("/dashboard/withdraw")}
            cursor={"pointer"}
            mb={"40px"}
          />
          <Heading
            color={useColorModeValue("#00332D", "#C7C7C7")}
            fontSize={"34px"}
            fontWeight={"700"}
            letterSpacing={"0.085px"}
            mb={"40px"}
          >
            Confirm Withdrawal
          </Heading>
        </Flex>
        <Text
          color={paraColor}
          fontSize={"14px"}
          fontWeight={"400"}
          lineHeight={"20px"}
          letterSpacing={"0.035px"}
          mb={"32px"}
        >
          Review and confirm your withdrawal details below.
        </Text>
        <Flex direction={"column"} mb={"20px"}>
          <Text
            color={paraColor}
            fontSize={"14px"}
            fontWeight={"400"}
            letterSpacing={"0.014px"}
          >
            Amount
          </Text>
          <Heading
            color={headColor2}
            fontSize={"34px"}
            fontWeight={"700"}
            letterSpacing={"0.085px"}
            display={"flex"}
            gap={"5px"}
          >
            {Number(formData?.amountSelected || 0)?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            <Text
              color={paraColor}
              fontSize={"18px"}
              fontWeight={"700"}
              lineHeight={"24px"}
              letterSpacing={"0.027px"}
              alignSelf={"flex-end"}
            >
              NGN
            </Text>
          </Heading>
        </Flex>
        <hr />
        <Flex mt={"20px"} justify={"space-between"}>
          <Text
            color={paraColor2}
            fontSize={"16px"}
            fontWeight={"400"}
            lineHeight={"22px"}
            letterSpacing={"0.08px"}
          >
            Title
          </Text>
          <Text
            color={paraColor3}
            fontSize={"18px"}
            fontWeight={"400"}
            lineHeight={"24px"}
            letterSpacing={"0.027px"}
          >
            {formData?.bankSelected?.accountName}
          </Text>
        </Flex>
        <Flex mt={"20px"} justify={"space-between"}>
          <Text
            color={paraColor2}
            fontSize={"16px"}
            fontWeight={"400"}
            lineHeight={"22px"}
            letterSpacing={"0.08px"}
          >
            Bank Name
          </Text>
          <Text
            color={paraColor3}
            fontSize={"18px"}
            fontWeight={"400"}
            lineHeight={"24px"}
            letterSpacing={"0.027px"}
          >
            {formData?.bankSelected?.bankName}
          </Text>
        </Flex>
        <Flex mt={"20px"} mb={"36px"} justify={"space-between"}>
          <Text
            color={paraColor2}
            fontSize={"16px"}
            fontWeight={"400"}
            lineHeight={"22px"}
            letterSpacing={"0.08px"}
          >
            Account Address
          </Text>
          <Text
            color={paraColor3}
            fontSize={"18px"}
            fontWeight={"400"}
            lineHeight={"24px"}
            letterSpacing={"0.027px"}
          >
            {formData?.bankSelected?.accountNumber}
          </Text>
        </Flex>
        <hr />
        <Box width={"240px"}>
          <PrimaryButton
            text={
              withdrawFiatMutation?.isLoading ||
              verifyOtpForTransactionMutation.isLoading ? (
                <Spinner />
              ) : (
                "Confirm Withdrawal"
              )
            }
            disabled={
              withdrawFiatMutation.isLoading ||
              verifyOtpForTransactionMutation.isLoading
            }
            onClick={withdrawFiat}
          />
        </Box>
        <CustomModal
          isOpen={isOpen}
          onClose={onClose}
          headerText="Complete Withdrawal Request"
          footerText="Verify Withdrawal"
          closeText="Cancel"
          isLoading={withdrawFiatMutation.isLoading}
          onSubmit={verifyOtp}
          isDisabled={pinInput?.length < 6}
        >
          <Flex
            justify={"space-between"}
            align={"center"}
            direction={"column"}
            gap="72px"
            mb="32px"
            mt={"32px"}
          >
            <Box
              textAlign={"center"}
              fontSize={"14px"}
              color={useColorModeValue("#5A5A5A", "#C7C7C7")}
            >
              <Text>
                To complete the withdrawal, kindly submit the six digits code
                sent to
              </Text>
              <Text fontWeight={700}>{`${user?.email?.slice(
                0,
                4
              )} **** ${user?.email?.slice(-4)}`}</Text>
            </Box>
            <Flex justify={"space-between"} gap="10px" fontFamily={"Roboto"}>
              <PinInput
                errorBorderColor="red.300"
                focusBorderColor="teal.300"
                size={"lg"}
                variant={"flushed"}
                placeholder="0"
                value={pinInput}
                isInvalid={isVerified === 0 && pinInput !== ""}
                onChange={(value) => {
                  setPinInput(value);
                }}
                autoFocus
              >
                <PinInputField
                  _placeholder={{ color: "#C7C7C7" }}
                  fontWeight={700}
                  color={useColorModeValue("#5A5A5A", "#EEEEEE")}
                  fontFamily={"Roboto"}
                  fontSize={"20px"}
                />
                <PinInputField
                  _placeholder={{ color: "#C7C7C7" }}
                  fontWeight={700}
                  color={useColorModeValue("#5A5A5A", "#EEEEEE")}
                  fontFamily={"Roboto"}
                  fontSize={"20px"}
                />
                <PinInputField
                  _placeholder={{ color: "#C7C7C7" }}
                  fontWeight={700}
                  color={useColorModeValue("#5A5A5A", "#EEEEEE")}
                  fontFamily={"Roboto"}
                  fontSize={"20px"}
                  onChange={() => setPinInput("")}
                />
                <PinInputField
                  _placeholder={{ color: "#C7C7C7" }}
                  fontWeight={700}
                  color={useColorModeValue("#5A5A5A", "#EEEEEE")}
                  fontFamily={"Roboto"}
                  fontSize={"20px"}
                />
                <PinInputField
                  _placeholder={{ color: "#C7C7C7" }}
                  fontWeight={700}
                  color={useColorModeValue("#5A5A5A", "#EEEEEE")}
                  fontFamily={"Roboto"}
                  fontSize={"20px"}
                  onChange={() => setPinInput("")}
                />
                <PinInputField
                  _placeholder={{ color: "#C7C7C7" }}
                  fontWeight={700}
                  color={useColorModeValue("#5A5A5A", "#EEEEEE")}
                  fontFamily={"Roboto"}
                  fontSize={"20px"}
                />
              </PinInput>
            </Flex>
            <Flex justify={"space-between"} mt="20px" align={"center"}>
              {isVerified === 0 && pinInput !== "" && (
                <Text color="red.300">Incorrect verification code.</Text>
              )}
              <Flex
                gap="5px"
                align="center"
                fontSize={"12px"}
                justify={"end"}
                ml="auto"
              >
                <Text color={useColorModeValue("#5A5A5A", "#C7C7C7")}>
                  {displayTime === "00:00"
                    ? "Didn’t received code?"
                    : "Resend within"}
                </Text>
                <Text
                  color={useColorModeValue("#00332D", "#00FFE0")}
                  fontWeight={700}
                  cursor={displayTime === "00:00" ? "pointer" : ""}
                  onClick={() => {
                    displayTime === "00:00" && withdrawFiat();
                  }}
                >
                  {displayTime === "00:00" ? "Send Again" : displayTime}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </CustomModal>
        <CustomModal
          isOpen={isCompleteOpen}
          onClose={() => {
            onCompleteClose();
            navigate("/dashboard/withdraw");
          }}
          headerText="Withdrawal Completed"
          onSubmit={() => {
            onCompleteClose();
            navigate("/dashboard/withdraw");
          }}
        >
          <Box pt="20px" mb="-30px">
            <AuthCardHeader
              title={"Withdrawal Completed"}
              imgSrc={verifiedIcon}
              imageW="56px"
              mbImg="10px"
            />
          </Box>
          <Divider variant={"dashed"} />
          <CustomDetailFlex title="Date" value={currentDate} />
          <CustomDetailFlex
            title="Bank"
            value={formData?.bankSelected?.bankName}
          />
          <CustomDetailFlex
            title="Account Title"
            value={formData?.bankSelected?.accountName}
          />
          <CustomDetailFlex
            title="Account Number"
            value={formData?.bankSelected?.accountNumber}
          />
          <Divider />
          <Flex justify={"space-between"} my="32px" gap="20px">
            <Text>Total </Text>
            <Text fontWeight={700} color={color}>
              {Number(formData?.amountSelected || 0)?.toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}{" "}
              <Text
                display={"inline"}
                ml="4px"
                fontSize={"14px"}
                color={useColorModeValue("#909090", "#C7C7C7")}
              >
                NGN
              </Text>{" "}
            </Text>
          </Flex>
        </CustomModal>
        <CustomModal
          isOpen={isErrorOpen}
          onClose={() => {
            onErrorClose();
            // navigate("/dashboard/index");
          }}
          noFooter={true}
          onSubmit={() => {
            // navigate("/dashboard/index");
            onErrorClose();
          }}
        >
          <Flex
            justify={"center"}
            align={"center"}
            direction={"column"}
            gap="24px"
            mb="32px"
            mt={"32px"}
          >
            <Image src={failedIcon} w={"56px"} h={"56px"} />
            <Heading
              fontFamily={"Audiowide"}
              fontSize={["15px", "18px", "21px", "24px"]}
              fontWeight={"400"}
            >
              Transaction Failed!
            </Heading>
            <Text
              textAlign={"center"}
              fontSize={["9px", "11px", "13px", "14px"]}
              fontWeight={"400"}
              lineHeight={"20px"}
              letterSpacing={"0.035px"}
              color={paraColor2}
            >
              {errorMsg}
            </Text>
          </Flex>
        </CustomModal>
      </Box>
    </AdminLayout>
  );
};

export default ConfirmNairaWithdrawal;
