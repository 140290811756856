import {
  Flex,
  Heading,
  Image,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import PrimaryButton from "./PrimaryButton";
import UserInfoInput from "./UserInfoInput";
import lockDark from "../assets/icons/dark/lock.svg";
import lockLight from "../assets/icons/light/lockIcon.svg";
import eyeDark from "../assets/icons/dark/eye.svg";
import shutEyeDark from "../assets/icons/dark/shutEye.svg";
import eyeLight from "../assets/icons/light/eyeIcon.svg";
import shutEyeLight from "../assets/icons/light/shutEyeIcon.svg";
import CustomModal from "./CustomModal";
import verifiedIcon from "../assets/verified.svg";
import lockActive from "../assets/icons/light/lockActive.svg";
import eyeActive from "../assets/icons/light/eyeActive.svg";
import shutEyeActive from "../assets/icons/light/shutEyeActive.svg";
import { useChangePassword } from "../utils/auth.api";

const ChangePassword = () => {
  const changePasswordMutation = useChangePassword();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const paraColor = useColorModeValue("#5A5A5A", "#C7C7C7");
  const paraColor2 = useColorModeValue("#909090", "#C7C7C7");
  const lockIcon = useColorModeValue(lockLight, lockDark);
  const eyeIcon = useColorModeValue(eyeLight, eyeDark);
  const shutEyeIcon = useColorModeValue(shutEyeLight, shutEyeDark);

  const isStrongPassword = (password: any) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])[A-Za-z\d !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div>
      <Flex mt={"65px"} direction={"column"} gap={"56px"}>
        <Flex direction={"column"} gap={"24px"} alignSelf={"stretch"}>
          <Text
            color={paraColor}
            fontSize={"16px"}
            fontWeight={"700"}
            lineHeight={"22px"}
            letterSpacing={"0.08px"}
          >
            Change Password
          </Text>

          <Text
            color={paraColor2}
            fontSize={"16px"}
            fontWeight={"400"}
            lineHeight={"22px"}
            letterSpacing={"0.08px"}
            mb={"-10px"}
          >
            Confirm your old password and setup a new one to update security.
          </Text>
          <Flex direction={"column"} gap={"16px"}>
            <UserInfoInput
              type={showOldPassword ? "text" : "password"}
              placeholder="Old Password"
              icon={lockIcon}
              activeIcon={useColorModeValue(lockActive, lockDark)}
              rightIcon={!showOldPassword ? eyeIcon : shutEyeIcon}
              activeRightIcon={!showOldPassword ? eyeActive : shutEyeActive}
              setShow={() => setShowOldPassword(!showOldPassword)}
              show={showOldPassword}
              name="oldPassword"
              value={formData?.oldPassword}
              onChange={(e: any) => handleChange(e)}
              mb="-10px"
            />
            <UserInfoInput
              type={showNewPassword ? "text" : "password"}
              placeholder="Add New Password"
              icon={lockIcon}
              activeIcon={useColorModeValue(lockActive, lockDark)}
              rightIcon={!showNewPassword ? eyeIcon : shutEyeIcon}
              activeRightIcon={!showNewPassword ? eyeActive : shutEyeActive}
              setShow={() => setShowNewPassword(!showNewPassword)}
              show={showNewPassword}
              name="newPassword"
              value={formData?.newPassword}
              onChange={(e: any) => handleChange(e)}
              mb="-10px"
            />
            <UserInfoInput
              type={showConfirmNewPassword ? "text" : "password"}
              placeholder="Confirm New Password"
              icon={lockIcon}
              activeIcon={useColorModeValue(lockActive, lockDark)}
              rightIcon={!showConfirmNewPassword ? eyeIcon : shutEyeIcon}
              activeRightIcon={
                !showConfirmNewPassword ? eyeActive : shutEyeActive
              }
              setShow={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
              show={showConfirmNewPassword}
              name="confirmNewPassword"
              value={formData?.confirmNewPassword}
              onChange={(e: any) => handleChange(e)}
              mb="0px"
            />
            {formData?.newPassword !== formData?.confirmNewPassword &&
              formData?.confirmNewPassword && (
                <Text color={"red"} mb="10px">
                  Password didn't match with the first one.
                </Text>
              )}
            {formData?.newPassword &&
              !isStrongPassword(formData?.newPassword) && (
                <Text color={"red"} mb="10px">
                  Password not strong enough. It should contain 1 uppercase
                  letter, 1 lowercase letter, 1 digit, 1 special character, and
                  be at least 8 characters long.
                </Text>
              )}
          </Flex>
        </Flex>
        <PrimaryButton
          text={
            changePasswordMutation.isLoading ? <Spinner /> : "Update Password"
          }
          mt="0px"
          disabled={
            changePasswordMutation?.isLoading ||
            formData?.newPassword !== formData?.confirmNewPassword ||
            !isStrongPassword(formData?.newPassword)
          }
          onClick={async () => {
            if (
              formData?.oldPassword &&
              formData?.newPassword &&
              formData?.confirmNewPassword &&
              formData?.newPassword === formData?.confirmNewPassword
            ) {
              try {
                await changePasswordMutation.mutateAsync({
                  oldPassword: formData?.oldPassword,
                  password: formData?.confirmNewPassword,
                  confirmPassword: formData?.confirmNewPassword,
                });
                setFormData({
                  oldPassword: "",
                  newPassword: "",
                  confirmNewPassword: "",
                });
                onOpen();
              } catch (error: any) {
                toast({
                  title: error.message,
                  status: "error",
                });
              }
            } else {
              toast({
                title:
                  "All passwords should be entered and passwords should match",
                status: "error",
              });
              return;
            }
          }}
        />
      </Flex>
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        headerText="Successful"
        onSubmit={onClose}
        footerText="Done"
      >
        <Flex
          justify={"center"}
          align={"center"}
          direction={"column"}
          gap="24px"
          mb="32px"
          mt={"32px"}
        >
          <Image src={verifiedIcon} w={"56px"} h={"56px"} />
          <Heading
            fontFamily={"Audiowide"}
            fontSize={["15px", "18px", "21px", "24px"]}
            fontWeight={"400"}
          >
            Password Updated
          </Heading>
          <Text
            textAlign={"center"}
            fontSize={["9px", "11px", "13px", "14px"]}
            fontWeight={"400"}
            lineHeight={"20px"}
            letterSpacing={"0.035px"}
            color={paraColor2}
          >
            Your password has been updated.
          </Text>
        </Flex>
      </CustomModal>
    </div>
  );
};

export default ChangePassword;
