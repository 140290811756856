import {
  Badge,
  Box,
  Divider,
  Flex,
  Image,
  ListItem,
  Text,
  UnorderedList,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import whiteCrownIcon from "../assets/icons/crownWhite.svg";
import PrimaryButton from "./PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useGetLoggedInUser } from "../utils/auth.api";

const UserLevelCard = ({ level, des, benefits, showBtn, status }: any) => {
  const navigate = useNavigate();
  const { data, isLoading } = useGetLoggedInUser();

  return (
    <Box
      py={"24px"}
      px={["5px", "16px"]}
      borderRadius={"12px"}
      mb="24px"
      border="1px solid"
      borderColor={useColorModeValue("#EEE", "#333")}
    >
      <Flex justify={"space-between"} align={"center"}>
        <Text fontWeight={700} color={useColorModeValue("black", "white")}>
          {level === "Pro" ? "Pro Account" : level}
        </Text>
        <Flex
          gap="5px"
          fontSize={"10px"}
          align={"center"}
          color={"white"}
          p="8px"
          borderRadius={"6px"}
          bg="#FFC960"
        >
          {" "}
          <Image src={whiteCrownIcon} /> {status}
        </Flex>
      </Flex>
      <UnorderedList my="16px">
        {des?.length > 1 ? (
          des?.map((item: any, idx: any) => (
            <ListItem key={idx}>{item}</ListItem>
          ))
        ) : (
          <Text>{des && des[0]}</Text>
        )}
      </UnorderedList>
      <Divider my="16px" />
      <Text
        mb="10px"
        fontWeight={700}
        color={useColorModeValue("#5A5A5A", "#C7C7C7")}
      >
        Benefits
      </Text>
      <UnorderedList>
        {benefits?.length > 1 ? (
          benefits?.map((item: any, idx: any) => (
            <ListItem key={idx}>{item}</ListItem>
          ))
        ) : (
          <Text>{benefits && benefits[0]}</Text>
        )}
      </UnorderedList>
      {level === "Pro" && (
        <PrimaryButton
          mt="16px"
          disabled={isLoading ||  data?.kycTier === "3"}
          text={
            data?.kycTier === "2"
              ? "Upgrade to Pro Account"
              : "Upgrade to Level 2"
          }
          onClick={() => {
            data?.kycTier === "2"
              ? navigate("/dashboard/kyc-level3")
              : navigate("/dashboard/kyc");
          }}
        />
      )}
    </Box>
  );
};

export default UserLevelCard;
