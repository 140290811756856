import React, { useEffect, useState } from "react";
import { csv } from "d3-fetch";
import AdminLayout from "../layouts/AdminLayout";
import { Box, Flex, Heading, Text } from "@chakra-ui/layout";
import searchIcon from "../assets/searchIcon.svg";
import { Button } from "@chakra-ui/button";
import { Image } from "@chakra-ui/image";
import CustomTable from "../components/CustomTable";
import cross from "../assets/crossGreen.svg";
import crossRed from "../assets/crossRed.svg";
import cloud from "../assets/downloadCloud.svg";

import nairaDark from "../assets/NairaWhite.svg";
import naira from "../assets/NairaSymbol.svg";
import shareBg from "../assets/shareBg.svg";
import cloudBg from "../assets/cloudBg.svg";
import { useColorModeValue } from "@chakra-ui/color-mode";
import theme from "../theme/theme";
import cloudDark from "../assets/cloudDark.svg";
import cloudBgDark from "../assets/cloudBgDark.svg";
import shareBgDark from "../assets/shareBgDark.svg";
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/input";
import filterIcon from "../assets/filterIcon.svg";
import FilterDropDown from "../components/FilterDropDown";
import { SearchIcon } from "@chakra-ui/icons";
import {
  useCsvWalletTransactions,
  useGetWalletFiatTransactions,
  useGetWalletTransactions,
} from "../utils/wallet.api";
import { Spinner } from "@chakra-ui/react";
import CustomTabs from "../components/CustomTabs";
import { useGetLoggedInUser } from "../utils/auth.api";

const WalletTransactions = () => {
  const [rows, setRows] = useState([]);
  const [fiatRows, setFiatRows] = useState([]);
  const [activeTab, setActiveTab] = useState("crypto");

  const [filterDropdownShow, setFilterDropDownShow] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({
    token: "",
    wallet: "",
    symbol: "",
  });
  const { data: user } = useGetLoggedInUser();
  const { data: csvData, isLoading: csvLoading } = useCsvWalletTransactions({
    l: Number.MAX_SAFE_INTEGER,
    o: 0,
    tokenId: appliedFilters?.token,
    filter:
      appliedFilters.token || appliedFilters.wallet
        ? appliedFilters.token
          ? appliedFilters.wallet
          : appliedFilters.wallet
        : "",
  });

  const { isLoading, data } = useGetWalletTransactions({
    l: 10000,
    o: 0,
    tokenId: appliedFilters?.token,
    filter:
      appliedFilters.token || appliedFilters.wallet
        ? appliedFilters.token
          ? appliedFilters.wallet
          : appliedFilters.wallet
        : "",
  });
  const { isLoading: fiatLoading, data: fiatData } =
    useGetWalletFiatTransactions({
      l: 10000,
      o: 0,
      filter: appliedFilters.wallet ? appliedFilters.wallet : "",
    });
  const headColor = useColorModeValue("#00332D", "#C7C7C7");
  const paraColor = useColorModeValue("#333", "#C7C7C7");
  const paraColor2 = useColorModeValue("#333", "#00FFE0");
  const borderColor = useColorModeValue("#C7C7C7", "#00665A");
  const borderColor2 = useColorModeValue("#EEE", "#333");
  const bg = useColorModeValue("#EEE", "#00332D");
  const color = useColorModeValue(
    theme.colors.light.accent.primary,
    theme.colors.dark.accent.primary
  );
  const cloudBtn = useColorModeValue(cloud, cloudDark);
  const cloudBgIcon = useColorModeValue(cloudBg, shareBgDark);
  const shareBgIcon = useColorModeValue(shareBg, cloudBgDark);
  const borderColorSecondary = useColorModeValue(
    theme.colors.light.border.secondary,
    theme.colors.dark.border.secondary
  );
  const bgColor = useColorModeValue("#FFF", "#121B18");
  const nairaIcon = useColorModeValue(naira, nairaDark);
  //   const [iconOpacity, setIconOpacity] = useState(0.5);

  const columns = [
    "Type",
    "Amount",
    "Transaction ID",
    "Hash ID",
    "Sender ID",
    "Recipient ID",
    "Status",
    "Date",
    // "Action",
  ];

  const fiatColumns = [
    "Type",
    "Amount",
    "Transaction ID",
    "Bank Name",
    "Account Number",
    "Status",
    "Date",
    // "Action",
  ];

  const reArrageData = () => {
    let tempArr: any = [];

    data?.map((item: any, idx: number) => {
      tempArr?.push({
        type:
          // item?.type?.includes("Transfer") &&
          // item?.receiverUserId === user?.uniqueId
          //   ? "Receive"
          //   :
          item?.type,
        amount: (
          <Flex align={"center"} gap={"8px"}>
            <Text>
              {item?.coin?.icon
                ? Number(item?.amount)?.toLocaleString(undefined, {
                    minimumFractionDigits: 5,
                    maximumFractionDigits: 5,
                  })
                : Number(item?.amount)?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
            </Text>
            {item?.type === "Swap" ? (
              item?.isToCrypto ? (
                <Image src={nairaIcon} alt="" w={"14px"} h={"14px"} />
              ) : (
                <Image src={item?.coin?.icon} alt="" w={"14px"} h={"14px"} />
              )
            ) : item?.coin?.icon ? (
              <Image src={item?.coin?.icon} alt="" w={"14px"} h={"14px"} />
            ) : (
              <Image src={nairaIcon} alt="" w={"14px"} h={"14px"} />
            )}
          </Flex>
        ),
        transactionID: item?.id,
        hashID: item?.trxHash
          ? `${item?.trxHash?.slice(0, 6)}...${item?.trxHash?.slice(-2)}`
          : "___",
        SenderID: item?.type?.includes("Transfer")
          ? item?.user?.email
          : item?.type?.includes("Receive")
          ? item?.senderId || item?.email
          : item?.type?.includes("Withdraw") || item?.type?.includes("Swap")
          ? item?.email
          : item?.fromAddress
          ? `${item?.fromAddress?.slice(0, 6)}...${item?.fromAddress?.slice(
              -2
            )}`
          : "___",
        ReceipientID: item?.type?.includes("Transfer")
          ? item?.receiverUserId
          : item?.type?.includes("Receive")
          ? item?.user?.email || item?.receiverUserId
          : item?.toAddress
          ? `${item?.toAddress?.slice(0, 6)}...${item?.toAddress?.slice(-2)}`
          : "___",
        Status: item?.status,
        Date: item?.createdAt?.slice(0, 10),
      });
    });

    setRows(tempArr);
  };

  const reArrageFiatData = () => {
    let tempArr: any = [];

    fiatData?.map((item: any, idx: number) => {
      tempArr?.push({
        type: item?.type,
        amount: (
          <Flex align={"center"} gap={"8px"}>
            <Text>
              {Number(item?.amount)?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>
            {item?.type === "Swap" ? (
              item?.isToCrypto ? (
                <Image src={nairaIcon} alt="" w={"14px"} h={"14px"} />
              ) : (
                <Image src={item?.coin?.icon} alt="" w={"14px"} h={"14px"} />
              )
            ) : item?.coin?.icon ? (
              <Image src={item?.coin?.icon} alt="" w={"14px"} h={"14px"} />
            ) : (
              <Image src={nairaIcon} alt="" w={"14px"} h={"14px"} />
            )}
          </Flex>
        ),
        transactionID: item?.id,
        bankName: item?.bankName || "___",
        accountNumber: item?.accountNumber || "___",
        Status: item?.status,
        Date: item?.createdAt?.slice(0, 10),
      });
    });

    setFiatRows(tempArr);
  };

  const toggleFilter = () => {
    setFilterDropDownShow(!filterDropdownShow);
  };

  const clearFilters = () => {
    setAppliedFilters({ token: "", wallet: "", symbol: "" });
  };

  useEffect(() => {
    if (activeTab === "crypto") {
      reArrageData();
    }
  }, [data, cloudBgIcon, shareBgIcon, appliedFilters]);

  useEffect(() => {
    if (activeTab === "fiat") {
      reArrageFiatData();
    }
  }, [fiatData, cloudBgIcon, shareBgIcon, appliedFilters]);

  return (
    <AdminLayout>
      <Box marginTop="72px" marginRight={"2%"} mb={"50px"} w={"95%"}>
        <Flex
          justify={"space-between"}
          align={["start", "start", "start", "center"]}
          mb={"30px"}
          direction={["column", "column", "column", "row"]}
        >
          <Heading
            color={headColor}
            fontSize={["18px", "24px", "30px", "34px"]}
            fontWeight={"700"}
            letterSpacing={"0.085px"}
          >
            Wallet Transactions
          </Heading>
          <Box w={["100%", "100%", "100%", "40%"]}>
            <InputGroup my="10px" mb="12px" bg={bgColor}>
              <InputLeftElement pointerEvents="none" py="23px">
                {useColorModeValue(
                  <Image
                    src={searchIcon}
                    px="10px"
                    borderRight={"1px solid"}
                    borderRightColor={borderColorSecondary}
                  />,
                  <SearchIcon />
                )}
              </InputLeftElement>

              <Input
                _focus={{ borderColor: borderColor, boxShadow: "none" }}
                type={"text"}
                name={"search"}
                placeholder={"Search"}
                py="23px"
                pl={"50px"}
                borderRadius={"12px"}
                _placeholder={{ color: "#C7C7C7" }}
                readOnly={true}
                onClick={() => setFilterDropDownShow(!filterDropdownShow)}
              />

              <InputRightElement py="23px" cursor="pointer">
                <Image
                  src={filterIcon}
                  cursor={"pointer"}
                  onClick={() => setFilterDropDownShow(!filterDropdownShow)}
                />
              </InputRightElement>
            </InputGroup>
          </Box>
        </Flex>
        {filterDropdownShow && (
          <Flex mt="-35px" justify={"end"}>
            <Box
              position={"relative"}
              zIndex={10}
              padding={"24px"}
              borderRadius={"16px"}
              border={`1px solid ${borderColor2}`}
              bg={bgColor}
              boxShadow={"0px 4px 40px 0px rgba(0,0,0,0.10)"}
              w={["100%", "100%", "40%", "40%"]}
            >
              <FilterDropDown
                toggleFilter={toggleFilter}
                appliedFilters={appliedFilters}
                setAppliedFilters={setAppliedFilters}
                clearFilters={clearFilters}
              />
            </Box>
          </Flex>
        )}
        <Flex
          mt={filterDropdownShow ? "-414px" : "0px"}
          align={["start", "start", "start", "center"]}
          justify={"space-between"}
          mb={"20px"}
          direction={["column", "column", "column", "row"]}
        >
          <Flex
            gap={"24px"}
            align={["start", "start", "start", "center"]}
            direction={["column", "column", "column", "row"]}
          >
            <Text
              color={paraColor}
              fontSize={"16px"}
              fontWeight={"400"}
              lineHeight={"22px"}
              letterSpacing={"0.024px"}
            >
              Applied Filters:
            </Text>
            {(appliedFilters?.wallet || appliedFilters?.token) && (
              <Flex
                align={["start", "start", "center", "center"]}
                direction={["column", "column", "row", "row"]}
                gap={"16px"}
                color={"#EEE"}
                fontSize={["8px", "9px", "11px", "12px"]}
                fontWeight={"400"}
                letterSpacing={"0.048px"}
                mb={["10px", "10px", "0px", "0px"]}
              >
                {appliedFilters?.token && (
                  <Text
                    padding={"10px 12px"}
                    display={"flex"}
                    alignItems={"center"}
                    gap={"8px"}
                    borderRadius={"28px"}
                    bg={"#00332D"}
                  >
                    {appliedFilters?.symbol}{" "}
                    <Image
                      src={cross}
                      alt="cross"
                      cursor={"pointer"}
                      onClick={() =>
                        setAppliedFilters({ ...appliedFilters, token: "" })
                      }
                    />
                  </Text>
                )}
                {appliedFilters?.wallet && (
                  <Text
                    padding={"10px 12px"}
                    display={"flex"}
                    alignItems={"center"}
                    gap={"8px"}
                    borderRadius={"28px"}
                    bg={"#00332D"}
                    cursor={"pointer"}
                    onClick={() =>
                      setAppliedFilters({ ...appliedFilters, wallet: "" })
                    }
                  >
                    {appliedFilters?.wallet} <Image src={cross} alt="cross" />
                  </Text>
                )}
                <Text
                  color={paraColor2}
                  fontWeight={"500"}
                  letterSpacing={"0.15px"}
                  textDecorationLine={"underline"}
                  textTransform={"capitalize"}
                  padding={"10px 12px"}
                  display={"flex"}
                  alignItems={"center"}
                  gap={"8px"}
                  onClick={() =>
                    setAppliedFilters({
                      wallet: "",
                      token: "",
                      symbol: "",
                    })
                  }
                  cursor={"pointer"}
                >
                  clear all filters <Image src={crossRed} alt="cross-red" />
                </Text>
              </Flex>
            )}
          </Flex>
          <Button
            display={"flex"}
            padding={"8px 16px"}
            alignItems={"center"}
            gap={"8px"}
            borderRadius={"8px"}
            border={`1px solid ${borderColor}`}
            bg={bg}
            color={color}
            fontSize={["8px", "9px", "11px", "12px"]}
            fontWeight={"400"}
            letterSpacing={"0.048px"}
            mt={["10px", "10px", "10px", "0px"]}
            _hover={{ bg: bg }}
            _active={{ bg: bg }}
            position={"relative"}
            isDisabled={csvLoading}
            onClick={() => {
              const blob = new Blob([csvData], { type: "text/csv" });
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = "wallet_transactions.csv";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
          >
            <Image src={cloudBtn} alt="cloud" />
            {csvLoading ? <Spinner /> : "Download History"}
          </Button>
        </Flex>
        <CustomTabs
          titles={[
            { sm: "Crypto", md: "Crypto Transactions" },
            { sm: "Fiat", md: "Fiat Transactions" },
          ]}
          onTabChange={(tab: any) => {
            if (tab == 0) {
              setActiveTab("crypto");
            } else {
              setActiveTab("fiat");
            }
            clearFilters();
          }}
        >
          {isLoading ? (
            <Spinner />
          ) : (
            <CustomTable
              rows={rows}
              columns={columns}
              borderB={true}
              pagination={true}
            />
          )}
          {fiatLoading ? (
            <Spinner />
          ) : (
            <CustomTable
              rows={fiatRows}
              columns={fiatColumns}
              borderB={true}
              pagination={true}
            />
          )}
        </CustomTabs>
      </Box>
    </AdminLayout>
  );
};

export default WalletTransactions;
