import {
  Box,
  Flex,
  IconButton,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import theme from "../theme/theme";
import NotificationCard from "./NotificationCard";
import upArrow from "../assets/upArrowRed.svg";
import upArrowDark from "../assets/upArrowRedDark.svg";
import downArrowGreen from "../assets/downArrowGreen.svg";
import downArrowDark from "../assets/downArrowGreenDark.svg";
import arrows from "../assets/arrows.svg";
import dotLight from "../assets/icons/light/bell-dot.svg";
import dotDark from "../assets/icons/dark/bellDot.svg";

import {
  useGetNotificationHistory,
  useMarkAsReadNotifications,
} from "../utils/auth.api";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const NotificationDropDown = ({ bellIcon }: any) => {
  const navigate = useNavigate();
  const { data } = useGetNotificationHistory();

  const [isOpen, setIsOpen] = useState(false);

  const btnColor = useColorModeValue(
    theme.colors.light.accent.primary,
    theme.colors.dark.accent.primary
  );
  const btnBG = useColorModeValue(
    theme.colors.light.accent.secondary,
    theme.colors.dark.accent.secondary
  );

  const markAsReadMutation = useMarkAsReadNotifications();

  const textColor = useColorModeValue("#000", "#EEE");
  const textColor2 = useColorModeValue("#333", "#EEE");
  const bgColor = useColorModeValue("#FFF", "#121B18");
  const borderColor = useColorModeValue("#FFF", "#333");
  const icon1 = useColorModeValue(upArrow, upArrowDark);
  const icon2 = useColorModeValue(downArrowGreen, downArrowDark);
  const icon3 = useColorModeValue(arrows, arrows);
  const dotIcon = useColorModeValue(dotLight, dotDark);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const timeAgo = (createdAt: string) => {
    const currentDate: any = new Date();
    const createdDate: any = new Date(createdAt);
    const timeDifference: any = currentDate - createdDate;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (days > 0) {
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    } else if (hours === 1) {
      return `${hours} hour ago`;
    } else if (hours > 1) {
      return `${hours} hours ago`;
    } else if (minutes > 0) {
      return `${minutes} mins ago`;
    } else {
      return `${seconds} seconds ago`;
    }
  };
  const getIconByType = (type: any) => {
    const lowercaseType = type.toLowerCase();

    if (lowercaseType.includes("deposit")) {
      return icon2;
    } else if (lowercaseType.includes("withdraw")) {
      return icon1;
    } else if (lowercaseType.includes("swap")) {
      return icon3;
    } else {
      return icon1;
    }
  };

  const hasNotifications = data?.some(
    (notification: any) => !notification?.isRead
  );

  return (
    <div>
      <Popover>
        <PopoverTrigger>
          <Box position="relative">
            <IconButton
              icon={<Image src={bellIcon} />}
              color={btnColor}
              bg={btnBG}
              borderRadius={"14px"}
              onClick={handleOpen}
              aria-label={""}
              w="30px"
            />
            {hasNotifications && (
              <Image
                src={dotIcon}
                position="absolute"
                top="7px"
                right="8px"
                // w="8px"
                // h="8px"
                borderRadius="50%"
              />
            )}
          </Box>
        </PopoverTrigger>
        <PopoverContent
          mr={"30px"}
          w={["220px", "330px", "380px", "430px"]}
          zIndex={222}
          position={"relative"}
          overflowY="auto"
          bg={bgColor}
          boxShadow={"0px 4px 40px 0px rgba(0,0,0,0.10)"}
          border={`1px solid ${borderColor}`}
          borderRadius={"16px"}
        >
          <PopoverBody
            p={["12px", "15px", "19px", "24px"]}
            maxH={"80vh"}
            sx={{
              overflowY: "auto",
              scrollbarWidth: "thin",
              scrollbarColor: "#888 transparent", // Change color as needed
              "&::-webkit-scrollbar": {
                width: "4px", // Adjust width as needed
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888", // Change color as needed
                borderRadius: "4px", // Adjust radius as needed
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
              },
            }}
          >
            <Flex justify={"space-between"} align={"center"} mb="30px">
              <Text
                color={textColor}
                fontSize={["10px", "12px", "14px", "16px"]}
                fontWeight={"700"}
                lineHeight={"22px"}
                letterSpacing={"0.08px"}
              >
                Notifications
              </Text>
              <Text
                color={"#00CCB3"}
                fontSize={["9px", "11px", "13px", "14px"]}
                fontWeight={"400"}
                letterSpacing={"0.014px"}
                cursor={"pointer"}
                onClick={async () => {
                  try {
                    await markAsReadMutation?.mutateAsync();
                    toast.success("Marked all Notifications as Read!!!");
                  } catch (error: any) {
                    toast.error(error?.message);
                  }
                }}
              >
                Mark all as read
              </Text>
            </Flex>
            {data?.map((item: any) => (
              <NotificationCard
                icon={getIconByType(item?.type)}
                type={item?.type}
                notificationTitle={item?.notificationTitle}
                isRead={item?.isRead}
                notificationBody={item?.notificationBody}
                time={timeAgo(item?.createdAt)}
                active={bgColor === "#FFF" ? true : false}
                onClick={() => {
                  if (
                    item?.type === "swap" ||
                    item?.type === "deposit" ||
                    item?.type === "withdraw"
                  ) {
                    navigate("/dashboard/transactions");
                  } else if (item?.type?.toLowerCase()?.includes("airtime")) {
                    navigate("/dashboard/services/airtime");
                  } else if (item?.type?.toLowerCase()?.includes("internet")) {
                    navigate("/dashboard/services/internet");
                  } else if (
                    item?.type?.toLowerCase()?.includes("electricity")
                  ) {
                    navigate("/dashboard/services/electricity");
                  } else if (
                    item?.type?.toLowerCase()?.includes("tv-subscription")
                  ) {
                    navigate("/dashboard/services/tv-subscription");
                  } else navigate("/dashboard/index");
                }}
              />
            ))}

            {/* <NotificationCard
              icon={icon2}
              type="withdrawal"
              amount="378.89 ETH"
              time="12h ago"
              active={false}
            />
            <hr />
            <Text
              color={textColor2}
              fontSize={["10px", "12px", "14px", "16px"]}
              fontWeight={"400"}
              lineHeight={"22px"}
              letterSpacing={"0.024px"}
              mt="16px"
              mb={"24px"}
            >
              Yesterday
            </Text>
            <NotificationCard
              icon={icon1}
              type="deposit"
              amount="378.89 NGN"
              time="14:00"
              active={false}
            />
            <NotificationCard
              icon={icon2}
              type="withdrawal"
              amount="378.89 BTC"
              time="19:27"
              active={false}
            />
            <hr />
            <Text
              color={textColor2}
              fontSize={["10px", "12px", "14px", "16px"]}
              fontWeight={"400"}
              lineHeight={"22px"}
              letterSpacing={"0.024px"}
              mt="16px"
              mb={"16px"}
            >
              This Week
            </Text>
            <NotificationCard
              icon={icon1}
              type="deposit"
              amount="378.89 NGN"
              time="19:27"
              active={false}
            /> */}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default NotificationDropDown;
