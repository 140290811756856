import { CheckIcon } from "@chakra-ui/icons";
import {
  Divider,
  Flex,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

const SelectCryptoDropdownItem = ({
  coin,
  isMenuOpen,
  setIsMenuOpen,
  coinsData,
  selectedCoin,
  setSelectedCoin,
  selectedTextColor,
}: any) => {
  const tickColor = useColorModeValue("#EEEEEE", "#5A5A5A");

  return (
    <React.Fragment key={coin.id}>
      <Flex
        h="60px"
        justify="space-between"
        align="center"
        cursor="pointer"
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
          setSelectedCoin(coinsData[coin?.id - 1]);
        }}
      >
        <Flex gap="10px" align="center">
          <Image w="20px" src={coin.icon} borderRadius="50%" />{" "}
          <Text
            fontSize="16px"
            color={selectedCoin?.id === coin?.id ? selectedTextColor : ""}
          >
            {coin.symbol}
          </Text>{" "}
        </Flex>
        <CheckIcon
          color={selectedCoin?.id === coin?.id ? "teal.300" : tickColor}
        />
      </Flex>
      <Divider />
    </React.Fragment>
  );
};

export default SelectCryptoDropdownItem;
