import React, { useState, useEffect } from "react";
import AdminLayout from "../layouts/AdminLayout";
import {
  Box,
  Button,
  Circle,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import CustomHeader from "../components/CustomHeader";
import CustomTabs from "../components/CustomTabs";
import theme from "../theme/theme";
import trc from "../assets/trc.svg";
import eth from "../assets/ethereum.svg";
import ton from "../assets/ton.svg";
import PrimaryButton from "../components/PrimaryButton";
import qrBlack from "../assets/imgs/qr.svg";
import qrWhite from "../assets/imgs/qrWhite.svg";
import nairaLight from "../assets/icons/light/naira.svg";
import nairaActive from "../assets/icons/light/nairaActive.svg";
import nairaDark from "../assets/icons/dark/naira.svg";
import copyLight from "../assets/icons/light/copy.svg";
import copyDark from "../assets/icons/dark/copy.svg";
import arrowRightLight from "../assets/icons/light/arrowRight.svg";
import arrowRightDark from "../assets/icons/dark/arrowRight.svg";
import cross from "../assets/icons/cross.svg";
import UserInfoInput from "../components/UserInfoInput";
import SelectCrypto from "../components/SelectCrypto";
import SelectCryptoDropdown from "../components/SelectCryptoDropdown";
import BankSelectionCard from "../components/BankSelectionCard";
import BankDetailsCard from "./BankDetailsCard";
import { useNavigate } from "react-router-dom";
import { copyFile } from "fs";
import {
  useGetWalletWithBalance,
  useGetDepositAddress,
} from "../utils/wallet.api";
import { useGetBankAccounts, useGetLoggedInUser } from "../utils/auth.api";
import QRCode from "react-qr-code";
import CustomModal from "../components/CustomModal";
import failedIcon from "../assets/failedIcon.svg";
import NetworkCard from "../components/NetworkCard";
import { useGetStableCoinsAndNetworks } from "../utils/coins.api";

const Deposit = () => {
  const navigate = useNavigate();
  const { data: banksData, isLoading: bankAccountsLoading } =
    useGetBankAccounts();

  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  const [errorMsg, setErrorMsg] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { isLoading, data } = useGetWalletWithBalance();
  const { isLoading: loggedInLoading, data: loggedInUser } =
    useGetLoggedInUser();
  const [stableType, setStableType] = useState("USDT");
  const { data: stableCoinsAndNetworks } =
    useGetStableCoinsAndNetworks(stableType);
  const [selectedNetwork, setSelectedNetwork]: any = useState<any>();

  const coinsData = data?.balance?.map((item: any, idx: any) => ({
    id: idx + 1,
    symbol: item.symbol?.includes("USDT")
      ? "USDT"
      : item.symbol?.includes("USDC")
      ? "USDC"
      : item.symbol,
    name: item.name,
    icon:
      item?.symbol?.includes("USDT") || item?.symbol?.includes("USDC")
        ? item?.symbol?.includes("USDT") &&
          selectedNetwork?.symbol?.includes("USDT")
          ? selectedNetwork?.networkInfo?.logoUrl
          : item?.symbol?.includes("USDC") &&
            selectedNetwork?.symbol?.includes("USDC")
          ? selectedNetwork?.networkInfo?.logoUrl
          : item.networkLogoUrl
        : item.logoUrl,
    address: item.address,
    balance: item.balance,
    networkId: item.networkId,
    isStable:
      item.isStable ||
      item?.symbol?.includes("USDT") ||
      item?.symbol?.includes("USDC"),
  }));
  // const sampleNetworkData = stableCoinsAndNetworks?.map(
  //   (item: any, idx: any) => ({
  //     // selected: selectedNetwork?.title === "Tron",
  //     index: idx,
  //     icon: item?.icon, // Replace with actual URL to the icon
  //     title: item?.name,
  //     subTitle: item?.symbol,
  //     minWithdraw: "25",
  //     currency: "USDT",
  //     time: 2,
  //     address: item?.addressInfo?.address,
  //   })
  // );

  const [selectedCoin, setSelectedCoin] = useState<any>("");
  const { data: networkAddress, isLoading: networkAddressLoading } =
    useGetDepositAddress(selectedCoin?.networkId);
  const [selectedBank, setSelectedBank] = useState(1);
  const [depositAmount, setDepositAmount] = useState<any>();

  const [selected, setSelected] = useState("NGN");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toast = useToast();

  const borderColor = useColorModeValue(
    theme.colors.light.border.secondary,
    theme.colors.dark.border.secondary
  );
  const color = useColorModeValue(
    theme.colors.light.text.primary,
    theme.colors.dark.text.primary
  );

  const menuBG = useColorModeValue("white", "black");
  const cardBG = useColorModeValue(
    theme.colors.light.bgSecondary,
    theme.colors.dark.bgSecondary
  );
  const darkerColor = useColorModeValue("#5A5A5A", "#C7C7C7");
  const darkestColor = useColorModeValue("#333", "#C7C7C7");
  const paraColor2 = useColorModeValue("#5A5A5A", "#EEE");
  const lableColor = useColorModeValue("#333", "#EEE");
  const headingColor = useColorModeValue("#333", "#FFF");
  const subHeadingColor = useColorModeValue("#909090", "#EEE");
  const closeBG = useColorModeValue("#F9F9F9", "#00FFE0");
  const modalBG = useColorModeValue("#FFF", "#121B18");
  const modalText = useColorModeValue("#5A5A5A", "#EEE");
  const modalBR = useColorModeValue("#EEEF", "#333");

  const qrBG = useColorModeValue(qrBlack, qrWhite);
  const nairaIcon = useColorModeValue(nairaLight, nairaDark);
  const nairaActiveIcon = useColorModeValue(nairaActive, nairaDark);
  const copyIcon = useColorModeValue(copyLight, copyDark);
  const arrowRightIcon = useColorModeValue(arrowRightLight, arrowRightDark);
  const [selectedBankDetail, setSelectedBankDetail] = useState<any>();
  const nairaDepositReference = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const stringLength = 10;
    let randomString = "";

    for (let i = 0; i < stringLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters?.length);
      randomString += characters.charAt(randomIndex);
    }
    localStorage.setItem("referenceCode", randomString);
    return randomString;
  };

  // Example usage

  const [modal, setModal] = useState(false);
  const handleModal = (type = "") => {
    // setStableType(type);
    setModal(!modal);
  };
  const [referenceCode, setReferenceCode] = useState("");

  useEffect(() => {
    if (data) {
      setSelectedCoin(coinsData[0]);
    }
    setSelectedBankDetail(banksData?.bankDetails?.[0]);
  }, [data, banksData]);

  useEffect(() => {
    if (stableCoinsAndNetworks && !selectedNetwork) {
      setSelectedNetwork(stableCoinsAndNetworks?.[0]);
      // setSelectedCoin(stableCoinsAndNetworks?.[0]);
    }
  }, [stableCoinsAndNetworks]);

  // useEffect(() => {
  //   if (data) {
  //     setSelectedCoin(coinsData[selectedIndex]);
  //   }
  // }, [selectedNetwork]);

  useEffect(() => {
    if (selectedCoin?.symbol?.includes("USDC")) {
      setStableType("USDC");
      setSelectedNetwork(stableCoinsAndNetworks?.[0]);
    }
    if (selectedCoin?.symbol?.includes("USDT")) {
      setStableType("USDT");
      setSelectedNetwork(stableCoinsAndNetworks?.[0]);
    }
  }, [selectedCoin, stableCoinsAndNetworks]);

  useEffect(() => {
    nairaDepositReference();
  }, []);

  return (
    <AdminLayout>
      <Box marginTop="72px" marginRight={"2%"} mb={"50px"} w={"95%"}>
        <Box marginBottom={"36px"}>
          <CustomHeader
            para1="Payremit Wallet"
            header="Deposit"
            amount="29.00082"
            setSelected={setSelected}
            data={data}
            isLoading={isLoading}
          />
        </Box>
        <CustomTabs
          titles={[
            { sm: "Crypto", md: "Cryptocurrency" },
            { sm: "Naira", md: "Nigerian Naira" },
          ]}
        >
          <Flex
            direction={{ base: "column", lg: "row" }}
            gap="24px"
            align={{ base: "center", lg: "start" }}
          >
            <Box w={{ base: "100%", lg: "40%" }}>
              {isLoading ? (
                <Flex justify={"center"} w={"100%"}>
                  <Spinner />
                </Flex>
              ) : (
                <SelectCrypto
                  isMenuOpen={isMenuOpen}
                  setIsMenuOpen={setIsMenuOpen}
                  selectedCoin={selectedCoin}
                  selectedNetwork={selectedNetwork}
                />
              )}
              {isMenuOpen && (
                <SelectCryptoDropdown
                  {...{
                    isMenuOpen,
                    setIsMenuOpen,
                    selectedCoin,
                    setSelectedCoin,
                    menuData: coinsData,
                  }}
                />
              )}
              <Box textAlign={"center"} my="80px">
                <Text
                  fontWeight={"700"}
                  fontSize={{ base: "12px", sm: "16px" }}
                >
                  Available Balance
                </Text>
                <Flex align={"end"} wrap={"wrap"} gap="8px" justify={"center"}>
                  <Heading
                    color={color}
                    fontSize={{ base: "16px", sm: "20px", md: "36px" }}
                    wordBreak={"break-word"}
                  >
                    {isLoading ? (
                      <Spinner />
                    ) : selectedCoin?.symbol?.split(" ")[0] === "ETH" ||
                      selectedCoin?.symbol?.split(" ")[0] === "BTC" ? (
                      Number(selectedCoin?.balance)?.toLocaleString(undefined, {
                        minimumFractionDigits: 5,
                        maximumFractionDigits: 5,
                      })
                    ) : (
                      Number(selectedCoin?.balance)?.toLocaleString(undefined, {
                        minimumFractionDigits: 5,
                        maximumFractionDigits: 5,
                      })
                    )}
                  </Heading>
                  <Text
                    fontWeight={"700"}
                    fontSize={{ base: "12px", sm: "16px" }}
                  >
                    {/* {selectedCoin?.symbol?.split(" ")[0] === "USDT"
                      ? `USDT (${selectedCoin?.symbol?.split("(")[1]}`
                      : selectedCoin?.symbol?.split(" ")[0]} */}
                    {selectedCoin?.symbol}
                  </Text>
                </Flex>
              </Box>
              {/* working */}
              <Flex direction={"column"} gap={"24px"}>
                {/* choose network */}
                {(selectedCoin?.symbol?.includes("USDT") ||
                  selectedCoin?.symbol?.includes("USDC")) && (
                  <Flex direction={"column"} gap={"16px"}>
                    <Text
                      textColor={lableColor}
                      fontSize={"16px"}
                      fontWeight={400}
                      lineHeight={"22px"}
                    >
                      Network
                    </Text>
                    <Flex
                      justify={"space-between"}
                      py="16px"
                      px={["5px", "16px"]}
                      border={`1px solid ${borderColor}`}
                      borderRadius={"12px"}
                      gap="5px"
                      align={"center"}
                      direction={{ base: "column", md: "row" }}
                    >
                      <Box textAlign={{ base: "center", md: "left" }}>
                        <Text fontWeight={"700"} color={darkerColor}>
                          Select Network
                        </Text>
                        <Flex gap={"4px"}>
                          <Text
                            textColor={headingColor}
                            fontFamily={"Plus Jakarta Sans"}
                            fontSize={"16px"}
                            fontWeight={700}
                            lineHeight={"22px"}
                            letterSpacing={"1.2px"}
                          >
                            {selectedNetwork?.networkInfo?.networkName}
                          </Text>

                          <Text
                            textColor={subHeadingColor}
                            fontFamily={"Plus Jakarta Sans"}
                            fontSize={"12px"}
                            fontWeight={400}
                            lineHeight={"22px"}
                            letterSpacing={"0.048px"}
                          >
                            {selectedNetwork?.networkInfo?.tokenStandard}
                          </Text>
                        </Flex>
                      </Box>
                      <Box mx={{ base: "auto", sm: "0" }}>
                        <PrimaryButton
                          text={"Choose"}
                          icon={<Image src={arrowRightIcon} />}
                          mt="0px"
                          disabled={networkAddressLoading}
                          onClick={() =>
                            handleModal(
                              selectedCoin?.symbol?.includes("USDT")
                                ? "USDT"
                                : "USDC"
                            )
                          }
                        />
                      </Box>
                    </Flex>
                  </Flex>
                )}

                {/* memo */}
                {(selectedCoin?.symbol?.toLowerCase()?.includes("ton") ||
                  (selectedCoin?.isStable &&
                    selectedNetwork?.networkInfo?.networkName
                      ?.toLowerCase()
                      ?.includes("ton"))) && (
                  <Flex direction={"column"} gap={"16px"}>
                    <Text
                      textColor={lableColor}
                      fontSize={"16px"}
                      fontWeight={400}
                      lineHeight={"22px"}
                    >
                      Memo
                    </Text>
                    <Flex
                      justify={"space-between"}
                      py="16px"
                      px={["5px", "16px"]}
                      border={`1px solid ${borderColor}`}
                      borderRadius={"12px"}
                      gap="5px"
                      align={"center"}
                      direction={{ base: "column", md: "row" }}
                    >
                      <Box textAlign={{ base: "center", md: "left" }}>
                        <Text fontWeight={"700"} color={darkerColor}>
                          Memo
                        </Text>
                        <Text wordBreak={"break-word"}>
                          {networkAddressLoading ? (
                            <Spinner />
                          ) : (
                            data?.wallet?.memo
                          )}
                        </Text>
                      </Box>
                      <Box mx={{ base: "auto", sm: "0" }}>
                        <PrimaryButton
                          text={"Copy"}
                          icon={<Image src={copyIcon} />}
                          mt="0px"
                          disabled={networkAddressLoading}
                          onClick={() => {
                            navigator.clipboard.writeText(data?.wallet?.memo);
                            toast({
                              title: "Copied to clipboard.",
                              // description: "We've created your account for you.",
                              status: "success",
                              duration: 3000,
                              isClosable: true,
                            });
                          }}
                        />
                      </Box>
                    </Flex>
                  </Flex>
                )}
                {/* deposited address */}
                <Flex direction={"column"} gap={"16px"}>
                  {" "}
                  <Text
                    textColor={lableColor}
                    fontSize={"16px"}
                    fontWeight={400}
                    lineHeight={"22px"}
                  >
                    Address
                  </Text>
                  <Flex
                    justify={"space-between"}
                    py="16px"
                    px={["5px", "16px"]}
                    border={`1px solid ${borderColor}`}
                    borderRadius={"12px"}
                    gap="5px"
                    align={"center"}
                    direction={{ base: "column", md: "row" }}
                  >
                    <Box textAlign={{ base: "center", md: "left" }}>
                      <Text fontWeight={"700"} color={darkerColor}>
                        Deposit Address
                      </Text>
                      <Text wordBreak={"break-word"}>
                        {networkAddressLoading ? (
                          <Spinner />
                        ) : selectedCoin?.isStable ? (
                          selectedNetwork?.addressInfo?.address
                        ) : (
                          networkAddress?.address
                        )}
                      </Text>
                    </Box>
                    <Box mx={{ base: "auto", sm: "0" }}>
                      <PrimaryButton
                        text={"Copy"}
                        icon={<Image src={copyIcon} />}
                        mt="0px"
                        disabled={networkAddressLoading}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            selectedCoin?.isStable
                              ? selectedNetwork?.addressInfo?.address
                              : networkAddress?.address
                          );
                          toast({
                            title: "Copied to clipboard.",
                            // description: "We've created your account for you.",
                            status: "success",
                            duration: 3000,
                            isClosable: true,
                          });
                        }}
                      />
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
            </Box>
            <Box
              w={{ base: "100%", lg: "58%" }}
              p={["10px", "24px"]}
              borderRadius={"12px"}
              border="1px solid"
              borderColor={useColorModeValue("transparent", "#333")}
              bgColor={useColorModeValue("#f9f9f9", "transparent")}
            >
              <Text fontWeight={700} color={darkerColor}>
                Token Address
              </Text>
              <Flex
                bg={useColorModeValue("#FFF", "#121B18")}
                border={"1px solid"}
                borderColor={useColorModeValue("#EEE", "#333")}
                // h="40px"
                borderRadius={"20px"}
                px="8px"
                justify="space-between"
                my="32px"
                py="8px"
                align="center"
                wrap={"wrap"}
                gap={["5px", "15px", "30px"]}
              >
                {isLoading ? (
                  <Flex justify={"center"} w={"100%"}>
                    <Spinner />
                  </Flex>
                ) : (
                  coinsData?.map((coin: any, idx: any) => (
                    <>
                      <Flex
                        key={idx}
                        align={"center"}
                        justify={"center"}
                        borderRadius={"30px"}
                        bgColor={coin?.id === selectedCoin?.id ? "#00FFE0" : ""}
                        fontSize={"10px"}
                        h="24px"
                        px="8px"
                        color={coin?.id === selectedCoin?.id ? "black" : ""}
                        fontWeight={700}
                        cursor={"pointer"}
                        onClick={() => setSelectedCoin(coin)}
                      >
                        <Text>
                          {/* {coin?.symbol.split(" ")[0] === "USDT"
                            ? `USDT (${coin?.symbol?.split("(")[1]}`
                            : coin?.symbol?.split(" ")[0]} */}
                          {coin?.symbol}
                        </Text>
                      </Flex>
                    </>
                  ))
                )}
              </Flex>
              <Text textAlign={"center"} fontSize={"12px"}>
                {isLoading ? <Spinner /> : selectedCoin?.name} QR Code
              </Text>
              <Flex my="32px" justify={"center"}>
                {/* <Flex
                  w="193px"
                  h="193px"
                  bgImage={`url(${qrBG})`}
                  align={"center"}
                  justify={"center"}
                >
                  <Flex
                    w="80px"
                    h="80px"
                    bg={menuBG}
                    borderRadius={"40px"}
                    justify={"center"}
                    align={"center"}
                  >
                    {isLoading ? (
                      <Spinner />
                    ) : (
                      <Image w="42px" src={selectedCoin?.icon} h={"42px"} />
                    )}
                  </Flex>
                </Flex> */}
                {networkAddressLoading ? (
                  <Spinner />
                ) : (
                  <>
                    <QRCode
                      size={220}
                      style={{
                        position: "relative",
                      }}
                      value={
                        (selectedCoin?.isStable
                          ? selectedNetwork?.addressInfo?.address
                          : networkAddress?.address) || "qr-code"
                      }
                      viewBox={`0 0 256 256`}
                    />
                    {/* <Flex
                    w="80px"
                    h="80px"
                    position={'relative'} left='-150px' top='70px'
                    bg={menuBG}
                    borderRadius={"40px"}
                    justify={"center"}
                    align={"center"}
                  >
                    {isLoading ? (
                      <Spinner />
                    ) : (
                      <Image w="42px" src={selectedCoin?.icon} h={"42px"} />
                    )}
                  </Flex> */}
                    {/* {isLoading ? (
                      <Spinner />
                    ) : (
                      <Image w="42px" src={selectedCoin?.icon} h={"42px"} position={'relative'} left='-131px' top='89px' />
                    )} */}
                  </>
                )}
              </Flex>
              <Text
                textAlign={"center"}
                fontSize={"14px"}
                maxW="324px"
                mx="auto"
              >
                Send only the specified coins to this deposit address. This
                address does NOT support deposit of non-fungible token.
              </Text>
            </Box>
          </Flex>
          <Flex
            direction={{ base: "column", lg: "row" }}
            gap="24px"
            align={{ base: "center", lg: "start" }}
          >
            <Box w={{ base: "100%", lg: "58%" }}>
              <Text mt="32px" color={darkestColor}>
                Amount you sent
              </Text>
              <UserInfoInput
                type="number"
                icon={nairaIcon}
                activeIcon={nairaActiveIcon}
                placeholder="00.00"
                value={depositAmount}
                onChange={(e: any) => setDepositAmount(e.target.value)}
              />
              <Text mt="30px" color={darkestColor}>
                Select Deposit Account
              </Text>
              <Text mt="10px">
                Ensure that your deposit account and profile names match, or the
                payment won't be processed.
              </Text>
              {banksData?.bankDetails?.map((item: any, idx: number) => (
                <>
                  {bankAccountsLoading ? (
                    <Spinner />
                  ) : banksData ? (
                    item.accountNumber && (
                      <BankSelectionCard
                        onClick={() => {
                          setSelectedBank(idx + 1);
                          setSelectedBankDetail(item);
                        }}
                        isActive={selectedBank === idx + 1}
                        bankName={item.bankName}
                        accountNumber={item.accountNumber}
                        accountName={item.accountName}
                      />
                    )
                  ) : (
                    <Text>No Data Available</Text>
                  )}
                </>
              ))}
              <PrimaryButton
                text="Continue"
                disabled={
                  !selectedBankDetail ||
                  loggedInLoading ||
                  !depositAmount ||
                  depositAmount <= 0
                }
                onClick={() => {
                  if (Number(loggedInUser?.kycTier) === 1) {
                    // toast({
                    //   title: "Upgrade your level to enable Fiat desposit.",
                    //   status: "error",
                    //   duration: 3000,
                    //   isClosable: true,
                    // });
                    onErrorOpen();
                    setErrorMsg("Upgrade your level to enable Fiat deposit.");
                  } else if (
                    Number(loggedInUser?.kycTier) === 2 &&
                    Number(depositAmount) > 5000000
                  ) {
                    // toast({
                    //   title:
                    //     "While you are on Level 2, you can only deposit upto 5M Naira. Please upgrade your level.",
                    //   status: "error",
                    //   duration: 3000,
                    //   isClosable: true,
                    // });
                    onErrorOpen();
                    setErrorMsg(
                      "While you are on Level 2, you can only deposit upto 5M Naira. Please upgrade your level."
                    );
                  } else {
                    navigate("/dashboard/deposit/summary", {
                      state: {
                        bank: selectedBankDetail,
                        depositAmount: depositAmount,
                      },
                    });
                  }
                }}
              />
            </Box>
            <BankDetailsCard />
          </Flex>
        </CustomTabs>
      </Box>
      <CustomModal
        isOpen={isErrorOpen}
        onClose={() => {
          onErrorClose();
          // navigate("/dashboard/index");
        }}
        noFooter={true}
        onSubmit={() => {
          onErrorClose();
        }}
      >
        <Flex
          justify={"center"}
          align={"center"}
          direction={"column"}
          gap="24px"
          mb="32px"
          mt={"32px"}
        >
          <Image src={failedIcon} w={"56px"} h={"56px"} />
          <Heading
            fontFamily={"Audiowide"}
            fontSize={["15px", "18px", "21px", "24px"]}
            fontWeight={"400"}
          >
            Transaction Failed!
          </Heading>
          <Text
            textAlign={"center"}
            fontSize={["9px", "11px", "13px", "14px"]}
            fontWeight={"400"}
            lineHeight={"20px"}
            letterSpacing={"0.035px"}
            color={paraColor2}
          >
            {errorMsg}
          </Text>
        </Flex>
      </CustomModal>

      {/* modal open on coin selection */}
      <Modal isOpen={modal} onClose={handleModal}>
        <ModalOverlay
          width={"100%"}
          height={"100%"}
          position={"absolute"}
          background={"rgba(0, 0, 0, 0.50)"}
          //bg={"Red"}
          backdropBlur={"3px"}
        />
        <ModalContent
          position={"absolute"}
          p={"7px"}
          maxWidth={"600px"}
          display={"flex"}
          flexDirection={"column"}
          gap={"24px"}
          bg={modalBG}
        >
          <ModalHeader
            display={"flex"}
            flexDirection={"row"}
            width={"100%"}
            justifyContent={"space-between"}
            borderBottom={`1px solid ${modalBR}`}
          >
            Select Network
            <Circle
              size={"32px"}
              alignItems={"center"}
              bg={"#00FFE0"}
              cursor={"pointer"}
              onClick={() => handleModal("")}
            >
              <Image src={cross} alt={"close"} boxSize="16px" />
            </Circle>
          </ModalHeader>

          <ModalBody>
            <Text
              textColor={modalText}
              fontSize={"14px"}
              fontWeight={400}
              lineHeight={"20px"}
              letterSpacing={"0.035px"}
            >
              Please ensure you select the correct chain, as choosing the wrong
              one will result in the permanent loss of your assets
            </Text>

            <Flex direction={"column"} gap={"16px"} mt={"32px"}>
              {stableCoinsAndNetworks?.map((network: any, index: any) => (
                <NetworkCard
                  key={index}
                  onClick={() => {
                    setSelectedNetwork(network);
                  }}
                  selected={selectedNetwork}
                  idx={index}
                  {...network}
                />
              ))}
            </Flex>
          </ModalBody>
          <ModalFooter borderTop={`1px solid ${modalBR}`}>
            <Button
              mr={3}
              onClick={() => handleModal("")}
              borderRadius={"12px"}
              border={`1px solid #C7C7C7`}
              bg={"#EEE"}
              textColor={"#00332D"}
              _hover={{
                bg: "#EEE",
                color: "#00332D",
              }}
            >
              Cancel
            </Button>

            <Button
              onClick={() => handleModal("")}
              borderRadius={"12px"}
              border={`1px solid #00CCB3`}
              bg={"#00FFE0"}
              textColor={"#00332D"}
              _hover={{
                bg: "#00FFE0",
                color: "#00332D",
              }}
            >
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </AdminLayout>
  );
};

export default Deposit;
