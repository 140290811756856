import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Naira from "../assets/NairaSymbol.svg";
import NairaWhite from "../assets/NairaWhite.svg";
import bgPic from "../assets/headerBg.svg";
import tickIcon from "../assets/tickIcon.svg";
import { ChevronDownIcon } from "@chakra-ui/icons";
import tickGreen from "../assets/tickIconGreen.svg";
import btcGreen from "../assets/btcGreen.svg";
import btcWhite from "../assets/btcWhite.svg";
import usd from "../assets/usd.svg";
import usdGreen from "../assets/usdGreen.svg";

interface CustomHeaderProps {
  para1: string;
  header: string;
  amount: string;
  setSelected?: any;
  para2?: string;
  para3?: string;
  selected?: string;
  data: any;
  isLoading: boolean;
}

const CustomHeader: React.FC<CustomHeaderProps> = ({
  para1,
  header,
  amount,
  setSelected,
  para2,
  para3,
  selected,
  data,
  isLoading,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    selected
      ? selected
      : localStorage.getItem("dashboardSelectedToken") || "NGN"
  );

  const selectColor = useColorModeValue("#5A5A5A", "#C7C7C7");
  const bgSelect = useColorModeValue("#F9F9F9", "#000");
  const popoverTextColor = useColorModeValue("#00332D", "#EEE");
  const popoverBgColor = useColorModeValue("white", "#121B18");
  const popoverBorderColor = useColorModeValue("#EEE", "#333");
  const getSelectedBalance = () => {
    let findIsCrypto = data?.balance?.find(
      (bnlc: any) => bnlc?.symbol?.toLowerCase() === header?.toLowerCase()
    );
    if (findIsCrypto) {
      switch (selectedOption) {
        case "BTC":
          return (
            Number(findIsCrypto?.balanceInBitcoin)?.toLocaleString(undefined, {
              minimumFractionDigits: 5,
              maximumFractionDigits: 5,
            }) || 0
          );
        case "NGN":
          return (
            Number(findIsCrypto?.balanceNaira)?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) || 0
          );
        default:
          return (
            Number(findIsCrypto?.balanceInUSD)?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) || 0
          );
      }
    } else {
      switch (selectedOption) {
        case "BTC":
          return (
            Number(data?.totalBalanceBitcoin)?.toLocaleString(undefined, {
              minimumFractionDigits: 5,
              maximumFractionDigits: 5,
            }) || 0
          );
        case "NGN":
          return (
            Number(data?.totalBalanceInNaira)?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) || 0
          );
        default:
          return (
            Number(data?.totalBalanceUSD)?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) || 0
          );
      }
    }
  };

  return (
    <div>
      <Flex
        h={"150px"}
        padding={["20px 15px", "24px 18px", "28px 21px", "32px 24px"]}
        borderRadius={"16px"}
        background={`url(${bgPic}) no-repeat, linear-gradient(91deg,#00DBFF 0%, #00FFE0 47.92%)`}
        backgroundSize={"cover"}
        align={["start", "start", "center", "center"]}
        justify={"space-between"}
        flexDirection={["column", "column", "row", "row"]}
        w={"100%"}
      >
        <Box display={"flex"} flexDirection={"column"} alignItems={"start"}>
          <Flex
            fontWeight={"700"}
            letterSpacing={"0.056px"}
            fontSize={"14px"}
            align={"center"}
            gap={para2 ? "5px" : ""}
            padding={"0px"}
          >
            <Text color={"#FFF"}>{para2}</Text>
            <Text color={"#FFF"}>{para3}</Text>
            <Text fontSize={["8px", "10px", "12px", "14px"]} color="#00665A">
              {para1}
            </Text>
          </Flex>
          <Heading
            color={"#00332D"}
            fontSize={["22px", "26px", "30px", "34px"]}
            fontWeight={"700"}
            letterSpacing={"0.085px"}
          >
            {header}
          </Heading>
        </Box>
        <Box display={"flex"} flexDirection={"column"} alignItems={"end"}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={["start", "end", "end", "end"]}
            marginRight={"12px"}
            gap={"16px"}
            color={"#00665A"}
            fontSize={["8px", "10px", "12px", "14px"]}
            fontWeight={"400"}
            letterSpacing={"0.056px"}
            w={"90%"}
          >
            <Text>Total Assets</Text>
          </Box>
          <Box
            display={"flex"}
            gap={["5px", "16px", "16px", "16px"]}
            justifyContent={"center"}
            alignItems={["start", "center", "center", "center"]}
            flexDirection={["column", "row", "row", "row"]}
          >
            <Heading
              alignSelf={"end"}
              display={"flex"}
              alignItems={"center"}
              gap={"8px"}
              color={"#00332D"}
              fontSize={["22px", "26px", "30px", "34px"]}
              fontWeight={"700"}
              letterSpacing={"0.085px"}
            >
              {" "}
              <span>
                <Image
                  src={
                    selectedOption === "BTC"
                      ? btcGreen
                      : selectedOption === "NGN"
                      ? Naira
                      : usdGreen
                  }
                  alt="naira symbol"
                  mt={"2px"}
                  w={["20px", "24px", "28px", "32px"]}
                  h={["16px", "20px", "24px", "28px"]}
                />
              </span>{" "}
              {isLoading ? (
                <Spinner size="sm" color="#00332D" />
              ) : (
                getSelectedBalance()
              )}
            </Heading>
            <Box
              display={"flex"}
              alignItems={"center"}
              padding={"8px"}
              // paddingRight={"0px"}
              background={bgSelect}
              gap={"8px"}
              borderRadius={"8px"}
              height={"25px"}
            >
              <Image
                src={
                  selectedOption === "NGN"
                    ? NairaWhite
                    : selectedOption === "BTC"
                    ? btcWhite
                    : usd
                }
                alt="naira symbol"
                w={"9px"}
                h="9px"
              />

              <Popover>
                <PopoverTrigger>
                  <Button
                    h={"100%"}
                    bg={"none"}
                    _hover={{ bg: "none" }}
                    _active={{ bg: "none" }}
                    rightIcon={<ChevronDownIcon w={"16px"} h={"16px"} />}
                    color={selectColor}
                    fontSize={"10px"}
                    fontWeight={"700"}
                    lineHeight={"14px"}
                    letterSpacing={"0.15px"}
                    padding={"0px"}
                  >
                    {selectedOption}
                  </Button>
                </PopoverTrigger>
                <PopoverContent
                  w={"85%"}
                  mr="38px"
                  borderRadius={"4px"}
                  marginTop={"2px"}
                  bg={popoverBgColor}
                  border={`0.5px solid ${popoverBorderColor}`}
                  boxShadow={"0px 4px 10px 0px rgba(0,0,0,0.05)"}
                >
                  <PopoverHeader
                    display={"flex"}
                    justifyContent={"space-between"}
                    borderBottom={`0.5px solid ${popoverBorderColor}`}
                    pb={"10px"}
                    color={
                      selectedOption === "NGN" ? popoverTextColor : "#909090"
                    }
                    fontSize={"10px"}
                    fontWeight={"700"}
                    lineHeight={"14px"}
                    letterSpacing={"0.15px"}
                    cursor={"pointer"}
                    onClick={() => {
                      if (selected) {
                        setSelected("NGN");
                      }
                      setSelectedOption("NGN");
                      localStorage.setItem("dashboardSelectedToken", "NGN");
                    }}
                  >
                    <Text>NGN</Text>
                    <Image
                      src={selectedOption === "NGN" ? tickGreen : tickIcon}
                      w={"14px"}
                      h={"14px"}
                    />
                  </PopoverHeader>
                  <PopoverHeader
                    display={"flex"}
                    justifyContent={"space-between"}
                    borderBottom={`0.5px solid ${popoverBorderColor}`}
                    pb={"10px"}
                    color={
                      selectedOption === "BTC" ? popoverTextColor : "#909090"
                    }
                    fontSize={"10px"}
                    fontWeight={"700"}
                    lineHeight={"14px"}
                    letterSpacing={"0.15px"}
                    cursor={"pointer"}
                    onClick={() => {
                      if (selected) {
                        setSelected("BTC");
                      }
                      setSelectedOption("BTC");
                      localStorage.setItem("dashboardSelectedToken", "BTC");
                    }}
                  >
                    <Text>BTC</Text>
                    <Image
                      src={selectedOption === "BTC" ? tickGreen : tickIcon}
                      w={"14px"}
                      h={"14px"}
                    />
                  </PopoverHeader>
                  <PopoverHeader
                    display={"flex"}
                    justifyContent={"space-between"}
                    pb={"10px"}
                    color={
                      selectedOption === "USD" ? popoverTextColor : "#909090"
                    }
                    fontSize={"10px"}
                    fontWeight={"700"}
                    lineHeight={"14px"}
                    letterSpacing={"0.15px"}
                    cursor={"pointer"}
                    onClick={() => {
                      if (selected) {
                        setSelected("USD");
                      }
                      setSelectedOption("USD");
                      localStorage.setItem("dashboardSelectedToken", "USD");
                    }}
                  >
                    <Text>USD</Text>
                    <Image
                      src={selectedOption === "USD" ? tickGreen : tickIcon}
                      w={"14px"}
                      h={"14px"}
                    />
                  </PopoverHeader>
                </PopoverContent>
              </Popover>
            </Box>
          </Box>
        </Box>
      </Flex>
    </div>
  );
};

export default CustomHeader;
