import { useQuery } from "@tanstack/react-query";
import { client } from "./api-client";

export const useGetStableCoinsAndNetworks = (type) =>
  useQuery(["getStableCoinsAndNetworks", type], async () => {
    const response = await client(
      `coins/getStableCoinsAndNetworks?type=${type}`
    );
    return response;
  });

export const useGetUSDTNetworks = () =>
  useQuery(["getUSDTNetworks"], async () => {
    const response = await client(`coins/getStableCoinsAndNetworks?type=USDT`);
    return response;
  });

export const useGetUSDCNetworks = () =>
  useQuery(["getUSDCNetworks"], async () => {
    const response = await client(`coins/getStableCoinsAndNetworks?type=USDC`);
    return response;
  });

export const useGetMinTrxAmount = () => {
  return useQuery(["getMinAmount"], async () => {
    const response = await client(
      `coins/getMinimumTransactionAmounts?amountInUsd=5`
    );
    return response;
  });
};
