import {
  Box,
  Button,
  Flex,
  Image,
  InputLeftElement,
  SimpleGrid,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import WithdrawSlider from "../components/WithdrawSlider";
import PrimaryButton from "../components/PrimaryButton";
import BankSelectionCard from "./BankSelectionCard";
import CustomModal from "./CustomModal";
import plusLight from "../assets/icons/light/plus.svg";
import plusDark from "../assets/icons/dark/plus.svg";
import nairaLightActive from "../assets/icons/light/naira-light.svg";
import nairaLight from "../assets/icons/light/naira-inactive.svg";
import nairaDark from "../assets/icons/dark/naira.svg";
import AuthCardHeader from "./AuthCardHeader";
import verifyIcon from "../assets/verified.svg";
import UserInfoInput from "./UserInfoInput";
import userLight from "../assets/icons/light/userIcon.svg";
import userDark from "../assets/icons/dark/user.svg";
import bankLight from "../assets/icons/light/bank.svg";
import bankDark from "../assets/icons/dark/bank.svg";
import numberLight from "../assets/icons/light/number.svg";
import numberDark from "../assets/icons/dark/number.svg";
import userActive from "../assets/icons/light/userActive.svg";
import bankActive from "../assets/icons/light/bankActive.svg";
import numberActive from "../assets/icons/light/numberActive.svg";
import {
  useAddBankAccount,
  useDeleteBankAccount,
  useGetBankAccounts,
} from "../utils/auth.api";
import {
  useGetUserNairaBalance,
  useGetWalletWithBalance,
} from "../utils/wallet.api";
import SelectWithSearch from "./SelectWithSearch";
import { useGetMinTrxAmount } from "../utils/coins.api";

const NairaWithdraw = () => {
  const { data: nairaBalance } = useGetUserNairaBalance();
  const { data: walletData } = useGetWalletWithBalance();
  const { data: minTrxWithdraw } = useGetMinTrxAmount();
  const addBankAccountMutation = useAddBankAccount();
  const deleteBankAccountMutation = useDeleteBankAccount();
  const toast = useToast();
  const [bankForm, setBankForm] = useState({
    bankName: "",
    accountNumber: "",
    accountName: "",
  });
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setBankForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBankChange = (selectedBank: any) => {
    setBankForm({ ...bankForm, bankName: selectedBank });
  };

  const navigate = useNavigate();
  const [selectedBank, setSelectedBank] = useState(1);
  const [selectedTokenBalance, setSelectedTokenBalance] = useState(0);
  const [amountSelected, setAmountSelected] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => {
    setIsOpen(!isOpen);
  };
  const [isDeletedOpen, setIsDeletedOpen] = useState(false);
  const onDeletedClose = () => {
    setIsDeletedOpen(!isDeletedOpen);
  };
  const [isAddOpen, setIsAddOpen] = useState(false);
  const onAddClose = () => {
    setIsAddOpen(!isAddOpen);
  };

  const borderColor = useColorModeValue("#FFF", "#333");
  const backColor2 = useColorModeValue("#F9F9F9", "");
  const paraColor = useColorModeValue("#5A5A5A", "#C7C7C7");

  const plusIcon = useColorModeValue(plusLight, plusDark);
  const nairaActiveIcon = useColorModeValue(nairaLightActive, nairaDark);
  const nairaIcon = useColorModeValue(nairaLight, nairaLight);
  const { data: banksData, isLoading } = useGetBankAccounts();
  const [seletcedAccountNumber, setSelectedAccountNumber] = useState<any>(null);

  const calculateMinTrxAmount = () => {
    return minTrxWithdraw?.nairaValue || 0;
  };

  useEffect(() => {
    setSelectedAccountNumber(banksData?.bankDetails?.[0]);
  }, [banksData]);

  // console.log(bankForm.bankName);

  return (
    <SimpleGrid columns={[1, 1, 1, 2]} mt={"16px"} spacing={"24px"}>
      <Box>
        <Flex justify={"space-between"}>
          <Text
            color={useColorModeValue("#5A5A5A", "#C7C7C7")}
            fontSize={"16px"}
            fontWeight={"700"}
            lineHeight={"22px"}
            letterSpacing={"0.08px"}
            mb={"32px"}
          >
            Select Bank Account
          </Text>
          <Button
            onClick={() => {
              banksData?.bankDetails?.length === 3 ? onClose() : onAddClose();
            }}
            color={useColorModeValue("#00332D", "#00FFE0")}
            fontSize={"12px"}
            fontWeight={"400"}
          >
            <Image src={plusIcon} display={"inline"} me="8px" /> Add New Bank
          </Button>
        </Flex>
        {banksData?.bankDetails?.map((item: any, idx: number) => (
          <>
            {item.accountNumber && (
              <BankSelectionCard
                onClick={() => setSelectedBank(idx + 1)}
                isActive={selectedBank === idx + 1}
                bankName={item.bankName}
                accountNumber={item.accountNumber}
                accountName={item.accountName}
              />
            )}
          </>
        ))}
      </Box>
      <Box
        p={["0px", "0px", "0px", "24px"]}
        display={"flex"}
        mt="-24px"
        flexDirection={"column"}
        gap={"24px"}
        border={`1px solid ${borderColor}`}
        borderRadius={"12px"}
      >
        <Text
          color={useColorModeValue("#5A5A5A", "#C7C7C7")}
          fontSize={"16px"}
          fontWeight={"700"}
          lineHeight={"22px"}
          letterSpacing={"0.08px"}
          mb={"32px"}
        >
          Enter Withdrawal Amount
        </Text>
        <WithdrawSlider
          withdrawCoin={nairaIcon}
          withdrawCoinActive={nairaActiveIcon}
          maxAmount={nairaBalance?.balance}
          setAmountSelected={setAmountSelected}
          minAmount={calculateMinTrxAmount()}
        />
        <Text
          textColor={paraColor}
          fontSize={"13px"}
          fontWeight={400}
          lineHeight={"12px"}
          textAlign="center"
        >
          Amount should be greater than $5 equivalent
        </Text>
        <PrimaryButton
          mt="0px"
          text="Withdraw"
          onClick={() =>
            navigate("/dashboard/confirm-naira-withdraw", {
              state: {
                amountSelected: amountSelected,
                bankSelected: banksData.bankDetails[selectedBank - 1],
              },
            })
          }
          disabled={
            Number(nairaBalance?.balance) === 0 ||
            banksData?.bankDetails?.length === 0
          }
        />
      </Box>
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={async () => {
          try {
            await deleteBankAccountMutation.mutateAsync(
              seletcedAccountNumber?.id
            );
            setIsDeletedOpen(true);
            onClose();
          } catch (error: any) {
            toast({
              title: error.message,
              status: "error",
            });
          }
        }}
        headerText="Delete Bank Account"
        footerText="Delete Account"
        closeText="Cancel"
        isLoading={deleteBankAccountMutation.isLoading}
      >
        <Box py="20px" fontSize={"14px"}>
          <Text color={useColorModeValue("#909090", "#C7C7C7")}>
            You can add only{" "}
            <Text
              color={useColorModeValue("#5A5A5A", "#EEE")}
              display={"inline"}
              fontWeight={700}
            >
              3 Bank Accounts.
            </Text>{" "}
            If you want to add a new one, please select & delete from the
            already existing banks and add a new one.
          </Text>
          <Text mt="54px">Select Account</Text>
          {banksData?.bankDetails?.map((item: any, idx: number) => (
            <>
              {item.accountNumber && (
                <BankSelectionCard
                  onClick={() => {
                    setSelectedBank(idx + 1);
                    setSelectedAccountNumber(item);
                  }}
                  isActive={selectedBank === idx + 1}
                  bankName={item.bankName}
                  accountNumber={item.accountNumber}
                  accountName={item.accountName}
                />
              )}
            </>
          ))}
        </Box>
      </CustomModal>
      <CustomModal
        isOpen={isDeletedOpen}
        onClose={onDeletedClose}
        onSubmit={() => {
          onDeletedClose();
          onAddClose();
        }}
        headerText="Successful"
        footerText="Add new Account"
      >
        <Box py="20px" fontSize={"14px"}>
          <AuthCardHeader
            title="Bank Account Deleted"
            des={`The bank account with account number {“${seletcedAccountNumber?.accountNumber}”} has been deleted.`}
            desColor={useColorModeValue("#909090", "#C7C7C7")}
            imgSrc={verifyIcon}
            imageW="56px"
          />
        </Box>
      </CustomModal>
      <CustomModal
        isOpen={isAddOpen}
        onClose={onAddClose}
        onSubmit={async () => {
          try {
            if (
              bankForm?.accountName === "" ||
              bankForm?.accountNumber === "" ||
              bankForm?.bankName === ""
            ) {
              toast({
                title: "Please fill in all fields",
                status: "error",
              });
              return;
            }
            await addBankAccountMutation.mutateAsync(bankForm);
            // getBanks();
            setBankForm({
              bankName: "",
              accountNumber: "",
              accountName: "",
            });
            toast({
              title: "Bank account added successfully.",
              status: "success",
            });
            onAddClose();
          } catch (error: any) {
            toast({
              title: error.message,
              status: "error",
            });
          }
        }}
        headerText="Add Bank Account"
        isLoading={addBankAccountMutation.isLoading}
      >
        <Box py="20px" fontSize={"14px"}>
          <Text>
            Enter your account title, bank name and account number for
            withdrawals. You can add a maximum of 3 bank accounts.
          </Text>
          <UserInfoInput
            placeholder="ACCOUNT NAME"
            icon={useColorModeValue(userLight, userDark)}
            activeIcon={useColorModeValue(userActive, userDark)}
            name="accountName"
            value={bankForm.accountName}
            onChange={handleChange}
          />
          {/* <UserInfoInput
            placeholder="Bank Name"
            icon={useColorModeValue(bankLight, bankDark)}
            activeIcon={useColorModeValue(bankActive, bankDark)}
            name="bankName"
            value={bankForm.bankName}
            onChange={handleChange}
          /> */}
          <SelectWithSearch
            icon={useColorModeValue(bankLight, bankDark)}
            activeIcon={useColorModeValue(bankActive, bankDark)}
            onChange={handleBankChange} // Pass the function to handle selected value
          />
          <UserInfoInput
            placeholder="ACCOUNT NUMBER"
            icon={useColorModeValue(numberLight, numberDark)}
            activeIcon={useColorModeValue(numberActive, numberDark)}
            name="accountNumber"
            value={bankForm.accountNumber}
            onChange={handleChange}
          />
        </Box>
      </CustomModal>
    </SimpleGrid>
  );
};

export default NairaWithdraw;
