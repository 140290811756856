import {
  Flex,
  Image,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { MouseEventHandler } from "react";

interface ProfileItemProps {
  iconSrc: string;
  text: string;
  switchIcon?: string;
  onSwitchChange?: MouseEventHandler<HTMLImageElement>;
  onItemClick: (item: any) => void;
  selected: string;
  isLoading?: boolean;
}

const ProfileItem: React.FC<ProfileItemProps> = ({
  iconSrc,
  text,
  switchIcon,
  onSwitchChange,
  onItemClick,
  selected,
  isLoading,
}) => {
  const paraColor1 = useColorModeValue("#909090", "#C7C7C7");
  const paraColor2 = useColorModeValue("#00332D", "#EEE");
  const bgColor = useColorModeValue("#EEE", "rgba(255,255,255,0.09)");
  return (
    <div>
      <Flex
        padding={"8px"}
        justify={"space-between"}
        align={"center"}
        alignSelf={"stretch"}
        borderRadius={"12px"}
        mb={"16px"}
        cursor={"pointer"}
        onClick={() => {
          if (selected === text) {
            onItemClick("myProfile");
          } else {
            onItemClick(text.replace(/[ -]/g, ""));
          }
        }}
        bg={
          selected === text.split("-").join("").replace(/\s/g, "")
            ? bgColor
            : ""
        }
      >
        <Flex gap={"16px"} align={"center"}>
          <Image src={iconSrc} />
          <Text
            color={
              selected === text.split("-").join("").replace(/\s/g, "")
                ? paraColor2
                : paraColor1
            }
            fontSize={"16px"}
            fontWeight={"400"}
            lineHeight={"22px"}
            letterSpacing={"0.08px"}
          >
            {text}
          </Text>
        </Flex>
        {switchIcon && isLoading ? (
          <Spinner />
        ) : (
          switchIcon && (
            <Image
              src={switchIcon}
              onClick={onSwitchChange}
              cursor={"pointer"}
            />
          )
        )}
      </Flex>
    </div>
  );
};

export default ProfileItem;
