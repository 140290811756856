import React, { useState, useEffect, useRef } from "react";
import AdminLayout from "../layouts/AdminLayout";
import CustomHeader from "../components/CustomHeader";
import {
  Box,
  Checkbox,
  Divider,
  Flex,
  Heading,
  Image,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import CustomTabs from "../components/CustomTabs";
import PrimaryButton from "../components/PrimaryButton";
import UserInfoInput from "../components/UserInfoInput";
import { useNavigate } from "react-router-dom";
import nairaLight from "../assets/icons/light/naira.svg";
import nairaDark from "../assets/icons/dark/naira.svg";
import RecentPurchase from "../components/RecentPurchase";
import CustomModal from "../components/CustomModal";
import AuthCardHeader from "../components/AuthCardHeader";
import { CustomDetailFlex } from "../components/CustomDeatilFlex";
import verifiedIcon from "../assets/verified.svg";
import SelectCrypto from "../components/SelectCrypto";
import SelectCryptoDropdown from "../components/SelectCryptoDropdown";
import NigeriaFlag from "../assets/icons/nigeria_flag.svg";
import theme from "../theme/theme";
import {
  useGetAllTelevisionProvider,
  useGetCompletedTvSubscription,
  useGetProductOfTelevisionById,
  usePurchaseTvSubscription,
} from "../utils/service.api";
import { useGetWalletWithBalance } from "../utils/wallet.api";
import failedIcon from "../assets/failedIcon.svg";

const TvSubscription = () => {
  const { data, isLoading } = useGetAllTelevisionProvider();
  const { data: balanceData, isLoading: balanceLoading } =
    useGetWalletWithBalance();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  const [errorMsg, setErrorMsg] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [purchaseResponse, setPurchaseResponse] = useState<any>(null);
  const placeholderItem: {
    id: number;
    symbol: string;
    isPlaceholder: boolean;
  } = {
    id: 1,
    symbol: "Service Provider",
    isPlaceholder: true,
  };
  const menuData = [
    placeholderItem,
    ...(data?.data?.map((item: any, idx: number) => ({
      id: idx + 2,
      symbol: item?.name,
      operatorId: item?.id,
    })) || []),
  ];

  const [selectedCoin, setSelectedCoin] = useState(menuData[0]);
  const [smartCardNo, setSmartCardNo] = useState("");
  const { data: productsData, isLoading: productDataLoading } =
    useGetProductOfTelevisionById(selectedCoin?.operatorId);

  const productData = [
    {
      id: 1,
      symbol: "Products",
      isPlaceholder: true,
    },
    ...(productsData?.map((item: any, idx: number) => ({
      id: idx + 2,
      symbol: `${item?.name}, fee = ${item?.meta?.fee}`,
      fee: item?.meta?.fee,
      currency: item?.meta?.currency,
      productId: item?.id,
    })) || []),
  ];
  const [amount, setAmount] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(productData[0]);
  const { data: recentTvSubs } = useGetCompletedTvSubscription(0, 3);
  const [selected, setSelected] = useState("NGN");
  const [isSaved, setIsSaved] = useState(false);
  const purchaseTvSubscriptionMutation = usePurchaseTvSubscription();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuProductOpen, setIsMenuProductOpen] = useState(false);
  const borderColor = useColorModeValue(
    theme.colors.light.border.secondary,
    theme.colors.dark.border.secondary
  );
  const paraColor = useColorModeValue("#5A5A5A", "#C7C7C7");
  const paraColor2 = useColorModeValue("#5A5A5A", "#EEE");
  const dropIconColor = useColorModeValue("#5A5A5A", "#C7C7C7");
  const selectedTextColor = useColorModeValue("#00332D", "#EEEEEE");
  const [isCompleteOpen, setIsCompleteOpen] = useState(false);
  const onCompleteOpen = () => {
    setIsCompleteOpen(true);
  };
  const onCompleteClose = () => {
    setPurchaseResponse(null);
    setIsCompleteOpen(false);
    setSmartCardNo("");
    setPhoneNo("");
    setSelectedProduct(productData[0]);
    setSelectedCoin(menuData?.[0]);
    setIsSaved(false);
  };
  const navigate = useNavigate();
  const toast = useToast();
  const nairaIcon = useColorModeValue(nairaLight, nairaDark);
  const [menuW, setmenuW] = useState();
  const elementRef: any = useRef(null);

  const getCurrentDate = () => {
    const now = new Date();
    const options: any = { day: "2-digit", month: "short", year: "numeric" };
    return now.toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    if (elementRef.current) {
      const width = elementRef.current.offsetWidth;
      setmenuW(width);
    }
  }, [menuW, isMenuOpen, isMenuProductOpen]);

  useEffect(() => {
    setSelectedCoin(isLoading ? "Loading..." : menuData[0]);
  }, [data]);

  useEffect(() => {
    // setSelectedProduct(productDataLoading ? "Loading..." : productData[1]);
    setAmount(Number(selectedProduct?.fee));
  }, [selectedCoin, selectedProduct]);

  return (
    <AdminLayout>
      <Box marginTop="72px" marginRight={"2%"} mb={"50px"} w={"95%"}>
        <CustomHeader
          para1="TV Subscription"
          para2="Services /"
          header={"TV Subscription"}
          amount="38,920.84"
          setSelected={setSelected}
          data={balanceData}
          isLoading={balanceLoading}
        />
        <Box mt={"32px"}>
          {/* <CustomTabs
            titles={[
              {
                sm: "",
                md: "GLO",
                icon: (
                  <Image
                    mr="5px"
                    opacity={useColorModeValue(0.1, 0.3)}
                    src={gloIcon}
                  />
                ),
              },
              {
                sm: "",
                md: "Airtel",
                icon: (
                  <Image
                    mr="5px"
                    opacity={useColorModeValue(0.1, 0.3)}
                    src={airtelIcon}
                  />
                ),
              },
              { sm: "", md: "MTN", icon: <Image mr="5px" src={mtnIcon} /> },
              {
                sm: "",
                md: "9Mobile",
                icon: (
                  <Image
                    mr="5px"
                    opacity={useColorModeValue(0.1, 0.3)}
                    src={nineMobileIcon}
                  />
                ),
              },
            ]}
          > */}
          {isLoading ? (
            <Flex w={"100%"} justify={"center"}>
              <Spinner />
            </Flex>
          ) : (
            <Flex
              direction={{ base: "column", lg: "row" }}
              gap="24px"
              align={{ base: "center", lg: "start" }}
            >
              <Box w={{ base: "100%", lg: "58%" }}>
                <Text mt="32px" fontWeight={"700"} color={paraColor}>
                  Purchase TV Subscription
                </Text>
                <Text my="24px">
                  Select a service provider and enter your smart card number to
                  purchase subscription.
                </Text>
                <Box ref={elementRef}>
                  <SelectCrypto
                    isMenuOpen={isMenuOpen}
                    setIsMenuOpen={setIsMenuOpen}
                    selectedCoin={selectedCoin}
                    fontWeight={400}
                    dropIconColor={dropIconColor}
                    iconSize="32px"
                    iconBG="transparent"
                  />
                </Box>
                {isMenuOpen && (
                  <SelectCryptoDropdown
                    {...{
                      isMenuOpen,
                      setIsMenuOpen,
                      selectedCoin,
                      setSelectedCoin,
                      menuData,
                      menuW,
                      selectedTextColor,
                    }}
                  />
                )}
                {productsData && (
                  <>
                    <Box ref={elementRef} mt={"24px"}>
                      <SelectCrypto
                        isMenuOpen={isMenuProductOpen}
                        setIsMenuOpen={setIsMenuProductOpen}
                        selectedCoin={selectedProduct}
                        fontWeight={400}
                        dropIconColor={dropIconColor}
                        iconSize="32px"
                        iconBG="transparent"
                      />
                    </Box>
                    {isMenuProductOpen && (
                      <SelectCryptoDropdown
                        {...{
                          isMenuOpen: isMenuProductOpen,
                          setIsMenuOpen: setIsMenuProductOpen,
                          selectedCoin: selectedProduct,
                          setSelectedCoin: setSelectedProduct,
                          menuData: productData,
                          menuW,
                          selectedTextColor,
                        }}
                      />
                    )}
                  </>
                )}
                <Box mt="24px">
                  <UserInfoInput
                    type="number"
                    placeholder="Smart card number"
                    placeholderColorDark="#909090"
                    value={smartCardNo}
                    onChange={(e: any) => setSmartCardNo(e.target.value)}
                  />
                </Box>
                <Box mt="24px">
                  <UserInfoInput
                    type="tel"
                    activeIcon={NigeriaFlag}
                    placeholder="Enter Phone Number"
                    icon={NigeriaFlag}
                    name="phoneNo"
                    value={phoneNo}
                    onChange={(e: any) => {
                      const pattern = /^\d+$/;
                      if (
                        pattern?.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        setPhoneNo(e.target.value);
                      }
                    }}
                  />
                  {phoneNo && phoneNo?.length > 11 && (
                    <Text fontSize={"12"} mt="-20px" color="red">
                      Incorrect phone number.
                    </Text>
                  )}
                </Box>
                <Checkbox
                  colorScheme="teal"
                  onChange={(e: any) => {
                    setIsSaved(e.target.checked);
                  }}
                  isChecked={isSaved}
                >
                  Save Address Book
                </Checkbox>
                <PrimaryButton
                  mt="0px"
                  text={
                    purchaseTvSubscriptionMutation?.isLoading
                      ? "Processing..."
                      : "Purchase TV Subscription"
                  }
                  disabled={
                    purchaseTvSubscriptionMutation?.isLoading ||
                    smartCardNo?.length !== 10 ||
                    selectedProduct?.symbol === "" ||
                    phoneNo?.length !== 11
                  }
                  // isLoading={purchaseTvSubscriptionMutation?.isLoading}
                  onClick={async () => {
                    if (
                      selectedCoin?.symbol === "Service Provider" ||
                      selectedProduct?.symbol === "Products" ||
                      smartCardNo === ""
                    ) {
                      toast({
                        title:
                          "Please select provider and product and write smart card number",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                      });
                      return;
                    }
                    const formData = {
                      operatorId: selectedCoin?.operatorId,
                      productId: selectedProduct?.productId,
                      provider: selectedCoin?.symbol,
                      amount: Number(selectedProduct?.fee),
                      smartCardNumber: smartCardNo,
                      phone: phoneNo,
                      addressBook: isSaved ? "true" : "",
                    };
                    await purchaseTvSubscriptionMutation
                      .mutateAsync(formData)
                      .then((result) => {
                        setPurchaseResponse(result?.dataFromApi);
                        setAmount(selectedProduct?.fee);
                        onCompleteOpen();
                      })
                      .catch((error) => {
                        onErrorOpen();
                        setErrorMsg(error?.message);
                      });
                  }}
                />
              </Box>
              <RecentPurchase
                data={recentTvSubs}
                from={"tv"}
                empty={
                  recentTvSubs?.length === 0 || recentTvSubs === undefined
                    ? true
                    : false
                }
                onSelect={(item: any) => {
                  setSmartCardNo(item?.smartCardNumber || "");
                  setPhoneNo(item?.phoneNumber || "");
                }}
                type="TV"
              />
            </Flex>
          )}
        </Box>
      </Box>
      <CustomModal
        isOpen={isCompleteOpen}
        onClose={onCompleteClose}
        headerText="Purchase Completed"
        footerText="Continue Purchasing"
        onSubmit={onCompleteClose}
      >
        <Box pt="20px" mb="-30px">
          <AuthCardHeader
            title={"TV Subscription Purchased"}
            imgSrc={verifiedIcon}
            imageW="56px"
            des="Your purchase has been completed."
          />
        </Box>
        <Divider variant={"dashed"} />
        <CustomDetailFlex title="Date" value={getCurrentDate()} />
        <CustomDetailFlex
          title="Cable TV"
          value={purchaseResponse?.cable_tv || ""}
        />
        <CustomDetailFlex
          title="Subscription Plan"
          value={purchaseResponse?.subscription_plan || ""}
        />
        <CustomDetailFlex
          title="Smart Card Number"
          value={purchaseResponse?.smartcard_number || ""}
        />
        <CustomDetailFlex title="Phone" value={purchaseResponse?.phone || ""} />
        <CustomDetailFlex
          title="Amount"
          value={`NGN${Number(purchaseResponse?.amount || 0)?.toLocaleString(
            undefined,
            {
              maximumFractionDigits: 2,
            }
          )}`}
        />
        <CustomDetailFlex
          title="Service Fee"
          value={`NGN${Number(
            purchaseResponse?.service_fee || 0
          )?.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`}
        />
        <CustomDetailFlex
          title="Order ID"
          value={purchaseResponse?.order_id || ""}
        />
      </CustomModal>
      <CustomModal
        isOpen={isErrorOpen}
        onClose={() => {
          onErrorClose();
          // navigate("/dashboard/index");
        }}
        noFooter={true}
        onSubmit={() => {
          // navigate("/dashboard/index");
          onErrorClose();
        }}
      >
        <Flex
          justify={"center"}
          align={"center"}
          direction={"column"}
          gap="24px"
          mb="32px"
          mt={"32px"}
        >
          <Image src={failedIcon} w={"56px"} h={"56px"} />
          <Heading
            fontFamily={"Audiowide"}
            fontSize={["15px", "18px", "21px", "24px"]}
            fontWeight={"400"}
          >
            Transaction Failed!
          </Heading>
          <Text
            textAlign={"center"}
            fontSize={["9px", "11px", "13px", "14px"]}
            fontWeight={"400"}
            lineHeight={"20px"}
            letterSpacing={"0.035px"}
            color={paraColor2}
          >
            {errorMsg}
          </Text>
        </Flex>
      </CustomModal>
    </AdminLayout>
  );
};

export default TvSubscription;
