import {
  Box,
  Flex,
  Image,
  Text,
  border,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { MouseEventHandler } from "react";

interface TransactionTableProps {
  month: string;
  date: string;
  icon: string;
  type: string;
  address: string;
  id: string;
  amount: string | number;
  amountIcon: string;
  borderC: string;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
}

const TransactionTable: React.FC<TransactionTableProps> = ({
  month,
  date,
  icon,
  type,
  address,
  id,
  amount,
  amountIcon,
  borderC,
  onClick,
}) => {
  const borderColor = useColorModeValue("#EEE", "#333");
  const bgColor = useColorModeValue("#FFF", "#181818");
  const colorText = useColorModeValue("#000", "#EEE");
  const addressColor = useColorModeValue("909090", "#C7C7C7");

  return (
    <div>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"8px"}
        mb={"8px"}
        onClick={onClick}
        cursor={"pointer"}
      >
        <Flex
          padding={["6px", "8px", "12px", "16px"]}
          paddingLeft={"20px"}
          justify={"space-between"}
          flexDirection={["column", "row", "row", "row"]}
          gap={"24px"}
          borderRadius={"12px"}
          position="relative"
          _before={{
            content: '""',
            position: "absolute",
            left: 0,
            top: "50%",
            height: "60%", // Adjusted to 75%
            width: "1px",
            backgroundColor: borderC,
            transform: "translateY(-50%)",
            boxShadow: `10px 0px 20px 1px ${borderC}`, // Modified for shadow to the right
          }}
          border={`1px solid ${borderColor}`}
          background={bgColor}
          width={"100%"}
        >
          <Box
            display={"flex"}
            gap={"14px"}
            flexDirection={["column", "column", "row", "row"]}
            alignItems={["start", "start", "", ""]}
          >
            <Flex gap={"14px"}>
              <Box color={colorText} textAlign={"center"}>
                <Text
                  fontSize={"18px"}
                  fontWeight={"700"}
                  lineHeight={"24px"}
                  letterSpacing={"0.027px"}
                >
                  {month}
                </Text>
                <Text
                  fontSize={"12px"}
                  fontWeight={"400"}
                  letterSpacing={"0.048px"}
                >
                  {date}
                </Text>
              </Box>
              <Box>
                <Image src={icon} alt="arrows" />
              </Box>
            </Flex>
            <Box>
              <Text
                color={colorText}
                fontSize={"16px"}
                fontWeight={"400"}
                lineHeight={"22px"}
                letterSpacing={"0.08px"}
                mb={"3px"}
              >
                {type}
              </Text>
              <Text
                color={addressColor}
                fontSize={"14px"}
                fontWeight={"400"}
                lineHeight={"20px"}
                letterSpacing={"0.035px"}
              >
                {address}
              </Text>
            </Box>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={["start", "start", "start", "end"]}
          >
            <Text
              color={addressColor}
              fontSize={"14px"}
              fontWeight={"400"}
              lineHeight={"20px"}
              letterSpacing={"0.035px"}
              mb={"5px"}
            >
              Transaction ID: {id}
            </Text>
            <Text
              display={"flex"}
              justifyContent={"end"}
              color={colorText}
              fontSize={"14px"}
              fontWeight={"400"}
              lineHeight={"20px"}
              letterSpacing={"0.035px"}
              gap={"6px"}
            >
              {amount || 0}{" "}
              <Image
                src={amountIcon}
                alt="crypto symbol"
                width={"20px"}
                height={"20px"}
                borderRadius="50%"
              />
            </Text>
          </Box>
        </Flex>
      </Box>
    </div>
  );
};

export default TransactionTable;
