import React, { useEffect, useState } from "react";
import AuthLayout from "../layouts/AuthLayout";
import {
  Box,
  Checkbox,
  Image,
  Input,
  Link,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import theme from "../theme/theme";
import AuthCardHeader from "../components/AuthCardHeader";
import UserInfoInput from "../components/UserInfoInput";
import PrimaryButton from "../components/PrimaryButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import userLight from "../assets/icons/light/userIcon.svg";
import emailLight from "../assets/icons/light/emailIcon.svg";
import numberLight from "../assets/icons/light/number.svg";
import lockLight from "../assets/icons/light/lockIcon.svg";
import eyeLight from "../assets/icons/light/eyeIcon.svg";
import shutEyeLight from "../assets/icons/light/shutEyeIcon.svg";
import userDark from "../assets/icons/dark/user.svg";
import emailDark from "../assets/icons/dark/email.svg";
import numberDark from "../assets/icons/dark/number.svg";
import lockDark from "../assets/icons/dark/lock.svg";
import eyeDark from "../assets/icons/dark/eye.svg";
import shutEyeDark from "../assets/icons/dark/shutEye.svg";
import emailActive from "../assets/icons/light/emailActive.svg";
import numberActive from "../assets/icons/light/numberActive.svg";
import lockActive from "../assets/icons/light/lockActive.svg";
import eyeActive from "../assets/icons/light/eyeActive.svg";
import shutEyeActive from "../assets/icons/light/shutEyeActive.svg";
import userActive from "../assets/icons/light/userActive.svg";
import { useSignup } from "../utils/auth.api";
import PhoneInputWithCountry from "../components/PhoneInput";
import NigeriaFlag from "../assets/icons/nigeria_flag.svg";
import { isValidPhoneNumber } from "react-phone-number-input";

const Signup = () => {
  const navigate = useNavigate();
  const signupMutation = useSignup();
  const [searchParams] = useSearchParams();
  const referralCode = searchParams?.get("referralCode");
  const toast = useToast();
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    referrerCode: "",
    password: "",
    confirmPassword: "",
  });

  const { name, email, password, confirmPassword, referrerCode } = formData;
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const isStrongPassword = (password: any) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[A-Za-z\d !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/;
    return passwordRegex.test(password);
  };

  const linkColor = useColorModeValue(
    theme.colors.light.link.primary,
    theme.colors.dark.link.primary
  );
  const color = useColorModeValue(
    theme.colors.light.text.primary,
    theme.colors.dark.text.primary
  );
  const checkBoxScheme = useColorModeValue("gray", "teal");
  const userIcon = useColorModeValue(userLight, userDark);
  const emailIcon = useColorModeValue(emailLight, emailDark);
  const numberIcon = useColorModeValue(numberLight, numberDark);
  const lockIcon = useColorModeValue(lockLight, lockDark);
  const eyeIcon = useColorModeValue(eyeLight, eyeDark);
  const shutEyeIcon = useColorModeValue(shutEyeLight, shutEyeDark);
  const activeEyeIcon = useColorModeValue(eyeActive, eyeDark);
  const activeEmailIcon = useColorModeValue(emailActive, emailDark);
  const activeNumberIcon = useColorModeValue(numberActive, numberDark);
  const activeLockIcon = useColorModeValue(lockActive, lockDark);
  const activeUserIcon = useColorModeValue(userActive, userDark);
  const activeShutEyeIcon = useColorModeValue(shutEyeActive, shutEyeDark);
  const [signupError, setSignupError] = useState<any>();

  // console.log(phoneNumber);

  useEffect(() => {
    const referral = searchParams?.get("referralCode");
    if (referral) {
      setFormData({ ...formData, referrerCode: referral });
    }
  }, [searchParams]);

  return (
    <AuthLayout>
      <AuthCardHeader
        title="Create Account"
        des="Securely Set Up Your Crypto Wallet"
      />
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          if (password === confirmPassword) {
            try {
              await signupMutation.mutateAsync({
                fullname: formData.name,
                email: formData.email,
                password: formData.password,
                confirmPassword: formData.confirmPassword,
                referrerCode: formData?.referrerCode,
                phone: phoneNumber,
              });
              navigate("/auth/verification", {
                state: { formData: formData, from: "signup" },
              });
              toast({
                title: "Otp sent successfully.",
                status: "success",
                isClosable: true,
                position: "top-right",
              });
            } catch (error: any) {
              setSignupError(error?.message);
              if (Array.isArray(error?.message)) {
                error.message.forEach((item: any) =>
                  toast({
                    title: item,
                    status: "error",
                    isClosable: true,
                    position: "top-right",
                  })
                );
              } else {
                toast({
                  title: error.message,
                  status: "error",
                  isClosable: true,
                  position: "top-right",
                });
              }
            }
          }
        }}
      >
        <UserInfoInput
          type="text"
          placeholder="Name"
          icon={userIcon}
          activeIcon={activeUserIcon}
          name="name"
          value={name}
          onChange={(e: any) => handleChange(e)}
        />
        <UserInfoInput
          type="email"
          activeIcon={activeEmailIcon}
          placeholder="Email"
          icon={emailIcon}
          name="email"
          value={email}
          onChange={(e: any) => handleChange(e)}
        />
        <PhoneInputWithCountry
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          mb="24px"
        />
        <UserInfoInput
          type="text"
          activeIcon={activeNumberIcon}
          placeholder="Referal Code (Optional)"
          icon={numberIcon}
          name="referrerCode"
          value={referrerCode}
          onChange={(e: any) => handleChange(e)}
          optional={true}
        />
        <UserInfoInput
          type={show ? "text" : "password"}
          placeholder="Password"
          icon={lockIcon}
          activeIcon={activeLockIcon}
          rightIcon={!show ? eyeIcon : shutEyeIcon}
          activeRightIcon={!show ? activeEyeIcon : activeShutEyeIcon}
          setShow={setShow}
          show={show}
          name="password"
          value={password}
          onChange={(e: any) => handleChange(e)}
        />
        <UserInfoInput
          type={showConfirm ? "text" : "password"}
          placeholder="Confirm Password"
          icon={lockIcon}
          activeIcon={activeLockIcon}
          rightIcon={!showConfirm ? eyeIcon : shutEyeIcon}
          activeRightIcon={!showConfirm ? activeEyeIcon : activeShutEyeIcon}
          setShow={setShowConfirm}
          show={showConfirm}
          name="confirmPassword"
          value={confirmPassword}
          onChange={(e: any) => handleChange(e)}
          // border={password !== confirmPassword && confirmPassword && "1px solid red"}
        />
        {password !== confirmPassword && confirmPassword && (
          <Text color={"red"} mb="10px">
            Password didn't match with the first one.
          </Text>
        )}
        {password && !isStrongPassword(password) && (
          <Text color={"red"} mb="10px">
            Password not strong enough.
          </Text>
        )}
        {Array.isArray(signupError) && signupError.length > 0 && (
          <>
            {signupError.map((error, index) => (
              <Text key={index} color={"red"} mb="10px">
                {error}
              </Text>
            ))}
          </>
        )}
        <Checkbox
          defaultChecked
          required
          onChange={() => setIsChecked(!isChecked)}
          colorScheme={checkBoxScheme}
          opacity={isChecked ? "1" : "0.5"}
        >
          Agree with{" "}
          <Link
            textDecoration={"underline"}
            target="_blank"
            color={linkColor}
            href="https://payremit.io/privacy"
          >
            Privacy Policy
          </Link>
          <Text as={"span"}> and </Text>
          <Link
            textDecoration={"underline"}
            target="_blank"
            color={linkColor}
            href="https://payremit.io/termsandconditions"
          >
            Terms & Conditions
          </Link>
        </Checkbox>
        {/* <Checkbox
          defaultChecked
          required
          onChange={() => setIsChecked(!isChecked)}
          colorScheme={checkBoxScheme}
          opacity={isChecked ? "1" : "0.5"}
        >
          Agree with{" "}
          <Link
            textDecoration={"underline"}
            target="_blank"
            color={linkColor}
            href="https://payremit.io/termsandconditions"
          >
            Terms & Conditions
          </Link>
        </Checkbox> */}
        <PrimaryButton
          type="submit"
          text={signupMutation.isLoading ? <Spinner /> : "Create Account"}
          disabled={
            signupMutation.isLoading ||
            (password && !isStrongPassword(password)) ||
            !phoneNumber ||
            (phoneNumber && !isValidPhoneNumber(phoneNumber))
          }
          mt="48px"
        />
      </form>
      <Text textAlign={"center"} mt="20px" fontSize={"12px"}>
        Already have an account?{" "}
        <Link
          color={useColorModeValue("#00332D", "#00FFE0")}
          fontWeight={600}
          ml="5px"
          onClick={() => {
            navigate("/auth/signin");
          }}
        >
          Sign In
        </Link>
      </Text>
    </AuthLayout>
  );
};

export default Signup;
