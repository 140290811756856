import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

const CustomTabs = ({
  titles,
  children,
  widthGiven,
  showDivider,
  onTabChange,
  setSelectedTab,
  activeTab,
}: any) => {
  const dividerColor = useColorModeValue("#EEE", "#333");
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (activeTab !== undefined || activeTab !== -1) {
      setTabIndex(activeTab);
    }
  }, [activeTab]);

  return (
    <Tabs
      index={tabIndex}
      onChange={(index) => {
        setTabIndex(index);
        if (setSelectedTab) {
          setSelectedTab(index);
        }
        if (onTabChange) {
          onTabChange(index);
        }
      }}
      colorScheme="teal"
    >
      <TabList w={widthGiven ? "100%" : "fit-content"}>
        {titles?.map((titleObj: any, index: number) => (
          <Tab key={index} w={widthGiven ? "100%" : ""}>
            {tabIndex === index ? titleObj["activeIcon"] : titleObj["icon"]}
            <Text display={{ sm: "none" }}>{titleObj["sm"]}</Text>
            <Text display={{ base: "none", sm: "block" }}>
              {titleObj["md"]}
            </Text>
            <Box
              display={index < 3 && showDivider ? "bolcok" : "none"}
              borderRight={"1px solid"}
              borderColor={dividerColor}
              h="15px"
              ml="12px"
              mr="-20px"
              mt="6px"
            />
          </Tab>
        ))}
      </TabList>

      <TabPanels>
        {React.Children.map(children, (child, index) => (
          <TabPanel px="0px" key={index}>
            {child}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default CustomTabs;
