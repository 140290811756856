import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "./api-client";

export const usePurchaseAirTime = () => {
  const queryClient = useQueryClient();
  const purchaseAirtime = async (payload) => {
    const response = await client("service/purchaseAirtime", {
      method: "POST",
      data: payload,
    });

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      queryClient.invalidateQueries("getWalletWithBalance");
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(purchaseAirtime);
};

export const usePurchaseInternet = () => {
  const queryClient = useQueryClient();
  const purchaseInternet = async (payload) => {
    const response = await client("service/purchaseInternet", {
      method: "POST",
      data: payload,
    });

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      queryClient.invalidateQueries("getCompletedInternet");
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(purchaseInternet);
};

export const usePurchaseElectricityBill = () => {
  const queryClient = useQueryClient();
  const purchaseElectricityBill = async (payload) => {
    const response = await client("service/purchaseElectricityBill", {
      method: "POST",
      data: payload,
    });

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      queryClient.invalidateQueries("getCompletedElectricityBill");
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(purchaseElectricityBill);
};

export const usePurchaseTvSubscription = () => {
  const queryClient = useQueryClient();
  const purchaseTvSubscription = async (payload) => {
    const response = await client("service/purchaseTvSubscription", {
      method: "POST",
      data: payload,
    });

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      queryClient.invalidateQueries("getWalletWithBalance");
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(purchaseTvSubscription);
};

export const useGetAllNetwork = () =>
  useQuery(["getAllNetwork"], async () => {
    const response = await client(`service/getAllNetwork`);
    return response;
  });

export const useGetAirtimeOperatorIdAndProductId = () =>
  useQuery(["getAirtimeOperatorIdAndProductId"], async () => {
    const response = await client(`service/getAirtimeOperatorIdAndProductId`);
    return response;
  });

export const useGetAllTelevisionProvider = () =>
  useQuery(["getAllTelevisionProvider"], async () => {
    const response = await client(`service/getAllTelevisionProvider`);
    return response;
  });

export const useGetAllElectricityProvider = () =>
  useQuery(["getAllElectricityProvider"], async () => {
    const response = await client(`service/getAllElectricityProvider`);
    return response;
  });

export const useGetProductOfTelevisionById = (id) =>
  useQuery(["getProductOfTelevisionById", id], async () => {
    const response = await client(
      `service/getProductOfTelevisionById?operaterId=${id}`
    );
    return response;
  });

export const useGetProductOfElectricityById = (id) =>
  useQuery(["getProductOfElectricityById", id], async () => {
    const response = await client(
      `service/getProductOfElectricityById?operaterId=${id}`
    );
    return response;
  });

export const useGetAirtimeByNetworkId = (id) =>
  useQuery(["getAirtimeByNetworkId", id], async () => {
    const response = await client(
      `service/getAirtimeByNetworkId?operaterId=${id}`
    );
    return response;
  });

export const useGetMobileDataOfNetworkById = (id) =>
  useQuery(["getMobileDataOfNetworkById", id], async () => {
    const response = await client(
      `service/getMobileDataOfNetworkById?operaterId=${id}`
    );
    return response;
  });

export const useGetCompletedInternet = (offset, limit, operatorId) =>
  useQuery(["getCompletedInternet", offset, limit, operatorId], async () => {
    const queryParams = {};
    if (offset !== undefined) {
      queryParams.offset = offset;
    }
    if (limit !== undefined) {
      queryParams.limit = limit;
    }
    if (operatorId !== undefined) {
      queryParams.operatorId = operatorId;
    }

    const response = await client(`service/getCompletedInternet`, {
      params: queryParams,
    });
    return response;
  });

export const useGetCompletedElectricityBill = (
  offset,
  limit,
  meterTypeFilter,
  meterProviderFilter
) =>
  useQuery(
    [
      "getCompletedElectricityBill",
      offset,
      limit,
      meterTypeFilter,
      meterProviderFilter,
    ],
    async () => {
      const queryParams = {};
      if (offset !== undefined) {
        queryParams.offset = offset;
      }
      if (limit !== undefined) {
        queryParams.limit = limit;
      }
      if (meterTypeFilter !== undefined) {
        queryParams.meterTypeFilter = meterTypeFilter;
      }

      if (meterProviderFilter !== undefined) {
        queryParams.meterProviderFilter = meterProviderFilter;
      }

      const response = await client(`service/getCompletedElectricityBill`, {
        params: queryParams,
      });
      return response;
    }
  );

export const useGetCompletedTvSubscription = (offset, limit) =>
  useQuery(["getCompletedTvSubscription", offset, limit], async () => {
    const queryParams = {};
    if (offset !== undefined) {
      queryParams.offset = offset;
    }
    if (limit !== undefined) {
      queryParams.limit = limit;
    }

    const response = await client(`service/getCompeletedtvSubscription`, {
      params: queryParams,
    });
    return response;
  });

export const useGetCompletedAirtime = (offset, limit, operatorId) =>
  useQuery(["getCompletedAirtime", offset, limit, operatorId], async () => {
    const queryParams = {};
    if (operatorId !== undefined) {
      queryParams.operatorId = operatorId;
    }
    if (offset !== undefined) {
      queryParams.offset = offset;
    }
    if (limit !== undefined) {
      queryParams.limit = limit;
    }

    const response = await client(`service/getCompletedAirtime`, {
      params: queryParams,
    });
    return response;
  });

export const useCsvAirtimeDownload = () =>
  useQuery(["CsvAirtimeDownload"], async () => {
    const response = await client(`service/CsvAirtimeDownload`);
    return response;
  });

export const useCsvInternetDownload = () =>
  useQuery(["CsvInternetDownload"], async () => {
    const response = await client(`service/CsvInternetDownload`);
    return response;
  });

export const useCsvElectricityDownload = () =>
  useQuery(["CsvElectricityDownload"], async () => {
    const response = await client(`service/CsvElectricityDownload`);
    return response;
  });

export const useCsvTvSubscriptionDownload = () =>
  useQuery(["CsvTvSubscriptionDownload"], async () => {
    const response = await client(`service/CsvTvSubscriptionDownload`);
    return response;
  });

export const useGetAccountdetails = () =>
  useQuery(["getAccountdetails"], async () => {
    const response = await client(`service/getAccountdetails`);
    return response;
  });

export const useGetListOfNigerianBank = () =>
  useQuery(["getListOfNigerianBank"], async () => {
    const response = await client(`service/getListOfNigerianBank`);
    return response;
  });

export const useGetAllServicesAddress = (type) =>
  useQuery(["getAllServicesAddress", type], async () => {
    const response = await client(`service/getAllServicesAddress?type=${type}`);
    return response;
  });

export const useAddNewServiceAddress = () => {
  const queryClient = useQueryClient();
  const mutation = async (payload) => {
    const response = await client("service/addNewServiceAddress", {
      method: "POST",
      data: payload,
    });

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      queryClient.invalidateQueries("getAllServicesAddress");
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(mutation);
};

export const useUpdateServiceAddress = () => {
  const queryClient = useQueryClient();
  const mutation = async (data) => {
    const response = await client(
      `service/updateServiceAddress/${data?.addressId}`,
      {
        method: "POST",
        data: data?.payload,
      }
    );

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      queryClient.invalidateQueries("getAllServicesAddress");
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(mutation);
};

export const useDeleteServiceAddress = () => {
  const queryClient = useQueryClient();
  const mutation = async (data) => {
    const response = await client(
      `service/deleteServiceAddress?addressId=${data?.addressId}&type=${data?.type}`,
      {
        method: "POST",
        // data: data?.payload,
      }
    );

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      queryClient.invalidateQueries("getAllServicesAddress");
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(mutation);
};
