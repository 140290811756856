import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import theme from "../theme/theme";
import { IconUser } from "@tabler/icons-react";

const UserInfoInput = ({
  icon,
  activeIcon,
  rightIcon,
  activeRightIcon,
  placeholder,
  placeholderColorDark,
  value,
  type,
  show,
  setShow,
  onChange,
  border,
  name,
  mb,
  h,
  darkBG,
  readOnly,
  optional,
  min,
  max,
  fontSize,
  my,
  mt,
  rightIconBorder,
  isLoading,
}: any) => {
  const borderColor = useColorModeValue(
    theme.colors.light.border.primary,
    theme.colors.dark.border.primary
  );
  const borderColorSecondary = useColorModeValue(
    theme.colors.light.border.secondary,
    theme.colors.dark.border.secondary
  );
  const bgColor = useColorModeValue("#FFF", `${darkBG || "#121B18"}`);
  const [isFocused, setIsFocused] = useState(false);
  const iconOpacity = useColorModeValue(1, isFocused ? 1 : 0.8);
  return (
    <InputGroup
      mt={mt}
      my={my || "10px"}
      mb={mb ? mb : "24px"}
      bg={bgColor}
      borderRadius={"12px"}
      border={`1px solid ${borderColor}`}
    >
      {icon && (
        <InputLeftElement pointerEvents="none" py="23px">
          <Image
            src={isFocused && activeIcon ? activeIcon : icon}
            opacity={iconOpacity}
            px="10px"
            borderRight={"1px solid"}
            borderRightColor={borderColorSecondary}
          />
        </InputLeftElement>
      )}
      <Input
        _focus={{ borderColor: border || borderColor, boxShadow: "none" }}
        errorBorderColor="red"
        border={"1px solid"}
        borderColor={useColorModeValue("#EEE", "#5A5A5A")}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        type={type}
        name={name}
        value={value}
        min={min || 0}
        max={max}
        placeholder={placeholder}
        py="23px"
        fontSize={fontSize || "auto"}
        pl={icon ? "50px" : ""}
        borderRadius={"12px"}
        onChange={onChange}
        required={optional ? false : true}
        readOnly={readOnly}
        h={h}
        _placeholder={{
          color: useColorModeValue(
            isFocused ? "#5A5A5A" : "#C7C7C7",
            placeholderColorDark || "#C7C7C7"
          ),
        }}
      />
      {rightIcon && (
        <InputRightElement
          py="23px"
          cursor={"pointer"}
          onClick={setShow ? () => setShow(!show) : () => {}}
        >
          {isLoading ? (
            <Spinner size="sm" color="#00ffe0" />
          ) : (
            <Image
              src={isFocused && activeRightIcon ? activeRightIcon : rightIcon}
              opacity={1}
              px={rightIconBorder ? "10px" : undefined}
              borderLeft={rightIconBorder ? "1px solid" : undefined}
              borderLeftColor={borderColorSecondary}
            />
          )}
        </InputRightElement>
      )}
    </InputGroup>
  );
};

export default UserInfoInput;
