import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import AuthNav from "../components/AuthNav";
import bgImage from "../assets/BackgroundImage.png";
import AuthCard from "../components/AuthCard";
import theme from "../theme/theme";

const AuthLayout = ({ children, noAuthNav }: any) => {
  const bgColor = useColorModeValue(theme.colors.light.bg, "#030303");
  return (
    <Box
      bgImage={bgImage}
      bgColor={bgColor}
      minH="100vh"
      display="flex"
      flexDirection="column"
      pb="50px"
      fontSize={"14px"}
    >
      {noAuthNav ? <Box h='100px' /> : <AuthNav />}
      <Flex justify={"center"} align={"center"} direction={"column"} flex={1}>
        <AuthCard>{children}</AuthCard>
      </Flex>
    </Box>
  );
};

export default AuthLayout;
