import {
  Avatar,
  Button,
  Flex,
  Heading,
  Image,
  Spinner,
  Text,
  Toast,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CircularNaira from "../assets/naira_circular.svg";
import { useSwap } from "../utils/wallet.api";
import CustomModal from "./CustomModal";
import { useNavigate } from "react-router-dom";
import verifiedIcon from "../assets/verified.svg";
import failedIcon from "../assets/failedIcon.svg";

const SwapNowButton = ({
  selectedCoin,
  toPay,
  nairaBalance,
  payAmount,
  setPayAmount,
  swapRateLoading,
  nairaLoading,
  balanceLoading,
  receiveAmount,
  setReceiveAmount,
  setSliderValue,
}: any) => {
  const toast = useToast();
  const swapMutation = useSwap();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [errorMsg, setErrorMsg] = useState("");
  const [toCrypt, setToCrypt] = useState(false);
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  const navigate = useNavigate();
  const paraColor2 = useColorModeValue("#5A5A5A", "#EEE");
  const callSwap = async (toCrypto: boolean) => {
    const formData = {
      coinId: selectedCoin?.coinId,
      networkId: selectedCoin?.networkId,
      amount: Number(payAmount),
    };
    swapMutation
      ?.mutateAsync({ payload: formData, toCrypto })
      .then((res: any) => {
        // toast({
        //   title: toCrypto
        //     ? `You swapped ${payAmount} NGN with ${selectedCoin?.name}`
        //     : `You swapped ${payAmount} ${selectedCoin?.symbol} with NGN`,
        //   status: "success",
        // });
        onOpen();
        setToCrypt(toCrypto);
        // setPayAmount(0);
      })
      .catch((err: any) => {
        onErrorOpen();
        setErrorMsg(err?.message);
        // toast({
        //   title: err?.message,
        //   status: "error",
        // });
      });
  };
  return (
    <div>
      <Button
        bg={"none"}
        _hover={{ bg: "none" }}
        w={"full"}
        padding={"0px"}
        isDisabled={
          swapMutation?.isLoading ||
          swapRateLoading ||
          nairaLoading ||
          balanceLoading
        }
        onClick={() => {
          if (toPay === "crypto") {
            if (Number(payAmount) > Number(selectedCoin?.balance)) {
              // toast({
              //   title:
              //     "Pay amount can not be greater then available coin balance!",
              //   status: "error",
              // });
              onErrorOpen();
              setErrorMsg(
                "Pay amount can not be greater than available coin balance"
              );
            } else {
              callSwap(false);
            }
          } else {
            if (Number(payAmount) > Number(nairaBalance?.balance)) {
              // toast({
              //   title: "Pay amount can not be greater then naira balance!",
              //   status: "error",
              // });
              onErrorOpen();
              setErrorMsg("Pay amount can not be greater then naira balance");
            } else {
              callSwap(true);
            }
          }
        }}
      >
        <Flex
          justify={"space-between"}
          w={"full"}
          bg={"linear-gradient(91deg, #00DBFF 0%, #00FFE0 47.92%)"}
          padding={"8px"}
          align={"center"}
          borderRadius={"72px"}
        >
          {toPay === "crypto" ? (
            <Avatar src={selectedCoin?.logoUrl} size="sm" />
          ) : (
            <Avatar src={CircularNaira} size="sm" />
          )}
          {swapMutation?.isLoading ? (
            <Spinner size="sm" color="#000332d" />
          ) : (
            <Text
              color={"#00332D"}
              fontSize={["11px", "12px", "14px", "16px"]}
              fontWeight={"600"}
              letterSpacing={"0.32px"}
              textTransform={"capitalize"}
            >
              Swap now
            </Text>
          )}
          {toPay === "crypto" ? (
            <Avatar src={CircularNaira} size="sm" />
          ) : (
            <Avatar src={selectedCoin?.logoUrl} size="sm" />
          )}
        </Flex>
      </Button>
      <CustomModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          navigate("/dashboard/index");
          setPayAmount(0);
          setReceiveAmount(0);
          setSliderValue(0);
        }}
        headerText="Success"
        // footerText="Perform Another Transaction"
        // closeText="Back To Home"
        // isLoading={swapMutation?.isLoading}
        // isDisabled={swapMutation?.isLoading}
        noFooter={true}
        onSubmit={() => {
          // navigate("/dashboard/index");
          onClose();
        }}
      >
        <Flex
          justify={"center"}
          align={"center"}
          direction={"column"}
          gap="24px"
          mb="32px"
          mt={"32px"}
        >
          <Image src={verifiedIcon} w={"56px"} h={"56px"} />
          <Heading
            fontFamily={"Audiowide"}
            fontSize={["15px", "18px", "21px", "24px"]}
            fontWeight={"400"}
          >
            Success!
          </Heading>
          <Text
            textAlign={"center"}
            fontSize={["9px", "11px", "13px", "14px"]}
            fontWeight={"400"}
            lineHeight={"20px"}
            letterSpacing={"0.035px"}
            color={paraColor2}
          >
            {toCrypt
              ? `You swapped ${payAmount} NGN with ${receiveAmount} ${selectedCoin?.name}.`
              : `You swapped ${payAmount} ${selectedCoin?.symbol} with ${receiveAmount} NGN.`}
          </Text>
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={isErrorOpen}
        onClose={() => {
          onErrorClose();
        }}
        noFooter={true}
        onSubmit={() => {
          navigate("/dashboard/index");
          onErrorClose();
        }}
      >
        <Flex
          justify={"center"}
          align={"center"}
          direction={"column"}
          gap="24px"
          mb="32px"
          mt={"32px"}
        >
          <Image src={failedIcon} w={"56px"} h={"56px"} />
          <Heading
            fontFamily={"Audiowide"}
            fontSize={["15px", "18px", "21px", "24px"]}
            fontWeight={"400"}
          >
            Transaction Failed!
          </Heading>
          <Text
            textAlign={"center"}
            fontSize={["9px", "11px", "13px", "14px"]}
            fontWeight={"400"}
            lineHeight={"20px"}
            letterSpacing={"0.035px"}
            color={paraColor2}
          >
            {errorMsg}
          </Text>
        </Flex>
      </CustomModal>
    </div>
  );
};

export default SwapNowButton;
