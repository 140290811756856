import React, { useEffect, useState } from "react";
import AdminLayout from "../layouts/AdminLayout";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import PrimaryButton from "../components/PrimaryButton";
import AuthCardHeader from "../components/AuthCardHeader";
import verifiedIcon from "../assets/verified.svg";
import CongratulationCard from "../components/CongratulationCard";
import AuthCard from "../components/AuthCard";
import CustomModal from "../components/CustomModal";
import { useLocation, useNavigate } from "react-router-dom";
import { useVerifyOtpForTransaction, useWithdraw } from "../utils/wallet.api";
import { useGetLoggedInUser } from "../utils/auth.api";
import failedIcon from "../assets/failedIcon.svg";
import arrow1 from "../assets/backArrow.svg";
import arrow2 from "../assets/backArrowDark.svg";

const ConfirmWithdraw = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: user } = useGetLoggedInUser();
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location?.state;
  // const selectedUser = location?.state;

  const toast = useToast();
  const headColor = useColorModeValue("#00332D", "#EEE");
  const headColor2 = useColorModeValue("#333", "#EEE");
  const paraColor = useColorModeValue("#909090", "#C7C7C7");
  const paraColor2 = useColorModeValue("#5A5A5A", "#EEE");
  const paraColor3 = useColorModeValue("#000", "#EEE");
  const [isCompleteOpen, setIsCompleteOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const arrowIcon = useColorModeValue(arrow1, arrow2);

  const onCompleteOpen = () => {
    setIsCompleteOpen(true);
  };
  const onCompleteClose = () => {
    setIsCompleteOpen(false);
    navigate("/dashboard/withdraw");
  };
  const [pinInput, setPinInput] = useState("");
  const [isVerified, setIsVerified] = useState(3);
  const [timer, setTimer] = useState(60);
  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;
  const displayTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  const withdrawMutation = useWithdraw();
  const verifyOtpForTransactionMutation = useVerifyOtpForTransaction();
  const verifyOtp = async () => {
    setPinInput("");
    onClose();

    try {
      await verifyOtpForTransactionMutation.mutateAsync({
        email: user?.email,
        otp: pinInput,
        coinId: data?.selectedTokenData?.coinId || data?.selectedTokenData?.id,
        networkId: data?.selectedTokenData?.networkId,
        address:
          data?.newAddress !== undefined
            ? data?.newAddress?.walletAddress
            : data?.selectedUser?.uniqueId
            ? ""
            : data?.selectedTokenData?.address,
        userId: data?.selectedUser?.uniqueId || null,
        messsage: data?.memo,
        amount: Number(data?.amount),
        walletLable:
          data?.newAddress !== undefined
            ? data?.newAddress?.walletLabel
            : data?.walletLabel || "",
      });
      onCompleteOpen();
    } catch (error: any) {
      onErrorOpen();
      setErrorMsg(error?.message);
      // toast({
      //   title: error?.message,
      //   status: "error",
      // });
    }
  };
  const withdraw = async () => {
    try {
      const formData = {
        coinId: data?.selectedTokenData?.coinId || data?.selectedTokenData?.id,
        networkId: data?.selectedTokenData?.networkId,
        address:
          data?.newAddress !== undefined
            ? data?.newAddress?.walletAddress
            : data?.selectedUser?.uniqueId
            ? ""
            : data?.selectedTokenData?.address,
        amount: Number(data?.amount),
        messsage: data?.memo,
        userId: data?.selectedUser?.uniqueId || null,
        walletLable:
          data?.newAddress !== undefined
            ? data?.newAddress?.walletLabel
            : data?.walletLabel || "",
      };
      await withdrawMutation?.mutateAsync(formData);
      if (user?.authTransaction) {
        onOpen();
        setTimer(60);
      } else onCompleteOpen();
    } catch (error: any) {
      // toast({
      //   title: error.message,
      //   status: "error",
      //   duration: 3000,
      //   isClosable: true,
      // });
      setErrorMsg(error?.message);
      onErrorOpen();
    }
  };

  useEffect(() => {
    const countdown = setInterval(() => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
      } else {
        clearInterval(countdown);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [timer, isOpen, onOpen, onClose]);

  //dummy select data
  // const selectedUser = {
  //   name: "John Doe",
  //   email: "john.doe@example.com",
  //   walletAddress: "0x12345",
  //   profilePicture: "https://via.placeholder.com/150",
  // };

  return (
    <AdminLayout>
      <Box marginTop="72px" marginRight={"2%"} mb={"50px"} w={"95%"}>
        <Flex align={"center"} gap={"10px"}>
          <Image
            src={arrowIcon}
            alt="arrow"
            transform={"rotate(270deg)"}
            onClick={() => navigate("/dashboard/withdraw")}
            cursor={"pointer"}
            mb={"40px"}
          />
          <Heading
            color={headColor}
            fontSize={"34px"}
            fontWeight={"700"}
            letterSpacing={"0.085px"}
            mb={"40px"}
          >
            Confirm Transaction
          </Heading>
        </Flex>
        <Text
          color={paraColor}
          fontSize={"14px"}
          fontWeight={"400"}
          lineHeight={"20px"}
          letterSpacing={"0.035px"}
          mb={"32px"}
        >
          Review and confirm your withdrawal details below.
        </Text>
        <Flex direction={"column"} mb={"20px"}>
          <Text
            color={paraColor}
            fontSize={"14px"}
            fontWeight={"400"}
            letterSpacing={"0.014px"}
          >
            Amount
          </Text>
          <Heading
            color={headColor2}
            fontSize={"34px"}
            fontWeight={"700"}
            letterSpacing={"0.085px"}
            display={"flex"}
            gap={"5px"}
            alignItems={"center"}
          >
            {Number(data?.amount || 0)?.toLocaleString(undefined, {
              minimumFractionDigits: 5,
              maximumFractionDigits: 5,
            })}
            <Image
              src={
                data?.selectedTokenData?.logoUrl ||
                data?.selectedTokenData?.icon
              }
              w={"16px"}
              h={"16px"}
              mb={"-10px"}
            />
            {/* <Text
              color={paraColor}
              fontSize={"18px"}
              fontWeight={"700"}
              lineHeight={"24px"}
              letterSpacing={"0.027px"}
              alignSelf={"flex-end"}
            >
              {data?.selectedTokenData?.symbol?.split(" ")[0] === "USDT"
                ? `USDT (${data?.selectedTokenData?.symbol?.split("(")[1]}`
                : data?.selectedTokenData?.symbol?.split(" ")[0]}
            </Text> */}
          </Heading>
        </Flex>

        <hr />

        {data?.selectedUser?.id && (
          <>
            <Flex align="center" my={"20px"}>
              <Avatar
                src={data?.selectedUser?.image}
                name={data?.selectedUser?.fullname}
                borderRadius="full"
                boxSize="56px"
                mr="8px"
              />
              <Box>
                <Text fontSize="14px" fontWeight="bold">
                  {data?.selectedUser?.fullname}
                </Text>
                <Text fontSize="12px" color={paraColor}>
                  {data?.selectedUser?.email}
                </Text>
              </Box>
            </Flex>

            <hr />
          </>
        )}
        <Flex mt={"20px"} justify={"space-between"}>
          <Text
            color={paraColor2}
            fontSize={"16px"}
            fontWeight={"400"}
            lineHeight={"22px"}
            letterSpacing={"0.08px"}
          >
            Amount in USD
          </Text>
          <Text
            color={paraColor3}
            fontSize={"18px"}
            fontWeight={"400"}
            lineHeight={"24px"}
            letterSpacing={"0.027px"}
          >
            ${" "}
            {Number(
              Number(data?.amount) *
                Number(
                  data?.selectedTokenData?.priceUsd ||
                    data?.selectedTokenData?.price
                )
            )?.toLocaleString(undefined, {
              minimumFractionDigits: 5,
              maximumFractionDigits: 5,
            })}
          </Text>
        </Flex>
        <Flex mt={"20px"} justify={"space-between"}>
          <Text
            color={paraColor2}
            fontSize={"16px"}
            fontWeight={"400"}
            lineHeight={"22px"}
            letterSpacing={"0.08px"}
          >
            Selected Token
          </Text>
          <Text
            color={paraColor3}
            fontSize={"18px"}
            fontWeight={"400"}
            lineHeight={"24px"}
            letterSpacing={"0.027px"}
          >
            {data?.selectedTokenData?.name?.split(" ")[0]}
          </Text>
        </Flex>
        {!data?.selectedUser?.id && (
          <Flex mt={"20px"} mb={"36px"} justify={"space-between"}>
            <Text
              color={paraColor2}
              fontSize={"16px"}
              fontWeight={"400"}
              lineHeight={"22px"}
              letterSpacing={"0.08px"}
            >
              Wallet Address
            </Text>
            <Text
              color={paraColor3}
              fontSize={"18px"}
              fontWeight={"400"}
              lineHeight={"24px"}
              letterSpacing={"0.027px"}
              // maxW={{base:'150px', sm:'200px', md:'fit-content'}}
              maxW={{ base: "55%", sm: "65%" }}
            >
              {data?.newAddress !== undefined
                ? data?.newAddress?.walletAddress
                : data?.selectedTokenData?.address}
            </Text>
          </Flex>
        )}
        {data?.selectedUser?.id && (
          <Flex mt={"20px"} mb={"36px"} justify={"space-between"}>
            <Text
              color={paraColor2}
              fontSize={"16px"}
              fontWeight={"400"}
              lineHeight={"22px"}
              letterSpacing={"0.08px"}
            >
              User Id
            </Text>
            <Text
              color={paraColor3}
              fontSize={"18px"}
              fontWeight={"400"}
              lineHeight={"24px"}
              letterSpacing={"0.027px"}
              // maxW={{base:'150px', sm:'200px', md:'fit-content'}}
              maxW={{ base: "55%", sm: "65%" }}
            >
              {data?.selectedUser?.uniqueId}
            </Text>
          </Flex>
        )}
        <hr />
        <Box width={"240px"}>
          <PrimaryButton
            text={
              withdrawMutation?.isLoading ||
              verifyOtpForTransactionMutation.isLoading ? (
                <Spinner />
              ) : (
                "Confirm"
              )
            }
            disabled={
              withdrawMutation?.isLoading ||
              verifyOtpForTransactionMutation.isLoading
            }
            onClick={withdraw}
          />
        </Box>
        <CustomModal
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setPinInput("");
          }}
          headerText="Complete Withdrawal Request"
          footerText="Verify Withdrawal"
          closeText="Cancel"
          isLoading={verifyOtpForTransactionMutation.isLoading}
          isDisabled={pinInput?.length < 6}
          onSubmit={verifyOtp}
        >
          <Flex
            justify={"space-between"}
            align={"center"}
            direction={"column"}
            gap="72px"
            mb="32px"
            mt={"32px"}
          >
            <Box
              textAlign={"center"}
              fontSize={"14px"}
              color={useColorModeValue("#5A5A5A", "#C7C7C7")}
            >
              <Text>
                To complete the withdrawal, kindly submit the six digits code
                sent to
              </Text>
              <Text fontWeight={700}>{`${user?.email?.slice(
                0,
                4
              )} **** ${user?.email?.slice(-4)}`}</Text>
            </Box>
            <Flex justify={"space-between"} gap="10px" fontFamily={"Roboto"}>
              <PinInput
                errorBorderColor="red.300"
                focusBorderColor="teal.300"
                size={"lg"}
                variant={"flushed"}
                placeholder="0"
                value={pinInput}
                isInvalid={isVerified === 0 && pinInput !== ""}
                onChange={(value) => {
                  setPinInput(value);
                }}
                autoFocus
              >
                <PinInputField
                  _placeholder={{ color: "#C7C7C7" }}
                  fontWeight={700}
                  color={useColorModeValue("#5A5A5A", "#EEEEEE")}
                  fontFamily={"Roboto"}
                  fontSize={"20px"}
                />
                <PinInputField
                  _placeholder={{ color: "#C7C7C7" }}
                  fontWeight={700}
                  color={useColorModeValue("#5A5A5A", "#EEEEEE")}
                  fontFamily={"Roboto"}
                  fontSize={"20px"}
                />
                <PinInputField
                  _placeholder={{ color: "#C7C7C7" }}
                  fontWeight={700}
                  color={useColorModeValue("#5A5A5A", "#EEEEEE")}
                  fontFamily={"Roboto"}
                  fontSize={"20px"}
                />
                <PinInputField
                  _placeholder={{ color: "#C7C7C7" }}
                  fontWeight={700}
                  color={useColorModeValue("#5A5A5A", "#EEEEEE")}
                  fontFamily={"Roboto"}
                  fontSize={"20px"}
                />
                <PinInputField
                  _placeholder={{ color: "#C7C7C7" }}
                  fontWeight={700}
                  color={useColorModeValue("#5A5A5A", "#EEEEEE")}
                  fontFamily={"Roboto"}
                  fontSize={"20px"}
                />
                <PinInputField
                  _placeholder={{ color: "#C7C7C7" }}
                  fontWeight={700}
                  color={useColorModeValue("#5A5A5A", "#EEEEEE")}
                  fontFamily={"Roboto"}
                  fontSize={"20px"}
                />
              </PinInput>
            </Flex>
            <Flex justify={"space-between"} mt="20px" align={"center"}>
              {isVerified === 0 && pinInput !== "" && (
                <Text color="red.300">Incorrect verification code.</Text>
              )}
              <Flex
                gap="5px"
                align="center"
                fontSize={"12px"}
                justify={"end"}
                ml="auto"
              >
                <Text color={useColorModeValue("#5A5A5A", "#C7C7C7")}>
                  {displayTime === "00:00"
                    ? "Didn’t received code?"
                    : "Resend within"}
                </Text>
                <Text
                  color={useColorModeValue("#00332D", "#00FFE0")}
                  fontWeight={700}
                  cursor={displayTime === "00:00" ? "pointer" : ""}
                  onClick={() => {
                    displayTime === "00:00" && withdraw();
                  }}
                >
                  {displayTime === "00:00" ? "Send Again" : displayTime}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </CustomModal>
        <CustomModal
          isOpen={isCompleteOpen}
          onClose={onCompleteClose}
          headerText="Completed"
          onSubmit={() => navigate("/dashboard/withdraw")}
        >
          <Flex
            justify={"center"}
            align={"center"}
            direction={"column"}
            gap="24px"
            mb="32px"
            mt={"32px"}
          >
            <Image src={verifiedIcon} w={"56px"} h={"56px"} />
            <Heading
              fontFamily={"Audiowide"}
              fontSize={["15px", "18px", "21px", "24px"]}
              fontWeight={"400"}
            >
              Withdrawal Completed
            </Heading>
            <Text
              textAlign={"center"}
              fontSize={["9px", "11px", "13px", "14px"]}
              fontWeight={"400"}
              lineHeight={"20px"}
              letterSpacing={"0.035px"}
              color={paraColor2}
            >
              You can view the details in transaction history.
            </Text>
          </Flex>
        </CustomModal>
        <CustomModal
          isOpen={isErrorOpen}
          onClose={() => {
            onErrorClose();
            // navigate("/dashboard/index");
          }}
          noFooter={true}
          onSubmit={() => {
            // navigate("/dashboard/withdraw");
            onErrorClose();
          }}
        >
          <Flex
            justify={"center"}
            align={"center"}
            direction={"column"}
            gap="24px"
            mb="32px"
            mt={"32px"}
          >
            <Image src={failedIcon} w={"56px"} h={"56px"} />
            <Heading
              fontFamily={"Audiowide"}
              fontSize={["15px", "18px", "21px", "24px"]}
              fontWeight={"400"}
            >
              Transaction Failed!
            </Heading>
            <Text
              textAlign={"center"}
              fontSize={["9px", "11px", "13px", "14px"]}
              fontWeight={"400"}
              lineHeight={"20px"}
              letterSpacing={"0.035px"}
              color={paraColor2}
            >
              {errorMsg}
            </Text>
          </Flex>
        </CustomModal>
      </Box>
    </AdminLayout>
  );
};

export default ConfirmWithdraw;
