import {
  Flex,
  Image,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import theme from "../theme/theme";
import copyLight from "../assets/copy.svg";
import copyDark from "../assets/copyDark.svg";

export const CustomDetailFlex = ({ title, value, copy }: any) => {
  const toast = useToast();
  const primaryText = useColorModeValue(
    theme.colors.light.text.primary,
    theme.colors.dark.text.primary
  );
  const copyIcon = useColorModeValue(copyLight, copyDark);
  return (
    <Flex
      justify={"space-between"}
      my="16px"
      direction={{ base: "row", md: "row" }}
      gap="20px"
    >
      <Text>{title}</Text>
      <Flex align="center" gap="10px">
        <Text color={primaryText} fontSize={{ base: "14px", md: "18px" }}>
          {value}
        </Text>
        {copy && (
          <Image
            src={copyIcon}
            alt="copy"
            cursor={"pointer"}
            onClick={() => {
              navigator.clipboard.writeText(value);
              toast({
                title: "Referral Code copied to clipboard",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
            }}
          />
        )}
      </Flex>
    </Flex>
  );
};
