import React, { useEffect, useState } from "react";
import AdminLayout from "../layouts/AdminLayout";
import CustomHeader from "../components/CustomHeader";
import {
  Avatar,
  Box,
  Button,
  Circle,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Text,
  textDecoration,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import CustomTabs from "../components/CustomTabs";
import TokenCard from "../components/TokenCard";
import erc20 from "../assets/erc20.svg";
import trc20 from "../assets/trc20.svg";
import trc from "../assets/trc.svg";
import eth from "../assets/ethereum.svg";
import ton from "../assets/ton.svg";

import AddressBook from "../assets/addressBook.svg";
import AddressBookButtonIcon from "../assets/addressBookButtonIcon.svg";
import AddressBookButtonDark from "../assets/addressBookDark.svg";
import notChecked from "../assets/uncheckIcon.svg";
import checkedLight from "../assets/checkedLight.svg";
import notCheckedDark from "../assets/notCheckedDark.svg";
import WithdrawSlider from "../components/WithdrawSlider";
import PrimaryButton from "../components/PrimaryButton";
import arrowRightLight from "../assets/icons/light/arrowRight.svg";
import arrowRightDark from "../assets/icons/dark/arrowRight.svg";
import addressBtn from "../assets/addressBookBtnDark.svg";
import UserInfoInput from "../components/UserInfoInput";
import { useLocation, useNavigate } from "react-router-dom";
import NairaWithdrawNoBank from "../components/NairaWithdrawNoBank";
import NairaWithdraw from "../components/NairaWithdraw";
import communicationLight from "../assets/icons/light/Communication.svg";
import communicationDark from "../assets/icons/dark/Communication.svg";
import copyLight from "../assets/icons/light/copy.svg";
import copyDark from "../assets/icons/dark/copy.svg";
import searchLight from "../assets/icons/light/search.svg";
import searchDark from "../assets/icons/dark/search.svg";
import cross from "../assets/icons/cross.svg";
import {
  useGetWalletWithBalance,
  useValidateAddress,
} from "../utils/wallet.api";
import NetworkCard from "../components/NetworkCard";
import { useGetUsersBySearch } from "../utils/auth.api";
import {
  useGetMinTrxAmount,
  useGetStableCoinsAndNetworks,
  useGetUSDCNetworks,
  useGetUSDTNetworks,
} from "../utils/coins.api";
import theme from "../theme/theme";

const Withdraw = ({ address, setAddress }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const dataAddressBook = location.state;
  const [usdtSelected, setUsdtSelected] = useState("erc20");
  const [addAddressChecked, setAddAddressChecked] = useState(
    address ? true : false
  );
  const { data, isLoading } = useGetWalletWithBalance();
  const validateAddressMutatation = useValidateAddress();
  // const { data: stableCoinsAndNetworks } = useGetStableCoinsAndNetworks();
  const [tokenCardSelected, setTokenCardSelected] = useState(
    dataAddressBook ? dataAddressBook?.symbol : data?.balance?.[0]?.symbol
  );
  const [addAddress, setAddAddress] = useState({
    walletAddress: address?.walletAddress || "",
    walletLabel: address?.walletLabel || "",
  });

  const { data: usdtNetworks } = useGetUSDTNetworks();
  const { data: usdcNetworks } = useGetUSDCNetworks();
  const { data: minTrxWithdraw } = useGetMinTrxAmount();

  const [stableNetworks, setStableNetworks] = useState([]);
  const [stableType, setStableType] = useState("");

  // const sampleNetworkData = stableCoinsAndNetworks?.map(
  //   (item: any, idx: any) => ({
  //     // selected: selectedNetwork?.title === "Tron",
  //     index: idx,
  //     icon: item?.icon, // Replace with actual URL to the icon
  //     title: item?.name,
  //     subTitle: item?.symbol,
  //     minWithdraw: "25",
  //     currency: "USDT",
  //     time: 2,
  //     address: item?.addressInfo?.address,
  //   })
  // );

  const [selectedNetwork, setSelectedNetwork]: any = useState<any>();
  const [selectedNetworkUsdc, setSelectedNetworkUsdc]: any = useState<any>();

  const [selectedTokenBalance, setSelectedTokenBalance] = useState(0);
  const [amountSelected, setAmountSelected] = useState(0);
  const [selectedTokenData, setSelectedTokenData] = useState<any | null>(null);
  const [, setSelected] = useState("NGN");
  const [walletAddress, setWalletAddress] = useState("");
  const [selectedUser, setSelectedUser] = useState<any>();
  const [modal, setModal] = useState(false);

  const {
    data: users,
    isFetching: userLoading,
    refetch: refetchUsers,
  } = useGetUsersBySearch(walletAddress);

  const darkerColor = useColorModeValue("#5A5A5A", "#C7C7C7");
  const headingColor = useColorModeValue("#333", "#FFF");
  const subHeadingColor = useColorModeValue("#909090", "#EEE");
  const paraColor = useColorModeValue("#5A5A5A", "#C7C7C7");
  const paraColor2 = useColorModeValue("#00332D", "#00FFE0");
  const borderColor = useColorModeValue("#FFF", "#333");

  const borderColor2 = useColorModeValue("#EEE", "#333");
  const backColor = useColorModeValue("#F9F9F9", "#121B18");
  const lableColor = useColorModeValue("#333", "#EEE");
  const backColor2 = useColorModeValue("#F9F9F9", "");
  const fontW = useColorModeValue("700", "400");
  const suggestBG = useColorModeValue("#FFF", "#121B18");
  const suggestBR = useColorModeValue("#EEE", "#333");
  const suggestedBG = useColorModeValue("#F9F9F9", "#121B18");
  const closeBG = useColorModeValue("#00FFE0", "#00FFE0");
  const modalBG = useColorModeValue("#FFF", "#121B18");
  const modalText = useColorModeValue("#5A5A5A", "#EEE");
  const modalBR = useColorModeValue("#EEEF", "#333");

  const arrowRightIcon = useColorModeValue(arrowRightLight, arrowRightDark);
  const addressIcon = useColorModeValue(AddressBook, AddressBookButtonDark);
  const addressIconBtn = useColorModeValue(AddressBookButtonIcon, addressBtn);
  const notCheckedIcon = useColorModeValue(notChecked, notCheckedDark);
  const checkedIcon = useColorModeValue(checkedLight, checkedLight);
  const copyIcons = useColorModeValue(copyLight, copyDark);
  const searchIcons = useColorModeValue(searchLight, searchDark);
  const [memo, setMemo] = useState("");

  const handleSearchChange = (e: any) => {
    const query = e.target.value;
    setWalletAddress(query);
    setAddAddress({
      ...addAddress,
      walletAddress: query,
    });
  };

  const handleSuggestionClick = (suggestion: any) => {
    setWalletAddress(suggestion.walletAddress);
    setAddAddress({
      ...addAddress,
      walletAddress: suggestion.walletAddress,
    });
    setSelectedUser(suggestion);
  };

  const handleModal = (type?: string) => {
    // setStableType(type);
    setStableNetworks(type === "usdt" ? usdtNetworks : usdcNetworks || []);
    setModal(!modal);
  };

  const calculateMinTrxAmount = () => {
    let tempObj = minTrxWithdraw?.coins?.find((coin: any) =>
      coin?.symbol?.includes(tokenCardSelected)
    );
    if (tempObj) {
      return tempObj?.minimumAmount;
    } else {
      return 0;
    }
  };

  const communicationIcons = useColorModeValue(
    communicationLight,
    communicationDark
  );
  const communicationIconsActive = useColorModeValue(
    communicationDark,
    communicationLight
  );

  const usdtTokens = data?.balance?.filter(
    (bal: any) => bal?.symbol?.split(" ")[0] === "USDT"
  );

  useEffect(() => {
    if (
      tokenCardSelected === "USDT" ||
      tokenCardSelected === "USDT (ERC 20) (Testnet)"
    ) {
      setUsdtSelected("erc20");
      setTokenCardSelected("USDT");
    } else if (
      tokenCardSelected === "trc20" ||
      tokenCardSelected === "USDT (TRC 20) (Testnet)"
    ) {
      setUsdtSelected("trc20");
      setTokenCardSelected("trc20");
    } else {
      setUsdtSelected(usdtSelected);
    }
  }, [tokenCardSelected, selectedTokenBalance]);

  useEffect(() => {
    if (data) {
      setSelectedTokenBalance(
        dataAddressBook ? dataAddressBook?.balance : data?.balance?.[0]?.balance
      );
      setSelectedTokenData(
        dataAddressBook ? dataAddressBook : data?.balance?.[0]
      );
      setTokenCardSelected(
        dataAddressBook ? dataAddressBook?.symbol : data?.balance?.[0]?.symbol
      );
    }
  }, [data]);

  useEffect(() => {
    refetchUsers();
  }, [walletAddress]);

  useEffect(() => {
    if (
      tokenCardSelected !== "USDT (TRC 20) (Testnet)" &&
      tokenCardSelected !== "trc20" &&
      tokenCardSelected !== "USDT (ERC 20) (Testnet)" &&
      tokenCardSelected !== "erc20"
    ) {
      setAmountSelected(0);
      setAddAddressChecked(false);
      setAddAddress({
        walletAddress: "",
        walletLabel: "",
      });
      setWalletAddress("");
      setAddress(null);
    }
  }, [tokenCardSelected]);

  useEffect(() => {
    if (
      tokenCardSelected !== "USDT (TRC 20) (Testnet)" &&
      tokenCardSelected !== "USDT (ERC 20) (Testnet)"
    ) {
      setAmountSelected(0);
      setAddAddressChecked(false);
      setAddAddress({
        walletAddress: "",
        walletLabel: "",
      });
      setWalletAddress("");
      setAddress(null);
    }
  }, [usdtSelected]);

  useEffect(() => {
    window.history.replaceState({}, document.title);
  }, []);

  useEffect(() => {
    setAddAddressChecked(address ? true : false);
    setAddAddress({
      walletAddress: address?.walletAddress,
      walletLabel: address?.walletLabel,
    });
    setAddress(address);
  }, [address]);

  useEffect(() => {
    if (usdtNetworks) {
      setSelectedNetwork(usdtNetworks?.[0]);
    }
    if (usdcNetworks) {
      setSelectedNetworkUsdc(usdcNetworks?.[0]);
    }
  }, [usdtNetworks, usdcNetworks]);

  console.log("-----------hello", usdtTokens);

  return (
    <AdminLayout>
      <Box marginTop="72px" marginRight={"2%"} mb={"50px"} w={"95%"}>
        <CustomHeader
          para1="Payremit Wallet"
          header={"Withdraw"}
          amount="38,920.84"
          setSelected={setSelected}
          data={data}
          isLoading={isLoading}
        />
        <Box mt={"32px"}>
          <CustomTabs
            titles={[
              { sm: "Crypto", md: "Cryptocurrency" },
              { sm: "Naira", md: "Nigerian Naira" },
            ]}
            onTabChange={() => {
              setAddAddressChecked(false);
            }}
          >
            {isLoading ? (
              <Flex w={"100%"} justify={"center"}>
                <Spinner />
              </Flex>
            ) : (
              <SimpleGrid columns={[1, 1, 1, 2]} mt={"16px"} spacing={"24px"}>
                <Box>
                  <Text
                    color={paraColor}
                    fontSize={"16px"}
                    fontWeight={fontW}
                    lineHeight={"22px"}
                    letterSpacing={"0.08px"}
                    mb={"20px"}
                  >
                    Select Token
                  </Text>
                  {/* working on modal when user select coin */}
                  {data?.balance?.map((bal: any) => (
                    <>
                      {bal?.symbol?.split(" ")[0] !== "USDT" ? (
                        <TokenCard
                          coin={bal?.symbol}
                          fullname={
                            bal?.symbol?.includes("USDC")
                              ? selectedNetworkUsdc?.networkInfo?.tokenStandard
                              : bal?.name
                          }
                          icon={
                            bal?.symbol?.includes("USDC")
                              ? selectedNetworkUsdc?.networkInfo?.logoUrl
                              : bal?.logoUrl
                          }
                          balance={
                            bal?.symbol?.includes("ETH") ||
                            bal?.symbol?.includes("BTC")
                              ? bal?.balance
                              : bal?.balance
                          }
                          selected={tokenCardSelected}
                          setSelected={setTokenCardSelected}
                          setSelectedTokenBalance={setSelectedTokenBalance}
                          tokenData={
                            bal?.symbol?.includes("USDC")
                              ? selectedNetworkUsdc
                              : bal
                          }
                          setSelectedTokenData={setSelectedTokenData}
                          setAddAddressChecked={setAddAddressChecked}
                          // handleModal={handleModal}
                          selectedNetwork={
                            bal?.symbol?.includes("USDC")
                              ? selectedNetworkUsdc
                              : null
                          }
                          handleModal={() => {
                            if (bal?.symbol?.includes("USDC")) {
                              handleModal("usdc");
                            }
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  ))}

                  <TokenCard
                    coin={"USDT"}
                    fullname={selectedNetwork?.networkInfo?.tokenStandard}
                    icon={selectedNetwork?.networkInfo?.logoUrl}
                    // iconTwo={trc20}
                    // iconThree={trc}
                    balance={usdtTokens?.[0]?.balance || 0}
                    usdtTokensData={usdtTokens}
                    selected={tokenCardSelected}
                    setSelected={setTokenCardSelected}
                    setSelectedTokenBalance={setSelectedTokenBalance}
                    selectedNetwork={selectedNetwork}
                    tokenData={selectedNetwork}
                    setSelectedTokenData={setSelectedTokenData}
                    handleModal={() => handleModal("usdt")}
                  />
                </Box>

                <Box
                  p={["0px", "0px", "0px", "24px"]}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"24px"}
                  border={`1px solid ${borderColor}`}
                  borderRadius={"12px"}
                  background={addAddressChecked ? backColor2 : ""}
                  // border={"2px solid red"}
                >
                  <Flex
                    p={"8px 8px 8px 12px"}
                    justify={"space-between"}
                    align={"center"}
                    alignSelf={"stretch"}
                    borderRadius={"12px"}
                    border={`1px solid ${borderColor}`}
                    bg={backColor}
                    cursor={"pointer"}
                    onClick={() => {
                      const formData = {
                        selectedTokenData,
                        userId: data?.wallet?.userId,
                      };
                      navigate("/dashboard/address-book", {
                        state: formData,
                      });
                    }}
                  >
                    <Flex gap={"8px"}>
                      <Image
                        src={addressIcon}
                        alt="icon"
                        w={"24px"}
                        h={"24px"}
                      />
                      <Text
                        color={paraColor2}
                        fontSize={"16px"}
                        fontWeight={"400"}
                        lineHeight={"22px"}
                        letterSpacing={"0.024px"}
                      >
                        Address Book
                      </Text>
                    </Flex>
                    <Image src={addressIconBtn} w={"40px"} h={"32px"} />
                  </Flex>
                  <Text
                    color={"#909090"}
                    fontSize={"14px"}
                    fontWeight={"400"}
                    letterSpacing={"0.056px"}
                    display={address ? "none" : "flex"}
                    gap={"8px"}
                    alignItems={"center"}
                  >
                    <Image
                      src={addAddressChecked ? checkedIcon : notCheckedIcon}
                      cursor={"pointer"}
                      onClick={() => setAddAddressChecked(!addAddressChecked)}
                    />
                    <Text
                      cursor={"pointer"}
                      onClick={() => setAddAddressChecked(!addAddressChecked)}
                    >
                      Add New Address
                    </Text>
                  </Text>

                  {/* working for suggest data */}

                  <Box position={"relative"}>
                    {/* {JSON.stringify(userLoading)} */}
                    <UserInfoInput
                      // mt={"-20px"}
                      readOnly={address ? true : false}
                      type="text"
                      placeholder="Email or Wallet Address "
                      mb={address ? "1px" : "16px"}
                      value={
                        address ? addAddress?.walletAddress : walletAddress
                      }
                      onChange={handleSearchChange}
                      rightIcon={searchIcons}
                      isLoading={userLoading}
                      rightIconBorder={true}
                    />
                    {users?.length > 0 && (
                      <Flex
                        position="absolute"
                        top="99%"
                        left="0"
                        right="0"
                        zIndex="10"
                        border="1px solid"
                        borderColor={suggestBR}
                        bgColor={suggestBG}
                        padding={"16px"}
                        flexDirection={"column"}
                        gap={"16px"}
                        boxShadow={"0px 2px 20px 0px rgba(0, 0, 0, 0.05)"}
                        borderRadius="16px"
                        mt="2px"
                      >
                        {users?.map((user: any, index: number) => (
                          <Flex
                            key={index}
                            p="8px"
                            align="center"
                            cursor="pointer"
                            _hover={{ bg: backColor2 }}
                            onClick={() => handleSuggestionClick(user)}
                          >
                            <Avatar
                              src={user?.image}
                              name={user?.uniqueId}
                              boxSize="32px"
                              fontSize="10px"
                              mr="8px"
                            />
                            <Box>
                              <Text fontSize="14px" fontWeight="bold">
                                {user?.uniqueId}
                              </Text>
                              <Text fontSize="12px" color={paraColor}>
                                {user?.email}
                              </Text>
                            </Box>
                          </Flex>
                        ))}
                      </Flex>
                    )}
                  </Box>

                  {selectedUser && (
                    <Flex
                      position={"relative"}
                      borderRadius={"16px"}
                      bgColor={suggestedBG}
                      border={`1px solid ${suggestBR}`}
                      height={"88px"}
                      mt={"-9px"}
                      width={"100%"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      p={"16px"}
                    >
                      <Flex align="center">
                        <Avatar
                          src={selectedUser?.image}
                          name={selectedUser?.fullname}
                          boxSize="56px"
                          mr="8px"
                        />
                        <Box>
                          <Text fontSize="14px" fontWeight="bold">
                            {selectedUser?.fullname}
                          </Text>
                          <Text fontSize="12px" color={paraColor}>
                            {selectedUser?.email}
                          </Text>
                        </Box>
                      </Flex>
                      <Circle
                        size={"32px"}
                        //  width={"32px"}
                        // height={"32px"}
                        alignItems={"center"}
                        bg={closeBG}
                        cursor={"pointer"}
                        onClick={() => {
                          setSelectedUser(null);
                        }}
                      >
                        <Image src={cross} alt={"close"} boxSize="16px" />
                      </Circle>
                    </Flex>
                  )}
                  {addAddressChecked && !selectedUser && (
                    <Flex direction={"column"}>
                      <UserInfoInput
                        readOnly={address ? true : false}
                        type="text"
                        placeholder="Wallet Label"
                        mb="16px"
                        borderColor="#00CCB3"
                        value={addAddress?.walletLabel}
                        onChange={(e: any) =>
                          setAddAddress({
                            ...addAddress,
                            walletLabel: e.target.value,
                          })
                        }
                      />
                    </Flex>
                  )}

                  <WithdrawSlider
                    amountSelected={amountSelected}
                    minAmount={calculateMinTrxAmount()}
                    maxAmount={Number(selectedTokenBalance)}
                    setAmountSelected={setAmountSelected}
                    withdrawCoin={
                      selectedTokenData?.logoUrl ||
                      selectedTokenData?.networkInfo?.logoUrl
                    }
                    withdrawCoinActive={
                      selectedTokenData?.logoUrl ||
                      selectedTokenData?.networkInfo?.logoUrl
                    }
                  />

                  {selectedTokenData?.symbol?.includes("TON") &&
                    !selectedUser && (
                      <Flex direction={"column"} gap={"16px"}>
                        <Text
                          textColor={lableColor}
                          fontSize={"16px"}
                          fontWeight={400}
                          lineHeight={"22px"}
                        >
                          Enter a Memo{" "}
                        </Text>

                        <UserInfoInput
                          placeholder="Type here..."
                          icon={communicationIcons}
                          activeIcon={communicationIconsActive}
                          name="memo"
                          value={memo}
                          onChange={(e: any) => setMemo(e.target.value)}
                          rightIcon={copyIcons}
                        />
                      </Flex>
                    )}

                  {selectedTokenData?.isStable && false && (
                    <Flex direction={"column"} gap={"16px"}>
                      <Text
                        textColor={lableColor}
                        fontSize={"16px"}
                        fontWeight={400}
                        lineHeight={"22px"}
                      >
                        Network
                      </Text>
                      <Flex
                        justify={"space-between"}
                        py="16px"
                        px={["5px", "16px"]}
                        border={`1px solid ${borderColor2}`}
                        borderRadius={"12px"}
                        gap="5px"
                        align={"center"}
                        direction={{ base: "column", md: "row" }}
                      >
                        <Box textAlign={{ base: "center", md: "left" }}>
                          <Text fontWeight={"700"} color={darkerColor}>
                            Select Network
                          </Text>
                          <Flex gap={"4px"}>
                            <Text
                              textColor={headingColor}
                              fontFamily={"Plus Jakarta Sans"}
                              fontSize={"16px"}
                              fontWeight={700}
                              lineHeight={"22px"}
                              letterSpacing={"1.2px"}
                            >
                              {selectedNetwork?.symbol}
                            </Text>

                            <Text
                              textColor={subHeadingColor}
                              fontFamily={"Plus Jakarta Sans"}
                              fontSize={"12px"}
                              fontWeight={400}
                              lineHeight={"22px"}
                              letterSpacing={"0.048px"}
                            >
                              {selectedNetwork?.networkInfo?.symbol}
                            </Text>
                          </Flex>
                        </Box>
                        <Box mx={{ base: "auto", sm: "0" }}>
                          <PrimaryButton
                            text={"Choose"}
                            icon={<Image src={arrowRightIcon} />}
                            mt="0px"
                            // disabled={networkAddressLoading}
                            onClick={handleModal}
                          />
                        </Box>
                      </Flex>
                    </Flex>
                  )}

                  <Text
                    textColor={paraColor}
                    fontSize={"13px"}
                    fontWeight={400}
                    lineHeight={"12px"}
                    textAlign="left"
                  >
                    Min Withdrawal: $5
                  </Text>
                  <Text
                    textColor="red"
                    fontSize={"13px"}
                    fontWeight={400}
                    lineHeight={"12px"}
                    textAlign="left"
                    mt="-20px"
                  >
                    Withdrawal Fee: ${selectedTokenData?.fee}{" "}
                  </Text>

                  <PrimaryButton
                    mt="0px"
                    text="Withdraw"
                    isLoading={validateAddressMutatation?.isLoading}
                    disabled={
                      // (selectedTokenData?.symbol?.includes("TON") &&
                      // !selectedUser ||
                      // &&
                      // !memo
                      Number(amountSelected) > Number(selectedTokenBalance) ||
                      amountSelected.toString() === "" ||
                      amountSelected <= 0 ||
                      (address
                        ? addAddress?.walletLabel === "" ||
                          addAddress?.walletAddress === ""
                        : !selectedUser?.fullname && walletAddress === "") ||
                      (addAddressChecked &&
                        !selectedUser?.fullname &&
                        addAddress?.walletLabel === "")
                    }
                    onClick={() => {
                      if (
                        amountSelected === undefined ||
                        Number(amountSelected) > selectedTokenBalance ||
                        amountSelected.toString() === "" ||
                        amountSelected <= 0
                      ) {
                        toast({
                          title: "Please enter correct amount.",
                          status: "error",
                          duration: 3000,
                          isClosable: true,
                        });
                        return;
                      }
                      if (selectedUser) {
                        const formData = {
                          selectedTokenData,
                          amount: amountSelected,
                          memo: memo,
                          selectedUser: selectedUser,
                          walletLabel: addAddressChecked
                            ? selectedUser?.email
                            : "",
                        };
                        navigate("/dashboard/confirm-withdraw", {
                          // state: formData,
                          state: {
                            data: formData,
                          },
                        });
                      } else if (
                        address ? addAddress?.walletAddress : walletAddress
                      ) {
                        validateAddressMutatation
                          ?.mutateAsync({
                            address: address
                              ? addAddress?.walletAddress
                              : walletAddress,
                            networkId: selectedTokenData?.networkId,
                          })
                          .then((res: any) => {
                            if (res?.status) {
                              const formData = {
                                selectedTokenData,
                                amount: amountSelected,
                                newAddress: addAddress,
                                memo: memo,
                              };

                              navigate("/dashboard/confirm-withdraw", {
                                // state: formData,
                                state: {
                                  data: formData,
                                },
                              });
                              setAddress(null);
                            } else {
                              toast({
                                title:
                                  "Please enter valid address for withdrawal",
                                status: "error",
                                duration: 3000,
                                isClosable: true,
                              });
                              return;
                            }
                          })
                          .catch((err: any) => {
                            toast({
                              title:
                                err?.message ||
                                "Please enter valid address for withdrawal",
                              status: "error",
                              duration: 3000,
                              isClosable: true,
                            });
                            return;
                          });
                      } else {
                        toast({
                          title: "Please enter valid address for withdrawal",
                          status: "error",
                          duration: 3000,
                          isClosable: true,
                        });
                        return;
                      }
                    }}
                  />
                </Box>
              </SimpleGrid>
            )}
            {false ? <NairaWithdrawNoBank /> : <NairaWithdraw />}
          </CustomTabs>
        </Box>
      </Box>
      {/* modal open on coin selection */}
      <Modal isOpen={modal} onClose={handleModal}>
        <ModalOverlay
          width={"100%"}
          height={"100%"}
          position={"absolute"}
          background={"rgba(0, 0, 0, 0.50)"}
          //bg={"Red"}
          backdropBlur={"3px"}
        />
        <ModalContent
          position={"absolute"}
          p={"7px"}
          maxWidth={"600px"}
          display={"flex"}
          flexDirection={"column"}
          gap={"24px"}
          bg={modalBG}
        >
          <ModalHeader
            display={"flex"}
            flexDirection={"row"}
            width={"100%"}
            justifyContent={"space-between"}
            borderBottom={`1px solid ${modalBR}`}
          >
            Select Network
            <Circle
              size={"32px"}
              alignItems={"center"}
              bg={"#00FFE0"}
              cursor={"pointer"}
              onClick={() => handleModal()}
            >
              <Image src={cross} alt={"close"} boxSize="16px" />
            </Circle>
          </ModalHeader>

          <ModalBody>
            <Text
              textColor={modalText}
              fontSize={"14px"}
              fontWeight={400}
              lineHeight={"20px"}
              letterSpacing={"0.035px"}
            >
              Please ensure you select the correct chain, as choosing the wrong
              one will result in the permanent loss of your assets
            </Text>

            <Flex direction={"column"} gap={"16px"} mt={"32px"}>
              {stableNetworks?.map((network: any, index: number) => (
                <NetworkCard
                  key={index}
                  onClick={() => {
                    if (network?.symbol?.includes("USDT")) {
                      setSelectedNetwork(network);
                      setSelectedTokenData(network);
                    } else {
                      setSelectedNetworkUsdc(network);
                      setSelectedTokenData(network);
                    }
                  }}
                  idx={index}
                  selected={
                    network?.symbol?.includes("USDT")
                      ? selectedNetwork
                      : selectedNetworkUsdc
                  }
                  id={network?.id}
                  {...network}
                />
              ))}
            </Flex>
          </ModalBody>
          <ModalFooter borderTop={`1px solid ${modalBR}`}>
            <Button
              mr={3}
              onClick={() => handleModal()}
              borderRadius={"12px"}
              border={`1px solid #C7C7C7`}
              bg={"#EEE"}
              textColor={"#00332D"}
              _hover={{
                bg: "#EEE",
                color: "#00332D",
              }}
            >
              Cancel
            </Button>

            <Button
              onClick={() => handleModal()}
              borderRadius={"12px"}
              border={`1px solid #00CCB3`}
              bg={"#00FFE0"}
              textColor={"#00332D"}
              _hover={{
                bg: "#00FFE0",
                color: "#00332D",
              }}
            >
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </AdminLayout>
  );
};

export default Withdraw;
