import React, { useEffect, useState } from "react";
import AdminLayout from "../layouts/AdminLayout";
import CustomHeader from "../components/CustomHeader";
import {
  Box,
  Checkbox,
  Divider,
  Flex,
  Heading,
  Image,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import CustomTabs from "../components/CustomTabs";
import PrimaryButton from "../components/PrimaryButton";
import UserInfoInput from "../components/UserInfoInput";
import { useNavigate } from "react-router-dom";
import visafoneIcon from "../assets/icons/visafoneIcon.svg";
import airtelIconActive from "../assets/icons/activeairtel.svg";
import visafoneIconActive from "../assets/icons/visafoneActiveIcon.svg";
import smileIcon from "../assets/icons/smileIcon.svg";
import smileIconActive from "../assets/icons/smileActiveIcon.svg";
import gloIcon from "../assets/icons/defaultglo.svg";
import airtelIcon from "../assets/icons/defaultairtel.svg";
import mtnIcon from "../assets/icons/defaultmtn.svg";
import nineMobileIcon from "../assets/icons/default9mobile.svg";
import gloActiveIcon from "../assets/icons/activeglo.svg";
import airtelActiveIcon from "../assets/icons/activeairtel.svg";
import mtnActiveIcon from "../assets/icons/activemtn.svg";
import nineMobileActiveIcon from "../assets/icons/active9mobile.svg";
import nairaLight from "../assets/icons/light/naira.svg";
import nairaDark from "../assets/icons/dark/naira.svg";
import PhoneInputWithCountry from "../components/PhoneInput";
import RecentPurchase from "../components/RecentPurchase";
import CustomModal from "../components/CustomModal";
import AuthCardHeader from "../components/AuthCardHeader";
import { CustomDetailFlex } from "../components/CustomDeatilFlex";
import verifiedIcon from "../assets/verified.svg";
import nairaSymbolActive from "../assets/icons/light/nairaSymbolActive.svg";
import NigeriaFlag from "../assets/icons/nigeria_flag.svg";
import {
  useGetAirtimeOperatorIdAndProductId,
  useGetCompletedAirtime,
  usePurchaseAirTime,
} from "../utils/service.api";
import { PhoneNumber } from "react-phone-number-input";
import { useGetWalletWithBalance } from "../utils/wallet.api";
import failedIcon from "../assets/failedIcon.svg";

const AirTime = () => {
  const {
    isOpen: isErrorOpen,
    onOpen: onErrorOpen,
    onClose: onErrorClose,
  } = useDisclosure();
  const [isCompleteOpen, setIsCompleteOpen] = useState(false);
  const [selected, setSelected] = useState("NGN");
  const [selectedTab, setSelectedTab] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [operatorId, setOperatorId] = useState("");

  const [productId, setProductId] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [amount, setAmount] = useState(0);
  const [isSaved, setIsSaved] = useState(false);
  const [purchaseResponse, setPurchaseResponse] = useState<any>(null);
  const { data, isLoading } = useGetAirtimeOperatorIdAndProductId();
  const { data: balanceData, isLoading: balanceLoading } =
    useGetWalletWithBalance();

  const purchaseAirTimeMutation = usePurchaseAirTime();
  const { data: recentAirtime, isLoading: recentAirtimeLoading } =
    useGetCompletedAirtime(0, 3, operatorId);

  const toast = useToast();

  const onCompleteOpen = () => {
    setIsCompleteOpen(true);
  };
  const onCompleteClose = () => {
    setPurchaseResponse(null);
    setIsCompleteOpen(false);
    setPhoneNo("");
    setAmount(0);
    setIsSaved(false);
  };
  const navigate = useNavigate();
  const nairaIcon = useColorModeValue(nairaLight, nairaDark);
  const nairaActiveIcon = useColorModeValue(nairaSymbolActive, nairaDark);
  const paraColor = useColorModeValue("#5A5A5A", "#C7C7C7");
  const paraColor2 = useColorModeValue("#5A5A5A", "#EEE");

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
    // if (data && data?.operatorId) {
    setOperatorId(data?.[index]?.operatorId);
    setProductId(data?.[index]?.productIds[0]?.id);
    // }
  };

  const getCurrentDate = () => {
    const now = new Date();
    const options: any = { day: "2-digit", month: "short", year: "numeric" };
    return now.toLocaleDateString("en-US", options);
  };

  // console.log(first)

  useEffect(() => {
    if (data) {
      setOperatorId(data?.[0]?.operatorId);
      setProductId(data?.[0]?.productIds[0]?.id);
    }
  }, [data]);
  return (
    <AdminLayout>
      <Box marginTop="72px" marginRight={"2%"} mb={"50px"} w={"95%"}>
        <CustomHeader
          para1="Airtime"
          para2="Services /"
          header={"Airtime"}
          amount="38,920.84"
          setSelected={setSelected}
          data={balanceData}
          isLoading={balanceLoading}
        />
        {isLoading ? (
          <Flex justify={"center"} w={"100%"} py={"20px"}>
            <Spinner />
          </Flex>
        ) : (
          <Box mt={"32px"}>
            <CustomTabs
              titles={data
                // ?.filter((item: any) => item?.operatorId !== "etisalat")
                ?.map((dat: any) => ({
                  sm: "",
                  md: dat?.productIds[0]?.name?.split("-")[0],
                  icon: (() => {
                    switch (
                      dat?.productIds[0]?.name?.split(" ")[0]?.toLowerCase()
                    ) {
                      case "airtime":
                        return (
                          <Image
                            w={"28px"}
                            h={"28px"}
                            mr="5px"
                            src={airtelIcon}
                          />
                        );
                      case "9mobile":
                        return (
                          <Image
                            w={"28px"}
                            h={"28px"}
                            mr="5px"
                            src={nineMobileIcon}
                          />
                        );
                      case "glo":
                        return (
                          <Image w={"28px"} h={"28px"} mr="5px" src={gloIcon} />
                        );
                      case "mtn":
                        return (
                          <Image w={"28px"} h={"28px"} mr="5px" src={mtnIcon} />
                        );
                      case "visafone":
                        return (
                          <Image
                            w={"28px"}
                            h={"28px"}
                            mr="5px"
                            src={visafoneIcon}
                          />
                        );
                      case "smile":
                        return (
                          <Image
                            w={"28px"}
                            h={"28px"}
                            mr="5px"
                            src={smileIcon}
                          />
                        );
                      default:
                        return (
                          <Image
                            w={"28px"}
                            h={"28px"}
                            mr="5px"
                            src={airtelIcon}
                          />
                        );
                    }
                  })(),
                  activeIcon: (() => {
                    switch (
                      dat?.productIds[0]?.name?.split(" ")[0]?.toLowerCase()
                    ) {
                      case "airtime":
                        return (
                          <Image
                            w={"28px"}
                            h={"28px"}
                            mr="5px"
                            src={airtelIconActive}
                          />
                        );
                      case "9mobile":
                        return (
                          <Image
                            w={"28px"}
                            h={"28px"}
                            mr="5px"
                            src={nineMobileActiveIcon}
                          />
                        );
                      case "glo":
                        return (
                          <Image
                            w={"28px"}
                            h={"28px"}
                            mr="5px"
                            src={gloActiveIcon}
                          />
                        );
                      case "mtn":
                        return (
                          <Image
                            w={"28px"}
                            h={"28px"}
                            mr="5px"
                            src={mtnActiveIcon}
                          />
                        );
                      case "visafone":
                        return (
                          <Image
                            w={"28px"}
                            h={"28px"}
                            mr="5px"
                            src={visafoneIconActive}
                          />
                        );
                      case "smile":
                        return (
                          <Image
                            w={"28px"}
                            h={"28px"}
                            mr="5px"
                            src={smileIconActive}
                          />
                        );
                      default:
                        return (
                          <Image
                            w={"28px"}
                            h={"28px"}
                            mr="5px"
                            src={airtelIconActive}
                          />
                        );
                    }
                  })(),
                }))}
              activeTab={selectedTab}
              onTabChange={handleTabChange}
              showDivider={true}
            >
              {data?.map((dat: any, idx: number) => (
                <Flex
                  key={dat?.operatorId}
                  direction={{ base: "column", lg: "row" }}
                  gap="24px"
                  align={{ base: "center", lg: "start" }}
                >
                  <Box w={{ base: "100%", lg: "58%" }}>
                    <Text mt="32px" fontWeight={"700"} color={paraColor}>
                      Purchase Airtime
                    </Text>
                    <Text my="24px">
                      Enter amount and the phone number for which you are buying
                      Airtime.
                    </Text>
                    <UserInfoInput
                      type="number"
                      icon={nairaIcon}
                      activeIcon={nairaActiveIcon}
                      placeholder="00.00"
                      value={amount}
                      onChange={(e: any) => setAmount(e.target.value)}
                    />
                    <UserInfoInput
                      type="tel"
                      activeIcon={NigeriaFlag}
                      placeholder="Enter Phone Number"
                      icon={NigeriaFlag}
                      name="phoneNo"
                      value={phoneNo}
                      onChange={(e: any) => {
                        const pattern = /^\d+$/;
                        if (
                          pattern?.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          setPhoneNo(e.target.value);
                        }
                      }}
                    />
                    {phoneNo && phoneNo?.length > 11 && (
                      <Text fontSize={"12"} mt="-20px" color="red">
                        Incorrect phone number.
                      </Text>
                    )}
                    <Checkbox
                      colorScheme="teal"
                      onChange={(e: any) => {
                        setIsSaved(e.target.checked);
                      }}
                      isChecked={isSaved}
                    >
                      Save Address Book
                    </Checkbox>
                    <PrimaryButton
                      mt="44px"
                      text={
                        purchaseAirTimeMutation?.isLoading
                          ? "Processing..."
                          : `Purchase Airtime for ${
                              dat?.productIds[0]?.name?.split("-")[0]
                            }`
                      }
                      disabled={
                        purchaseAirTimeMutation?.isLoading ||
                        amount == 0 ||
                        !amount ||
                        phoneNo?.length !== 11
                      }
                      onClick={async () => {
                        if (
                          amount == 0 ||
                          !phoneNo ||
                          !amount ||
                          phoneNo === ""
                        ) {
                          toast({
                            title: "Please enter amount and phone number",
                            status: "error",
                            duration: 3000,
                            isClosable: true,
                          });
                          return;
                        }
                        const formData = {
                          operatorId,
                          productId,

                          phoneNumber: phoneNo,
                          amount,
                          addressBook: isSaved ? "true" : "",
                        };
                        await purchaseAirTimeMutation
                          .mutateAsync(formData)
                          .then((result) => {
                            setPurchaseResponse(result?.dataFromApi);
                            onCompleteOpen();
                          })
                          .catch((err) => {
                            onErrorOpen();
                            setErrorMsg(err?.message);
                          });
                      }}
                    />
                  </Box>
                  {recentAirtimeLoading ? (
                    <Spinner />
                  ) : (
                    <RecentPurchase
                      // coin={dat?.productIds[0]?.name?.split("-")[0]}
                      empty={
                        recentAirtime?.length === 0 ||
                        recentAirtime === undefined
                          ? true
                          : false
                      }
                      data={recentAirtime}
                      onSelect={(item: any) => {
                        const findIndex = data.findIndex(
                          (d: any) =>
                            d?.operatorId?.toLowerCase() ===
                            item?.operatorId?.toLowerCase()
                        );
                        console.log(findIndex, data, item);
                        handleTabChange(findIndex || 0);
                        setPhoneNo(item?.phoneNumber || "");
                      }}
                      type="Airtime"
                    />
                  )}
                </Flex>
              ))}
            </CustomTabs>
          </Box>
        )}
      </Box>
      <CustomModal
        isOpen={isCompleteOpen}
        onClose={onCompleteClose}
        headerText="Purchase Completed"
        footerText="Continue Purchasing"
        onSubmit={onCompleteClose}
      >
        <Box pt="20px" mb="-30px">
          <AuthCardHeader
            title={"Airtime Purchased"}
            imgSrc={verifiedIcon}
            imageW="56px"
            des="Transaction Successful"
          />
        </Box>
        <Divider variant={"dashed"} />
        <CustomDetailFlex title="Date" value={getCurrentDate()} />
        <CustomDetailFlex
          title="Network"
          value={purchaseResponse?.network || ""}
        />
        <CustomDetailFlex title="Phone" value={purchaseResponse?.phone || ""} />
        <CustomDetailFlex
          title="Amount"
          value={`NGN${Number(purchaseResponse?.amount || 0)?.toLocaleString(
            undefined,
            {
              maximumFractionDigits: 2,
            }
          )}`}
        />
        <CustomDetailFlex
          title="Order ID"
          value={purchaseResponse?.order_id || ""}
        />
      </CustomModal>
      <CustomModal
        isOpen={isErrorOpen}
        onClose={() => {
          onErrorClose();
          // navigate("/dashboard/index");
        }}
        noFooter={true}
        onSubmit={() => {
          onErrorClose();
        }}
      >
        <Flex
          justify={"center"}
          align={"center"}
          direction={"column"}
          gap="24px"
          mb="32px"
          mt={"32px"}
        >
          <Image src={failedIcon} w={"56px"} h={"56px"} />
          <Heading
            fontFamily={"Audiowide"}
            fontSize={["15px", "18px", "21px", "24px"]}
            fontWeight={"400"}
          >
            Transaction Failed!
          </Heading>
          <Text
            textAlign={"center"}
            fontSize={["9px", "11px", "13px", "14px"]}
            fontWeight={"400"}
            lineHeight={"20px"}
            letterSpacing={"0.035px"}
            color={paraColor2}
          >
            {errorMsg}
          </Text>
        </Flex>
      </CustomModal>
    </AdminLayout>
  );
};

export default AirTime;
