import { ChevronDownIcon } from "@chakra-ui/icons";
import { Box, Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import theme from "../theme/theme";

const SelectCrypto = ({
  isMenuOpen,
  setIsMenuOpen,
  selectedCoin,
  fontWeight,
  iconBG,
  h,
  textColor,
  pad,
  bg,
  dropIconColor,
  iconSize,
  selectedNetwork,
}: any) => {
  const borderColor = useColorModeValue(
    theme.colors.light.border.secondary,
    theme.colors.dark.border.secondary
  );

  const inputBG = useColorModeValue("#FFF", "#121B18");
  const iconBGLocal = useColorModeValue("#EEE", "#00332D");
  const iconColor = useColorModeValue("black", "#00FFE0");

  return (
    <Flex
      h={h || "60px"}
      px={["5px", "16px"]}
      padding={pad ? pad : ""}
      borderRadius={"12px"}
      border="1px solid"
      borderColor={borderColor}
      justify={"space-between"}
      align={"center"}
      cursor="pointer"
      onClick={() => setIsMenuOpen(!isMenuOpen)}
      bg={bg || inputBG}
    >
      <Flex gap="10px" align={"center"}>
        <Image
          w="30px"
          src={
            selectedCoin?.isStable
              ? selectedNetwork?.networkInfo?.logoUrl
              : selectedCoin?.icon
          }
          borderRadius="50%"
        />
        <Text
          color={textColor ? textColor : ""}
          fontSize={"16px"}
          fontWeight={fontWeight || 700}
        >
          {selectedCoin?.symbol}
        </Text>
      </Flex>
      <Flex gap="15px" align={"center"}>
        <Text display={{ base: "none", sm: "block" }}>
          {selectedCoin?.isStable
            ? selectedNetwork?.networkInfo?.tokenStandard
            : selectedCoin?.name}
        </Text>
        <Flex
          mr={["0px", "10px"]}
          p="3px"
          borderRadius={"20px"}
          bg={iconBG || iconBGLocal}
        >
          <ChevronDownIcon
            color={dropIconColor || iconColor}
            fontSize={iconSize || "20px"}
            p="0px"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SelectCrypto;
