import {
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import less from "../assets/lessThanIcon.svg";
import lessDark from "../assets/lessThanIconDark.svg";
import greater from "../assets/greaterThanIcon.svg";
import greaterDark from "../assets/greaterThanDark.svg";

interface TableProps {
  rows: any;
  columns: any;
  borderB?: any;
  pagination?: boolean;
}

const CustomTable: React.FC<TableProps> = ({
  rows,
  columns,
  borderB,
  pagination,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page
  const headerColor = useColorModeValue("#0A1726", "#EEE");
  const bodyColor = useColorModeValue("#5A5A5A", "#C7C7C7");
  const bodyColor2 = useColorModeValue("#909090", "#C7C7C7");
  const borderColor = useColorModeValue("#EEE", "#333");
  const paginationColor = useColorModeValue("#909090", "#EEE");
  const icon1 = useColorModeValue(less, lessDark);
  const icon2 = useColorModeValue(greater, greaterDark);

  const totalPages = Math.ceil(rows?.length / itemsPerPage);

  const displayedRows = rows.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div>
      <TableContainer>
        <Table
          variant="unstyled"
          borderBottom={
            borderB ? `2px solid ${borderColor}` : `1px solid ${borderColor}`
          }
        >
          <Thead>
            <Tr>
              {columns.map((column: any, index: number) => {
                return (
                  <Th
                    textTransform={"none"}
                    color={headerColor}
                    fontFamily={"Poppins"}
                    fontSize={["11px", "13px", "15px", "16px"]}
                    px="10px"
                    fontWeight={"400"}
                    lineHeight={"24px"}
                    // border={"1px solid red"}
                    w={index === columns.length - 1 ? "50px" : ""}
                    textAlign={"left"}
                  >
                    {column}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody
            borderTop={
              borderB ? `2px solid ${borderColor}` : `1px solid ${borderColor}`
            }
          >
            {displayedRows?.length === 0 ? (
              <Flex px={"10px"} py={"10px"}>
                No Data Available
              </Flex>
            ) : (
              displayedRows?.map((row: any, index: any) => {
                const valuePair = Object?.values(row);
                return (
                  <Tr
                    key={index}
                    fontSize={["9px", "11px", "13px", "14px"]}
                    fontWeight={"400"}
                    lineHeight={"20px"}
                    letterSpacing={"0.035px"}
                  >
                    {valuePair?.map((value: any, idx) => (
                      <Td
                        key={idx}
                        color={idx === 0 ? bodyColor : bodyColor2}
                        px="10px"
                        borderTop={
                          index !== 0 && borderB
                            ? `1px solid ${borderColor}`
                            : "none"
                        }
                        textAlign={"left"}
                      >
                        {value}
                      </Td>
                    ))}
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>
        {displayedRows?.length > 0 && pagination && (
          <Flex
            padding={"10px"}
            justify={"flex-end"}
            gap={"8px"}
            align={"center"}
            fontSize={["9px", "11px", "13px", "14px"]}
            fontWeight={"700"}
            lineHeight={"20px"}
            letterSpacing={"0.035px"}
          >
            <Image
              src={icon1}
              alt="leftChevron"
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              h={["22px", "26px", "30px", "34px"]}
              cursor={"pointer"}
              w={["22px", "26px", "30px", "34px"]}
            />

            {Array.from({ length: totalPages }).map((_, index) => {
              // Display only 4 pages around the current page
              const startPage = Math.max(currentPage - 2, 1);
              const endPage = Math.min(startPage + 2, totalPages);

              if (index + 1 >= startPage && index + 1 <= endPage) {
                // Display the page button
                return (
                  <Text
                    key={index}
                    padding={"10px"}
                    borderRadius={"5px"}
                    onClick={() => setCurrentPage(index + 1)}
                    bg={currentPage === index + 1 ? "#00FFE0" : ""}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    cursor={"pointer"}
                    color={
                      currentPage === index + 1 ? "#00332D" : paginationColor
                    }
                    h={["22px", "26px", "30px", "34px"]}
                  >
                    {index + 1}
                  </Text>
                );
              }

              if (index + 1 === totalPages && endPage < totalPages) {
                // Display ellipsis at the end
                return (
                  <Text
                    key={`ellipsis-end-${index}`}
                    padding={"10px"}
                    borderRadius={"5px"}
                    cursor={"default"}
                    color={paginationColor}
                    h={["22px", "26px", "30px", "34px"]}
                  >
                    ...
                  </Text>
                );
              }

              return null; // Return null for pages not in the range
            })}

            <Image
              src={icon2}
              alt="rightChevron"
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              h={["22px", "26px", "30px", "34px"]}
              cursor={"pointer"}
              w={["22px", "26px", "30px", "34px"]}
            />
          </Flex>
        )}
      </TableContainer>
    </div>
  );
};

export default CustomTable;
