import React, { useState } from "react";
import AuthLayout from "../layouts/AuthLayout";
import {
  Box,
  Checkbox,
  Flex,
  Heading,
  Image,
  Input,
  Link,
  PinInput,
  PinInputField,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import theme from "../theme/theme";
import AuthCardHeader from "../components/AuthCardHeader";
import UserInfoInput from "../components/UserInfoInput";
import PrimaryButton from "../components/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink, LinkProps } from "@chakra-ui/react";
import userLight from "../assets/icons/light/userIcon.svg";
import emailLight from "../assets/icons/light/emailIcon.svg";
import emailLightActive from "../assets/icons/light/emailActive.svg";
import lockLight from "../assets/icons/light/lockIcon.svg";
import lockActive from "../assets/icons/light/lockActive.svg";
import eyeLight from "../assets/icons/light/eyeIcon.svg";
import eyeActive from "../assets/icons/light/eyeActive.svg";
import shutEyeLight from "../assets/icons/light/shutEyeIcon.svg";
import shutEyeLightActive from "../assets/icons/light/shutEyeActive.svg";
import userDark from "../assets/icons/dark/user.svg";
import emailDark from "../assets/icons/dark/email.svg";
import lockDark from "../assets/icons/dark/lock.svg";
import eyeDark from "../assets/icons/dark/eye.svg";
import shutEyeDark from "../assets/icons/dark/shutEye.svg";
import { useLogin, useVerifyLogin2FA } from "../utils/auth.api";
import CustomModal from "../components/CustomModal";
import { APP_VERSION } from "../config";
import { osName, browserName } from "react-device-detect";

const Signin = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const navigate = useNavigate();
  const loginMutation = useLogin();
  const verifyLogin = useVerifyLogin2FA();
  const [loginError, setLoginError] = useState();
  const toast = useToast();
  const [show, setShow] = useState(false);
  const [pinInput, setPinInput] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [isVerified, setIsVerified] = useState(1);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const emailIcon = useColorModeValue(emailLight, emailDark);
  const lockIcon = useColorModeValue(lockLight, lockDark);
  const eyeIcon = useColorModeValue(eyeLight, eyeDark);
  const shutEyeIcon = useColorModeValue(shutEyeLight, shutEyeDark);
  const activeEyeIcon = useColorModeValue(eyeActive, eyeDark);
  const activeShutEyeIcon = useColorModeValue(shutEyeLightActive, shutEyeDark);
  const paraColor2 = useColorModeValue("#909090", "#C7C7C7");
  const color = useColorModeValue("#00332D", theme.colors.dark.text.primary);

  return (
    <AuthLayout>
      <AuthCardHeader
        title="Sign in"
        des="Login to resume your journey with Payremit."
      />
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          try {
            loginMutation
              .mutateAsync({
                email: email,
                password: password,
                appVersion: APP_VERSION,
                deviceName: osName + " | " + browserName,
              })
              .then((res: any) => {
                if (res?.is2FAEnabled) {
                  setAccessToken(res?.token);
                  onOpen();
                } else {
                  toast({
                    title: "Logged in successfully!!!",
                    status: "success",
                    duration: 1500,
                  });
                  navigate("/dashboard/index", {
                    state: formData,
                  });
                }
              })
              .catch((err: any) => {
                setLoginError(err.message);
                toast({ title: err.message, status: "error" });
              });
          } catch (error: any) {
            setLoginError(error.message);
            toast({ title: error.message, status: "error" });
          }
        }}
      >
        <UserInfoInput
          type="email"
          placeholder="Email"
          darkBG="transparent"
          icon={emailIcon}
          activeIcon={useColorModeValue(emailLightActive, emailDark)}
          name="email"
          value={email}
          onChange={(e: any) => handleChange(e)}
          border={!emailPattern.test(email) && email && "red"}
        />
        {!emailPattern.test(email) && email && (
          <Text mt="-16px" color={"red"}>
            Enter correct email{" "}
          </Text>
        )}
        <UserInfoInput
          type={show ? "text" : "password"}
          placeholder="Password"
          darkBG="transparent"
          icon={lockIcon}
          activeIcon={useColorModeValue(lockActive, lockDark)}
          rightIcon={!show ? eyeIcon : shutEyeIcon}
          activeRightIcon={!show ? activeEyeIcon : activeShutEyeIcon}
          setShow={setShow}
          show={show}
          name="password"
          value={password}
          onChange={(e: any) => handleChange(e)}
          border={loginError && "red"}
        />
        {loginError && (
          <Text mt="-16px" color={"red"}>
            {loginError}. Try again{" "}
          </Text>
        )}
        <Box textAlign={"right"} mt="-16px">
          <ChakraLink
            as={ReactRouterLink}
            to="/auth/forgot-password"
            color={useColorModeValue("#00332D", "#00FFE0")}
            fontWeight={600}
            fontSize={"12px"}
          >
            Forgot Password?
          </ChakraLink>
        </Box>
        <PrimaryButton
          mt="130px"
          type="submit"
          text={loginMutation.isLoading ? <Spinner /> : "Login"}
          disabled={loginMutation.isLoading}
        />
      </form>
      <Text textAlign={"center"} mt="20px" fontSize={"12px"}>
        Not registered yet?{" "}
        <ChakraLink
          as={ReactRouterLink}
          to="/auth/signup"
          color={useColorModeValue("#00332D", "#00FFE0")}
          fontWeight={600}
          fontSize={"12px"}
          ml="8px"
        >
          Sign Up
        </ChakraLink>
      </Text>
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        headerText="Verify 2FA"
        onSubmit={() => {
          verifyLogin
            ?.mutateAsync({
              payload: pinInput,
              token: accessToken,
            })
            .then((res: any) => {
              if (res?.isValid) {
                onClose();
                toast({
                  title: "Logged in successfully!!!",
                  status: "success",
                  duration: 1500,
                });
                navigate("/dashboard/index", {
                  state: formData,
                });
              } else {
                toast({
                  title: "Wrong otp, Verify again",
                  status: "error",
                  duration: 1500,
                });
              }
            })
            .catch((err: any) => {
              toast({
                title: err?.message,
                status: "error",
                duration: 1500,
              });
            });
        }}
        isDisabled={verifyLogin?.isLoading}
        isLoading={verifyLogin?.isLoading}
        footerText={"Verify Now"}
        closeText={"Cancel"}
      >
        <Flex
          my={"32px"}
          direction={"column"}
          justify={"center"}
          align={"center"}
          gap={"16px"}
          alignSelf={"stretch"}
        >
          <Heading
            color={color}
            fontFamily={"Audiowide"}
            fontSize={"20px"}
            fontWeight={"400"}
            textAlign="center"
          >
            Verify Two Factor Authentication
          </Heading>
          <Flex justify={"space-between"} gap="10px" fontFamily={"Roboto"}>
            <PinInput
              errorBorderColor="red.300"
              focusBorderColor="teal.300"
              size={"lg"}
              variant={"flushed"}
              placeholder="0"
              value={pinInput}
              isInvalid={isVerified === 0 && pinInput !== ""}
              onChange={(value) => {
                setPinInput(value);
              }}
              autoFocus
            >
              <PinInputField
                _placeholder={{ color: "#C7C7C7" }}
                fontWeight={700}
                color={useColorModeValue("#5A5A5A", "#EEEEEE")}
                fontFamily={"Roboto"}
                fontSize={"20px"}
                onChange={() => setPinInput("")}
              />
              <PinInputField
                _placeholder={{ color: "#C7C7C7" }}
                fontWeight={700}
                color={useColorModeValue("#5A5A5A", "#EEEEEE")}
                fontFamily={"Roboto"}
                fontSize={"20px"}
                onChange={() => setPinInput("")}
              />
              <PinInputField
                _placeholder={{ color: "#C7C7C7" }}
                fontWeight={700}
                color={useColorModeValue("#5A5A5A", "#EEEEEE")}
                fontFamily={"Roboto"}
                fontSize={"20px"}
                onChange={() => setPinInput("")}
              />
              <PinInputField
                _placeholder={{ color: "#C7C7C7" }}
                fontWeight={700}
                color={useColorModeValue("#5A5A5A", "#EEEEEE")}
                fontFamily={"Roboto"}
                fontSize={"20px"}
                onChange={() => setPinInput("")}
              />
              <PinInputField
                _placeholder={{ color: "#C7C7C7" }}
                fontWeight={700}
                color={useColorModeValue("#5A5A5A", "#EEEEEE")}
                fontFamily={"Roboto"}
                fontSize={"20px"}
                onChange={() => setPinInput("")}
              />
              <PinInputField
                _placeholder={{ color: "#C7C7C7" }}
                fontWeight={700}
                color={useColorModeValue("#5A5A5A", "#EEEEEE")}
                fontFamily={"Roboto"}
                fontSize={"20px"}
                onChange={() => setPinInput("")}
              />
            </PinInput>
          </Flex>
          <Flex justify={"space-between"} mt="20px" align={"center"}>
            {isVerified === 0 && pinInput !== "" && (
              <Text color="red.300">Incorrect verification code.</Text>
            )}
          </Flex>
        </Flex>
      </CustomModal>
    </AuthLayout>
  );
};

export default Signin;
