import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Flex,
    Image,
    Text,
    Tooltip,
    useColorModeValue,
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  import theme from "../theme/theme";
  import {
    ArrowDownIcon,
    ArrowUpIcon,
    ClockIcon,
    SparkelsIcon,
    WalletIcon,
    WindowsIcon,
  } from "../assets/icons/Icons";
  import { useLocation, useNavigate } from "react-router-dom";
  import activeIcon from "../assets/icons/activeMenuIcon.svg";
  import subMenuLVectorDark from "../assets/icons/dark/submenuLVector.svg";
  import subMenuLVectorLight from "../assets/icons/light/submenuLVector.svg";

const SidebarMainMenu = ({ isOpen, isActive, setIsActive, isSubActive, setIsSubActive }: any) => {
  const location = useLocation();
  const menuItems = [
    { icon: WindowsIcon, text: "Dashboard", path: "/index" },
    { icon: WalletIcon, text: "Wallet", path: "/wallet" },
    { icon: ArrowUpIcon, text: "Deposit", path: "/deposit" },
    { icon: ArrowDownIcon, text: "Withdraw", path: "/withdraw" },
  ];
  const navigate = useNavigate();
  

  const [expandedIndex, setExpandedIndex] = useState(
    Number(sessionStorage.getItem("expandedIndex")) || -1
  );
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const menuBG = useColorModeValue(
    theme.colors.light.accent.secondary,
    theme.colors.dark.accent.secondary
  );
  const subMenuBG = useColorModeValue("#FFFFFF", "rgba(255, 255, 255, 0.03)");
  const toolTipBG = useColorModeValue("#FFFFFF", "#333");
  const color = useColorModeValue("#00332D", theme.colors.dark.text.primary);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (
      isSubActive === "Wallet Transactions" ||
      isSubActive === "Services Transactions"
    ) {
      sessionStorage.setItem("isActive", "Transaction");
      setIsActive("Transaction");
    }
    if (
      isSubActive === "Airtime" ||
      isSubActive === "Internet" ||
      isSubActive === "Electricity" ||
      isSubActive === "TV Subscription"
    ) {
      sessionStorage.setItem("isActive", "Services");
      setIsActive("Services");
    }
  }, [screenWidth, isOpen]);
  useEffect(() => {
    if (isActive === "Transaction") {
      setExpandedIndex(0);
      sessionStorage.setItem("expandedIndex", "0");
    } else if (isActive === "Services") {
      setExpandedIndex(1);
      sessionStorage.setItem("expandedIndex", "1");
    } else {
      setExpandedIndex(-1);
      sessionStorage.setItem("expandedIndex", "-1");
    }
  }, [isActive]);

  useEffect(() => {
    const pathMap: { [key: string]: string | { main: string; sub: string } } = {
      "/index": "/index",
      "/wallet": "/wallet",
      "/deposit": "/deposit",
      "/withdraw": "/withdraw",
      "/dashboard/transactions": {
        main: "Transaction",
        sub: "Wallet Transactions",
      },
      "/dashboard/services-transactions": {
        main: "Transaction",
        sub: "Services Transactions",
      },
      "/airtime": { main: "Services", sub: "Airtime" },
      "/internet": { main: "Services", sub: "Internet" },
      "/electricity-bill": { main: "Services", sub: "Electricity" },
      "/tv-sbscription": { main: "Services", sub: "TV Subscription" },
      "/settings": "Settings",
    };

    const setActiveState = (main: string, sub?: string) => {
      setIsActive(main);
      if (sub) setIsSubActive(sub);
    };

    for (const [path, value] of Object.entries(pathMap)) {
      if (location.pathname.includes(path)) {
        if (typeof value === "string") {
          setActiveState(value);
        } else {
          setActiveState(value.main, value.sub);
        }
        break;
      }
    }
  }, [location]);
  return (
    <Box>
          {menuItems.map((item, index) => (
            <Box key={index}>
              {isActive === item.path && (
                <Image position={"absolute"} left={"0px"} src={activeIcon} />
              )}
              {!isOpen ? (
                <Tooltip
                  label={item.text}
                  placement="right"
                  borderRadius={"6px"}
                  bg={toolTipBG}
                  color={color}
                >
                  <Flex
                    p={"12px"}
                    align={"center"}
                    justify={isOpen ? "left" : "center"}
                    gap="15px"
                    cursor={"pointer"}
                    borderRadius={"8px"}
                    my="15px"
                    _hover={{ bg: menuBG }}
                    bg={isActive === item.path && menuBG}
                    onClick={() => {
                      // setIsActive(item.text);
                      sessionStorage.setItem("isActive", item.path);
                      sessionStorage.removeItem("isSubActive");
                      setIsActive(item.path);
                      navigate(`/dashboard${item.path}`);
                    }}
                    color={isActive === item.path && color}
                  >
                    {<item.icon color={isActive === item.path && color} />}
                    {isOpen && <Text>{item.text}</Text>}
                  </Flex>
                </Tooltip>
              ) : (
                <Flex
                  p={"12px"}
                  align={"center"}
                  justify={isOpen ? "left" : "center"}
                  gap="15px"
                  cursor={"pointer"}
                  fontWeight={500}
                  borderRadius={"8px"}
                  my="15px"
                  _hover={{ bg: menuBG }}
                  bg={isActive === item.path && menuBG}
                  onClick={() => {
                    sessionStorage.setItem("isActive", item.path);
                    sessionStorage.removeItem("isSubActive");
                    setIsActive(item.path);
                    navigate(`/dashboard${item.path}`);
                  }}
                  color={isActive === item.path && color}
                >
                  {<item.icon color={isActive === item.path && color} />}
                  {isOpen && <Text>{item.text}</Text>}
                </Flex>
              )}
            </Box>
          ))}
          <Accordion
            border={"0px solid transparent"}
            defaultIndex={expandedIndex}
            index={expandedIndex}
            reduceMotion={true}
            onChange={(index: any) => setExpandedIndex(index)}
            allowToggle
          >
            <AccordionItem my="15px">
              <h2>
                {isActive === "Transaction" && (
                  <Image position={"absolute"} left={"0px"} src={activeIcon} />
                )}
                <AccordionButton
                  p="12px"
                  borderRadius={"8px"}
                  // opacity={isActive === "Transaction" ? 1 : 0.7}
                  fontWeight={500}
                  color={isActive === "Transaction" && color}
                  justifyContent={isOpen ? "left" : "center"}
                  _hover={{ bg: menuBG }}
                  bg={isActive === "Transaction" && menuBG}
                >
                  <Tooltip
                    label="Transaction"
                    placement="right"
                    borderRadius={"6px"}
                    ml="10px"
                    bg={toolTipBG}
                    color={color}
                  >
                    <Box mr={isOpen && "15px"}>
                      <ClockIcon color={isActive === "Transaction" && color} />
                    </Box>
                  </Tooltip>
                  {isOpen && (
                    <Box as="span" flex="1" textAlign="left">
                      Transactions
                    </Box>
                  )}
                  {isOpen && <AccordionIcon />}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} px={isOpen ? "" : "3px"}>
                <Box
                  ml={isOpen ? "20px" : "0px"}
                  pl={isOpen ? "10px" : "0px"}
                  pt="10px"
                  borderLeft={"1px solid"}
                  borderLeftColor={useColorModeValue("#EEEEEE", "#5A5A5A")}
                  // display={isOpen ? "block" : "none"}
                  h={isOpen && "105px"}
                >
                  <Image
                    display={isOpen ? "block" : "none"}
                    src={useColorModeValue(
                      subMenuLVectorLight,
                      subMenuLVectorDark
                    )}
                    position={"relative"}
                    left={"-11px"}
                    top="20px"
                  />
                  <Text
                    py="10px"
                    px={isOpen ? "10px" : "15px"}
                    display={"flex"}
                    justifyContent={isOpen ? "left" : "center"}
                    mb="5px"
                    borderRadius={"8px"}
                    opacity={isSubActive === "Wallet Transactions" ? 1 : 0.7}
                    _hover={{ bg: subMenuBG }}
                    bg={isSubActive === "Wallet Transactions" ? subMenuBG : ""}
                    onClick={() => {
                      sessionStorage.setItem(
                        "isSubActive",
                        "Wallet Transactions"
                      );
                      setIsSubActive("Wallet Transactions");
                      navigate("/dashboard/transactions");
                    }}
                    cursor="pointer"
                  >
                    {" "}
                    {isOpen ? (
                      "Wallet Transactions"
                    ) : (
                      <Tooltip
                        label="Wallet Transactions"
                        placement="right"
                        borderRadius={"6px"}
                        ml={"10px"}
                        bg={toolTipBG}
                        color={color}
                      >
                        <i className="fa-solid fa-wallet"></i>
                      </Tooltip>
                    )}
                  </Text>
                  <Image
                    display={isOpen ? "block" : "none"}
                    src={useColorModeValue(
                      subMenuLVectorLight,
                      subMenuLVectorDark
                    )}
                    position={"relative"}
                    left={"-11px"}
                    top="20px"
                  />
                  <Text
                    py="10px"
                    px={isOpen ? "10px" : "15px"}
                    display={"flex"}
                    justifyContent={isOpen ? "left" : "center"}
                    mb="5px"
                    borderRadius={"8px"}
                    opacity={isSubActive === "Services Transactions" ? 1 : 0.7}
                    _hover={{ bg: subMenuBG }}
                    bg={
                      isSubActive === "Services Transactions" ? subMenuBG : ""
                    }
                    onClick={() => {
                      sessionStorage.setItem(
                        "isSubActive",
                        "Services Transactions"
                      );
                      setIsSubActive("Services Transactions");
                      navigate("/dashboard/services-transactions");
                    }}
                    cursor="pointer"
                  >
                    {isOpen ? (
                      "Services Transactions"
                    ) : (
                      <Tooltip
                        label="Services Transactions"
                        placement="right"
                        borderRadius={"6px"}
                        ml={"10px"}
                        bg={toolTipBG}
                        color={color}
                      >
                        <i className="fa-brands fa-servicestack"></i>
                      </Tooltip>
                    )}
                  </Text>
                </Box>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem my="15px">
              <h2>
                {isActive === "Services" && (
                  <Image position={"absolute"} left={"0px"} src={activeIcon} />
                )}
                <AccordionButton
                  p="12px"
                  borderRadius={"8px"}
                  fontWeight={500}
                  color={isActive === "Services" && color}
                  justifyContent={isOpen ? "left" : "center"}
                  _hover={{ bg: menuBG }}
                  bg={isActive === "Services" && menuBG}
                >
                  <Tooltip
                    label="Services"
                    placement="right"
                    borderRadius={"6px"}
                    ml="10px"
                    bg={toolTipBG}
                    color={color}
                  >
                    <Box mr={isOpen && "15px"}>
                      <SparkelsIcon color={isActive === "Services" && color} />
                    </Box>
                  </Tooltip>
                  {isOpen && (
                    <Box as="span" flex="1" textAlign="left">
                      Services
                    </Box>
                  )}
                  {isOpen && <AccordionIcon />}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} px={isOpen ? "" : "3px"}>
                <Box
                  ml={isOpen ? "20px" : "0px"}
                  pl={isOpen ? "10px" : "0px"}
                  pt="10px"
                  borderLeft={"1px solid"}
                  borderLeftColor={useColorModeValue("#EEEEEE", "#5A5A5A")}
                  h={isOpen && "235px"}
                >
                  <Image
                    display={isOpen ? "block" : "none"}
                    src={useColorModeValue(
                      subMenuLVectorLight,
                      subMenuLVectorDark
                    )}
                    position={"relative"}
                    left={"-11px"}
                    top="20px"
                  />
                  <Text
                    py="10px"
                    px={isOpen ? "10px" : "15px"}
                    display={"flex"}
                    justifyContent={isOpen ? "left" : "center"}
                    mb="5px"
                    borderRadius={"8px"}
                    opacity={isSubActive === "Airtime" ? 1 : 0.7}
                    _hover={{ bg: subMenuBG }}
                    bg={isSubActive === "Airtime" ? subMenuBG : ""}
                    onClick={() => {
                      sessionStorage.setItem("isSubActive", "Airtime");
                      setIsSubActive("Airtime");
                      navigate("/dashboard/services/airtime");
                    }}
                    cursor="pointer"
                  >
                    {" "}
                    {isOpen ? (
                      "Airtime"
                    ) : (
                      <Tooltip
                        label="Airtime"
                        placement="right"
                        borderRadius={"6px"}
                        ml={"10px"}
                        bg={toolTipBG}
                        color={color}
                      >
                        <i className="fa-solid fa-voicemail"></i>
                      </Tooltip>
                    )}
                  </Text>
                  <Image
                    display={isOpen ? "block" : "none"}
                    src={useColorModeValue(
                      subMenuLVectorLight,
                      subMenuLVectorDark
                    )}
                    position={"relative"}
                    left={"-11px"}
                    top="20px"
                  />
                  <Text
                    py="10px"
                    px={isOpen ? "10px" : "15px"}
                    display={"flex"}
                    justifyContent={isOpen ? "left" : "center"}
                    mb="5px"
                    borderRadius={"8px"}
                    opacity={isSubActive === "Internet" ? 1 : 0.7}
                    _hover={{ bg: subMenuBG }}
                    bg={isSubActive === "Internet" ? subMenuBG : ""}
                    onClick={() => {
                      sessionStorage.setItem("isSubActive", "Internet");
                      setIsSubActive("Internet");
                      navigate("/dashboard/services/internet");
                    }}
                    cursor="pointer"
                  >
                    {isOpen ? (
                      "Internet"
                    ) : (
                      <Tooltip
                        label="Internet"
                        placement="right"
                        borderRadius={"6px"}
                        ml={"10px"}
                        bg={toolTipBG}
                        color={color}
                      >
                        <i className="fa-solid fa-wifi"></i>
                      </Tooltip>
                    )}
                  </Text>
                  <Image
                    display={isOpen ? "block" : "none"}
                    src={useColorModeValue(
                      subMenuLVectorLight,
                      subMenuLVectorDark
                    )}
                    position={"relative"}
                    left={"-11px"}
                    top="20px"
                  />
                  <Text
                    py="10px"
                    px="10px"
                    display={"flex"}
                    justifyContent={isOpen ? "left" : "center"}
                    mb="5px"
                    borderRadius={"8px"}
                    opacity={isSubActive === "Electricity" ? 1 : 0.7}
                    _hover={{ bg: subMenuBG }}
                    bg={isSubActive === "Electricity" ? subMenuBG : ""}
                    onClick={() => {
                      sessionStorage.setItem("isSubActive", "Electricity");
                      setIsSubActive("Electricity");
                      navigate("/dashboard/services/electricity-bill");
                    }}
                    cursor="pointer"
                  >
                    {isOpen ? (
                      "Electricity"
                    ) : (
                      <Tooltip
                        label="Electricity"
                        placement="right"
                        borderRadius={"6px"}
                        ml={"10px"}
                        bg={toolTipBG}
                        color={color}
                      >
                        <i className="fa-solid fa-bolt"></i>
                      </Tooltip>
                    )}
                  </Text>
                  <Image
                    display={isOpen ? "block" : "none"}
                    src={useColorModeValue(
                      subMenuLVectorLight,
                      subMenuLVectorDark
                    )}
                    position={"relative"}
                    left={"-11px"}
                    top="20px"
                  />
                  <Text
                    py="10px"
                    px="10px"
                    display={"flex"}
                    justifyContent={isOpen ? "left" : "center"}
                    mb="5px"
                    borderRadius={"8px"}
                    opacity={isSubActive === "TV Subscription" ? 1 : 0.7}
                    _hover={{ bg: subMenuBG }}
                    bg={isSubActive === "TV Subscription" ? subMenuBG : ""}
                    onClick={() => {
                      sessionStorage.setItem("isSubActive", "TV Subscription");
                      setIsSubActive("TV Subscription");
                      navigate("/dashboard/services/tv-sbscription");
                    }}
                    cursor="pointer"
                  >
                    {isOpen ? (
                      "TV Subscription "
                    ) : (
                      <Tooltip
                        label="TV Subscription"
                        placement="right"
                        borderRadius={"6px"}
                        ml={"10px"}
                        bg={toolTipBG}
                        color={color}
                      >
                        <i className="fa-solid fa-tv"></i>
                      </Tooltip>
                    )}
                  </Text>
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
  )
}

export default SidebarMainMenu