import {
  Button,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import closeBtn from "../assets/modalCloseBtn.svg";

interface CustomModalProps {
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  headerText?: string;

  onSubmit: () => void;
  children: any;
  footerText?: string;
  closeText?: string;
  btnDirection?: boolean;
  btnBgColor?: string;
  btnColor?: string;
  btnBorderColor?: string;
  isDisabled?: boolean;
  noFooter?: boolean;
  noCloseFooter?: boolean;
}

const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  onClose,
  headerText,
  isLoading,
  onSubmit,
  children,
  footerText,
  closeText,
  btnDirection,
  btnBgColor,
  btnColor,
  btnBorderColor,
  isDisabled,
  noFooter,
  noCloseFooter,
}) => {
  const bgColor = useColorModeValue("#FFF", "#121B18");
  const paraColor = useColorModeValue("#000", "#EEE");
  const borderColor = useColorModeValue("#EEE", "#333");
  const btnBorder = useColorModeValue("#C7C7C7", "#333");
  const btnBorder2 = useColorModeValue("#00CCB3", "#00665A");
  const btnBg = useColorModeValue("#EEE", "");
  const btnBg2 = useColorModeValue("#00FFE0", "#00332D");
  const btnText = useColorModeValue("#00332D", "#C7C7C7");
  const btnText2 = useColorModeValue("#00332D", "#00FFE0");

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={["xs", "sm", "md", "lg"]}
      isCentered
    >
      <ModalOverlay backdropFilter={"blur(3px)"} />
      <ModalContent
        bg={bgColor}
        color={paraColor}
        padding={["12px", "16px", "20px", "24px"]}
      >
        <ModalHeader
          padding={"0px"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          mb={"16px"}
        >
          <Text
            fontSize={["11px", "13px", "15px", "16px"]}
            fontWeight={"700"}
            lineHeight={"22px"}
            letterSpacing={"0.08px"}
            fontFamily={"Plus Jakarta Sans"}
          >
            {headerText}
          </Text>
          <Image
            src={closeBtn}
            cursor={"pointer"}
            onClick={onClose}
            w={["20px", "24px", "28px", "32px"]}
          />
        </ModalHeader>
        <ModalBody
          padding={"0px"}
          mt={"16px"}
          borderTop={`1px solid ${borderColor}`}
        >
          {children}
        </ModalBody>

        {noFooter ? (
          <></>
        ) : (
          <ModalFooter
            display={["block", "flex"]}
            padding={"0px"}
            borderTop={`1px solid ${borderColor}`}
          >
            <Flex
              mt={"24px"}
              gap={"16px"}
              direction={
                btnDirection
                  ? ["column", "row", "row", "row"]
                  : ["column", "row"]
              }
            >
              {/* <Button
                onClick={onClose}
                p={["10px 23px", "12px 26px", "14px 29px", "16px 32px"]}
                borderRadius={"12px"}
                border={`1px solid ${btnBorder}`}
                bg={btnBg}
                color={btnText}
                fontSize={"16px"}
                fontWeight={"600"}
                letterSpacing={"0.32px"}
                textTransform={"capitalize"}
                _active={{ bg: btnBg }}
                _hover={{ bg: btnBg }}
                isDisabled={isLoading}
                w={["100%", "auto"]}
              >
                {closeText ? closeText : "Close"}
              </Button> */}
              {/* <Button
              onClick={onSubmit}
              p={"16px 32px"}
              borderRadius={"12px"}
              border={
                btnBorderColor
                  ? `1px solid ${btnBorderColor}`
                  : `1px solid ${btnBorder2}`
              }
              bg={btnBgColor ? btnBgColor : btnBg2}
              color={btnColor ? btnColor : btnText2}
              isDisabled={isLoading || isDisabled}
              fontSize={"16px"}
              fontWeight={"600"}
              letterSpacing={"0.32px"}
              textTransform={"capitalize"}
              _active={{ bg: btnBgColor ? btnBgColor : btnBg2 }}
              _hover={{ bg: btnBgColor ? btnBgColor : btnBg2 }}
              w={["100%", "auto"]}
            > */}
              {!noCloseFooter && (
                <Button
                  onClick={onClose}
                  p={["10px 23px", "12px 26px", "14px 29px", "16px 32px"]}
                  borderRadius={"12px"}
                  border={`1px solid ${btnBorder}`}
                  bg={btnBg}
                  color={btnText}
                  fontSize={"16px"}
                  fontWeight={"600"}
                  letterSpacing={"0.32px"}
                  textTransform={"capitalize"}
                  _active={{ bg: btnBg }}
                  _hover={{ bg: btnBg }}
                  isDisabled={isLoading}
                >
                  {closeText ? closeText : "Close"}
                </Button>
              )}
              <Button
                onClick={onSubmit}
                p={"16px 32px"}
                borderRadius={"12px"}
                border={
                  btnBorderColor
                    ? `1px solid ${btnBorderColor}`
                    : `1px solid ${btnBorder2}`
                }
                bg={btnBgColor ? btnBgColor : btnBg2}
                color={btnColor ? btnColor : btnText2}
                isDisabled={isLoading || isDisabled}
                fontSize={"16px"}
                fontWeight={"600"}
                letterSpacing={"0.32px"}
                textTransform={"capitalize"}
                _active={{ bg: btnBgColor ? btnBgColor : btnBg2 }}
                _hover={{ bg: btnBgColor ? btnBgColor : btnBg2 }}
                w={["100%", "auto"]}
              >
                {isLoading ? <Spinner /> : footerText ? footerText : "Continue"}
              </Button>
            </Flex>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;
