import { Box, Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import nairaLight from "../assets/icons/nairaWhite.svg";
import nairaDark from "../assets/icons/dark/naira.svg";
import folderIcon from "../assets/icons/folder.svg";
import { ServiceAdresses } from "./AddressBook";

const RecentPurchase = ({ coin, empty, data, from, type, onSelect }: any) => {
  const paraColor = useColorModeValue("black", "white");
  const paraColor2 = useColorModeValue("#5A5A5A", "#C7C7C7");
  const paraColor3 = useColorModeValue("#EEE", "#333");
  const bgColor = useColorModeValue("black", "#5a5a5a");
  const img1 = useColorModeValue(nairaDark, nairaLight);
  return (
    <>
      <Box
        w={{ base: "100%", lg: "40%" }}
        // display={empty && "none"}
      >
        <Text mt="32px" fontWeight={"700"} color={paraColor2}>
          Service Addresses
        </Text>
        <ServiceAdresses type={type} onSelect={onSelect} />
        {/* {data?.map((item: any, index: any) => (
          <Flex
            key={index}
            my="24px"
            align={"center"}
            justify={"space-between"}
            h="72px"
            borderRadius={"12px"}
            border="1px solid"
            borderColor={paraColor3}
            px={["5px", "16px"]}
            wrap={"wrap"}
            gap="10px"
          >
            <Box>
              <Text>
                {from === "tv"
                  ? item?.provider
                  : from === "electricity"
                  ? item?.meterType
                  : item?.networkName}
              </Text>
              <Text
                fontWeight={700}
                fontSize={"16px"}
                mt="10px"
                color={paraColor}
              >
                {from === "tv"
                  ? item?.smartCardNumber
                  : from === "electricity"
                  ? item?.meterNumber
                  : item?.paidTo}
              </Text>
            </Box>
            <Box>
              <Text textAlign={"right"}>Amount</Text>
              <Flex
                fontWeight={700}
                fontSize={"16px"}
                mt="10px"
                color={paraColor}
                gap="10px"
                align={"center"}
              >
                <Text>{Number(item?.amount || 0)?.toFixed(2)}</Text>
                <Box borderRadius={"20px"} p="5px" bg={bgColor}>
                  <Image w="12px" h="12px" src={img1} />
                </Box>
              </Flex>
            </Box>
          </Flex>
        ))} */}
      </Box>

      {/* <Box w={{ base: "100%", lg: "40%" }} display={empty ? "block" : "none"}>
        <Text
          mt="32px"
          fontWeight={"700"}
          color={useColorModeValue("#5A5A5A", "#C7C7C7")}
        >
          Recent Purchases
        </Text>

        <Flex
          mt="46px"
          align={"center"}
          justify={"center"}
          h="100%"
          py="40px"
          borderRadius={"12px"}
          border="1px solid"
          borderColor={useColorModeValue("#EEE", "#333")}
          px={["5px", "16px"]}
          wrap={"wrap"}
          gap="10px"
        >
          <Box>
            <Image mx="auto" src={folderIcon} />
            <Text mt="24px">No Recent Purchases</Text>
          </Box>
        </Flex>
      </Box> */}
    </>
  );
};

export default RecentPurchase;
