import {
  Box,
  Flex,
  Image,
  Input,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  OrderedList,
  Select,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomTabs from "./CustomTabs";
import menuDown from "../assets/menuDownIcon.svg";
import menuDownDark from "../assets/menuDownIconDark.svg";
import edit from "../assets/editIcon.svg";
import deleteIcon from "../assets/deleteIcon.svg";
import CustomTable from "./CustomTable";
import CustomModal from "./CustomModal";
import emptyWallet from "../assets/emptyWallet.svg";
import emptyWalletDark from "../assets/emptyWalletDark.svg";
import userLight from "../assets/icons/light/userIcon.svg";
import userDark from "../assets/icons/dark/user.svg";
import bankLight from "../assets/icons/light/bank.svg";
import bankDark from "../assets/icons/dark/bank.svg";
import numberLight from "../assets/icons/light/number.svg";
import numberDark from "../assets/icons/dark/number.svg";
import userActive from "../assets/icons/light/userActive.svg";
import bankActive from "../assets/icons/light/bankActive.svg";
import numberActive from "../assets/icons/light/numberActive.svg";
import {
  useDeleteWalletAddress,
  useEditWalletAddress,
  useGetWalletAddress,
  useUpdateBankAccount,
  useValidateAddress,
} from "../utils/wallet.api";
import {
  useAddBankAccount,
  useDeleteBankAccount,
  useGetBankAccounts,
} from "../utils/auth.api";
import SelectWithSearch from "./SelectWithSearch";
import PrimaryButton from "./PrimaryButton";
import {
  useDeleteServiceAddress,
  useGetAllServicesAddress,
  useUpdateServiceAddress,
} from "../utils/service.api";
import UserInfoInput from "./UserInfoInput";
import { useNavigate } from "react-router-dom";

const AddressBook = () => {
  const [rows, setRows] = useState([]);
  const [walletRows, setWalletRows] = useState([]);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAddOpen,
    onOpen: OnAddOpen,
    onClose: onAddClose,
  } = useDisclosure();
  const {
    isOpen: isAddAddressOpen,
    onOpen: onAddAdressOpen,
    onClose: onAddAddressClose,
  } = useDisclosure();
  const {
    isOpen: isWalletEditOpen,
    onOpen: onWalletEditOpen,
    onClose: onWalletEditClose,
  } = useDisclosure();
  const {
    isOpen: deleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const {
    isOpen: deleteBankOpen,
    onOpen: onDeleteBankOpen,
    onClose: onDeleteBankClose,
  } = useDisclosure();
  const { data: walletAddressData, isLoading: walletAddressLoading } =
    useGetWalletAddress();
  const { data: banksData, isLoading: bankAccountsLoading } =
    useGetBankAccounts();

  const editBankAddressBookMutation = useUpdateBankAccount();
  const validateAddressMutatation = useValidateAddress();
  const [activeWallet, setActiveWallet] = useState<any>(null);
  const [formData, setFormData] = useState({
    label: "",
    address: "",
    icon: "",
  });

  const toast = useToast();
  const [bankAccountNo, setBankAccountNo] = useState("");
  const addBankAccountMutation = useAddBankAccount();
  const editWalletAddressMutation = useEditWalletAddress();
  const deleteWalletAddressMutation = useDeleteWalletAddress();
  const deleteBankMutation = useDeleteBankAccount();
  const columns = ["Account Name", "Account No", "Bank", "Date", "Action"];
  const walletColumns = ["Token", "Label", "Address", "Date", "Action"];

  const borderColor = useColorModeValue("#EEE", "#00332D");
  const inputBorder = useColorModeValue("#EEE", "#333");
  const textColor = useColorModeValue("#C7C7C7", "#EEE");
  const inputBg = useColorModeValue("#FFF", "#121B18");
  const btnBg = useColorModeValue("#F00C0C", "rgba(240, 12, 12, 0.10);");
  const btnText = useColorModeValue("#FFF", "#FF5959");
  const btnBorderColor = useColorModeValue("transparent", "#FF5959");
  const inputText = useColorModeValue("#5A5A5A", "#909090");
  const menuIcon = useColorModeValue(menuDown, menuDownDark);
  const emptyWalletIcon = useColorModeValue(emptyWallet, emptyWalletDark);

  const [editBankFormData, setEditBankFormData] = useState({
    bankAccount: "",
    name: "",
    accountNo: "",
    Bank: "",
    id: "",
  });
  const [bankForm, setBankForm] = useState({
    bankName: "",
    accountNumber: "",
    accountName: "",
  });
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setBankForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setEditBankFormData({ ...editBankFormData, [name]: value });
  };

  const reArrageData = () => {
    let tempArr: any = [];

    banksData?.bankDetails?.map((item: any) => {
      tempArr?.push({
        name: item?.accountName,
        accountNo: item?.accountNumber,
        Bank: item?.bankName,
        Date: item?.createdAt?.slice(0, 10),
        Action: (
          <Flex align={"center"} gap={"16px"}>
            <Image
              src={edit}
              onClick={() => {
                onOpen();
                setEditBankFormData({
                  ...editBankFormData,
                  bankAccount: item?.accountNumber,
                  name: item?.accountName,
                  accountNo: item?.accountNumber,
                  Bank: item?.bankName,
                  id: item?.id,
                });
              }}
              cursor={"pointer"}
            />
            <Image
              src={deleteIcon}
              cursor={"pointer"}
              onClick={() => {
                setBankAccountNo(item?.id);
                onDeleteBankOpen();
              }}
            />
          </Flex>
        ),
      });
    });

    setRows(tempArr);

    tempArr = [];

    walletAddressData?.map((dat: any) => {
      tempArr?.push({
        token: (
          <Flex gap={"2px"} align={"center"}>
            <Image src={dat?.coin?.icon} w="15px" alt="" />
            <Text>{dat?.coin?.symbol}</Text>
          </Flex>
        ),
        label: dat?.walletLabel,
        address: dat?.walletAddress,

        Date: dat?.createdAt?.slice(0, 10),
        Action: (
          <Flex align={"center"} gap={"16px"}>
            <Image
              src={edit}
              onClick={() => {
                onWalletEditOpen();
                setActiveWallet(dat);
                setFormData({
                  label: dat?.walletLabel,
                  address: dat?.walletAddress,
                  icon: dat?.coin?.icon,
                });
              }}
              cursor={"pointer"}
            />
            <Image
              src={deleteIcon}
              onClick={() => {
                setActiveWallet(dat);
                onDeleteOpen();
              }}
              cursor={"pointer"}
            />
          </Flex>
        ),
      });
    });

    setWalletRows(tempArr);
  };

  const handleBankChange = (selectedBank: any) => {
    setEditBankFormData({ ...editBankFormData, Bank: selectedBank });
    setBankForm({ ...bankForm, bankName: selectedBank });
  };

  useEffect(() => {
    if (walletAddressData || banksData) {
      reArrageData();
    }
  }, [walletAddressData, banksData]);

  return (
    <div>
      <Box pt={"65px"}>
        <CustomTabs
          titles={[
            { sm: "Bank", md: "Bank Addresses" },
            { sm: "Wallet", md: "Wallet Addresses" },
            { sm: "Services", md: " Services" },
          ]}
          widthGiven={true}
        >
          <Box>
            <Flex justify={"end"} mt={"8px"} mb={"24px"}>
              {rows?.length === 0 ? (
                <></>
              ) : (
                <></>
                // <Menu>
                //   <MenuButton
                //     px={"16px"}
                //     py={"8px"}
                //     transition="all 0.2s"
                //     borderRadius="12px"
                //     border={`1px solid ${borderColor}`}
                //     _focus={{ boxShadow: "outline" }}
                //     display={"flex"}
                //     alignItems={"center"}
                //     gap={"10px"}
                //   >
                //     <Text
                //       display={"inline"}
                //       color={textColor}
                //       fontSize={"14px"}
                //       fontWeight={"400"}
                //       lineHeight={"20px"}
                //       letterSpacing={"0.035px"}
                //     >
                //       My Own Account
                //     </Text>{" "}
                //     <Image
                //       src={menuIcon}
                //       alt="menu-down"
                //       display={"inline"}
                //       mb={"-7px"}
                //     />
                //   </MenuButton>
                //   <MenuList>
                //     <MenuItem>Other Account</MenuItem>
                //   </MenuList>
                // </Menu>
              )}
            </Flex>
            {rows.length === 0 ? (
              bankAccountsLoading ? (
                <Spinner />
              ) : (
                <Image src={emptyWalletIcon} />
              )
            ) : (
              <CustomTable rows={rows} columns={columns} pagination={false} />
            )}
            {rows?.length < 3 && (
              <PrimaryButton
                mt="24px"
                text="Add New Bank"
                height={"56px"}
                onClick={OnAddOpen}
              />
            )}
          </Box>
          <Box>
            <Box mt={"8px"}>
              {walletRows.length === 0 ? (
                <Image src={emptyWalletIcon} />
              ) : (
                <CustomTable
                  rows={walletRows}
                  columns={walletColumns}
                  pagination={true}
                />
              )}
              <PrimaryButton
                mt="24px"
                text="Add New Wallet Address"
                height={"56px"}
                //  isLoading={validateAddressMutatation?.isLoading}

                onClick={onAddAdressOpen}
              />
            </Box>
          </Box>
          {/* service address working */}
          <ServiceAdresses />
        </CustomTabs>
        <CustomModal
          isOpen={isAddAddressOpen}
          onClose={onAddAddressClose}
          onSubmit={() => {
            navigate("/dashboard/withdraw");
          }}
          headerText="Navigate to Withdrawal"
        >
          <Box py="20px" fontSize={"14px"}>
            <OrderedList spacing={3}>
              <ListItem>Navigate to the withdrawal page.</ListItem>
              <ListItem>Select a token.</ListItem>
              <ListItem>Go to the address book.</ListItem>
              <ListItem>Add a new address for the selected token.</ListItem>
            </OrderedList>
          </Box>
        </CustomModal>
        <CustomModal
          isOpen={isAddOpen}
          onClose={onAddClose}
          onSubmit={async () => {
            try {
              if (
                bankForm?.accountName === "" ||
                bankForm?.accountNumber === "" ||
                bankForm?.bankName === ""
              ) {
                toast({
                  title: "Please fill in all fields",
                  status: "error",
                });
                return;
              }
              await addBankAccountMutation.mutateAsync(bankForm);
              // getBanks();
              setBankForm({
                bankName: "",
                accountNumber: "",
                accountName: "",
              });
              toast({
                title: "Bank account added successfully.",
                status: "success",
              });
              onAddClose();
            } catch (error: any) {
              toast({
                title: error.message,
                status: "error",
              });
            }
          }}
          headerText="Add Bank Account"
          isLoading={addBankAccountMutation.isLoading}
        >
          <Box py="20px" fontSize={"14px"}>
            <Text>
              Enter your account title, bank name and account number for
              withdrawals. You can add a maximum of 3 bank accounts.
            </Text>
            <UserInfoInput
              placeholder="ACCOUNT NAME"
              icon={useColorModeValue(userLight, userDark)}
              activeIcon={useColorModeValue(userActive, userDark)}
              name="accountName"
              value={bankForm.accountName}
              onChange={handleChange}
            />
            {/* <UserInfoInput
            placeholder="Bank Name"
            icon={useColorModeValue(bankLight, bankDark)}
            activeIcon={useColorModeValue(bankActive, bankDark)}
            name="bankName"
            value={bankForm.bankName}
            onChange={handleChange}
          /> */}
            <SelectWithSearch
              icon={useColorModeValue(bankLight, bankDark)}
              activeIcon={useColorModeValue(bankActive, bankDark)}
              onChange={handleBankChange} // Pass the function to handle selected value
            />
            <UserInfoInput
              placeholder="ACCOUNT NUMBER"
              icon={useColorModeValue(numberLight, numberDark)}
              activeIcon={useColorModeValue(numberActive, numberDark)}
              name="accountNumber"
              value={bankForm.accountNumber}
              onChange={handleChange}
            />
          </Box>
        </CustomModal>
        <CustomModal
          isOpen={isOpen}
          onClose={onClose}
          headerText="Edit Bank Address"
          onSubmit={async () => {
            try {
              if (
                editBankFormData?.bankAccount?.trim() === "" ||
                editBankFormData?.name?.trim() === "" ||
                editBankFormData?.accountNo?.trim() === "" ||
                editBankFormData?.Bank?.trim() === ""
              ) {
                toast({
                  title: "Please fill in all fields.",
                  status: "error",
                });
                onClose();
                setEditBankFormData({
                  bankAccount: "",
                  name: "",
                  accountNo: "",
                  Bank: "",
                  id: "",
                });
              } else {
                await editBankAddressBookMutation.mutateAsync(editBankFormData);
                toast({
                  title: "Bank account updated.",
                  status: "success",
                });
                onClose();
              }
            } catch (error: any) {
              toast({
                title: error.message,
                status: "error",
              });
            }
          }}
          footerText={"Update Bank Address"}
          closeText={"Cancel"}
          btnDirection={true}
          isLoading={editBankAddressBookMutation.isLoading}
        >
          <Flex direction={"column"} gap={"16px"} my={"32px"}>
            <Input
              variant={"unstyled"}
              placeholder="ACCOUNT NAME"
              type="text"
              display={"flex"}
              h={"48px"}
              p={"0px 12px"}
              alignItems={"center"}
              alignSelf={"stretch"}
              borderRadius={"12px"}
              border={`1px solid ${inputBorder}`}
              bg={inputBg}
              fontSize={"16px"}
              fontWeight={"400"}
              lineHeight={"22px"}
              letterSpacing={"0.08px"}
              _focus={{ border: "1px solid #00CCB3" }}
              _placeholder={{
                color: inputText,
              }}
              name="name"
              value={editBankFormData.name}
              onChange={handleInputChange}
            />
            {/* <Input
              variant={"unstyled"}
              placeholder="Bank Name"
              type="text"
              display={"flex"}
              h={"48px"}
              p={"0px 12px"}
              alignItems={"center"}
              alignSelf={"stretch"}
              borderRadius={"12px"}
              border={`1px solid ${inputBorder}`}
              bg={inputBg}
              fontSize={"16px"}
              fontWeight={"400"}
              lineHeight={"22px"}
              letterSpacing={"0.08px"}
              _focus={{ border: "1px solid #00CCB3" }}
              _active={{ border: "1px solid #00CCB3" }}
              _hover={{ border: "1px solid #00CCB3" }}
              _placeholder={{
                color: inputText,
              }}
              name="Bank"
              value={editBankFormData.Bank}
              onChange={handleInputChange}
            /> */}
            <SelectWithSearch
              initialValue={editBankFormData.Bank}
              onChange={handleBankChange}
            />
            <Input
              variant={"unstyled"}
              placeholder="ACCOUNT NUMBER"
              type="text"
              display={"flex"}
              h={"48px"}
              p={"0px 12px"}
              alignItems={"center"}
              alignSelf={"stretch"}
              borderRadius={"12px"}
              border={`1px solid ${inputBorder}`}
              bg={inputBg}
              fontSize={"16px"}
              fontWeight={"400"}
              lineHeight={"22px"}
              letterSpacing={"0.08px"}
              _focus={{ border: "1px solid #00CCB3" }}
              _active={{ border: "1px solid #00CCB3" }}
              _hover={{ border: "1px solid #00CCB3" }}
              _placeholder={{
                color: inputText,
              }}
              name="accountNo"
              value={editBankFormData.accountNo}
              onChange={handleInputChange}
            />
          </Flex>
        </CustomModal>
        <CustomModal
          isOpen={deleteOpen}
          onClose={onDeleteClose}
          headerText="Delete Address"
          isLoading={deleteWalletAddressMutation?.isLoading}
          onSubmit={async () => {
            try {
              await deleteWalletAddressMutation.mutateAsync(activeWallet?.id);
              toast({
                title: "Address Deleted Successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
              });

              onDeleteClose();
            } catch (error: any) {
              toast({
                title: error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
          }}
          footerText={"Yes, Delete Address"}
          closeText={"Cancel"}
          btnDirection={true}
          btnBgColor={btnBg}
          btnColor={btnText}
          btnBorderColor={btnBorderColor}
        >
          <Text
            my={"32px"}
            color={"#909090"}
            fontSize={"14px"}
            fontWeight={"400"}
            lineHeight={"20px"}
            letterSpacing={"0.035px"}
          >
            Are you sure you want to delete the address?
          </Text>
        </CustomModal>

        <CustomModal
          isOpen={isWalletEditOpen}
          onClose={onWalletEditClose}
          headerText="Edit Wallet Address"
          isLoading={
            editWalletAddressMutation?.isLoading ||
            validateAddressMutatation?.isLoading
          }
          onSubmit={async () => {
            try {
              if (formData?.label === "" || formData?.address === "") {
                toast({
                  title: "Label and Address should not be empty",
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                });
                return;
              }
              validateAddressMutatation
                ?.mutateAsync({
                  address: formData?.address,
                  networkId: activeWallet?.coin?.networkId,
                })
                .then(async (res: any) => {
                  if (res?.status) {
                    const payload: any = {
                      walletLabel: formData?.label,
                      walletAddress: formData?.address,
                      walletAddressId: activeWallet?.id,
                    };
                    await editWalletAddressMutation.mutateAsync(payload);
                    toast({
                      title: "Address Updated Successfully",
                      status: "success",
                      duration: 3000,
                      isClosable: true,
                    });
                    setFormData({
                      label: "",
                      address: "",
                      icon: "",
                    });
                    onWalletEditClose();
                  } else {
                    toast({
                      title: "Please enter a valid address",
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                    });
                  }
                })
                .catch((err: any) => {
                  toast({
                    title: err?.message || "Please enter a valid address",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                  });
                });
            } catch (error: any) {
              toast({
                title: error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
          }}
          footerText={"Update Wallet Address"}
          closeText={"Cancel"}
          btnDirection={true}
        >
          <Flex direction={"column"} gap={"16px"} my={"32px"}>
            {/* working */}

            <Menu matchWidth>
              <MenuButton
                height={"48px"}
                px={"16px"}
                py={"8px"}
                transition="all 0.2s"
                borderRadius="12px"
                border={`1px solid #EEE`}
                _focus={{ boxShadow: "outline" }}
                width="100%"
                // _hover={{ borderColor: "#00CCB3" }}
                _active={{ border: "#00CCB3" }}
              >
                <Flex justifyContent={"space-between"} alignItems={"center"}>
                  <Flex gap={"8px"}>
                    <Image
                      width={"20px"}
                      height={"20px"}
                      src={formData?.icon}
                    />
                    <Text>BTC</Text>
                  </Flex>

                  <Image src={menuIcon} alt="menu-down" />
                </Flex>
              </MenuButton>
              <MenuList>
                <MenuItem>this data will be added at integration time</MenuItem>
              </MenuList>
            </Menu>

            <Menu matchWidth>
              <MenuButton
                height={"48px"}
                px={"16px"}
                py={"8px"}
                transition="all 0.2s"
                borderRadius="12px"
                border={`1px solid #EEE`}
                _focus={{ boxShadow: "outline" }}
                width="100%"
                //  _hover={{ borderColor: "#00CCB3" }}
                _active={{ border: "#00CCB3" }}
              >
                <Flex justifyContent={"space-between"} alignItems={"center"}>
                  <Text>Bitcoin</Text>

                  <Image src={menuIcon} alt="menu-down" />
                </Flex>
              </MenuButton>
              <MenuList>
                <MenuItem>this data will be added at integration time</MenuItem>
              </MenuList>
            </Menu>
            <Input
              variant={"unstyled"}
              placeholder="Eth Wallet for Binance"
              type="text"
              display={"flex"}
              h={"48px"}
              p={"0px 12px"}
              alignItems={"center"}
              alignSelf={"stretch"}
              borderRadius={"12px"}
              border={`1px solid ${inputBorder}`}
              bg={inputBg}
              fontSize={"16px"}
              fontWeight={"400"}
              lineHeight={"22px"}
              letterSpacing={"0.08px"}
              _focus={{ border: "1px solid #00CCB3" }}
              _placeholder={{
                color: inputText,
              }}
              value={formData?.label}
              onChange={(e: any) => {
                setFormData({ ...formData, label: e.target.value });
              }}
            />
            <Input
              variant={"unstyled"}
              placeholder="01heiw632hicnjnk"
              type="text"
              display={"flex"}
              h={"48px"}
              p={"0px 12px"}
              alignItems={"center"}
              alignSelf={"stretch"}
              borderRadius={"12px"}
              border={`1px solid ${inputBorder}`}
              bg={inputBg}
              fontSize={"16px"}
              fontWeight={"400"}
              lineHeight={"22px"}
              letterSpacing={"0.08px"}
              _focus={{ border: "1px solid #00CCB3" }}
              _active={{ border: "1px solid #00CCB3" }}
              _hover={{ border: "1px solid #00CCB3" }}
              _placeholder={{
                color: inputText,
              }}
              value={formData?.address}
              onChange={(e: any) => {
                setFormData({ ...formData, address: e.target.value });
              }}
            />
          </Flex>
        </CustomModal>
        <CustomModal
          isOpen={deleteBankOpen}
          onClose={onDeleteBankClose}
          headerText="Delete Address"
          isLoading={deleteBankMutation?.isLoading}
          onSubmit={async () => {
            try {
              await deleteBankMutation.mutateAsync(bankAccountNo);
              toast({
                title: "Address Deleted Successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
              });

              onDeleteBankClose();
            } catch (error: any) {
              toast({
                title: error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
          }}
          footerText={"Yes, Delete Address"}
          closeText={"Cancel"}
          btnDirection={true}
          btnBgColor={btnBg}
          btnColor={btnText}
          btnBorderColor={btnBorderColor}
        >
          <Text
            my={"32px"}
            color={"#909090"}
            fontSize={"14px"}
            fontWeight={"400"}
            lineHeight={"20px"}
            letterSpacing={"0.035px"}
          >
            Are you sure you want to delete this address?
          </Text>
        </CustomModal>
      </Box>
    </div>
  );
};

export default AddressBook;

export const ServiceAdresses = ({ type, onSelect }: any) => {
  const [serviceType, setServiceType] = useState("Airtime");
  const [activeService, setActiveService] = useState<any>(null);
  const { data: services } = useGetAllServicesAddress(serviceType);
  const updateService = useUpdateServiceAddress();
  const deleteService = useDeleteServiceAddress();

  const toast = useToast();
  const serviceColumns = [
    "Service",
    serviceType === "Airtime" || serviceType === "Internet"
      ? "Phone No"
      : serviceType === "Electricity"
      ? "Meter Number"
      : "Smart Card",
    "Date",
    "Action",
  ];
  const [formDataSerive, setFormDataService] = useState({
    Service: "",
    "Phone No": "",
    Date: "",
  });
  const [serviceRows, setServiceRows] = useState<any>([]);
  const {
    isOpen: isServiceEditOpen,
    onOpen: onServiceEditOpen,
    onClose: onServiceEditClose,
  } = useDisclosure();
  const {
    isOpen: deleteOpenService,
    onOpen: onDeleteOpenService,
    onClose: onDeleteCloseService,
  } = useDisclosure();
  const inputBorder = useColorModeValue("#EEE", "#333");
  const inputBg = useColorModeValue("#FFF", "#121B18");
  const btnBg = useColorModeValue("#F00C0C", "rgba(240, 12, 12, 0.10)");
  const btnText = useColorModeValue("#FFF", "#FF5959");
  const btnBorderColor = useColorModeValue("transparent", "#FF5959");
  const inputText = useColorModeValue("#5A5A5A", "#909090");
  const emptyWalletIcon = useColorModeValue(emptyWallet, emptyWalletDark);
  const reArrageData = () => {
    // Adding serviceRows data
    const serviceTempArr = services?.map((service: any) => ({
      Service: (
        <Text
          fontWeight="600"
          cursor="pointer"
          onClick={() => {
            if (onSelect) {
              onSelect(service);
            }
          }}
        >
          {service?.type}
        </Text>
      ),
      "Phone No":
        serviceType === "Airtime" || serviceType === "Internet"
          ? service?.phoneNumber
          : serviceType === "Electricity"
          ? service?.meterNumber
          : service?.smartCardNumber,
      Date: new Date(service?.updatedAt)?.toLocaleDateString(),
      Action: (
        <Flex align={"center"} gap={"16px"}>
          <Image
            src={edit}
            onClick={() => {
              onServiceEditOpen();
              setActiveService(service);
              setFormDataService({
                Service: service?.type,
                "Phone No":
                  serviceType === "Airtime" || serviceType === "Internet"
                    ? service?.phoneNumber
                    : serviceType === "Electricity"
                    ? service?.meterNumber
                    : service?.smartCardNumber,
                Date: service.Date,
              });
            }}
            cursor={"pointer"}
          />
          <Image
            src={deleteIcon}
            onClick={() => {
              setActiveService(service);
              onDeleteOpenService();
            }}
            cursor={"pointer"}
          />
        </Flex>
      ),
    }));

    setServiceRows(serviceTempArr);
  };

  useEffect(() => {
    if (services) {
      reArrageData();
    }
    if (type) {
      setServiceType(type);
    }
  }, [type, services]);

  return (
    <Box>
      {!type && (
        <Select
          // placeholder="Airtime"
          w="fit-content"
          ml="auto"
          size="sm"
          value={serviceType}
          onChange={(e: any) => setServiceType(e.target.value)}
        >
          <option value="Airtime">Airtime</option>
          <option value="Internet">Internet</option>
          <option value="Electricity">Electricity</option>
          <option value="TV">TV</option>
        </Select>
      )}
      <Box mt={"8px"}>
        {serviceRows?.length === 0 ? (
          <Image src={emptyWalletIcon} />
        ) : (
          <CustomTable
            rows={serviceRows}
            columns={serviceColumns}
            pagination={false}
          />
        )}
        {!type && false && (
          <PrimaryButton
            mt="24px"
            text="Add Service Address"
            height={"56px"}
            //  isLoading={validateAddressMutatation?.isLoading}

            onClick={() => {}}
          />
        )}
      </Box>
      <CustomModal
        isOpen={isServiceEditOpen}
        onClose={onServiceEditClose}
        isLoading={updateService.isLoading}
        headerText="Edit Service"
        onSubmit={async () => {
          try {
            const payLoadKey =
              formDataSerive?.Service === "Airtime"
                ? "phoneNumber"
                : formDataSerive?.Service === "Electricity"
                ? "meterNumber"
                : "smartCardNumber";
            await updateService.mutateAsync({
              addressId: activeService?.id,
              payload: {
                type: formDataSerive?.Service,
                [payLoadKey]: formDataSerive?.["Phone No"],
              },
            });
            toast({ title: "Updated.", status: "success" });
            onServiceEditClose();
          } catch (error: any) {
            toast({ title: error?.message, status: "error" });
          }
        }}
        footerText={"Update Service"}
        closeText={"Cancel"}
        btnDirection={true}
      >
        <Flex direction={"column"} gap={"16px"} my={"32px"}>
          <Input
            variant={"unstyled"}
            placeholder="Service"
            type="text"
            display={"flex"}
            h={"48px"}
            p={"0px 12px"}
            alignItems={"center"}
            alignSelf={"stretch"}
            borderRadius={"12px"}
            border={`1px solid ${inputBorder}`}
            bg={inputBg}
            fontSize={"16px"}
            fontWeight={"400"}
            lineHeight={"22px"}
            letterSpacing={"0.08px"}
            _focus={{ border: "1px solid #00CCB3" }}
            _placeholder={{
              color: inputText,
            }}
            readOnly
            value={formDataSerive?.Service}
            onChange={(e: any) => {
              setFormDataService({
                ...formDataSerive,
                Service: e.target.value,
              });
            }}
          />
          <Input
            variant={"unstyled"}
            placeholder={
              serviceType === "Airtime" || serviceType === "Internet"
                ? "Update phone number"
                : serviceType === "Electricity"
                ? "Update meter number"
                : "Update smart card number"
            }
            type="text"
            display={"flex"}
            h={"48px"}
            p={"0px 12px"}
            alignItems={"center"}
            alignSelf={"stretch"}
            borderRadius={"12px"}
            border={`1px solid ${inputBorder}`}
            bg={inputBg}
            fontSize={"16px"}
            fontWeight={"400"}
            lineHeight={"22px"}
            letterSpacing={"0.08px"}
            _focus={{ border: "1px solid #00CCB3" }}
            _active={{ border: "1px solid #00CCB3" }}
            _hover={{ border: "1px solid #00CCB3" }}
            _placeholder={{
              color: inputText,
            }}
            value={formDataSerive?.["Phone No"]}
            onChange={(e: any) => {
              setFormDataService({
                ...formDataSerive,
                "Phone No": e.target.value,
              });
            }}
          />
        </Flex>
      </CustomModal>
      <CustomModal
        isOpen={deleteOpenService}
        onClose={onDeleteCloseService}
        isLoading={deleteService.isLoading}
        headerText="Delete Address"
        onSubmit={async () => {
          try {
            await deleteService.mutateAsync({
              addressId: activeService?.id,
              type: serviceType?.toLowerCase(),
            });
            toast({ title: "Deleted.", status: "success" });
            onDeleteCloseService();
          } catch (error: any) {
            toast({ title: error?.message, status: "error" });
          }
        }}
        footerText={"Yes, Delete Service"}
        closeText={"Cancel"}
        btnDirection={true}
        btnBgColor={btnBg}
        btnColor={btnText}
        btnBorderColor={btnBorderColor}
      >
        <Text
          my={"32px"}
          color={"#909090"}
          fontSize={"14px"}
          fontWeight={"400"}
          lineHeight={"20px"}
          letterSpacing={"0.035px"}
        >
          Are you sure you want to delete this Service?
        </Text>
      </CustomModal>
    </Box>
  );
};
