import React, { useState, useRef, useEffect } from "react";
import AdminLayout from "../layouts/AdminLayout";
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Spinner,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import SelectCrypto from "../components/SelectCrypto";
import SelectCryptoDropdown from "../components/SelectCryptoDropdown";
import PrimaryButton from "../components/PrimaryButton";
import cloudDark from "../assets/icons/dark/cloud.svg";
import cloudLight from "../assets/icons/light/cloud.svg";
import imgLight from "../assets/icons/light/img.svg";
import imgDark from "../assets/icons/dark/img.svg";
import selfiePlaceholder from "../assets/imgs/selfie-placeholder.png";
import { useUploadMedia } from "../utils/media.api";
import { useUpgradeCustomerToKYCT3 } from "../utils/auth.api";
import CustomModal from "../components/CustomModal";

const KycLevel3 = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const uploadMediaMutation = useUploadMedia();
  const navigate = useNavigate();
  const upgradeCustomerToKYCT3Mutation = useUpgradeCustomerToKYCT3();
  const toast = useToast();
  const menuData = [
    { id: 1, symbol: "Gender", isPlaceholder: true },
    { id: 2, symbol: "Male" },
    { id: 3, symbol: "Female" },
  ];
  const countryMenuData = [
    { id: 1, symbol: "Country", isPlaceholder: true },
    { id: 2, symbol: "Pakistan" },
    { id: 3, symbol: "USA" },
  ];
  const idTypesMenuData = [
    { id: 1, symbol: "ID Type", isPlaceholder: true },
    { id: 2, symbol: "Drivers License" },
    { id: 3, symbol: "International Passport" },
    { id: 4, symbol: "Voters Card" },
    { id: 5, symbol: "NIN Slip" },
  ];
  const [selectedIdType, setSelectedIdType] = useState(idTypesMenuData[0]);
  const [isIdTypeMenuOpen, setIsIdTypeMenuOpen] = useState(false);
  const occupationsMenuData = [
    { id: 1, symbol: "Occupation", isPlaceholder: true },
    { id: 2, symbol: "Employed" },
    { id: 3, symbol: "Self-Employed" },
    { id: 4, symbol: "Business Owner" },
    { id: 5, symbol: "Investor" },
    { id: 6, symbol: "Others" },
  ];
  const [selectedOccupation, setSelectedOccupation] = useState(
    occupationsMenuData[0]
  );
  const [isOccupationMenuOpen, setIsOccupationMenuOpen] = useState(false);

  const [formData3, setFormData3] = useState({
    uploadSelfie: "",
    occupation: "",
    sourceOfFund: "",
    description: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData3((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      if (
        formData3?.uploadSelfie &&
        formData3?.occupation &&
        formData3?.sourceOfFund &&
        formData3?.description
      ) {
        await upgradeCustomerToKYCT3Mutation.mutateAsync(formData3);
        // navigate("/dashboard/settings");
        onOpen();
        toast({
          title: `Applied for Level 3!!!`,
          status: "success",
        });
      }
    } catch (error: any) {
      setFormData3({
        uploadSelfie: "",
        occupation: "",
        sourceOfFund: "",
        description: "",
      });
      setSelfie("");
      setFundImage("");
      toast({
        title: "Error on upgrading customer",
        status: "error",
      });
    }
  };

  const fileInputRef = useRef<HTMLInputElement>(null);
  const selfieInput = useRef<HTMLInputElement>(null);
  const [selfie, setSelfie] = useState("");
  const [fundImage, setFundImage] = useState("");
  const selectedTextColor = useColorModeValue("#00332D", "#00FFE0");

  const menuW = { base: "86%", sm: "90%", md: "46%" };
  const [step, setStep] = useState(3);
  const dropIconColor = useColorModeValue("#5A5A5A", "#C7C7C7");
  const cloudIcon = useColorModeValue(cloudLight, cloudDark);
  const paraColor = useColorModeValue("#909090", "#C7C7C7");

  useEffect(() => {
    setFormData3((prevState) => ({
      ...prevState,
      uploadSelfie: selfie,
      sourceOfFund: fundImage,
      occupation: selectedOccupation.isPlaceholder
        ? ""
        : selectedOccupation.symbol,
    }));
  }, [selectedOccupation, selfie, fundImage, formData3.description]);

  return (
    <AdminLayout noSidebar={true}>
      <Box marginTop="72px" marginRight={"2%"} mb={"50px"} w={"95%"}>
        <ChakraLink
          color={useColorModeValue("#009986", "#00FFE0")}
          as={ReactRouterLink}
          to="/dashboard/settings"
          onClick={() => sessionStorage.setItem("isActive", "Settings")}
        >
          <Box
            borderBottom={"1px solid"}
            borderColor={useColorModeValue("#009986", "#00FFE0")}
            w="fit-content"
          >
            <Text mb="-5px">Go Back to Settings</Text>
          </Box>
        </ChakraLink>
        <Flex my="40px" gap={"24px"} align={"end"}>
          <Heading color={useColorModeValue("black", "white")}>
            {step === 4 ? "Source of Fund" : "KYC"}
          </Heading>
          {<Text>Step {step}</Text>}
        </Flex>
        <Text
          mb="15px"
          fontWeight={700}
          color={useColorModeValue("#5A5A5A", "#C7C7C7")}
        >
          {step === 1 && "Personal Info"}
          {step === 2 && "Your Identity Info"}
          {step === 3 && "Upload a Selfie"}
        </Text>
        <Box
          my="24px"
          w={{ base: "100%", md: "49%" }}
          display={step === 2 ? "block" : "none"}
        >
          <SelectCrypto
            isMenuOpen={isIdTypeMenuOpen}
            setIsMenuOpen={setIsIdTypeMenuOpen}
            selectedCoin={selectedIdType}
            fontWeight="400"
            iconBG="transparent"
            h="48px"
            dropIconColor={dropIconColor}
          />
          {isIdTypeMenuOpen && (
            <SelectCryptoDropdown
              isMenuOpen={isIdTypeMenuOpen}
              setIsMenuOpen={setIsIdTypeMenuOpen}
              selectedCoin={selectedIdType}
              setSelectedCoin={setSelectedIdType}
              menuData={idTypesMenuData}
              menuW={menuW}
              selectedTextColor={selectedTextColor}
            />
          )}
        </Box>
        <Box
          my="24px"
          w={{ base: "100%", md: "49%" }}
          display={step === 4 ? "block" : "none"}
        >
          <Box my="24px">
            <SelectCrypto
              isMenuOpen={isOccupationMenuOpen}
              setIsMenuOpen={setIsOccupationMenuOpen}
              selectedCoin={selectedOccupation}
              fontWeight="400" // Corrected typo: fontWeight
              iconBG="transparent"
              h="48px"
              dropIconColor={dropIconColor}
            />
            {isOccupationMenuOpen && (
              <SelectCryptoDropdown
                isMenuOpen={isOccupationMenuOpen}
                setIsMenuOpen={setIsOccupationMenuOpen}
                selectedCoin={selectedOccupation}
                setSelectedCoin={setSelectedOccupation}
                menuData={occupationsMenuData}
                menuW={menuW}
                selectedTextColor={selectedTextColor} // Assuming this variable is defined elsewhere
              />
            )}
          </Box>
        </Box>
        <Flex
          gap="24px"
          direction={{ base: "column", md: "row" }}
          align={"center"}
          justify={"center"}
        >
          <Box flexGrow={1} w={{ base: "100%", md: "" }}>
            <Box display={step === 3 ? "block" : "none"}>
              <Flex
                direction={"column"}
                align={"center"}
                justify={"center"}
                p={["56px 5px", "56px 16px"]}
                gap="24px"
                border="1px dashed"
                borderColor={useColorModeValue("#00332D", "#333")}
                borderRadius={"12px"}
                my="16px"
                bgGradient={useColorModeValue(
                  "linear-gradient(180deg, rgba(247, 247, 247, 0.00) 0%, rgba(226, 226, 226, 0.40) 100%)",
                  "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 100%)"
                )}
              >
                <Image src={useColorModeValue(imgLight, imgDark)} />
                <Text>Upload Image</Text>
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  ref={selfieInput}
                  style={{ display: "none" }}
                  onChange={async (e) => {
                    const file2 = e.target.files?.[0];
                    const formData32: any = new FormData();
                    formData32.append("file", file2);
                    try {
                      const img = await uploadMediaMutation.mutateAsync(
                        formData32
                      );
                      setSelfie(img);
                      toast({
                        title: "Selfie uploaded successfully.",
                        status: "success",
                      });
                    } catch (error: any) {
                      toast({
                        title: error.message,
                        status: "error",
                      });
                    }
                  }}
                />
                <Box w="142px">
                  <PrimaryButton
                    bgLight="#EEE"
                    mt="0"
                    text={
                      uploadMediaMutation.isLoading ? <Spinner /> : "Browse"
                    }
                    disabled={uploadMediaMutation.isLoading}
                    onClick={() => selfieInput.current?.click()}
                  />
                </Box>
              </Flex>
            </Box>
            <Box display={step === 4 ? "block" : "none"}>
              <Text color={useColorModeValue("#333333", "#C7C7C7")}>
                Document
              </Text>
              <Text>Upload the document that you have verified on Sumsub.</Text>
              <Flex
                direction={"column"}
                align={"center"}
                justify={"center"}
                p={["56px 5px", "56px 16px"]}
                gap="24px"
                border="1px dashed"
                borderColor={useColorModeValue("#00332D", "#333")}
                bgGradient={useColorModeValue(
                  "linear-gradient(180deg, rgba(247, 247, 247, 0.00) 0%, rgba(226, 226, 226, 0.40) 100%)",
                  "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 100%)"
                )}
                borderRadius={"12px"}
                my="16px"
              >
                <Image
                  w={fundImage ? "120px" : "auto"}
                  src={fundImage || cloudIcon}
                />
                <Text>Upload Document</Text>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={async (e) => {
                    const file2 = e.target.files?.[0];
                    const formData32: any = new FormData();
                    formData32.append("file", file2);
                    try {
                      const img = await uploadMediaMutation.mutateAsync(
                        formData32
                      );
                      setFundImage(img);
                      toast({
                        title: "Source of fund image uploaded successfully.",
                        status: "success",
                      });
                    } catch (error: any) {
                      toast({
                        title: error.message,
                        status: "error",
                      });
                    }
                  }}
                />
                <Box w="142px">
                  <PrimaryButton
                    bgLight="#EEE"
                    mt="0"
                    text={
                      uploadMediaMutation.isLoading ? <Spinner /> : "Browse"
                    }
                    disabled={uploadMediaMutation.isLoading}
                    onClick={() => fileInputRef.current?.click()}
                  />
                </Box>
              </Flex>
            </Box>
          </Box>
          <Box flexGrow={1} w={{ base: "100%", md: "" }}>
            <Box display={step === 3 ? "block" : "none"}>
              <Flex
                direction={"column"}
                align={"center"}
                justify={"end"}
                p={["0px 5px", "0px 16px"]}
                gap="24px"
                borderRadius={"12px"}
                my="16px"
                bg={useColorModeValue("#EEE", "#121B18")}
                h="268px"
                // bgImage={selfie}
                bgSize={"100% 100%"}
                bgPosition={"bottom"}
              >
                <Image
                  maxW="98%"
                  maxH={"300px"}
                  src={selfie || selfiePlaceholder}
                />
              </Flex>
            </Box>
            <Box display={step === 4 ? "block" : "none"}>
              <Box h="50px" />
              <Textarea
                h="268px"
                borderRadius={"12px"}
                focusBorderColor={"teal.300"}
                placeholder="Your comment"
                bg={useColorModeValue("", "#121B18")}
                name="description"
                value={formData3.description}
                onChange={handleChange}
              />
            </Box>
          </Box>
        </Flex>
        <Flex gap="6px" display={step === 1 ? "none" : "flex"}>
          <Text color={useColorModeValue("black", "#333333")} fontWeight={700}>
            Note:
          </Text>
          <Text color={"#909090"}>Image size should be less than 2 MBs</Text>
        </Flex>
        <Flex gap="24px" justify={"end"} mt="40px">
          <Button
            w="312px"
            h="48px"
            display={step !== 3 && step !== 4 ? "flex" : "none"}
            border={`1px solid ${useColorModeValue("#C7C7C7", "#333333")}`}
            borderRadius={"12px"}
            onClick={() => {
              if (step > 3) {
                setStep(step - 1);
              }
            }}
          >
            Back
          </Button>

          <Box w="312px">
            <PrimaryButton
              mt="0"
              text={
                step === 4 ? (
                  upgradeCustomerToKYCT3Mutation.isLoading ? (
                    <Spinner />
                  ) : (
                    "Submit"
                  )
                ) : (
                  "Next"
                )
              }
              disabled={
                upgradeCustomerToKYCT3Mutation.isLoading ||
                (step === 3
                  ? !formData3?.uploadSelfie
                  : !formData3?.occupation ||
                    !formData3?.description ||
                    !formData3?.sourceOfFund)
              }
              onClick={() => {
                if (step < 4) {
                  setStep(step + 1);
                }
                if (step === 4) {
                  handleSubmit();
                }
              }}
            />
          </Box>
        </Flex>
      </Box>
      <CustomModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          navigate("/dashboard/settings");
          setFormData3({
            uploadSelfie: "",
            occupation: "",
            sourceOfFund: "",
            description: "",
          });
          setSelfie("");
          setFundImage("");
        }}
        headerText="KYC Upgrade Pending"
        footerText="Done"
        closeText="Close"
        isLoading={upgradeCustomerToKYCT3Mutation?.isLoading}
        isDisabled={upgradeCustomerToKYCT3Mutation?.isLoading}
        onSubmit={() => {
          navigate("/dashboard/settings");
          setFormData3({
            uploadSelfie: "",
            occupation: "",
            sourceOfFund: "",
            description: "",
          });
          setSelfie("");
          setFundImage("");
          onClose();
        }}
      >
        <Flex justifyContent={"flex-start"} alignItems={"center"} my={"20px"}>
          <Text fontSize={"14px"} lineHeight={"20px"} color={paraColor}>
            Your KYC has been submitted and is pending for approval.
          </Text>
        </Flex>
      </CustomModal>
    </AdminLayout>
  );
};

export default KycLevel3;
