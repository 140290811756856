import { Box, Divider, Input, useColorModeValue } from "@chakra-ui/react";
import React, { useState } from "react";
import theme from "../theme/theme";
import btcIcon from "../assets/icons/btc.svg";
import ethIcon from "../assets/icons/ethIcon.svg";
import trxIcon from "../assets/icons/trxIcon.svg";
import usdtIcon from "../assets/icons/usdtIcon.svg";
import usdcIcon from "../assets/icons/usdcIcon.svg";
import SelectCryptoDropdownItem from "./SelectCryptoDropdownItem";
import UserInfoInput from "./UserInfoInput";

const SelectCryptoDropdown = ({
  isMenuOpen,
  setIsMenuOpen,
  selectedCoin,
  setSelectedCoin,
  menuData,
  menuW,
  selectedTextColor,
  h,
  isSearch,
}: any) => {
  const [search, setSearch] = useState("");
  const coinsData = menuData || [
    { id: 1, name: "Bitcoin", symbol: "BTC", icon: btcIcon },
    { id: 2, name: "Ethereum", symbol: "ETH", icon: ethIcon },
    { id: 3, name: "TRON", symbol: "TRX", icon: trxIcon },
    { id: 4, name: "Tether", symbol: "USDT (TRC20)", icon: usdtIcon },
    { id: 5, name: "Tether", symbol: "USDT (ERC20)", icon: usdtIcon },
    { id: 6, name: "USDC", symbol: "USDC", icon: usdcIcon },
  ];
  const borderColor = useColorModeValue(
    theme.colors.light.border.secondary,
    theme.colors.dark.border.secondary
  );
  const filteredCoins = coinsData?.filter((coin: any) =>
    coin?.symbol?.toLowerCase()?.includes(search?.toLowerCase())
  );
  return (
    <Box
      border={`1px solid ${borderColor}`}
      overflowY={"auto"}
      borderRadius={"12px"}
      mt="7px"
      px={["5px", "16px"]}
      position={"absolute"}
      zIndex={1}
      h={h || "auto"}
      bg={useColorModeValue("white", "#121B18")}
      w={menuW || { base: "100%", lg: "40%" }}
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "gray",
          borderRadius: "24px",
        },
      }}
    >
      {isSearch && (
        <>
          <UserInfoInput
            placeholder="Search by name"
            name="search"
            value={search}
            onChange={(e: any) => setSearch(e.target.value)}
          />
          <Divider mt="10px" />
        </>
      )}
      {filteredCoins?.map((coin: any) =>
        coin?.isPlaceholder === true ? null : (
          <SelectCryptoDropdownItem
            key={coin.id}
            coin={coin}
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            selectedCoin={selectedCoin}
            setSelectedCoin={setSelectedCoin}
            coinsData={coinsData}
            selectedTextColor={selectedTextColor}
          />
        )
      )}
    </Box>
  );
};

export default SelectCryptoDropdown;
