import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "./api-client";

export const useGetWalletWithBalance = () =>
  useQuery(
    ["getWalletBalance"],
    async () => {
      const response = await client("wallet/getWalletWithBalance");
      return response;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

export const useGetNairaBalance = () =>
  useQuery(["getNairaBalance"], async () => {
    const response = await client("wallet/getUserNairaBalance");
    return response;
  });

export const useGetSwapRates = () =>
  useQuery(["getSwapRates"], async () => {
    const response = await client("wallet/getSwapRates");
    return response;
  });

export const useGetDepositAddress = (networkId) =>
  useQuery(["getDepositAddress", networkId], async () => {
    const response = await client(
      `wallet/getDepositAddress?networkId=${networkId}`
    );
    return response;
  });

export const useGetBankAddresses = () =>
  useQuery(["getBankAddresses"], async () => {
    const response = await client(`wallet/getBankAddresses`);
    return response;
  });

export const useSwap = () => {
  const queryClient = useQueryClient();
  const swap = async ({ payload, toCrypto }) => {
    const response = await client(`wallet/swap?toCrypto=${toCrypto}`, {
      method: "POST",
      data: payload,
    });

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      queryClient?.invalidateQueries("getNairaBalance");
      queryClient?.invalidateQueries("getWalletBalance");
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(swap, {
    onSuccess: () => {
      queryClient?.invalidateQueries("getNairaBalance");
      queryClient?.invalidateQueries("getWalletBalance");
    },
  });
};

export const useWithdraw = () => {
  const withdraw = async (payload) => {
    const response = await client("wallet/withdraw", {
      method: "POST",
      data: payload,
    });

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(withdraw);
};

export const useAddNewWalletAddress = () => {
  const queryClient = useQueryClient();
  const addNewWalletAddress = async (payload) => {
    const response = await client("wallet/addNewWalletAddress", {
      method: "POST",
      data: payload,
    });

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(addNewWalletAddress, {
    onSuccess: () => {
      queryClient.invalidateQueries("getWalletAddress");
    },
  });
};

export const useGetWalletAddress = () =>
  useQuery(["getWalletAddress"], async () => {
    const response = await client(`wallet/getAllWalletAddresses`);
    return response;
  });

export const useClaimRewards = () => {
  const withdraw = async () => {
    const response = await client("wallet/claimRewards", {
      method: "POST",
    });

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(withdraw);
};

export const useEditWalletAddress = () => {
  const query = useQueryClient();
  const editWalletAddress = async ({
    walletLabel,
    walletAddress,
    walletAddressId,
  }) => {
    const response = await client(
      `wallet/editWalletAddress?walletAddressId=${walletAddressId}`,
      {
        method: "POST",
        data: { walletLabel, walletAddress },
      }
    );

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(editWalletAddress, {
    onSuccess: () => {
      query?.invalidateQueries("getWalletAddress");
    },
  });
};

export const useDeleteWalletAddress = () => {
  const query = useQueryClient();
  const deleteWalletAddress = async (walletAddressId) => {
    const response = await client(
      `wallet/deletWalletAddress?walletAddressId=${walletAddressId}`,
      {
        method: "POST",
      }
    );

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(deleteWalletAddress, {
    onSuccess: () => {
      query?.invalidateQueries("getWalletAddress");
    },
  });
};

export const useDeleteAccount = () => {
  const query = useQueryClient();
  const deleteAccount = async (accountNumber) => {
    const response = await client(
      `wallet/deleteAccountAddress=${accountNumber}`,
      {
        method: "POST",
      }
    );

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(deleteAccount, {
    onSuccess: () => {
      query?.invalidateQueries("getWalletAddress");
    },
  });
};

export const useEditBankAddressBook = () => {
  const queryClient = useQueryClient();

  const editBankAddressBook = async ({
    bankAccount,
    name,
    accountNo,
    Bank,
  }) => {
    const response = await client(
      `wallet/editBankAddressBook?bankAccount=${bankAccount}`,
      {
        method: "POST",
        data: { name, accountNo, Bank },
      }
    );
    if (response.error) {
      throw new Error(response.error);
    }
    return response;
  };

  return useMutation(editBankAddressBook, {
    onSuccess: () => {
      queryClient.invalidateQueries("getBankAddresses");
    },
  });
};

export const useUpdateBankAccount = () => {
  const queryClient = useQueryClient();

  const updateBankAccount = async ({
    bankAccount,
    name,
    accountNo,
    Bank,
    id,
  }) => {
    const response = await client(`auth/updateBankAccount?id=${id}`, {
      method: "POST",
      data: { bankName: Bank, accountNumber: accountNo, accountName: name },
    });
    if (response.error) {
      throw new Error(response.error);
    }
    return response;
  };

  return useMutation(updateBankAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries("getBankAccounts");
    },
  });
};

export const useCsvWalletTransactions = ({ o, l, filter, tokenId }) =>
  useQuery(["CsvWalletTransactions", o, l, filter, tokenId], async () => {
    let type = "";
    if (filter) {
      type = "&type=" + filter;
    }
    const response = await client(
      `wallet/CsvWalletTransactions?limit=${l}&offset=${o}&tokenId=${tokenId}` +
        type
    );
    return response;
  });

export const useGetWalletTransactions = ({ o, l, filter, tokenId }) =>
  useQuery(["getTransactions", o, l, filter, tokenId], async () => {
    let type = "";
    if (filter) {
      type = "&type=" + filter;
    }
    const response = await client(
      `wallet/getTransactions?limit=${l}&offset=${o}&tokenId=${tokenId}` + type
    );
    return response;
  });

export const useGetWalletFiatTransactions = ({ o, l, filter }) =>
  useQuery(["getTransactionsFiat", o, l, filter], async () => {
    let type = "";
    if (filter) {
      type = "&type=" + filter;
    }
    const response = await client(
      `wallet/getTransactionsFiat?limit=${l}&offset=${o}` + type
    );
    return response;
  });

export const useDepositAmount = () => {
  const depositAmount = async (payload) => {
    const response = await client(
      `wallet/depositAmount?bankAccount=${payload.bankAccount}&depositAmount=${payload.depositAmount}&referenceId=${payload.referenceId}`,
      {
        method: "POST",
      }
    );

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(depositAmount);
};

export const useWithdrawFiat = () => {
  const withdrawFiat = async (payload) => {
    const response = await client("wallet/withdrawFiat", {
      method: "POST",
      data: payload,
    });

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(withdrawFiat);
};

export const useGetUserNairaBalance = () =>
  useQuery(["getUserNairaBalance"], async () => {
    const response = await client(`wallet/getUserNairaBalance`);
    return response;
  });

export const useGetWalletAddressByTokenId = (tokenId) =>
  useQuery(["getWalletAddressByTokenId", tokenId], async () => {
    const response = await client(
      `wallet/getWalletAddressByTokenId?getWalletAddressByTokenId=${tokenId}`
    );
    return response;
  });

export const useVerifyOtpForTransaction = () => {
  const verifyOtpForTransaction = async (payload) => {
    const response = await client("wallet/verifyOtpForTransaction", {
      method: "POST",
      data: payload,
    });

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(verifyOtpForTransaction);
};

export const useValidateAddress = () => {
  const validateAddress = async ({ address, networkId }) => {
    const response = await client(
      `wallet/validateAddress/${address}/${networkId}`,
      {
        method: "GET",
      }
    );

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(validateAddress);
};
