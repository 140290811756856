import {
  Box,
  Button,
  Flex,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import SideBar from "../components/sideBar";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import Header from "../components/Header";
import { getMessaging, onMessage } from "@firebase/messaging";
import {
  useFetchLoggedInUser,
  useGetLoggedInUser,
  useLogout,
} from "../utils/auth.api";

const AdminLayout = ({ children, noSidebar }: any) => {
  const messaging = getMessaging();
  const toast = useToast();
  const loggedInMutation = useFetchLoggedInUser();
  const { data: loggedInUser } = useGetLoggedInUser();
  const loggedOut = useLogout();
  const [isOpen, setIsOpen] = useState(
    localStorage.getItem("isOpen") === "0" ? false : true
  );
  const borderColor = useColorModeValue("white", "black");
  // const color = useColorModeValue(theme.colors.light.text.primary, theme.colors.dark.text.primary);

  const checkActivation = () => {
    loggedInMutation
      ?.mutateAsync()
      .then((res: any) => {
        if (!res?.isActive) {
          toast({
            title: "Your account has been deactivated!",
            status: "warning",
            duration: 3000,
            isClosable: true,
          });
          loggedOut?.mutateAsync();
        }
      })
      .catch((err: any) => {
        // console.log("errr", err);
      });
  };

  useEffect(() => {
    if (!loggedInUser?.isActive) {
      checkActivation();
    }
  }, [loggedInUser]);

  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload: any) => {
      // console.log("------check notification 2", payload);
      if (payload?.notification?.title === "deactivated") {
        checkActivation();
      }
    });

    return unsubscribe;
  }, []);
  return (
    <Box position={"relative"}>
      <Flex>
        {!noSidebar && (
          <>
            {" "}
            <SideBar isOpen={isOpen} setIsOpen={setIsOpen} />
            <Button
              variant={"unstyled"}
              bg="#00ffe0"
              w="28px"
              h="40px"
              borderRadius={"100px"}
              border={"4px solid"}
              borderColor={borderColor}
              mt="70px"
              ml={isOpen ? "250px" : "90px"}
              transition={"margin-left 0.3s ease"}
              color="black"
              onClick={() => {
                setIsOpen(!isOpen);
                if (isOpen) {
                  localStorage.setItem("isOpen", "0");
                } else localStorage.setItem("isOpen", "1");
              }}
              position={"fixed"}
              zIndex={2}
              display={{ base: "none", md: "block" }}
            >
              {isOpen ? (
                <ChevronLeftIcon boxSize={"24px"} />
              ) : (
                <ChevronRightIcon boxSize={"24px"} />
              )}
            </Button>
          </>
        )}
        <Box
          p="36px"
          flexGrow={1}
          onClick={() => {
            if (window.innerWidth < 480) {
              setIsOpen(false);
              localStorage.setItem("isOpen", "0");
            }
          }}
        >
          <Header noSidebar={noSidebar} isOpen={isOpen} />
        </Box>
      </Flex>
      <Box
        // w={isOpen ? "80%" : "93%"}

        ml={
          !noSidebar
            ? isOpen
              ? ["0", "300px"]
              : ["70px", "120px", "140px", "140px"]
            : "30px"
        }
        onClick={() => {
          if (window.innerWidth < 480) {
            setIsOpen(false);
            localStorage.setItem("isOpen", "0");
          }
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default AdminLayout;
