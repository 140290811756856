import React, { useState, useRef, useEffect } from "react";
import AdminLayout from "../layouts/AdminLayout";
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import UserInfoInput from "../components/UserInfoInput";
import SelectCrypto from "../components/SelectCrypto";
import SelectCryptoDropdown from "../components/SelectCryptoDropdown";
import PhoneInputWithCountry from "../components/PhoneInput";
import theme from "../theme/theme";
import PrimaryButton from "../components/PrimaryButton";
import cloudDark from "../assets/icons/dark/cloud.svg";
import cloudLight from "../assets/icons/light/cloud.svg";
import imgLight from "../assets/icons/light/img.svg";
import imgDark from "../assets/icons/dark/img.svg";
import selfiePlaceholder from "../assets/imgs/selfie-placeholder.png";
import { useUploadMedia } from "../utils/media.api";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import {
  useGetCountriesList,
  useUpgradeCustomerToKYCT2,
  useUpgradeCustomerToKYCT3,
} from "../utils/auth.api";
import CustomModal from "../components/CustomModal";
import NigeriaFlag from "../assets/icons/nigeria_flag.svg";
import { isValidPhoneNumber } from "react-phone-number-input";

const KYC = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const getCountriesList = useGetCountriesList();
  const uploadMediaMutation = useUploadMedia();
  const upgradeCustomerToKYCT2Mutation = useUpgradeCustomerToKYCT2();
  const upgradeCustomerToKYCT3Mutation = useUpgradeCustomerToKYCT3();
  const toast = useToast();
  const menuData = [
    { id: 1, symbol: "Gender", isPlaceholder: true },
    { id: 2, symbol: "Male" },
    { id: 3, symbol: "Female" },
  ];
  const [selectedCoin, setSelectedCoin] = useState(menuData[0]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const countryMenuData = [{ id: 1, symbol: "Nigeria" }];
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [isCountryMenuOpen, setIsCountryMenuOpen] = useState(false);
  const idTypesMenuData = [
    { id: 1, symbol: "ID Type", isPlaceholder: true },
    { id: 2, symbol: "Drivers License" },
    { id: 3, symbol: "International Passport" },
    { id: 4, symbol: "Voters Card" },
    { id: 5, symbol: "NIN Slip" },
  ];
  const [selectedIdType, setSelectedIdType] = useState(idTypesMenuData[0]);
  const [isIdTypeMenuOpen, setIsIdTypeMenuOpen] = useState(false);
  const occupationsMenuData = [
    { id: 1, symbol: "Occupation", isPlaceholder: true },
    { id: 2, symbol: "Employed" },
    { id: 3, symbol: "Self-Employed" },
    { id: 4, symbol: "Business Owner" },
    { id: 5, symbol: "Investor" },
    { id: 6, symbol: "Others" },
  ];
  const [selectedOccupation, setSelectedOccupation] = useState(
    occupationsMenuData[0]
  );
  const [isOccupationMenuOpen, setIsOccupationMenuOpen] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [formData, setFormData] = useState({
    means_of_id: "",
    imageOfId: "",
    passportPhoto: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
    bvn: "",
    street: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    placeOfBirth: "",
    dob: "",
    gender: "",
    documentNumber: "",
  });

  const isAnyFieldEmpty = () => {
    const {
      firstName,
      lastName,
      bvn,
      street,
      postalCode,
      city,
      state,
      country,
      placeOfBirth,
      dob,
      gender,
    } = formData;

    if (
      !firstName ||
      !lastName ||
      !bvn ||
      !street ||
      !postalCode ||
      !city ||
      !state ||
      !country ||
      !placeOfBirth ||
      !dob ||
      !gender
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isAnyFieldEmptyInStep2 = () => {
    const { means_of_id, imageOfId, passportPhoto } = formData;

    if (!means_of_id || !imageOfId || !passportPhoto) {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      await upgradeCustomerToKYCT2Mutation.mutateAsync({
        ...formData,
        country: selectedCountry?.symbol,
      });
      if (selfie) {
        await upgradeCustomerToKYCT3Mutation.mutateAsync(selfie);
      }
      // navigate("/dashboard/settings");
      onOpen();
      // toast({
      //   title: `Upgraded to Level ${selfie ? "3" : "2"}!!!`,
      //   status: "success",
      // });
    } catch (error: any) {
      toast({
        title: error.message,
        status: "error",
      });
      setFormData({
        means_of_id: "",
        imageOfId: "",
        passportPhoto: "",
        phoneNumber: "",
        firstName: "",
        lastName: "",
        bvn: "",
        street: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        placeOfBirth: "",
        dob: "",
        gender: "",
        documentNumber: "",
      });
    }
    // setFormData({
    //   means_of_id: "",
    //   imageOfId: "",
    //   passportPhoto: "",
    //   phoneNumber: "",
    //   firstName: "",
    //   lastName: "",
    //   bvn: "",
    //   street: "",
    //   city: "",
    //   state: "",
    //   country: "",
    //   postalCode: "",
    //   placeOfBirth: "",
    //   dob: "",
    //   gender: "",
    // });
  };

  const imgIdInput = useRef<HTMLInputElement>(null);
  const passportInput = useRef<HTMLInputElement>(null);

  const [selfie, setSelfie] = useState("");
  const [idUploadLoading, setIdUploadLoading] = useState(false);
  const [photoUploadLoading, setPhotoUploadLoading] = useState(false);
  const [idFileName, setIdFileName] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [searchCountry, setSearchCountry] = useState("");
  const [photoFileName, setPhotoFileName] = useState("");
  const [date, setDate] = useState(new Date());
  const paraColor = useColorModeValue("#909090", "#C7C7C7");
  const dateProps = {
    dateNavBtnProps: {
      colorScheme: "teal",
      variant: "outline",
    },
    dayOfMonthBtnProps: {
      defaultBtnProps: {
        borderColor: "red.300",
        _hover: {
          background: "teal.400",
        },
      },
      isInRangeBtnProps: {
        color: "yellow",
      },
      selectedBtnProps: {
        background: "teal.300",
        color: "white",
      },
      todayBtnProps: {
        background: "teal.500",
      },
    },
    inputProps: {
      size: "lg",
      borderRadius: "12px",
    },
    popoverCompProps: {
      popoverContentProps: {
        background: useColorModeValue("white", "black"),
        color: useColorModeValue("black", "white"),
      },
    },
    calendarPanelProps: {
      wrapperProps: {
        borderColor: "green",
      },
      contentProps: {
        borderWidth: 0,
      },
      headerProps: {
        padding: "5px",
      },
      dividerProps: {
        display: "none",
      },
    },
    weekdayLabelProps: {
      fontWeight: "normal",
    },
    dateHeadingProps: {
      fontWeight: "semibold",
    },
  };

  const [dateFocused, setDateFocused] = useState(false);
  const [textDateValue, setTextDateValue] = useState("");

  const selectedTextColor = useColorModeValue("#00332D", "#00FFE0");

  const menuW = { base: "86%", sm: "90%", md: "46%" };
  const [step, setStep] = useState(1);
  const dropIconColor = useColorModeValue("#5A5A5A", "#C7C7C7");

  const refactorCountriesSelection = (countries: any) => {
    const tempArr = countries.map((country: any, idx: number) => {
      return { id: idx + 1, symbol: country };
    });
    setSelectedCountry(tempArr[0]);
    setCountryList(tempArr);
  };

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      gender: selectedCoin.isPlaceholder ? "" : selectedCoin.symbol,
      country: selectedCountry?.symbol,
      dob: textDateValue,
      means_of_id: selectedIdType.isPlaceholder ? "" : selectedIdType.symbol,
      phoneNumber: phoneNumber,
    }));
  }, [
    selectedCoin,
    selectedCountry,
    selectedIdType,
    phoneNumber,
    textDateValue,
  ]);

  useEffect(() => {
    getCountriesList.mutateAsync(searchCountry).then((result) => {
      refactorCountriesSelection(result);
    });
  }, []);

  return (
    <AdminLayout noSidebar={true}>
      <Box marginTop="72px" marginRight={"2%"} mb={"50px"} w={"95%"}>
        <ChakraLink
          color={useColorModeValue("#009986", "#00FFE0")}
          as={ReactRouterLink}
          to="/dashboard/settings"
          onClick={() => sessionStorage.setItem("isActive", "Settings")}
        >
          <Box
            borderBottom={"1px solid"}
            borderColor={useColorModeValue("#009986", "#00FFE0")}
            w="fit-content"
          >
            <Text mb="-5px">Go Back to Settings</Text>
          </Box>
        </ChakraLink>
        <Flex my="40px" gap={"24px"} align={"end"}>
          <Heading color={useColorModeValue("black", "white")}>
            {step === 4 ? "KYC" : "KYC"}
          </Heading>
          {<Text>Step {step}</Text>}
        </Flex>
        <Text
          mb="15px"
          fontWeight={700}
          color={useColorModeValue("#5A5A5A", "#C7C7C7")}
        >
          {step === 1 && "Personal Info"}
          {step === 2 && "Your Identity Info"}
          {step === 3 && "Upload a Selfie"}
        </Text>
        <Flex
          my="24px"
          justify={"space-between"}
          gap="15px"
          direction={{ base: "column", md: "row" }}
        >
          <Box
            w={{ base: "100%", md: "49%" }}
            display={step === 2 ? "block" : "none"}
          >
            <SelectCrypto
              isMenuOpen={isIdTypeMenuOpen}
              setIsMenuOpen={setIsIdTypeMenuOpen}
              selectedCoin={selectedIdType}
              fontWeight="400"
              iconBG="transparent"
              h="48px"
              dropIconColor={dropIconColor}
            />
            {isIdTypeMenuOpen && (
              <SelectCryptoDropdown
                isMenuOpen={isIdTypeMenuOpen}
                setIsMenuOpen={setIsIdTypeMenuOpen}
                selectedCoin={selectedIdType}
                setSelectedCoin={setSelectedIdType}
                menuData={idTypesMenuData}
                menuW={menuW}
                selectedTextColor={selectedTextColor}
              />
            )}
          </Box>
          <Box
            w={{ base: "100%", md: "49%" }}
            display={step === 2 ? "block" : "none"}
          >
            <UserInfoInput
              placeholder="Document Number"
              name="documentNumber"
              value={formData.documentNumber}
              onChange={handleChange}
              my="0"
              mb="0"
            />
          </Box>
        </Flex>
        <Box
          my="24px"
          w={{ base: "100%", md: "49%" }}
          display={step === 4 ? "block" : "none"}
        >
          <Box my="24px">
            <SelectCrypto
              isMenuOpen={isOccupationMenuOpen}
              setIsMenuOpen={setIsOccupationMenuOpen}
              selectedCoin={selectedOccupation}
              fontWeight="400" // Corrected typo: fontWeight
              iconBG="transparent"
              h="48px"
              dropIconColor={dropIconColor}
            />
            {isOccupationMenuOpen && (
              <SelectCryptoDropdown
                isMenuOpen={isOccupationMenuOpen}
                setIsMenuOpen={setIsOccupationMenuOpen}
                selectedCoin={selectedOccupation}
                setSelectedCoin={setSelectedOccupation}
                menuData={occupationsMenuData}
                menuW={menuW}
                selectedTextColor={selectedTextColor} // Assuming this variable is defined elsewhere
              />
            )}
          </Box>
        </Box>
        <Flex
          gap="24px"
          direction={{ base: "column", md: "row" }}
          // align={"center"}
          justify={"center"}
        >
          <Box flexGrow={1} w={{ base: "100%", md: "" }}>
            <Box display={step === 1 ? "block" : "none"}>
              <UserInfoInput
                placeholder="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
              <UserInfoInput
                placeholder="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
              <UserInfoInput
                placeholder="BVN No"
                name="bvn"
                value={formData.bvn}
                onChange={handleChange}
              />
              <UserInfoInput
                placeholder="Place of birth"
                name="placeOfBirth"
                value={formData.placeOfBirth}
                onChange={handleChange}
              />
              <UserInfoInput
                placeholder="City"
                name="city"
                value={formData.city}
                onChange={handleChange}
              />
              <SelectCrypto
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
                selectedCoin={selectedCoin}
                fontWeight="400"
                iconBG="transparent"
                h="48px"
                dropIconColor={dropIconColor}
              />
              {isMenuOpen && (
                <SelectCryptoDropdown
                  {...{
                    isMenuOpen,
                    setIsMenuOpen,
                    selectedCoin,
                    setSelectedCoin,
                    menuData,
                    menuW,
                    selectedTextColor,
                  }}
                />
              )}
            </Box>
            <Box display={step === 2 ? "block" : "none"}>
              <Text color={useColorModeValue("#333333", "#C7C7C7")}>
                Upload Your Identity File
              </Text>
              <Flex
                direction={"column"}
                align={"center"}
                justify={"center"}
                p={["56px 5px", "56px 16px"]}
                gap="24px"
                border="1px dashed"
                bgGradient={useColorModeValue(
                  "linear-gradient(180deg, rgba(247, 247, 247, 0.00) 0%, rgba(226, 226, 226, 0.40) 100%)",
                  "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 100%)"
                )}
                borderColor={useColorModeValue("#00332D", "#333")}
                borderRadius={"12px"}
                my="16px"
              >
                <Image src={useColorModeValue(cloudLight, cloudDark)} />
                <Text>Upload Document</Text>
                <input
                  type="file"
                  ref={imgIdInput}
                  name="imageOfId"
                  style={{ display: "none" }}
                  onChange={async (e) => {
                    setIdUploadLoading(true);
                    const file2: any = e.target.files?.[0];
                    setIdFileName(file2?.name);
                    const formData2: any = new FormData();
                    formData2.append("file", file2);
                    try {
                      const img = await uploadMediaMutation.mutateAsync(
                        formData2
                      );
                      setIdUploadLoading(false);
                      setFormData({ ...formData, imageOfId: img });
                      toast({
                        title: "Image uploaded successfully.",
                        status: "success",
                      });
                    } catch (error: any) {
                      setIdUploadLoading(false);
                      toast({
                        title: error.message,
                        status: "error",
                      });
                    }
                  }}
                />
                <Box w="142px">
                  <PrimaryButton
                    bgLight="#EEE"
                    mt="0"
                    text={idUploadLoading ? <Spinner /> : "Browse"}
                    disabled={idUploadLoading}
                    onClick={() => {
                      imgIdInput.current?.click();
                    }}
                  />
                </Box>
                <Text
                  display={idFileName ? "block" : "none"}
                  mt="-10px"
                  fontSize={"14px"}
                  color={"teal"}
                >
                  Upload: {idFileName}
                </Text>
              </Flex>
            </Box>
          </Box>
          <Box flexGrow={1} w={{ base: "100%", md: "" }}>
            <Box mt="10px" display={step === 1 ? "block" : "none"}>
              <PhoneInputWithCountry
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                mb="24px"
              />
              <Box my="24px">
                <SelectCrypto
                  isMenuOpen={isCountryMenuOpen}
                  setIsMenuOpen={setIsCountryMenuOpen}
                  selectedCoin={selectedCountry}
                  fontWeight="400"
                  iconBG="transparent"
                  h="48px"
                  dropIconColor={dropIconColor}
                />
                {isCountryMenuOpen && (
                  <SelectCryptoDropdown
                    {...{
                      isMenuOpen: isCountryMenuOpen,
                      setIsMenuOpen: setIsCountryMenuOpen,
                      selectedCoin: selectedCountry,
                      setSelectedCoin: setSelectedCountry,
                      menuData: countryList,
                      h: "200px",
                      isSearch: true,
                    }}
                  />
                )}
              </Box>

              <UserInfoInput
                placeholder="State of residence"
                name="state"
                value={formData.state}
                onChange={handleChange}
              />
              <UserInfoInput
                placeholder="Street No"
                name="street"
                value={formData.street}
                onChange={handleChange}
              />
              <UserInfoInput
                placeholder="Postal Code"
                name="postalCode"
                value={formData.postalCode}
                onChange={handleChange}
              />
              {dateFocused ? (
                <Box mb="24px">
                  <SingleDatepicker
                    name="date-input"
                    date={date}
                    onDateChange={(e) => {
                      setDate(e);
                      setDateFocused(false);
                      let newDate = new Date(e);
                      newDate.setDate(newDate.getDate() + 1);
                      setTextDateValue(newDate.toISOString().split("T")[0]);
                    }}
                    maxDate={new Date()}
                    propsConfigs={dateProps}
                  />
                </Box>
              ) : (
                <Box
                  onMouseEnter={() => setDateFocused(true)}
                  onMouseLeave={() => setDateFocused(false)}
                >
                  <UserInfoInput
                    placeholder="Date of Birth"
                    value={textDateValue}
                    fontSize="18px"
                  />
                </Box>
              )}
              {/* <InputGroup>
                <Input
                  placeholder="Date of Birth"
                  size="md"
                  type="text"
                  h="48px"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  _placeholder={{ color: "#C7C7C7" }}
                  onKeyDown={(e) => e.preventDefault()}
                  onFocus={(e) => {
                    e.target.type = "date";
                    setDateFocused(true);
                  }}
                  onBlur={(e) => {
                    e.target.type = "text";
                    setDateFocused(false);
                  }}
                  borderRadius={"12px"}
                  _focus={{
                    border: `1px solid ${inputBderColor}`,
                    boxShadow: "none",
                  }}
                  bg={useColorModeValue("transparent", "#121B18")}
                  max={new Date().toISOString().split("T")[0]}
                />
                <InputRightElement
                  h="20px"
                  my="14px"
                  pl="8px"
                  pr="16px"
                  borderLeft={"1px solid"}
                  borderColor={useColorModeValue("#EEE", "#333333")}
                  display={dateFocused ? "none" : "flex"}
                  alignItems={"center"}
                >
                  <i className="fa-regular fa-calendar-minus"></i>
                </InputRightElement>
              </InputGroup> */}
            </Box>
            <Box display={step === 2 ? "block" : "none"}>
              <Text color={useColorModeValue("#333333", "#C7C7C7")}>
                Upload Passport Size Photograph
              </Text>
              <Flex
                direction={"column"}
                align={"center"}
                justify={"center"}
                p={["56px 5px", "56px 16px"]}
                gap="24px"
                border="1px dashed"
                bgGradient={useColorModeValue(
                  "linear-gradient(180deg, rgba(247, 247, 247, 0.00) 0%, rgba(226, 226, 226, 0.40) 100%)",
                  "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 100%)"
                )}
                borderColor={useColorModeValue("#00332D", "#333")}
                borderRadius={"12px"}
                my="16px"
              >
                <Image src={useColorModeValue(imgLight, imgDark)} />
                <Text>Upload Image</Text>

                <input
                  type="file"
                  ref={passportInput}
                  style={{ display: "none" }}
                  name="passportPhoto"
                  onChange={async (e) => {
                    setPhotoUploadLoading(true);
                    const file2: any = e.target.files?.[0];
                    setPhotoFileName(file2?.name);
                    const formData2: any = new FormData();
                    formData2.append("file", file2);
                    try {
                      const img = await uploadMediaMutation.mutateAsync(
                        formData2
                      );
                      setPhotoUploadLoading(false);
                      setFormData({ ...formData, passportPhoto: img });
                      toast({
                        title: "Image uploaded successfully.",
                        status: "success",
                      });
                    } catch (error: any) {
                      setPhotoUploadLoading(false);
                      toast({
                        title: error.message,
                        status: "error",
                      });
                    }
                  }}
                />
                <Box w="142px">
                  <PrimaryButton
                    bgLight="#EEE"
                    mt="0"
                    text={photoUploadLoading ? <Spinner /> : "Browse"}
                    disabled={photoUploadLoading}
                    onClick={() => {
                      passportInput.current?.click();
                    }}
                  />
                </Box>
                <Text
                  display={photoFileName ? "block" : "none"}
                  mt="-10px"
                  fontSize={"14px"}
                  color={"teal"}
                >
                  Upload: {photoFileName}
                </Text>
              </Flex>
            </Box>
          </Box>
        </Flex>
        <Flex gap="6px" display={step === 1 ? "none" : "flex"}>
          <Text color={useColorModeValue("black", "#333333")} fontWeight={700}>
            Note:
          </Text>
          <Text color={"#909090"}>Image size should be less than 2 MBs</Text>
        </Flex>
        <Flex gap="24px" justify={"end"} mt="40px">
          <Button
            w="312px"
            h="48px"
            display={step !== 1 ? "flex" : "none"}
            border={`1px solid ${useColorModeValue("#C7C7C7", "#333333")}`}
            borderRadius={"12px"}
            onClick={() => {
              if (step > 1) {
                setStep(step - 1);
              }
            }}
          >
            Back
          </Button>

          <Box w="312px">
            <PrimaryButton
              mt="0"
              text={
                step === 2 ? (
                  upgradeCustomerToKYCT2Mutation.isLoading ? (
                    <Spinner />
                  ) : (
                    "Submit"
                  )
                ) : (
                  "Next"
                )
              }
              disabled={
                upgradeCustomerToKYCT2Mutation.isLoading ||
                (step === 2 && !formData?.documentNumber) ||
                (step === 1
                  ? isAnyFieldEmpty() ||
                    !phoneNumber ||
                    (phoneNumber && !isValidPhoneNumber(phoneNumber))
                  : isAnyFieldEmptyInStep2())
              }
              onClick={() => {
                if (step < 2) {
                  setStep(step + 1);
                }
                if (step === 2) {
                  handleSubmit();
                }
              }}
            />
          </Box>
        </Flex>
      </Box>
      <CustomModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          navigate("/dashboard/settings");
          setFormData({
            means_of_id: "",
            imageOfId: "",
            passportPhoto: "",
            phoneNumber: "",
            firstName: "",
            lastName: "",
            bvn: "",
            street: "",
            city: "",
            state: "",
            country: "",
            postalCode: "",
            placeOfBirth: "",
            dob: "",
            gender: "",
            documentNumber: "",
          });
        }}
        headerText="KYC Upgrade Pending"
        footerText="Done"
        closeText="Close"
        isLoading={
          upgradeCustomerToKYCT2Mutation?.isLoading ||
          upgradeCustomerToKYCT3Mutation?.isLoading
        }
        isDisabled={upgradeCustomerToKYCT2Mutation?.isLoading}
        onSubmit={() => {
          navigate("/dashboard/settings");
          setFormData({
            means_of_id: "",
            imageOfId: "",
            passportPhoto: "",
            phoneNumber: "",
            firstName: "",
            lastName: "",
            bvn: "",
            street: "",
            city: "",
            state: "",
            country: "",
            postalCode: "",
            placeOfBirth: "",
            dob: "",
            gender: "",
            documentNumber: "",
          });
          onClose();
        }}
      >
        <Flex justifyContent={"flex-start"} alignItems={"center"} my={"20px"}>
          <Text fontSize={"14px"} lineHeight={"20px"} color={paraColor}>
            Your KYC has been submitted and is pending for approval.
          </Text>
        </Flex>
      </CustomModal>
    </AdminLayout>
  );
};

export default KYC;
