import { Box, Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";

interface NotificationProps {
  icon: string;
  type: string;
  amount?: string;
  time: string;
  active: boolean;
  notificationBody: string;
  notificationTitle: string;
  onClick?: any;
  isRead?: boolean;
}

const NotificationCard: React.FC<NotificationProps> = ({
  icon,
  type,
  amount,
  time,
  active,
  notificationBody,
  notificationTitle,
  onClick,
  isRead,
}) => {
  const text1 = useColorModeValue("#000", "#EEE");
  const text2 = useColorModeValue("#5A5A5A", "#C7C7C7");
  const imgOpacity = useColorModeValue(0.9, 0.7);

  return (
    <div>
      <Box
        p={["2px", "6px", "10px", "16px"]}
        borderRadius={"12px"}
        bg={active ? (!isRead ? "#EDFDFD" : "") : !isRead ? "#111213" : ""}
        display={"flex"}
        alignItems={"center"}
        gap={"16px"}
        mb={"16px"}
        onClick={onClick}
        cursor={"pointer"}
      >
        <Image
          src={icon}
          alt="down-arrow"
          borderRadius={type === "swap" ? "50px" : "0"}
          opacity={type === "swap" ? imgOpacity : 1}
          w={["20px", "30px", "40px", "50px"]}
          h={["20px", "30px", "40px", "50px"]}
        />
        <Flex
          direction={"column"}
          justifyContent={"space-between"}
          gap={"6px"}
          w="100%"
        >
          <Flex justifyContent={"space-between"} w="100%" alignItems={"center"}>
            <Text
              color={text1}
              fontSize={["10px", "12px", "14px", "16px"]}
              fontWeight={"700"}
              lineHeight={"22px"}
              letterSpacing={"0.08px"}
            >
              {notificationTitle}
            </Text>
            <Text
              color={"#909090"}
              fontSize={["6px", "8px", "10px", "12px"]}
              fontWeight={"400"}
            >
              {time}
            </Text>
          </Flex>
          <Text
            color={text2}
            fontSize={["9px", "11px", "13px", "14px"]}
            fontWeight={"400"}
            lineHeight={"20px"}
            letterSpacing={"0.035px"}
          >
            {notificationBody}
          </Text>
        </Flex>
      </Box>
    </div>
  );
};

export default NotificationCard;
