import { Box, Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import React, { MouseEventHandler } from "react";

interface CryptoCardProps {
  coin: string;
  fullname: string;
  balance: string;
  amount: string;
  icon: string;
  arrow: string;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
}

const CryptoCard: React.FC<CryptoCardProps> = ({
  coin,
  fullname,
  balance,
  amount,
  icon,
  arrow,
  onClick,
}) => {
  const paraColor = useColorModeValue("#333", "#EEE");
  const paraColor2 = useColorModeValue("#909090", "#C7C7C7");
  const paraColor3 = useColorModeValue("#5A5A5A", "#C7C7C7");
  const borderColor = useColorModeValue("#FFF", "#333");
  const bgColor = useColorModeValue("#F9F9F9", "");
  const bgColor2 = useColorModeValue(
    amount === "+0.2%" ? "#EEE" : "#EEE",
    amount === "+0.2%" ? "#00332D" : "rgba(240,12,12,0.20)"
  );

  //bg colour update in ui change remove bg and add border color
  const borderColorNew = useColorModeValue(" #EEE", "#333");
  return (
    <div>
      <Box
        p={"24px"}
        borderRadius={"12px"}
        //border={`1px solid ${borderColor}`}
        // bg={bgColor}
        border={`1px solid ${borderColorNew}`}
        cursor={"pointer"}
        onClick={onClick}
      >
        <Flex justify={"space-between"} mb={"26px"}>
          <Flex direction={"column"}>
            <Text
              color={paraColor}
              fontSize={"16px"}
              fontWeight={"700"}
              lineHeight={"22px"}
              letterSpacing={"1.2px"}
            >
              {coin}
            </Text>
            <Text
              color={paraColor2}
              fontSize={"14px"}
              fontWeight={"400"}
              lineHeight={"20px"}
              letterSpacing={"0.035px"}
            >
              {fullname}
            </Text>
          </Flex>
          <Image
            src={icon}
            alt="coin-icon"
            w={"32px"}
            h={"32px"}
            borderRadius="50%"
          />
        </Flex>
        <Flex justify={"space-between"}>
          <Box>
            <Text
              color={paraColor2}
              fontSize={"14px"}
              fontWeight={"400"}
              lineHeight={"20px"}
              letterSpacing={"0.035px"}
            >
              Balance
            </Text>
            <Text
              color={paraColor}
              fontSize={"18px"}
              fontWeight={"700"}
              lineHeight={"24px"}
              letterSpacing={"0.027px"}
            >
              {balance}
            </Text>
          </Box>
          <Flex
            align={"center"}
            gap={"8px"}
            direction={["column", "row", "row", "row"]}
          >
            <Text
              borderRadius={"20px"}
              bg={bgColor2}
              display={"flex"}
              p={"4px 8px"}
              alignItems={"center"}
              gap={"4px"}
              color={Number(amount) > 0 ? "#00CCB3" : "#F00C0C"}
              fontSize={"10px"}
              fontWeight={"700"}
              lineHeight={"14px"}
              letterSpacing={"0.15px"}
            >
              {Number(amount)?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) || 0}
              % <Image src={arrow} alt="arrow" w={"14px"} h={"14px"} />
            </Text>
            <Text
              color={paraColor3}
              fontSize={"14px"}
              fontWeight={"400"}
              lineHeight={"20px"}
              letterSpacing={"0.035px"}
            >
              This week
            </Text>
          </Flex>
        </Flex>
      </Box>
    </div>
  );
};

export default CryptoCard;
