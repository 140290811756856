import React, { useEffect, useState } from "react";
import AdminLayout from "../layouts/AdminLayout";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Image,
  Img,
  Spinner,
  Table,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import CustomTable from "../components/CustomTable";
import bitcoin from "../assets/bitcoin.svg";
import plus from "../assets/plusIcon.svg";
import copyIcon from "../assets/copyIcon.svg";
import CustomModal from "../components/CustomModal";
import UserInfoInput from "../components/UserInfoInput";
import copyIconDark from "../assets/copyIconDark.svg";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAddNewWalletAddress,
  useGetWalletAddress,
  useGetWalletAddressByTokenId,
  useValidateAddress,
} from "../utils/wallet.api";
import arrow1 from "../assets/backArrow.svg";
import arrow2 from "../assets/backArrowDark.svg";

const AddressBook = ({ setAddress, address }: any) => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const data = location.state;
  const { data: addressData, isLoading } = useGetWalletAddressByTokenId(
    data?.selectedTokenData?.coinId
  );
  const [newAddress, setNewAddress] = useState({
    walletLabel: "",
    walletAddress: "",
  });
  const addNewWalletAddressMutation = useAddNewWalletAddress();
  const validateAddressMutatation = useValidateAddress();
  const columns = ["Address label", "Token", "Address", "Date", "Action"];
  const toast = useToast();
  const paraColor1 = useColorModeValue("#00332D", "#EEE");
  const paraColor2 = useColorModeValue("#909090", "#C7C7C7");
  const copy = useColorModeValue(copyIcon, copyIconDark);
  const borderColor = useColorModeValue("#EEE", "#333");
  const arrowIcon = useColorModeValue(arrow1, arrow2);

  const reArrageData = () => {
    let tempArr: any = [];

    addressData?.forEach((addData: any) => {
      const createdAt = addData?.createdAt;
      const options: any = { year: "numeric", month: "long", day: "2-digit" };
      const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
        new Date(createdAt)
      );
      tempArr?.push({
        AddressLabel: addData?.walletLabel,
        Token: (
          <Flex align={"center"} gap={"8px"} pr={"32px"}>
            {data?.selectedTokenData?.name?.split(" ")[0]}{" "}
            <Image
              src={data?.selectedTokenData?.logoUrl}
              alt="Bitcoin"
              w={"14px"}
              h={"14px"}
            />
          </Flex>
        ),
        Address: addData?.walletAddress,
        Date: formattedDate,
        Action: (
          <Flex gap={5}>
            <Checkbox
              colorScheme="teal"
              onChange={() => {
                setAddress(addData);
                navigate("/dashboard/withdraw", {
                  state: data?.selectedTokenData,
                });
              }}
              defaultChecked={address === addData ? true : false}
            >
              {address === addData ? "Selected" : "Select"}
            </Checkbox>
            {/* <Flex align={"center"} gap={"8px"} justifyContent={"start"}>
            Copy{" "}
            <Image
              src={copy}
              alt="copy-icon"
              pr={"0px"}
              cursor={"pointer"}
              onClick={() => {
                navigator.clipboard.writeText(addData?.walletAddress);
                toast({
                  title: "Copied to clipboard.",
                  status: "success",
                  duration: 3000,
                  isClosable: true,
                });
              }}
            />
          </Flex> */}
          </Flex>
        ),
      });
    });
    setRows(tempArr);
  };
  function isValidCryptoAddress(adrs: any) {
    const isEthereumAddress = /^(0x)?[0-9a-fA-F]{40}$/.test(adrs);
    const isBitcoinAddress = /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/.test(adrs);
    const isTronAddress = /^T[1-9A-HJ-NP-Za-km-z]{33}$/.test(adrs);
    return isEthereumAddress || isBitcoinAddress || isTronAddress;
  }
  useEffect(() => {
    reArrageData();
  }, [addressData]);

  return (
    <AdminLayout>
      <Box marginTop="72px" marginRight={"2%"} mb={"50px"} w={"95%"}>
        <Heading
          display={"flex"}
          alignItems={"center"}
          gap={"16px"}
          pb={"40px"}
          borderBottom={`1px solid ${borderColor}`}
          mb={"24px"}
          color={paraColor1}
          fontSize={["18px", "24px", "30px", "34px"]}
          fontWeight={"700"}
          letterSpacing={"0.085px"}
        >
          Address Book {data?.selectedTokenData?.name?.split(" ")[0]}
          <Image
            src={data?.selectedTokenData?.logoUrl}
            alt="bitcoin-icon"
            w={["21px", "24px", "26px", "28px"]}
            h={["21px", "24px", "26px", "28px"]}
          />
        </Heading>
        {isLoading ? (
          <Flex w={"100%"} justify={"center"}>
            <Spinner />
          </Flex>
        ) : (
          <>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Image
                src={arrowIcon}
                alt="arrow"
                transform={"rotate(270deg)"}
                onClick={() => navigate("/dashboard/withdraw")}
                cursor={"pointer"}
                mb={"24px"}
              />
              <Button
                mb={"24px"}
                bg={"#00FFE0"}
                padding={["4px", "5px", "6px", "8px"]}
                borderRadius={"6px"}
                color={"#00332D"}
                fontSize={["8px", "9px", "11px", "12px"]}
                py={"0px"}
                fontWeight={"400"}
                letterSpacing={"0.048px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"8px"}
                _hover={{ bg: "#00FFE2" }}
                _active={{ bg: "#00FFE2" }}
                onClick={() => onOpen()}
              >
                <Image
                  src={plus}
                  alt="plus"
                  w={["10px", "12px", "14px", "16px"]}
                  h={["10px", "12px", "14px", "16px"]}
                />{" "}
                Add New Address
              </Button>
            </Box>
            <CustomTable rows={rows} columns={columns} pagination={true} />
          </>
        )}
      </Box>
      <CustomModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setNewAddress({
            walletLabel: "",
            walletAddress: "",
          });
        }}
        headerText="Add New Address"
        footerText="Add Address"
        onSubmit={async () => {
          try {
            if (
              newAddress?.walletLabel === "" ||
              newAddress?.walletAddress === ""
            ) {
              toast({
                title: "Please fill in all fields",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
              setNewAddress({
                walletLabel: "",
                walletAddress: "",
              });
              return;
            }
            validateAddressMutatation
              ?.mutateAsync({
                address: newAddress?.walletAddress,
                networkId: data?.selectedTokenData?.networkId,
              })
              .then(async (res: any) => {
                if (res?.status) {
                  const formData = {
                    userId: data?.userId,
                    TokenId: data?.selectedTokenData?.coinId,
                    walletLabel: newAddress?.walletLabel,
                    walletAddress: newAddress?.walletAddress,
                  };
                  await addNewWalletAddressMutation.mutateAsync(formData);
                  toast({
                    title: "New Address Added Successfully!!!",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  });
                  onClose();
                  setNewAddress({
                    walletLabel: "",
                    walletAddress: "",
                  });
                } else {
                  toast({
                    title: "Please enter valid address",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                  });
                }
              })
              .catch((err: any) => {
                toast({
                  title: err?.message || "Please enter valid address",
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                });
              });
          } catch (error: any) {
            toast({
              title: error.message,
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        }}
        closeText="Cancel"
        isLoading={
          addNewWalletAddressMutation.isLoading ||
          validateAddressMutatation?.isLoading
        }
      >
        <Box pt={"32px"} pb={"16px"}>
          <Text
            color={paraColor2}
            mb={"40px"}
            fontSize={"16px"}
            fontWeight={"400"}
            lineHeight={"20px"}
            letterSpacing={"0.035px"}
          >
            Enter wallet table and address to add.
          </Text>
          <UserInfoInput
            placeholder="Wallet label"
            value={newAddress?.walletLabel}
            onChange={(e: any) =>
              setNewAddress({ ...newAddress, walletLabel: e.target.value })
            }
          />
          <UserInfoInput
            placeholder="Wallet Address"
            value={newAddress?.walletAddress}
            onChange={(e: any) =>
              setNewAddress({ ...newAddress, walletAddress: e.target.value })
            }
          />
        </Box>
      </CustomModal>
    </AdminLayout>
  );
};

export default AddressBook;
