import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css"; // Import the styles

const PhoneInputWithCountry = ({
  phoneNumber,
  setPhoneNumber,
  mb,
  borderC,
  p,
}: any) => {
  const borderColor = useColorModeValue("#EEEEEE", "#333333");
  const borderColor2 = useColorModeValue("#D8DFE8", "#333");

  const phoneInputCountries =
    document.getElementsByClassName("PhoneInputCountry");
  Array.from(phoneInputCountries).forEach((element: any) => {
    element.style.setProperty(
      "border-right",
      `1px solid ${borderColor}`,
      "important"
    );
    element.style.setProperty(
      "margin-right",
      borderC ? "8px" : "5px",
      "important"
    );
    element.style.setProperty("width", borderC ? "11%" : "", "important");
  });

  const handleOnFocus = () => {
    const phoneInputs = document.getElementsByClassName("PhoneInput");
    Array.from(phoneInputs).forEach((element: any) => {
      element.style.setProperty("outline", `1px solid #00CCB3`, "important");
    });
  };

  const handleOnBlur = () => {
    const phoneInputs = document.getElementsByClassName("PhoneInput");
    Array.from(phoneInputs).forEach((element: any) => {
      element.style.setProperty(
        "outline",
        `1px solid transparent`,
        "important"
      );
    });
  };

  return (
    <Flex flexDir="column" gap="5px" mb={mb ? mb : ""}>
      <PhoneInput
        international={true}
        placeholder="Enter phone number"
        value={phoneNumber}
        limitMaxLength={true}
        onChange={(e: any) => {
          setPhoneNumber(e);
        }}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        defaultCountry="NG"
        style={{
          marginLeft: p ? "1px" : "0px",
          border: `1px solid `,
          borderColor: borderC ? borderC : borderColor2,
          padding: p ? p : "2px 8px",
          borderRadius: "12px",
          backgroundColor: useColorModeValue("", "#121B18"),
        }}
      />
      {phoneNumber && !isValidPhoneNumber(phoneNumber) && (
        <Text fontSize={"12"} mt="5px" color="red">
          Incorrect phone number.
        </Text>
      )}
    </Flex>
  );
};

export default PhoneInputWithCountry;
