import { Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import serviceBg from "../assets/service_bg.svg";
import serviceBgDark from "../assets/servicesBgDark.svg";

interface ServicesProps {
  logo: string;
  text: string;
  flex?: number;
  onClick?: () => void;
}

const ServicesCard: React.FC<ServicesProps> = ({
  logo,
  flex,
  text,
  onClick,
}) => {
  const paraColor = useColorModeValue("#000", "#EEE");
  const borderColor = useColorModeValue("#FFF", "#333");
  const bgIcon = useColorModeValue(serviceBg, serviceBgDark);

  return (
    <div style={{ flex }}>
      <Flex
        height={"120px"}
        padding={"16px"}
        direction="column"
        justify={"space-between"}
        align={"start"}
        border={`0.5px solid ${borderColor}`}
        borderRadius={"12px"}
        backgroundImage={bgIcon}
        bgColor={bgIcon === serviceBgDark ? "#121B1B" : ""}
        backgroundRepeat={"no-repeat"}
        backgroundSize={"cover"}
        onClick={onClick}
        cursor={"pointer"}
      >
        <Image src={logo} alt="icon" />
        <Text
          color={paraColor}
          fontSize={"16px"}
          fontWeight={"400"}
          lineHeight={"22px"}
          letterSpacing={"0.024px"}
        >
          {text}
        </Text>
      </Flex>
    </div>
  );
};

export default ServicesCard;
