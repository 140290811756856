import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import theme from "../theme/theme";
import { ExitIcon, HelpIcon, SettingsIcon } from "../assets/icons/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import activeIcon from "../assets/icons/activeMenuIcon.svg";
import CustomModal from "./CustomModal";
import verifiedIcon from "../assets/verified.svg";
import { useLogout, useRemoveDeviceToken } from "../utils/auth.api";
import { requestForToken } from "../utils/firebase";
import helpCenter from "../assets/help.svg";
import helpCenterDark from "../assets/helpDark.svg";

const SidebarOtherMenu = ({ isOpen }: any) => {
  const removeDeviceToken = useRemoveDeviceToken();
  const userLogout = useLogout();
  const location = useLocation();
  const { isOpen: isModalOpen, onOpen, onClose } = useDisclosure();
  const paraColor2 = useColorModeValue("#909090", "#C7C7C7");
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(() => {
    const storedValue = sessionStorage.getItem("isActive");
    return storedValue ? storedValue : "/index";
  });

  const [isSubActive, setIsSubActive] = useState(
    sessionStorage.getItem("isSubActive") || "/index"
  );

  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  const menuBG = useColorModeValue(
    theme.colors.light.accent.secondary,
    theme.colors.dark.accent.secondary
  );
  const color = useColorModeValue("#00332D", theme.colors.dark.text.primary);
  const helpCenterIcon = useColorModeValue(helpCenter, helpCenterDark);
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  const logout = () => {
    userLogout?.mutateAsync();
  };

  const removeToken = async () => {
    try {
      const token = await requestForToken();
      if (token) {
        removeDeviceToken
          .mutateAsync({ deviceToken: token })
          .then(() => {
            logout();
          })
          .catch(() => {
            logout();
          });
      }
    } catch (error: any) {
      logout();
      // toast.warn(error?.message);
      console.error("Error fetching device token:", error);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (
      isSubActive === "Wallet Transactions" ||
      isSubActive === "Services Transactions"
    ) {
      sessionStorage.setItem("isActive", "Transaction");
      setIsActive("Transaction");
    }
    if (
      isSubActive === "Airtime" ||
      isSubActive === "Internet" ||
      isSubActive === "Electricity" ||
      isSubActive === "TV Subscription"
    ) {
      sessionStorage.setItem("isActive", "Services");
      setIsActive("Services");
    }
  }, [screenWidth, isOpen]);

  useEffect(() => {
    const pathMap: { [key: string]: string | { main: string; sub: string } } = {
      "/index": "/index",
      "/wallet": "/wallet",
      "/deposit": "/deposit",
      "/withdraw": "/withdraw",
      "/dashboard/transactions": {
        main: "Transaction",
        sub: "Wallet Transactions",
      },
      "/dashboard/services-transactions": {
        main: "Transaction",
        sub: "Services Transactions",
      },
      "/airtime": { main: "Services", sub: "Airtime" },
      "/internet": { main: "Services", sub: "Internet" },
      "/electricity-bill": { main: "Services", sub: "Electricity" },
      "/tv-sbscription": { main: "Services", sub: "TV Subscription" },
      "/settings": "Settings",
    };

    const setActiveState = (main: string, sub?: string) => {
      setIsActive(main);
      if (sub) setIsSubActive(sub);
    };

    for (const [path, value] of Object.entries(pathMap)) {
      if (location.pathname.includes(path)) {
        if (typeof value === "string") {
          setActiveState(value);
        } else {
          setActiveState(value.main, value.sub);
        }
        break;
      }
    }
  }, [location]);
  return (
    <Box mt="30px">
      <Text fontSize={"12px"} textAlign={isOpen ? "left" : "center"}>
        OTHERS
      </Text>
      {isActive === "Settings" && (
        <Image position={"absolute"} left={"0px"} mt="8px" src={activeIcon} />
      )}
      {isOpen ? (
        <Flex
          p={"12px"}
          align={"center"}
          justify={isOpen ? "left" : "center"}
          gap="15px"
          cursor={"pointer"}
          borderRadius={"8px"}
          my="10px"
          _hover={{ bg: menuBG }}
          color={isActive === "Settings" && color}
          justifyContent={isOpen ? "left" : "center"}
          bg={isActive === "Settings" && menuBG}
          onClick={() => {
            sessionStorage.setItem("isActive", "Settings");
            setIsActive("Settings");
            sessionStorage.removeItem("isSubActive");
            navigate("/dashboard/settings");
          }}
        >
          <SettingsIcon color={isActive === "Settings" && color} />
          {isOpen && <Text>Settings</Text>}
        </Flex>
      ) : (
        <Tooltip label="Settings" placement="right" borderRadius={"6px"}>
          <Flex
            p={"12px"}
            align={"center"}
            justify={isOpen ? "left" : "center"}
            gap="15px"
            cursor={"pointer"}
            borderRadius={"8px"}
            my="10px"
            _hover={{ bg: menuBG }}
            color={isActive === "Settings" && color}
            justifyContent={isOpen ? "left" : "center"}
            bg={isActive === "Settings" && menuBG}
            onClick={() => {
              sessionStorage.setItem("isActive", "Settings");
              sessionStorage.removeItem("isSubActive");
              setIsActive("Settings");
              navigate("/dashboard/settings");
            }}
          >
            <SettingsIcon color={isActive === "Settings" && color} />
            {isOpen && <Text>Settings</Text>}
          </Flex>
        </Tooltip>
      )}
      {false && <Image position={"absolute"} left={"0px"} src={helpCenter} />}
      <Flex
        p={"12px"}
        align={"center"}
        justify={isOpen ? "left" : "center"}
        fontWeight={500}
        gap="15px"
        cursor={"pointer"}
        borderRadius={"8px"}
        my="10px"
        _hover={{ bg: menuBG }}
        onClick={() => navigate("/dashboard/help-center")}
      >
        {/* <Image src={helpCenter} /> */}
        <HelpIcon />
        {isOpen && <Text>Help Center</Text>}
      </Flex>

      {false && (
        <Image position={"absolute"} left={"0px"} src="activeMenuIcon.svg" />
      )}
      <Flex
        p={"12px"}
        align={"center"}
        justify={isOpen ? "left" : "center"}
        fontWeight={500}
        gap="15px"
        cursor={"pointer"}
        borderRadius={"8px"}
        my="10px"
        _hover={{ bg: menuBG }}
        onClick={() => onOpen()}
      >
        <ExitIcon />
        {isOpen && <Text>Logout</Text>}
      </Flex>
      <CustomModal
        isOpen={isModalOpen}
        onClose={onClose}
        headerText="Confirm Logout"
        onSubmit={() => {
          onClose();
          removeToken();
        }}
        footerText="Yes, Logout"
      >
        <Flex
          justify={"center"}
          align={"center"}
          direction={"column"}
          gap="24px"
          mb="32px"
          mt={"32px"}
        >
          <Image src={verifiedIcon} w={"56px"} h={"56px"} />
          <Heading
            fontFamily={"Audiowide"}
            fontSize={["15px", "18px", "21px", "24px"]}
          >
            Logout
          </Heading>
          <Text
            textAlign={"center"}
            fontSize={["9px", "11px", "13px", "14px"]}
            fontWeight={"400"}
            lineHeight={"20px"}
            letterSpacing={"0.035px"}
            color={paraColor2}
          >
            Are you sure you want to end this session?
          </Text>
        </Flex>
      </CustomModal>
    </Box>
  );
};

export default SidebarOtherMenu;
