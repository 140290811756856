import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import profileBg from "../assets/profileBg.svg";
import profileBgDark from "../assets/profileBgDark.svg";
import camera from "../assets/camera.svg";
import UserInfoInput from "./UserInfoInput";
import bgPic from "../assets/headerBg.svg";
import PrimaryButton from "./PrimaryButton";
import UpgradeAccount from "./UpgradeAccount";
import userLight from "../assets/icons/light/userIcon.svg";
import emailLight from "../assets/icons/light/emailIcon.svg";
import userDark from "../assets/icons/dark/user.svg";
import emailDark from "../assets/icons/dark/email.svg";
import emailActive from "../assets/icons/light/emailActive.svg";
import userActive from "../assets/icons/light/userActive.svg";
import phone from "../assets/phone.svg";
import phoneDark from "../assets/phoneDark.svg";
import phoneActive from "../assets/phoneActive.svg";
import { useUploadMedia } from "../utils/media.api";
import { useGetLoggedInUser, useUpdateProfile } from "../utils/auth.api";
import CustomModal from "./CustomModal";

const EditProfile = ({ setSelected, data, isLoading }: any) => {
  const [isUpgrade, setIsUpgrade] = useState(false);
  const uploadMediaMutation = useUploadMedia();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const paraColor1 = useColorModeValue("#5A5A5A", "#C7C7C7");
  const borderColor = useColorModeValue("#EEE", "#333");
  const profileBgImg = useColorModeValue(profileBg, profileBgDark);
  const personIcon = useColorModeValue(userLight, userDark);
  const personActiveIcon = useColorModeValue(userActive, userDark);
  const emailIcon = useColorModeValue(emailLight, emailDark);
  const emailActiveIcon = useColorModeValue(emailActive, emailDark);
  const phoneIcon = useColorModeValue(phone, phoneDark);
  const phoneActiveIcon = useColorModeValue(phoneActive, phoneDark);
  const inputFileRef: any = useRef<HTMLInputElement | null>(null);
  const paraColor2 = useColorModeValue("#5A5A5A", "#EEE");

  // const { data: data, isLoading } = useGetLoggedInUser();
  const updateProfileMutation = useUpdateProfile();
  const { data: user } = useGetLoggedInUser();
  const [formData, setFormData] = useState({
    imageUrl: "",
    fullName: "",
    email: "",
    phone: "",
  });
  const toast = useToast();

  const handleImageChange = async (event: any) => {
    const file = event.target.files[0];
    const formDataImg: any = new FormData();
    formDataImg.append("file", file);
    const img = await uploadMediaMutation.mutateAsync(formDataImg);
    setFormData({ ...formData, imageUrl: img });
  };

  const onImageClick = () => {
    inputFileRef.current.click();
  };

  useEffect(() => {
    if (data) {
      setFormData({
        imageUrl: data?.image,
        fullName: data?.fullname,
        email: data?.email,
        phone: data?.phone,
      });
    }
  }, [data]);
  // console.log(data?.phone);

  return (
    <div>
      {isUpgrade ? (
        <UpgradeAccount />
      ) : (
        <>
          {isLoading ? (
            <Flex w={"100%"} justify={"center"}>
              <Spinner />
            </Flex>
          ) : (
            <Box>
              <Box
                h={"160px"}
                borderRadius={"16px 16px 16px 16px"}
                background={`url(${profileBgImg}), lightgray 50% / cover no-repeat`}
                backgroundSize={"cover"}
                backgroundPosition={"center"}
              ></Box>
              <Flex
                direction={"column"}
                justify={"center"}
                align={"center"}
                gap={"24px"}
                alignSelf={"stretch"}
                mt={"-90px"}
              >
                <Avatar
                  src={formData?.imageUrl ? formData?.imageUrl : user?.image}
                  w={"160px"}
                  h={"160px"}
                />
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                  ref={inputFileRef}
                />
                {/* Trigger file input click when the camera icon is clicked */}
                {uploadMediaMutation?.isLoading ? (
                  <Box
                    w="32px"
                    h={"32px"}
                    mt={"-12%"}
                    mr={"-12%"}
                    position={"relative"}
                  >
                    <Spinner size="sm" color="#00FFE0" />
                  </Box>
                ) : (
                  <Image
                    src={camera}
                    w="32px"
                    h={"32px"}
                    mt={"-12%"}
                    mr={"-12%"}
                    cursor={"pointer"}
                    onClick={onImageClick}
                    position={"relative"}
                    // zIndex={1}
                  />
                )}
                <Text
                  w={"100%"}
                  color={paraColor1}
                  fontSize={"16px"}
                  fontWeight={"700"}
                  lineHeight={"22px"}
                  letterSpacing={"0.08px"}
                  mt={"19px"}
                  mb={"-10px"}
                >
                  Personal Info
                </Text>
                <UserInfoInput
                  icon={personIcon}
                  activeIcon={personActiveIcon}
                  placeholder="Alexander Divid"
                  type="text"
                  border="#00CCB3"
                  value={data?.fullname}
                  readOnly={true}
                  // onChange={(e: any) =>
                  //   setFormData({ ...formData, fullName: e.target.value })
                  // }
                />
                <Flex
                  mt={"-24px"}
                  w={"100%"}
                  p={"24px"}
                  direction={"column"}
                  justify={"center"}
                  align={"center"}
                  gap={"24px"}
                  borderRadius={"16px"}
                  background={`url(${bgPic}) no-repeat, linear-gradient(91deg,#00DBFF 0%, #00FFE0 47.92%)`}
                  backgroundSize={"cover"}
                >
                  <Flex direction={"column"} gap={"16px"}>
                    <Text
                      color={"#00665A"}
                      fontSize={"14px"}
                      fontWeight={"700"}
                      letterSpacing={"0.014px"}
                    >
                      Your Account
                    </Text>
                    <Heading
                      color={"#FFF"}
                      fontSize={["28px", "30px", "32px", "34px"]}
                      fontWeight={"800"}
                      letterSpacing={"0.085px"}
                    >
                      Level {data?.kycTier || 1}
                    </Heading>
                  </Flex>
                  <Text
                    color={"#333"}
                    fontSize={["11px", "12px", "13px", "14px"]}
                    fontWeight={"400"}
                    lineHeight={"20px"}
                    letterSpacing={"0.035px"}
                    textAlign={"center"}
                  >
                    Your account is currently on level {data?.kycTier || 1}{" "}
                    upgrade and get more benefits.
                  </Text>
                  <Button
                    display={"flex"}
                    h={"48px"}
                    p={"16px 32px"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={"10px"}
                    alignSelf={"stretch"}
                    borderRadius={"12px"}
                    bg={"#00332D"}
                    color={"#00FFE0"}
                    fontSize={"16px"}
                    fontWeight={"600"}
                    letterSpacing={"0.032px"}
                    textTransform={"capitalize"}
                    _hover={{ bg: "#00332D" }}
                    onClick={() => {
                      if (data?.kycStatus === "Pending") {
                        onOpen();
                      } else {
                        setIsUpgrade(true);
                      }
                    }}
                    isDisabled={Number(data?.kycTier) === 3}
                  >
                    {Number(data?.kycTier) === 3 ? "Upgraded" : `Upgrade`} To
                    Level{" "}
                    {Number(data?.kycTier) < 3
                      ? (Number(data?.kycTier) || 1) + 1
                      : 3}
                  </Button>
                </Flex>
                <Text
                  paddingTop={"24px"}
                  borderTop={`1px solid ${borderColor}`}
                  w={"100%"}
                  color={paraColor1}
                  fontSize={"16px"}
                  fontWeight={"700"}
                  lineHeight={"22px"}
                  letterSpacing={"0.08px"}
                >
                  Contact Info
                </Text>
                <Flex
                  direction={"column"}
                  gap={"16px"}
                  w={"100%"}
                  mb={"-30px"}
                  mt={"-10px"}
                >
                  <UserInfoInput
                    icon={emailIcon}
                    activeIcon={emailActiveIcon}
                    placeholder="alex6767@email.com"
                    type="email"
                    border="#00CCB3"
                    mb="-10px"
                    value={data?.email}
                    readOnly={true}
                    // onChange={(e: any) =>
                    //   setFormData({ ...formData, email: e.target.value })
                    // }
                  />
                  <UserInfoInput
                    icon={phoneIcon}
                    activeIcon={phoneActiveIcon}
                    placeholder="03000000000"
                    type="text"
                    border="#00CCB3"
                    mb="-10px"
                    value={data?.phone}
                    readOnly={true}
                    // onChange={(e: any) =>
                    //   setFormData({ ...formData, phone: e.target.value })
                    // }
                  />
                </Flex>
                <PrimaryButton
                  text="Save & Update"
                  disabled={
                    updateProfileMutation?.isLoading ||
                    uploadMediaMutation?.isLoading
                  }
                  onClick={async () => {
                    try {
                      const payload = {
                        image: formData?.imageUrl,
                      };
                      await updateProfileMutation.mutateAsync(payload);
                      toast({
                        title: "Profile Updated Successfully",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                      });
                      setFormData({
                        ...formData,
                        imageUrl: "",
                        fullName: "",

                        phone: "",
                      });
                      setSelected("myProfile");
                    } catch (error: any) {
                      toast({
                        title: error.message,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                      });
                    }
                  }}
                />
              </Flex>
              <CustomModal
                isOpen={isOpen}
                onClose={() => {
                  onClose();
                }}
                headerText="KYC Upgrade Pending"
                // noFooter={true}
                footerText="Done"
                noCloseFooter={true}
                onSubmit={() => {
                  // navigate("/dashboard/index");
                  onClose();
                }}
              >
                <Flex
                  // justify={"center"}
                  // align={"center"}
                  // direction={"column"}
                  gap="24px"
                  mb="32px"
                  mt={"32px"}
                >
                  <Text
                    textAlign={"left"}
                    fontSize={["9px", "11px", "13px", "14px"]}
                    fontWeight={"400"}
                    lineHeight={"20px"}
                    letterSpacing={"0.035px"}
                    color={paraColor2}
                  >
                    Your KYC has been submitted and is pending for approval.
                  </Text>
                </Flex>
              </CustomModal>
            </Box>
          )}
        </>
      )}
    </div>
  );
};

export default EditProfile;
