import {
  Box,
  Button,
  Circle,
  Flex,
  Image,
  Input,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import blueBg from "../assets/imgs/swapcardexp/blue-dark.svg";
import blueBgLight from "../assets/imgs/swapcardexp/blue-light.svg";
import greyBg from "../assets/imgs/swapcardexp/grey-dark.svg";
import greyBgLight from "../assets/imgs/swapcardexp/grey-light.svg";
import switchCircleLight from "../assets/imgs/swapcardexp/switch-circle-light.svg";
import switchCircleDark from "../assets/imgs/swapcardexp/switch-circle-dark.svg";
import rectangle from "../assets/imgs/swapcardexp/rectangle.svg";
import sliderThumb from "../assets/sliderThumb.svg";

import nairaSymbol from "../assets/icons/nairaWhite.svg";

import React, { useRef, useState, useEffect } from "react";
import SelectSwapCrypto from "./SelectSwapCrypto";
import { CheckIcon } from "@chakra-ui/icons";
import SwapNowButton from "./SwapNowButton";
// import { useGetWalletWithBalance } from "../utils/wallet.api";

const SwapCardExp = ({
  balances,
  balanceLoading,
  nairaBalance,
  nairaLoading,
  swapRates,
  swapRateLoading,
}: any) => {
  const cryptoRef: any = useRef(null);
  const blueBG = useColorModeValue(blueBgLight, blueBg);
  const greyBG = useColorModeValue(greyBgLight, greyBg);
  const switchCircle = useColorModeValue(switchCircleLight, switchCircleDark);
  const swapBg = useColorModeValue("#F9F9F9", "#121B18");
  const paraColor = useColorModeValue("#5A5A5A", "#C7C7C7");
  const borderColor = useColorModeValue("#EEE", "#333");
  const bgColor = useColorModeValue("#F9F9F9", "#121B18");
  const colorBackground = useColorModeValue("#C7C7C7", "#5A5A5A");
  const colorBackground2 = useColorModeValue("#000", "#5A5A5A");
  const tickColor = useColorModeValue("#EEEEEE", "#5A5A5A");
  const dropdownBg = useColorModeValue("white", "#121B18");
  const viewColor = useColorModeValue("#00332D", "#00FFE0");

  const [isSwapped, setIsSwapped] = useState(false);
  const [isCryptoDropdown, setIsCryptoDropdown] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState(
    balances?.balance?.[0] || null
  );
  const [payAmount, setPayAmount] = useState(0);
  const [receiveAmount, setReceiveAmount] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);
  const [selectedBalnceIndex, setSelectedBalnceIndex] = useState(0);
  const [dropdownHeight, setDropdownHeight] = useState(200);
  const balanceString = selectedCoin?.balance?.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const balanceNumber = parseFloat(balanceString?.replace(/,/g, ""));
  // const getSwapRate: any = (currency: string) => {
  //   let findRate = swapRates?.find(
  //     (rate: any) =>
  //       rate?.coinGeckoId === selectedCoin?.coinGeckoId &&
  //       rate?.currencyName === currency
  //   );

  //   if (findRate) {
  //     return findRate?.current_price_sell;
  //   } else {
  //     return 0;
  //   }
  // };
  const getSwapRate = (currency: string) => {
    let findRate = swapRates?.find(
      (rate: any) =>
        rate?.coinGeckoId === selectedCoin?.coinGeckoId &&
        rate?.currencyName === currency
    );

    if (findRate) {
      if (!isSwapped) {
        return findRate?.current_price_sell;
      } else {
        return findRate?.current_price;
      }
    } else {
      return 0;
    }
  };
  useEffect(() => {
    if (!selectedCoin && balances) {
      setSelectedCoin(balances?.balance?.[0]);
    }
  }, [balances]);

  useEffect(() => {
    if (selectedCoin && balances) {
      setSelectedCoin(balances?.balance?.[selectedBalnceIndex]);
    }
  }, [balances]);
  useEffect(() => {
    setDropdownHeight(cryptoRef?.current?.clientHeight);
  }, [cryptoRef, isCryptoDropdown]);

  useEffect(() => {
    if (isSwapped) {
      setReceiveAmount(Number(Number(payAmount) / getSwapRate("ngn")));
    } else setReceiveAmount(Number(Number(payAmount) * getSwapRate("ngn")));
  }, [payAmount]);
  //   const { data } = useGetWalletWithBalance();

  // console.log("klop", selectedCoin);
  return (
    <>
      <Text
        mb={"16px"}
        color={paraColor}
        fontSize={"16px"}
        fontWeight={"700"}
        lineHeight={"22px"}
        letterSpacing={"0.08px"}
      >
        Swap Crypto
      </Text>
      <Stack w="100%" p="16px" bg={swapBg} borderRadius={"12px"} gap="14px">
        <Image
          src={rectangle}
          mx="auto"
          mb="-24px"
          order={1}
          w="20%"
          h={{ base: "10px", sm: "15px" }}
        />
        <Box
          bgImage={blueBG}
          bgSize={"100% 100%"}
          w="100%"
          h="230px"
          py="30px"
          px="20px"
          order={isSwapped ? 4 : 2}
          color="white"
        >
          <Flex justify={"space-between"}>
            <Text
              fontSize={["9px", "10px", "11px", "12px"]}
              fontWeight={"700"}
              letterSpacing={"0.048px"}
            >
              {isSwapped ? "Receive" : "Pay"} Amount
            </Text>
            {!isSwapped && (
              <Button
                bg={"#FFF"}
                borderRadius={"6px"}
                padding={"8px"}
                _hover={{ bg: "#FFF" }}
                _active={{ bg: "#FFF" }}
                color={"#00332D"}
                fontSize={"10px"}
                fontWeight={"400"}
                lineHeight={"14px"}
                letterSpacing={"0.15px"}
                h={"25px"}
                //   width={"37px"}
                onClick={() => {
                  const value = Number(selectedCoin?.balance);
                  setSliderValue(value);
                  setPayAmount(value);
                }}
              >
                Max Amount
              </Button>
            )}
          </Flex>
          <Flex
            justify={"space-between"}
            align={"center"}
            my={isSwapped ? "30px" : "10px"}
          >
            <Flex gap="5px">
              <Image
                src={selectedCoin?.logoUrl}
                alt="eth-icon"
                borderRadius="50%"
                w={["32px", "36px", "40px", "40px"]}
                h={["32px", "36px", "40px", "40px"]}
              />
              <Box pt={"5px"}>
                <SelectSwapCrypto
                  isMenuOpen={isCryptoDropdown}
                  setIsMenuOpen={setIsCryptoDropdown}
                  selectedCoin={selectedCoin}
                />
                {isCryptoDropdown && (
                  <Flex
                    ref={cryptoRef}
                    minW={{ base: "170px", sm: "200px" }}
                    zIndex="2"
                    pos="relative"
                    flexDir="column"
                    bg={dropdownBg}
                    p="10px"
                    gap="10px"
                    borderRadius="6px"
                    marginTop="5px"
                    w={"fit-content"}
                  >
                    {balances?.balance?.map((bnlc: any, idx: number) => (
                      <Flex
                        cursor="pointer"
                        justify="space-between"
                        align="center"
                        onClick={() => {
                          setSelectedCoin(bnlc);
                          setSelectedBalnceIndex(idx);
                          setIsCryptoDropdown(false);
                          //working value zero
                          setPayAmount(0);
                          setSliderValue(0);
                        }}
                      >
                        <Flex gap="10px" align="center">
                          <Image src={bnlc?.logoUrl} w="16px" />
                          <Flex flexDir="column">
                            <Text
                              fontSize="14px"
                              whiteSpace="nowrap"
                              color={paraColor}
                            >
                              {bnlc?.symbol?.includes("USDT") ||
                              bnlc?.symbol?.includes("USDC")
                                ? bnlc?.symbol?.slice(0, 4)
                                : bnlc?.symbol}
                            </Text>
                            <Text
                              fontSize="10px"
                              whiteSpace="nowrap"
                              color={paraColor}
                            >
                              {Number(bnlc?.balance)?.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 5,
                                  maximumFractionDigits: 5,
                                }
                              ) || 0}
                            </Text>
                          </Flex>
                        </Flex>
                        <CheckIcon
                          color={
                            selectedCoin?.coinId === bnlc?.coinId
                              ? "teal.300"
                              : tickColor
                          }
                        />
                      </Flex>
                    ))}
                  </Flex>
                )}
              </Box>
            </Flex>
            <Flex
              gap="5px"
              align={"center"}
              justify={"end"}
              mt={isCryptoDropdown ? `-${dropdownHeight}px` : "0"}
            >
              {!isSwapped && (
                <Input
                  w="40%"
                  h={["23px", "26px", "29px", "32px"]}
                  size={"sm"}
                  focusBorderColor="transparent"
                  outline="none"
                  placeholder="0.00"
                  color={"white"}
                  _placeholder={{
                    color: "white",
                  }}
                  _hover={{ border: "0.5px solid #FFF" }}
                  _focus={{ border: "0.5px solid #FFF" }}
                  value={payAmount}
                  type="number"
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    let maxValue = 0;
                    maxValue = Number(selectedCoin?.balance) || 0;

                    if (value < 0) {
                      return;
                    }

                    if (value <= maxValue) {
                      setPayAmount(value);
                      setSliderValue(value);
                    } else {
                      setPayAmount(maxValue);
                      setSliderValue(maxValue);
                    }
                  }}
                />
              )}
              <Text
                fontSize={["12px"]}
                letterSpacing={"0px"}
                fontWeight={"700"}
                // lineHeight={"20px"}
                // alignSelf={"end"}
                alignItems={"end"}
                alignSelf={"right"}
                color={"white"}
                //  bg={"yellow"}
                display={isSwapped ? "block" : { base: "none", xl: "block" }}
              >
                {isSwapped ? (
                  <>
                    {Number(
                      Number(payAmount) / getSwapRate("ngn")
                    )?.toLocaleString(undefined, {
                      minimumFractionDigits: 5,
                      maximumFractionDigits: 5,
                    })}{" "}
                  </>
                ) : (
                  Number(Number(payAmount))?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                )}{" "}
                <span style={{ fontSize: "10px", marginLeft: "2px" }}>
                  {selectedCoin?.symbol.slice(0, 4)}
                </span>
              </Text>
            </Flex>
          </Flex>
          <Text
            fontSize={["9px", "10px", "11px", "12px"]}
            fontWeight={"700"}
            letterSpacing={"0.048px"}
            color={"#FFF"}
            mt={isCryptoDropdown ? `-${dropdownHeight}px` : "0px"}
          >
            Balance:{" "}
            {balanceLoading ? (
              <Spinner size="xs" />
            ) : (
              Number(selectedCoin?.balance)?.toLocaleString(undefined, {
                minimumFractionDigits: 5,
                maximumFractionDigits: 5,
              })
            )}
          </Text>
          {!isSwapped && (
            <Box mt={"42px"}>
              <Slider
                aria-label="slider-ex-6"
                onChange={(val) => {
                  setSliderValue(val);
                  setPayAmount(Number(val));
                  // setInputValue(val);
                  //setAmountSelected(val);
                }}
                colorScheme="green"
                min={0}
                max={Number(selectedCoin?.balance)}
                value={sliderValue}
                focusThumbOnChange={false}
                step={Number(selectedCoin?.balance) > 3 ? 1 : 0.00005}
              >
                <SliderMark
                  value={0}
                  ml={"-1px"}
                  mt={"10px"}
                  color={"#C7C7C7"}
                  fontSize={"12px"}
                  fontWeight={"700"}
                  letterSpacing={"0.048px"}
                >
                  0
                </SliderMark>

                <SliderMark
                  value={balanceNumber}
                  mt={"10px"}
                  ml={
                    Number(selectedCoin?.balance) > 100000 ? "-80px" : "-60px"
                  }
                  // ml={
                  //   selectedCoin === balances?.balance?.[0] && !isSwapped
                  //     ? "80%"
                  //     : "-40px"
                  // }
                  // ml={
                  //   Number(
                  //     Number(selectedCoin?.balance)?.toLocaleString(undefined, {
                  //       minimumFractionDigits: 5,
                  //       maximumFractionDigits: 5,
                  //     })
                  //   ) < 999
                  //     ? "80%"
                  //     : "40%"
                  // }
                  color={"#C7C7C7"}
                  fontSize={"12px"}
                  fontWeight={"700"}
                  letterSpacing={"0.048px"}
                >
                  {Number(selectedCoin?.balance || 0)?.toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 5,
                      maximumFractionDigits: 5,
                    }
                  ) || 0}
                </SliderMark>

                <SliderMark value={sliderValue} textAlign="center" mt={"-20px"}>
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "-50px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        textAlign: "center",
                      }}
                    >
                      <div
                        style={{
                          background: "#00332D",
                          borderRadius: "20px",
                          color: "#00FFE0",
                          padding: "4px 12px",
                          fontSize: "12px",
                          fontWeight: "700",
                          letterSpacing: "0.048px",
                          position: "relative",
                        }}
                      >
                        {Number(sliderValue || 0)?.toLocaleString()}
                        <div
                          style={{
                            position: "absolute",
                            width: 0,
                            height: 0,
                            borderTop: "10px solid #00332D",
                            borderLeft: "5px solid transparent",
                            borderRight: "5px solid transparent",
                            top: "100%",
                            left: "50%",
                            transform: "translateX(-50%)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </SliderMark>

                <SliderTrack>
                  <SliderTrack
                    //  bg={sliderTrack}
                    bg={"#A5B8FF"}
                  />
                  <SliderFilledTrack bg={"#FFF"} />
                </SliderTrack>

                <SliderThumb
                  display={"flex"}
                  w={"24px"}
                  h="24px"
                  p={"4px"}
                  alignItems={"flex-start"}
                  gap={"2.667px"}
                  borderRadius={"32px"}
                  border={"1.333px solid #000"}
                  bg={"#FFF"}
                  zIndex={0}
                >
                  <Image src={sliderThumb} alt="thumb" w={"16px"} h={"16px"} />
                </SliderThumb>
              </Slider>
            </Box>
          )}
        </Box>
        <Circle
          size={"80px"}
          bg={useColorModeValue("white", "black")}
          mx="auto"
          position={"relative"}
          zIndex={1}
          mt="-47px"
          mb="-47px"
          order={3}
          cursor={"pointer"}
          onClick={() => {
            setIsSwapped(!isSwapped);
            setSliderValue(0);
            setPayAmount(0);
          }}
        >
          <Image src={switchCircle} />
        </Circle>
        <Box
          bgImage={greyBG}
          bgSize={"100% 100%"}
          w="100%"
          h="230px"
          py="30px"
          px="20px"
          order={isSwapped ? 2 : 4}
          color={paraColor}
        >
          <Naira
            data={nairaBalance}
            isSwapped={isSwapped}
            swapRates={swapRates}
            selectedCoin={selectedCoin}
            {...{
              sliderValue,
              setSliderValue,
              payAmount,
              setPayAmount,
              setReceiveAmount,
              getSwapRate,
            }}
          />
        </Box>
        <Image
          src={rectangle}
          mx="auto"
          mt="-24px"
          order={5}
          w="20%"
          h={{ base: "10px", sm: "15px" }}
        />
        <Box order={6}>
          <Flex my={"24px"} justify={"space-between"} align={"center"}>
            <Text
              color={paraColor}
              fontSize={["11px", "12px", "14px", "16px"]}
              fontWeight={"400"}
              lineHeight={"22px"}
              letterSpacing={"0.024px"}
            >
              Exchange Rate
            </Text>
            <Text
              color={viewColor}
              fontSize={["11px", "12px", "14px", "16px"]}
              fontWeight={"700"}
              lineHeight={"22px"}
              letterSpacing={"0.08px"}
            >
              1
              {balanceLoading ? (
                <Spinner size="sm" />
              ) : selectedCoin?.symbol?.includes("USDT") ||
                selectedCoin?.symbol?.includes("USDC") ? (
                selectedCoin?.symbol?.slice(0, 4)
              ) : (
                selectedCoin?.name
              )}{" "}
              ={" "}
              {swapRateLoading ? (
                <Spinner size="sm" />
              ) : (
                Number(getSwapRate("ngn"))?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              )}
              NGN
            </Text>
          </Flex>
          <SwapNowButton
            selectedCoin={selectedCoin}
            toPay={isSwapped ? "" : "crypto"}
            nairaBalance={nairaBalance}
            payAmount={payAmount}
            setPayAmount={setPayAmount}
            receiveAmount={receiveAmount}
            setReceiveAmount={setReceiveAmount}
            swapRateLoading={swapRateLoading}
            nairaLoading={nairaLoading}
            balanceLoading={balanceLoading}
            setSliderValue={setSliderValue}
          />
        </Box>
      </Stack>
    </>
  );
};

export default SwapCardExp;

const Naira = ({
  data,
  isSwapped,
  swapRates,
  selectedCoin,
  payAmount,
  setPayAmount,
  sliderValue,
  setSliderValue,
  getSwapRate,
}: any) => {
  const [isCryptoDropdown, setIsCryptoDropdown] = useState(false);
  const paraColor3 = useColorModeValue("#5A5A5A", "#EEE");
  //   const [payAmount, setPayAmount] = useState(0);
  const paraColor4 = useColorModeValue("#909090", "#909090");
  // const getSwapRate: any = (currency: string) => {
  //   let findRate = swapRates?.find(
  //     (rate: any) =>
  //       rate?.coinGeckoId === selectedCoin?.coinGeckoId &&
  //       rate?.currencyName === currency
  //   );

  //   if (findRate) {
  //     return findRate?.current_price_sell;
  //   } else {
  //     return 0;
  //   }
  // };
  // useEffect(() => {
  //   if (!isSwapped) {
  //     setReceiveAmount(Number(Number(payAmount) / getSwapRate("ngn")));
  //   }
  //   if (isSwapped) {

  //   }
  // }, [payAmount]);
  return (
    <Stack gap={isSwapped ? "10px" : "30px"}>
      <Flex justify={"space-between"}>
        <Text
          fontSize={["9px", "10px", "11px", "12px"]}
          fontWeight={"700"}
          letterSpacing={"0.048px"}
        >
          {isSwapped ? "Pay" : "Receive"} Amount
        </Text>
        {isSwapped && (
          <Button
            bg={"#FFF"}
            borderRadius={"6px"}
            padding={"8px"}
            _hover={{ bg: "#FFF" }}
            _active={{ bg: "#FFF" }}
            color={"#00332D"}
            fontSize={"10px"}
            fontWeight={"400"}
            lineHeight={"14px"}
            letterSpacing={"0.15px"}
            h={"25px"}
            //   width={"37px"}
            onClick={() => {
              const value = Number(data?.balance);
              setSliderValue(value);
              setPayAmount(value);
            }}
          >
            Max Amount
          </Button>
        )}
      </Flex>
      <Flex justify={"space-between"} align={"center"}>
        <Flex align={"center"} gap="12px">
          <Circle bg={useColorModeValue("black", "#5A5A5A")} size="32px">
            <Image src={nairaSymbol} w="15px" />
          </Circle>
          <SelectSwapCrypto
            isMenuOpen={isCryptoDropdown}
            setIsMenuOpen={setIsCryptoDropdown}
            selectedCoin={{
              symbol: "Naira",
            }}
          />
        </Flex>
        <Flex gap="20px" align="end">
          <Flex gap="5px" align={{ base: "center" }} justify={"end"}>
            {isSwapped && (
              <Input
                w="48%"
                ml={isCryptoDropdown ? "-10%" : "0"}
                h={["23px", "26px", "29px", "32px"]}
                size={"sm"}
                focusBorderColor="transparent"
                border="0.5px solid grey"
                outline="none"
                placeholder="0.00"
                color={paraColor4}
                _placeholder={{
                  color: paraColor4,
                }}
                _hover={{ border: "0.5px solid grey" }}
                _focus={{ border: "0.5px solid grey" }}
                value={payAmount}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  let maxValue = 0;
                  maxValue = Number(data?.balance) || 0;

                  if (value <= maxValue) {
                    setPayAmount(value);
                    setSliderValue(value);
                  } else {
                    setPayAmount(maxValue);
                    setSliderValue(maxValue);
                  }
                }}
              />
            )}
            <Text
              ml={"10px"}
              // mt={"-25px"}
              fontSize={["12px"]}
              letterSpacing={"0px"}
              fontWeight={"700"}
              // lineHeight={"20px"}
              // alignSelf={"end"}
              alignItems={"end"}
              alignSelf={"right"}
              color={paraColor4}
              //  bg={"yellow"}
              display={!isSwapped ? "block" : { base: "none", xl: "block" }}
            >
              {!isSwapped ? (
                <Text as={"span"}>
                  {/* {setReceiveAmount(Number(payAmount) * getSwapRate("ngn"))} */}
                  {Number(
                    Number(payAmount) * getSwapRate("ngn")
                  )?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Text>
              ) : (
                Number(Number(payAmount))?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              )}{" "}
              <span style={{ fontSize: "10px", marginLeft: "2px" }}>
                {"NGN"}
              </span>
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Text
        fontSize={["9px", "10px", "11px", "12px"]}
        fontWeight={"700"}
        letterSpacing={"0.048px"}
        color={paraColor3}
      >
        Balance:{" "}
        {Number(data?.balance)?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </Text>
      {isSwapped && (
        <Slider
          aria-label="slider-ex-6"
          onChange={(val) => {
            setSliderValue(val);
            setPayAmount(Number(val));
            // setInputValue(val);
            //setAmountSelected(val);
          }}
          colorScheme="green"
          mt={"32px"}
          mb={"16px"}
          min={0}
          max={Number(data?.balance)}
          value={sliderValue}
          focusThumbOnChange={false}
        >
          <SliderMark
            value={0}
            ml={"-1px"}
            mt={"10px"}
            color={"#C7C7C7"}
            fontSize={"12px"}
            fontWeight={"700"}
            letterSpacing={"0.048px"}
          >
            0
          </SliderMark>

          <SliderMark
            value={Number(data?.balance)}
            mt={"10px"}
            ml={
              Number(
                Number(selectedCoin?.balance)?.toLocaleString(undefined, {
                  minimumFractionDigits: 5,
                  maximumFractionDigits: 5,
                })
              ) < 999
                ? "-4.4em"
                : "-7em"
            }
            color={"#C7C7C7"}
            fontSize={"12px"}
            fontWeight={"700"}
            letterSpacing={"0.048px"}
          >
            {Number(data?.balance)?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </SliderMark>

          <SliderMark value={sliderValue} textAlign="center" mt={"-20px"}>
            <div
              style={{
                position: "relative",
                display: "inline-block",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-50px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    background: "#00332D",
                    borderRadius: "20px",
                    color: "#00FFE0",
                    padding: "4px 12px",
                    fontSize: "12px",
                    fontWeight: "700",
                    letterSpacing: "0.048px",
                    position: "relative",
                  }}
                >
                  {Number(sliderValue || 0)?.toLocaleString()}

                  <div
                    style={{
                      position: "absolute",
                      width: 0,
                      height: 0,
                      borderTop: "10px solid #00332D",
                      borderLeft: "5px solid transparent",
                      borderRight: "5px solid transparent",
                      top: "100%",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </SliderMark>

          <SliderTrack>
            <SliderTrack
              //  bg={sliderTrack}
              bg={"#A5B8FF"}
            />
            <SliderFilledTrack bg={"#FFF"} />
          </SliderTrack>

          <SliderThumb
            display={"flex"}
            w={"24px"}
            h="24px"
            p={"4px"}
            alignItems={"flex-start"}
            gap={"2.667px"}
            borderRadius={"32px"}
            border={"1.333px solid #000"}
            bg={"#FFF"}
            zIndex={0}
          >
            <Image src={sliderThumb} alt="thumb" w={"16px"} h={"16px"} />
          </SliderThumb>
        </Slider>
      )}
    </Stack>
  );
};
