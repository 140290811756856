import React, { useState } from "react";
import AuthLayout from "../layouts/AuthLayout";
import {
  Box,
  Checkbox,
  Image,
  Input,
  Link,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import theme from "../theme/theme";
import AuthCardHeader from "../components/AuthCardHeader";
import UserInfoInput from "../components/UserInfoInput";
import PrimaryButton from "../components/PrimaryButton";
import { useNavigate } from "react-router-dom";
import userLight from "../assets/icons/light/userIcon.svg";
import emailLight from "../assets/icons/light/emailIcon.svg";
import lockLight from "../assets/icons/light/lockIcon.svg";
import eyeLight from "../assets/icons/light/eyeIcon.svg";
import shutEyeLight from "../assets/icons/light/shutEyeIcon.svg";
import userDark from "../assets/icons/dark/user.svg";
import emailDark from "../assets/icons/dark/email.svg";
import lockDark from "../assets/icons/dark/lock.svg";
import eyeDark from "../assets/icons/dark/eye.svg";
import shutEyeDark from "../assets/icons/dark/shutEye.svg";
import { useResetPassword } from "../utils/auth.api";
import arrow1 from "../assets/backArrow.svg";
import arrow2 from "../assets/backArrowDark.svg";

const AddNewPassword = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const resetPasswordMutation = useResetPassword();
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const { name, email, password, confirmPassword } = formData;

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const linkColor = useColorModeValue(
    theme.colors.light.link.primary,
    theme.colors.dark.link.primary
  );
  const color = useColorModeValue(
    theme.colors.light.text.primary,
    theme.colors.dark.text.primary
  );
  const checkBoxScheme = useColorModeValue("gray", "teal");
  const userIcon = useColorModeValue(userLight, userDark);
  const emailIcon = useColorModeValue(emailLight, emailDark);
  const lockIcon = useColorModeValue(lockLight, lockDark);
  const eyeIcon = useColorModeValue(eyeLight, eyeDark);
  const shutEyeIcon = useColorModeValue(shutEyeLight, shutEyeDark);
  const arrowIcon = useColorModeValue(arrow1, arrow2);

  return (
    <AuthLayout>
      <Image
        src={arrowIcon}
        alt="arrow"
        transform={"rotate(270deg)"}
        onClick={() => navigate("/auth/forgot-password")}
        cursor={"pointer"}
        mb={"40px"}
      />
      <AuthCardHeader
        title="Add New Password"
        des="Enter a strong password of minimum 8 characters including numbers."
      />
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          try {
            await resetPasswordMutation.mutateAsync({
              password: password,
              confirmPassword: confirmPassword,
            });
            navigate("/auth/password-updated");
            toast({
              title: "Password updated.",
              status: "success",
            });
          } catch (error: any) {
            toast({
              title: error.message,
              status: "error",
            });
          }
        }}
      >
        <UserInfoInput
          type={show ? "text" : "password"}
          placeholder="Add new password"
          icon={lockIcon}
          rightIcon={!show ? eyeIcon : shutEyeIcon}
          setShow={setShow}
          show={show}
          name="password"
          value={password}
          onChange={(e: any) => handleChange(e)}
        />
        <UserInfoInput
          type={show ? "text" : "password"}
          placeholder="Confirm Password"
          icon={lockIcon}
          rightIcon={!show ? eyeIcon : shutEyeIcon}
          setShow={setShow}
          show={show}
          name="confirmPassword"
          value={confirmPassword}
          onChange={(e: any) => handleChange(e)}
          border={password !== confirmPassword && confirmPassword && "red"}
        />
        {password !== confirmPassword && confirmPassword && (
          <Text color={"red"}>Password didn't match with the first one.</Text>
        )}
        <Box minH="100px" />
        <PrimaryButton type="submit" text="Update Password" />
      </form>
    </AuthLayout>
  );
};

export default AddNewPassword;
