import {
  Box,
  Divider,
  Flex,
  Heading,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import theme from "../theme/theme";
import Logo from "../assets/Logo.svg";
import LogoDark from "../assets/LogoDark.svg";
import SidebarMainMenu from "./SidebarMainMenu";
import SidebarOtherMenu from "./SidebarOtherMenu";
import { HamburgerIcon } from "@chakra-ui/icons";
import Hamburger from "hamburger-react";
import { useLocation } from "react-router-dom";

const SideBar = ({ isOpen, setIsOpen }: any) => {
  const [isActive, setIsActive] = useState(() => {
    const storedValue = sessionStorage.getItem("isActive");
    return storedValue ? storedValue : "/index";
  });

  const [isSubActive, setIsSubActive] = useState(
    sessionStorage.getItem("isSubActive") || "/index"
  );
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const [width, setWidth] = useState<string>(
    screenWidth < 480
      ? "60px"
      : screenWidth < 768 && screenWidth > 479
      ? "110px"
      : "270px"
  );

  const sidebarW = sessionStorage.getItem("sidebarW");

  const bg = useColorModeValue("#F9F9F9", "#1f1f1f");

  const headerImage = useColorModeValue(Logo, LogoDark);
  const color = useColorModeValue("#00332D", theme.colors.dark.text.primary);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  const location = useLocation();

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (screenWidth < 768 && screenWidth > 479) {
      setIsOpen(false);
      sessionStorage.setItem("isOpen", "0");
    }
    if (screenWidth > 479 && isOpen) {
      setWidth("270px");
      sessionStorage.setItem("sidebarW", "270px");
    } else if (screenWidth > 479 && !isOpen) {
      setWidth("110px");
      sessionStorage.setItem("sidebarW", "110px");
    } else if (screenWidth < 480 && isOpen) {
      setWidth("270px");
      sessionStorage.setItem("sidebarW", "270px");
    } else if (screenWidth < 480 && !isOpen) {
      setWidth("60px");
      sessionStorage.setItem("sidebarW", "60px");
    }
  }, [screenWidth, isOpen]);

  useEffect (()=>{
    if (screenWidth<479) {
      setIsOpen(false);
        localStorage.setItem("isOpen", "0");
    }
  }, [location.pathname, isActive])

  return (
    <Box
      bg={bg}
      h="100vh"
      position={"fixed"}
      w={sidebarW || width || "270px"}
      transition={"width 0.3s ease"}
      p={["36px 4px 40px", "36px 4px 40px", "56px 24px 40px"]}
      zIndex={2}
      overflowY={"auto"}
      css={{
        "&::-webkit-scrollbar": {
          width: "2px",
        },
        "&::-webkit-scrollbar-track": {
          width: "4px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "teal",
          borderRadius: "24px",
        },
      }}
    >
      <Box
        display={{ sm: "none" }}
        onClick={() => {
          setIsOpen(!isOpen);
          if (isOpen) {
            localStorage.setItem("isOpen", "0");
          } else localStorage.setItem("isOpen", "1");
        }}
      >
        <Hamburger toggled={isOpen} size={20} />
      </Box>
      <Flex justify={"center"} align={"center"} gap={"15px"}>
        <Image
          display={!isOpen && screenWidth < 480 ? "none" : "block"}
          h={["36px", "36px", "56px"]}
          src={headerImage}
        />

        {isOpen && (
          <Heading
            color={color}
            fontSize={"24px"}
            fontFamily={"Audiowide"}
            fontWeight={400}
          >
            payremit
          </Heading>
        )}
      </Flex>
      <Divider my="40px" />
      <Text fontSize={"12px"} textAlign={isOpen ? "left" : "center"}>
        MENU
      </Text>
      <Flex direction={"column"} justify={"space-between"} h="80%">
        <SidebarMainMenu
          {...{
            isOpen,
            setIsOpen,
            isActive,
            setIsActive,
            isSubActive,
            setIsSubActive,
          }}
        />
        <SidebarOtherMenu isOpen={isOpen} setIsOpen={setIsOpen} />
      </Flex>
    </Box>
  );
};

export default SideBar;
