import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "./api-client";
import { clientPasswordReset } from "./resetPassword-client";

export const useLogin = () => {
  const queryClient = useQueryClient();
  const login = async (loginData) => {
    const response = await client("auth/login", {
      method: "POST",
      data: loginData,
    });

    if (response.error) {
      throw new Error(response.error);
    }

    if (response?.token) {
      if (response?.user?.is2FAEnabled) {
        return {
          is2FAEnabled: response?.user?.is2FAEnabled,
          token: response?.token?.access_token,
        };
      } else {
        localStorage.setItem("token", response.token.access_token);
        queryClient.invalidateQueries(["token"]);
        return { is2FAEnabled: response?.user?.is2FAEnabled };
      }
    } else {
      throw new Error("Unexpected response from the server");
    }
  };

  return useMutation(login);
};

export const useVerifyLogin2FA = () => {
  const queryClient = useQueryClient();

  const verifyLogin2FA = async ({ payload, token }) => {
    const response = await client(`auth/enable2FA/${payload}`, {
      method: "POST",
      data: { access_token: token },
    });

    if (response.error) {
      throw new Error(response.error);
    }
    if (response?.isValid) {
      localStorage.setItem("token", token);
      queryClient.invalidateQueries(["token"]);
      queryClient.invalidateQueries(["getLoggedInUser"]);
      return response;
    } else {
      return response;
    }
  };

  const mutation = useMutation(verifyLogin2FA, {
    onSuccess: () => {
      // Optionally, you can invalidate queries here
      queryClient.invalidateQueries(["enable2FA"]);
    },
  });

  return mutation;
};

export const useSignup = () => {
  const signup = async (signupData) => {
    const response = await client("auth/signup", {
      method: "POST",
      data: signupData,
    });

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(signup);
};

export const useVerifyEmail = () => {
  const queryClient = useQueryClient();
  const verifyEmail = async (payload) => {
    const response = await client("auth/verifyEmail", {
      method: "POST",
      data: payload,
    });

    if (response.error) {
      throw new Error(response.error);
    }
    if (response?.token) {
      localStorage.setItem("token", response.token.access_token);
      queryClient.invalidateQueries("token");
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(verifyEmail);
};

export const useResendOtp = () => {
  const resendOtp = async (payload) => {
    const response = await client("auth/resendOtp", {
      method: "POST",
      data: payload,
    });

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(resendOtp);
};

export const useForgotPassword = () => {
  const forgotPassword = async (payload) => {
    const response = await client("auth/forgotPassword", {
      method: "POST",
      data: payload,
    });

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(forgotPassword);
};

export const useVerifyOtpForForgotPassword = () => {
  const queryClient = useQueryClient();
  const verifyOtpForForgotPassword = async (payload) => {
    const response = await client("auth/verifyOtpForForgotPassword", {
      method: "POST",
      data: payload,
    });

    if (response.error) {
      throw new Error(response.error);
    }
    if (response?.token) {
      localStorage.setItem("resetPasswordToken", response.token.access_token);
      queryClient.invalidateQueries("token");
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(verifyOtpForForgotPassword);
};

export const useResetPassword = () => {
  const resetPassword = async (payload) => {
    const response = await clientPasswordReset("auth/resetPassword", {
      method: "POST",
      data: payload,
    });

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      localStorage.removeItem("resetPasswordToken");
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(resetPassword);
};

export const useChangePassword = () => {
  const changePassword = async (payload) => {
    const response = await client("auth/changePassword", {
      method: "POST",
      data: payload,
    });

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(changePassword);
};

export const useAddBankAccount = () => {
  const queryClient = useQueryClient();
  const addBankAccount = async (payload) => {
    const response = await client("auth/addBankAccount", {
      method: "POST",
      data: payload,
    });

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      queryClient.invalidateQueries("getBankAccounts");
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(addBankAccount);
};

export const useEnable2FA = () =>
  useQuery(
    ["enable2FA"],
    async () => {
      const response = await client(`auth/enable2FA`);
      return response;
    },
    {
      enabled: false,
    }
  );

export const useVerify2FaOtp = () => {
  const queryClient = useQueryClient();

  const enable2FaOtp = async (payload) => {
    const response = await client(`auth/enable2FA/${payload}`, {
      method: "POST",
    });

    if (response.error) {
      throw new Error(response.error);
    }
    queryClient.invalidateQueries(["getLoggedInUser"]);
    return response;
  };

  const mutation = useMutation(enable2FaOtp, {
    onSuccess: () => {
      // Optionally, you can invalidate queries here
      queryClient.invalidateQueries(["enable2FA"]);
    },
  });

  return mutation;
};

export const useGetLoggedInUser = () =>
  useQuery(["getLoggedInUser"], async () => {
    const response = await client(`auth/getLoggedInUser`);
    return response;
  });

export const useFetchLoggedInUser = () => {
  const fetchLoggedIn = async () => {
    const response = await client("auth/getLoggedInUser", {
      method: "GET",
    });

    if (response.error) {
      throw new Error(response.error);
    }
    return response;
  };
  return useMutation(fetchLoggedIn);
};

export const useUpdateProfile = () => {
  const queryClient = useQueryClient();
  const updateProfile = async (payload) => {
    const response = await client("auth/updateProfile", {
      method: "POST",
      data: payload,
    });

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      queryClient.invalidateQueries(["getLoggedInUser"]);
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(updateProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries("getLoggedInUser");
    },
  });
};

export const useAuthorizeTransaction = () => {
  const queryClient = useQueryClient();
  const authorizeTransaction = async () => {
    const response = await client("auth/authorizeTransaction", {
      method: "POST",
    });

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(authorizeTransaction, {
    onSuccess: () => {
      queryClient.invalidateQueries("getLoggedInUser");
    },
  });
};

export const useUpgradeCustomerToKYCT2 = () => {
  const queryClient = useQueryClient();
  const upgradeCustomerToKYCT2 = async (payload) => {
    const response = await client("auth/upgradeCustomerToKYCT2", {
      method: "POST",
      data: payload,
    });

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      queryClient.invalidateQueries("getLoggedInUser");
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(upgradeCustomerToKYCT2);
};

export const useUpgradeCustomerToKYCT3 = () => {
  const queryClient = useQueryClient();
  const upgradeCustomerToKYCT3 = async (payload) => {
    const response = await client("auth/upgradeCustomerToKYCT3", {
      method: "POST",
      data: payload,
    });

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      queryClient.invalidateQueries("getLoggedInUser");
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(upgradeCustomerToKYCT3);
};

export const useGetBankAccounts = () =>
  useQuery(["getBankAccounts"], async () => {
    const response = await client(`auth/getBankAccounts`);
    return response;
  });

export const useDeleteBankAccount = () => {
  const queryClient = useQueryClient();
  const deleteAccount = async (payload) => {
    const response = await client(`auth/deletAccount?id=${payload}`, {
      method: "POST",
    });

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      queryClient.invalidateQueries("getBankAccounts");
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(deleteAccount);
};

export const useDeleteUserOtp = () =>
  useQuery(
    ["deleteUserOtp"],
    async () => {
      const response = await client(`auth/deleteUserOtp`);
      return response;
    },
    {
      enabled: false,
    }
  );

export const useDeleteUser = () => {
  const deleteUser = async (payload) => {
    const response = await client(`auth/deleteUser`, {
      method: "POST",
      data: payload,
    });

    if (response.error) {
      throw new Error(response.error);
    }
    if (response) {
      return response;
    } else {
      throw new Error("Unexpected response from the server");
    }
  };
  return useMutation(deleteUser);
};

export const useGetNotificationHistory = () =>
  useQuery(["notificationHistory"], async () => {
    const response = await client(`auth/notificationHistory`);
    return response;
  });

export const useAddDeviceToken = () => {
  const addDevice = async (deviceToken) => {
    const response = await client("auth/addDeviceToken", {
      method: "POST",
      data: deviceToken,
    });

    // console.log("response api", response);

    if (response?.error) {
      // Assuming that your server returns an 'error' field when login fails
      throw new Error(response?.error);
    }

    return response;
  };

  return useMutation(addDevice);
};

export const useRemoveDeviceToken = () => {
  const removeDevice = async (deviceToken) => {
    const response = await client("auth/deletDeviceToken", {
      method: "POST",
      data: deviceToken,
    });

    if (response?.error) {
      // Assuming that your server returns an 'error' field when login fails
      throw new Error(response?.error);
    }

    return response;
  };

  return useMutation(removeDevice);
};

export const useMarkAsReadNotifications = () => {
  const queryClient = useQueryClient();
  const markAsRead = async () => {
    const response = await client("auth/markAsReadNotifications", {
      method: "POST",
    });

    if (response?.error) {
      // Assuming that your server returns an 'error' field when login fails
      throw new Error(response?.error);
    }
    queryClient.invalidateQueries("notificationHistory");
    return response;
  };

  return useMutation(markAsRead);
};

export const useGetCountriesList = () => {
  const queryClient = useQueryClient();
  const getCountryList = async (name) => {
    const response = await client(`auth/getAllCountriesBySearch?name=${name}`, {
      method: "GET",
    });

    if (response?.error) {
      // Assuming that your server returns an 'error' field when login fails
      throw new Error(response?.error);
    }
    queryClient.invalidateQueries("notificationHistory");
    return response;
  };

  return useMutation(getCountryList);
};

export const useLogout = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      sessionStorage.removeItem("isActive");
      sessionStorage.removeItem("isSubActive");
      sessionStorage.removeItem("isSubActive");
      localStorage.removeItem("token");
      queryClient.invalidateQueries(["token"]);
    },
  });
};

export const useGetUsersBySearch = (search) => {
  return useQuery(
    ["getUsersBySearch"],
    async () => {
      const response = await client(`auth/getUsersBySearch?search=${search}`);
      if (!search) {
        return [];
      }
      return response;
    },
    {
      enabled: !!search,
    }
  );
};

export const useSendOtpAuthorizeTransaction = () =>
  useQuery(
    ["sendOtpAuthorizeTransaction"],
    async () => {
      const response = await client(`auth/sendOtpAuthorizeTransaction`);
      return response;
    },
    {
      enabled: false,
    }
  );

export const useVerifyOtpAuthorizeTransaction = () => {
  const queryClient = useQueryClient();

  const verifyOtpAuthorizeTransaction = async (payload) => {
    const response = await client(
      `auth/verifyOtpAuthorizeTransaction/${payload}`,
      {
        method: "POST",
      }
    );

    if (response.error) {
      throw new Error(response.error);
    }
    queryClient.invalidateQueries(["getLoggedInUser"]);
    return response;
  };

  const mutation = useMutation(verifyOtpAuthorizeTransaction, {
    onSuccess: () => {
      // Optionally, you can invalidate queries here
      queryClient.invalidateQueries(["sendOtpAuthorizeTransaction"]);
    },
  });

  return mutation;
};
