import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import sliderThumb from "../assets/sliderThumb.svg";
import bitcoinIcon from "../assets/bitcoinInput.svg";
import bitcoinActive from "../assets/icons/light/bitcoinActive.svg";
import UserInfoInput from "./UserInfoInput";

const WithdrawSlider = ({
  withdrawCoin,
  withdrawCoinActive,
  maxAmount,
  setAmountSelected,
  amountSelected,
  minAmount,
}: any) => {
  const [sliderValue, setSliderValue] = useState(0);
  const [inputValue, setInputValue] = useState(0);
  const borderColor = useColorModeValue("#EEE", "#333");
  const paraColor = useColorModeValue("#333", "#C7C7C7");
  const sliderTrack = useColorModeValue("", "#00665A");
  const labelStyles = {
    mt: "2",
    ml: "-4",
    mr: "-12",
    fontSize: "sm",
  };

  // useEffect(() => {
  //   if (amountSelected === 0) {
  //     setInputValue(0);
  //   }
  // }, [amountSelected]);

  useEffect(() => {
    if (Number(maxAmount) <= Number(minAmount)) {
      setSliderValue(0);
      setInputValue(0);
    } else {
      setSliderValue(minAmount);
      setInputValue(minAmount);
    }
  }, [minAmount]);

  return (
    <>
      <Flex
        p={"16px"}
        direction={"column"}
        gap={"16px"}
        alignItems={"flex-start"}
        alignSelf={"stretch"}
        borderRadius={"16px"}
        border={`1px solid ${borderColor}`}
      >
        <Flex
          justify={"space-between"}
          align={"center"}
          alignSelf={"stretch"}
          mb={"16px"}
        >
          <Text
            color={paraColor}
            fontSize={"16px"}
            fontWeight={"400"}
            lineHeight={"22px"}
            letterSpacing={"0.024px"}
          >
            Withdrawal Amount
          </Text>
          <Button
            bg={"#00FFE0"}
            borderRadius={"6px"}
            padding={"8px"}
            _hover={{ bg: "#00FFE0" }}
            _active={{ bg: "#00FFE0" }}
            color={"#00332D"}
            fontSize={"10px"}
            fontWeight={"400"}
            lineHeight={"14px"}
            letterSpacing={"0.15px"}
            h={"25px"}
            onClick={() => {
              setSliderValue(maxAmount);
              setInputValue(maxAmount);
              setAmountSelected(maxAmount);
            }}
          >
            Max Amount
          </Button>
        </Flex>
        <Slider
          aria-label="slider-ex-6"
          onChange={(val) => {
            setSliderValue(val);
            setInputValue(val);
            setAmountSelected(val);
          }}
          colorScheme="green"
          mt={"20px"}
          mb={"16px"}
          min={Number(maxAmount) <= Number(minAmount) ? 0 : minAmount}
          max={maxAmount}
          value={sliderValue}
          focusThumbOnChange={false}
          step={Number(maxAmount) > 3 ? 1 : 0.00005}
        >
          <SliderMark
            value={Number(maxAmount) <= Number(minAmount) ? 0 : minAmount}
            ml={"-1px"}
            mt={"10px"}
            color={"#C7C7C7"}
            fontSize={"12px"}
            fontWeight={"700"}
            letterSpacing={"0.048px"}
          >
            0
          </SliderMark>
          {maxAmount !== 0 && (
            <SliderMark
              value={maxAmount}
              mt={"10px"}
              ml={maxAmount < 10 ? "-1.4em" : "-3em"}
              color={"#C7C7C7"}
              fontSize={"12px"}
              fontWeight={"700"}
              letterSpacing={"0.048px"}
            >
              {Number(maxAmount || 0)?.toLocaleString()}
            </SliderMark>
          )}
          <SliderMark value={sliderValue} textAlign="center" mt={"-20px"}>
            <div
              style={{
                position: "relative",
                display: "inline-block",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-50px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    background: "#00332D",
                    borderRadius: "20px",
                    color: "#00FFE0",
                    padding: "4px 12px",
                    fontSize: "12px",
                    fontWeight: "700",
                    letterSpacing: "0.048px",
                    position: "relative",
                  }}
                >
                  {Number(sliderValue || 0)?.toLocaleString()}
                  <div
                    style={{
                      position: "absolute",
                      width: 0,
                      height: 0,
                      borderTop: "10px solid #00332D",
                      borderLeft: "5px solid transparent",
                      borderRight: "5px solid transparent",
                      top: "100%",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </SliderMark>

          <SliderTrack>
            <SliderTrack bg={sliderTrack} />
            <SliderFilledTrack bg={"#00FFE0"} />
          </SliderTrack>

          <SliderThumb
            display={"flex"}
            w={"24px"}
            h="24px"
            p={"4px"}
            alignItems={"flex-start"}
            gap={"2.667px"}
            borderRadius={"32px"}
            border={"1.333px solid #000"}
            bg={"#FFF"}
            zIndex={0}
          >
            <Image src={sliderThumb} alt="thumb" w={"16px"} h={"16px"} />
          </SliderThumb>
        </Slider>

        <UserInfoInput
          icon={withdrawCoin || bitcoinIcon}
          activeIcon={withdrawCoinActive || bitcoinActive}
          placeholder="0"
          type="text"
          name="withdrawAmount"
          mb="0px"
          min={0}
          max={maxAmount}
          value={inputValue === 0 ? "" : inputValue}
          onChange={(e: any) => {
            let val: any = e.target.value;
            val = val.replace(/[eE]/g, null);
            if (Number(val) >= 0 && Number(val) <= Number(maxAmount)) {
              setInputValue(val);
              setSliderValue(val);
              setAmountSelected(val);
            }

            // if(val > maxAmount){
            //   setInputValue(Number(inputValue));
            //   setSliderValue(Number(sliderValue));
            //   setAmountSelected(Number(amountSelected));
            // }

            // if (val === "") {
            //   setInputValue(0);
            //   setSliderValue(0);
            //   setAmountSelected(0);
            // }
          }}
        />
      </Flex>
    </>
  );
};

export default WithdrawSlider;
