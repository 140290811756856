import React, { useState } from "react";
import AdminLayout from "../layouts/AdminLayout";
import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import CustomHeader from "../components/CustomHeader";
import ActionButton from "../components/ActionButton";
import upArrowWhite from "../assets/upArrowWhite.svg";
import upArrowWhiteDark from "../assets/upArrowWhiteDark.svg";
import downArrowWhiteDark from "../assets/downArrowWhiteDark.svg";
import swapArrowWhiteDark from "../assets/swapArrowWhiteDark.svg";
import swapIcon from "../assets/swapIcon.svg";
import downArrowWhite from "../assets/downArrowWhite.svg";
import bgRed from "../assets/redBg.svg";
import bgGreen from "../assets/greenBg.svg";
import bgBlue from "../assets/yellowBg.svg";
import CryptoCard from "../components/CryptoCard";
import increaseArrow from "../assets/increaseArrow.svg";
import decreaseArrow from "../assets/decreaseArrow.svg";
import BtcIcon from "../assets/bitcoin.svg";
import ethreum from "../assets/ethereum.svg";
import tronIcon from "../assets/tron.svg";
import nairaIcon from "../assets/nairaWhiteSymbol.svg";
import bag from "../assets/bag.svg";
import FlatBg from "../assets/flatPicBg.svg";
import TransactionTable from "../components/TransactionTable";
import arrows from "../assets/arrows.svg";
import bitcoin from "../assets/bitcoin.svg";
import tv from "../assets/tv.svg";
import upArrow from "../assets/upArrow.svg";
import downArrow from "../assets/downArrow.svg";
import usdtIcon from "../assets/walletUsdt.svg";
import usdcIcon from "../assets/walletUsdc.svg";
import { useNavigate } from "react-router-dom";
import {
  useGetUserNairaBalance,
  useGetWalletTransactions,
  useGetWalletWithBalance,
} from "../utils/wallet.api";
import arrow1 from "../assets/backArrow.svg";
import arrow2 from "../assets/backArrowDark.svg";

const Wallet = () => {
  const navigate = useNavigate();

  const [selected, setSelected] = useState(
    localStorage.getItem("dashboardSelectedToken") || "NGN"
  );
  const [cryptoHeader, setCryptoHeader] = useState("");
  const [selectedCrypto, setSelectedCrypto] = useState("");
  const [cryptoIcon, setCryptoIcon] = useState(bitcoin);
  const paraColor = useColorModeValue("#5A5A5A", "#C7C7C7");
  const viewColor = useColorModeValue("#909090", "#00FFE0");
  const borderColor = useColorModeValue("#FFF", "#333");
  const bgColor = useColorModeValue("#F9F9F9", "#121B18");
  const shadowColor1 = useColorModeValue("#FFEDED", "#4C2B2B");
  const shadowColor2 = useColorModeValue("#FFF2D7", "#463A22");
  const shadowColor3 = useColorModeValue("#D7FFFA", "#26423F");

  const upArrowIcon = useColorModeValue(upArrowWhite, upArrowWhiteDark);
  const downArrowIcon = useColorModeValue(downArrowWhite, downArrowWhiteDark);
  const swapArrowIcon = useColorModeValue(swapIcon, swapArrowWhiteDark);
  const [tokenId, setTokenId] = useState();
  const arrowIcon = useColorModeValue(arrow1, arrow2);

  const { data, isLoading } = useGetWalletWithBalance();
  const { data: nairaBalance, isLoading: nairaLoading } =
    useGetUserNairaBalance();
  const { isLoading: transactionsLoading, data: transactions } =
    useGetWalletTransactions({ o: 0, l: 10, tokenId: tokenId, filter: "" });

  return (
    <AdminLayout>
      <Box marginTop="72px" marginRight={"2%"} mb={"50px"} w={"95%"}>
        <CustomHeader
          para1={selected === cryptoHeader ? "Wallet" : "Payremit Wallet"}
          para2={selected === cryptoHeader ? "Crypto" : ""}
          para3={selected === cryptoHeader ? "/ " : ""}
          header={selected === cryptoHeader ? selected : "Wallet"}
          amount="38,920.84"
          // setSelected={setSelected}
          // selected={selected}
          data={data}
          isLoading={isLoading}
        />

        <Box mt="36px" mb={"59px"}>
          <Text
            mb={"16px"}
            color={paraColor}
            fontSize={"16px"}
            lineHeight={"22px"}
            fontWeight={"700"}
            letterSpacing={"0.08px"}
          >
            Actions
          </Text>
          <Flex
            gap={"16px"}
            h={"58px"}
            direction={["column", "column", "row", "row"]}
            mb={["170px", "170px", "0px", "0px"]}
          >
            <ActionButton
              arrow={downArrowIcon}
              text="Deposit"
              flex={1}
              borderS={shadowColor3}
              bgPic={bgGreen}
              fromWallet="#00FFE0"
              onClick={() => {
                navigate("/dashboard/deposit");
                sessionStorage.setItem("isActive", "/deposit");
              }}
            />

            <ActionButton
              arrow={swapArrowIcon}
              text="Swap"
              flex={1}
              borderS={shadowColor2}
              bgPic={bgBlue}
              fromWallet="#FFC960"
              onClick={() => {
                navigate("/dashboard/index");
                sessionStorage.setItem("isActive", "/index");
              }}
            />
            <ActionButton
              arrow={upArrowIcon}
              text="Withdraw"
              flex={1}
              borderS={shadowColor1}
              bgPic={bgRed}
              fromWallet="#F00C0C"
              onClick={() => {
                navigate("/dashboard/withdraw");
                sessionStorage.setItem("isActive", "/withdraw");
              }}
            />
          </Flex>
        </Box>
        {selectedCrypto === "" ? (
          <Flex gap={"2%"} direction={["column", "column", "row", "row"]}>
            <Box w={["100%", "100%", "61%", "61%"]}>
              <Text
                color={paraColor}
                fontSize={"16px"}
                lineHeight={"22px"}
                fontWeight={"700"}
                letterSpacing={"0.08px"}
                mb={"24px"}
              >
                Crypto
              </Text>
              {isLoading ? (
                <Box display={"flex"} justifyContent={"center"} w={"100%"}>
                  <Spinner
                    size={"xl"}
                    thickness="3px"
                    emptyColor="gray.200"
                    color="blue.500"
                  />
                </Box>
              ) : (
                <SimpleGrid columns={[1, 1, 1, 2]} spacing="40px" mb={"40px"}>
                  {data?.balance?.map((bal: any) => (
                    <CryptoCard
                      key={bal?.id}
                      coin={
                        // bal?.symbol?.split(" ")[0] === "USDT"
                        //   ? `USDT (${bal?.symbol}`
                        //   :
                        bal?.symbol?.includes("USDT") ||
                        bal?.symbol?.includes("USDC")
                          ? bal?.symbol?.slice(0, 4)
                          : bal?.symbol
                      }
                      fullname={
                        bal?.symbol?.includes("USDT")
                          ? "USDT"
                          : bal?.symbol?.includes("USDC")
                          ? "USDC"
                          : bal?.name?.split(" ")[0]
                      }
                      balance={
                        bal?.symbol?.includes("ETH") ||
                        bal?.symbol?.includes("BTC")
                          ? Number(bal?.balance)?.toLocaleString(undefined, {
                              minimumFractionDigits: 5,
                              maximumFractionDigits: 5,
                            })
                          : Number(bal?.balance)?.toLocaleString(undefined, {
                              minimumFractionDigits: 5,
                              maximumFractionDigits: 5,
                            })
                      }
                      amount={bal?.price_change_percentage_7d_in_currency}
                      icon={bal?.logoUrl}
                      arrow={
                        Number(bal?.price_change_percentage_7d_in_currency) > 0
                          ? increaseArrow
                          : decreaseArrow
                      }
                      onClick={() => {
                        setSelectedCrypto(bal?.symbol?.split(" ")[0]);
                        setCryptoIcon(bal?.logoUrl);
                        setTokenId(bal?.coinId);
                        setSelected(
                          bal?.symbol?.includes("USDT") ||
                            bal?.symbol?.includes("USDC")
                            ? bal?.symbol?.slice(0, 4)
                            : bal?.symbol
                        );
                        setCryptoHeader(
                          bal?.symbol?.includes("USDT") ||
                            bal?.symbol?.includes("USDC")
                            ? bal?.symbol?.slice(0, 4)
                            : bal?.symbol
                        );
                      }}
                    />
                  ))}
                </SimpleGrid>
              )}
            </Box>
            <Box w={["100%", "100%", "37%", "37%"]}>
              <Text
                color={paraColor}
                fontSize={"16px"}
                lineHeight={"22px"}
                fontWeight={"700"}
                letterSpacing={"0.08px"}
                mb={"24px"}
                mt={["16px", "16px", "0px", "0px"]}
              >
                Fiat
              </Text>
              <Box
                p={"56px 24px 20px 24px"}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
                alignItems={"center"}
                flex={"1 0 0"}
                borderRadius={"12px"}
                bg={"#00332D"}
                background={`url(${FlatBg}) no-repeat, #00332D`}
                backgroundPosition={"bottom"}
                backgroundSize={"contain"}
                h={"92%"}
              >
                <Flex
                  direction={"column"}
                  justify={"center"}
                  align={"center"}
                  gap={"24px"}
                >
                  <Text
                    color={"#EEE"}
                    fontSize={"14px"}
                    fontWeight={"400"}
                    lineHeight={"20px"}
                    letterSpacing={"0.035px"}
                  >
                    Balance
                  </Text>
                  <Heading
                    color={"#FFF"}
                    fontSize={"34px"}
                    fontWeight={"700"}
                    letterSpacing={"0.085px"}
                    display={"flex"}
                    alignItems={"center"}
                    gap={"10px"}
                  >
                    <Image
                      src={nairaIcon}
                      alt="coin-icon"
                      // w={"30px"}
                      // h="25px"
                      mt="3px"
                    />{" "}
                    {nairaLoading ? (
                      <Spinner size="sm" />
                    ) : (
                      Number(nairaBalance?.balance)?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    )}
                  </Heading>
                  <Text
                    color={"#EEE"}
                    fontSize={"10px"}
                    fontWeight={"700"}
                    lineHeight={"20px"}
                    letterSpacing={"5.125px"}
                    textTransform={"uppercase"}
                  >
                    Nigerian Naira
                  </Text>
                </Flex>
                <Image src={bag} alt="bag" />
              </Box>
            </Box>
          </Flex>
        ) : (
          <>
            <Flex
              justify={"space-between"}
              align={"center"}
              mt={"24px"}
              mb={"16px"}
            >
              <Flex gap={"10px"}>
                <Image
                  src={arrowIcon}
                  alt="arrow"
                  transform={"rotate(270deg)"}
                  onClick={() => setSelectedCrypto("")}
                  cursor={"pointer"}
                  // mb={"40px"}
                />
                <Text
                  color={paraColor}
                  fontSize={"16px"}
                  fontWeight={"700"}
                  lineHeight={"22px"}
                  letterSpacing={"0.08px"}
                >
                  Transaction History
                </Text>
              </Flex>
              <Text
                color={viewColor}
                fontSize={"14px"}
                fontWeight={"400"}
                letterSpacing={"0.014px"}
                onClick={() => {
                  navigate("/dashboard/transactions");
                  sessionStorage.setItem("isSubActive", "Wallet Transactions");
                }}
                cursor={"pointer"}
              >
                View All
              </Text>
            </Flex>
            <Box
              bg={bgColor}
              border={`1px solid ${borderColor}`}
              borderRadius={"12px"}
              padding={"13px"}
            >
              {transactionsLoading ? (
                <Spinner />
              ) : transactions?.length > 0 ? (
                transactions?.map((item: any, idx: number) => (
                  <TransactionTable
                    month={new Date(item?.createdAt).toLocaleString("en-us", {
                      month: "short",
                    })}
                    date={new Date(item?.createdAt).getUTCDate().toString()}
                    icon={
                      item?.type?.includes("Withdraw")
                        ? downArrow
                        : item?.type === "Deposit"
                        ? upArrow
                        : arrows
                    }
                    type={item?.type}
                    address={
                      item?.toAddress?.slice(0, 6) +
                        "..." +
                        item?.toAddress?.slice(-2) || "N/A"
                    }
                    id={`${item?.id?.slice(0, 4)}...${item?.id?.slice(-4)}`}
                    amount={
                      item?.coin?.symbol?.includes("ETH") ||
                      item?.coin?.symbol?.includes("BTC")
                        ? item?.type === "Swap"
                          ? Number(item?.swappedAmount)?.toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 5,
                                maximumFractionDigits: 5,
                              }
                            )
                          : Number(item?.amount)?.toLocaleString(undefined, {
                              minimumFractionDigits: 5,
                              maximumFractionDigits: 5,
                            })
                        : item?.type === "Swap"
                        ? Number(item?.swappedAmount)?.toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 5,
                              maximumFractionDigits: 5,
                            }
                          )
                        : Number(item?.amount)?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                    }
                    amountIcon={
                      item?.coin?.isStable
                        ? item?.coin?.network?.logoUrl
                        : item?.coin?.icon || nairaIcon
                    }
                    borderC={
                      item?.type === "Withdraw"
                        ? "#00FFE0"
                        : item?.type === "Deposit"
                        ? "#F00C0C"
                        : "#FFC960"
                    }
                    onClick={() => {
                      if (item?.type?.includes("Withdraw")) {
                        navigate("/dashboard/withdraw");
                        sessionStorage.setItem("isActive", "/withdraw");
                      } else if (item?.type?.includes("Deposit")) {
                        navigate("/dashboard/deposit");
                        sessionStorage.setItem("isActive", "/deposit");
                      } else {
                        navigate("/dashboard/index");
                        sessionStorage.setItem("isActive", "/index");
                      }
                    }}
                  />
                ))
              ) : (
                <Text>No Data Available</Text>
              )}
            </Box>
          </>
        )}
      </Box>
    </AdminLayout>
  );
};

export default Wallet;
