import React, { useState } from "react";
import AuthLayout from "../layouts/AuthLayout";
import {
  Box,
  Checkbox,
  Image,
  Input,
  Link,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import theme from "../theme/theme";
import AuthCardHeader from "../components/AuthCardHeader";
import UserInfoInput from "../components/UserInfoInput";
import PrimaryButton from "../components/PrimaryButton";
import { useNavigate } from "react-router-dom";
import emailLight from "../assets/icons/light/emailIcon.svg";
import emailLightActive from "../assets/icons/light/emailActive.svg";
import emailDark from "../assets/icons/dark/email.svg";
import { useForgotPassword } from "../utils/auth.api";
import arrow1 from "../assets/backArrow.svg";
import arrow2 from "../assets/backArrowDark.svg";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const forgotPasswordMutation = useForgotPassword();
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const { name, email, password, confirmPassword } = formData;
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [isInvalid, setIsInvalid] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const linkColor = useColorModeValue(
    theme.colors.light.link.primary,
    theme.colors.dark.link.primary
  );
  const color = useColorModeValue(
    theme.colors.light.text.primary,
    theme.colors.dark.text.primary
  );
  const arrowIcon = useColorModeValue(arrow1, arrow2);
  const checkBoxScheme = useColorModeValue("gray", "teal");
  const emailIcon = useColorModeValue(emailLight, emailDark);

  return (
    <AuthLayout>
      <Image
        src={arrowIcon}
        alt="arrow"
        transform={"rotate(270deg)"}
        onClick={() => navigate("/auth/signin")}
        cursor={"pointer"}
        mb={"40px"}
      />
      <AuthCardHeader
        title="Forgot Password?"
        des="Enter your associated email to get the verification code."
      />
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          try {
            await forgotPasswordMutation.mutateAsync({ email: email });
            toast({
              title: "Otp sent.",
              status: "success",
            });
            navigate("/auth/verification", {
              state: { formData: formData, from: "forgotPassword" },
            });
          } catch (error: any) {
            toast({
              title: error.message,
              status: "error",
            });
          }
        }}
      >
        <UserInfoInput
          type="email"
          placeholder="Email"
          darkBG="transparent"
          icon={emailIcon}
          activeIcon={useColorModeValue(emailLightActive, emailDark)}
          name="email"
          value={email}
          onChange={(e: any) => handleChange(e)}
          border={!emailPattern.test(email) && email && "red"}
        />
        {!emailPattern.test(email) && email && (
          <Text mt="-16px" color={"red"}>
            Enter correct email{" "}
          </Text>
        )}
        <Box minH="200px"></Box>
        <PrimaryButton
          type="submit"
          text={
            forgotPasswordMutation.isLoading ? (
              <Spinner />
            ) : (
              "Get Verification Code"
            )
          }
          disabled={forgotPasswordMutation.isLoading}
          onClick={() => {
            if (email === "") {
              setIsInvalid(true);
            } else setIsInvalid(false);
          }}
        />
      </form>
    </AuthLayout>
  );
};

export default ForgotPassword;
