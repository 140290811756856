import React, { useEffect, useState } from "react";
import AdminLayout from "../layouts/AdminLayout";
import {
  Box,
  Flex,
  Spinner,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import CustomHeader from "../components/CustomHeader";
import ServicesCard from "../components/ServicesCard";
import Anime from "../assets/anime.svg";
import TvSub from "../assets/tvSub.svg";
import dataPlan from "../assets/dataPlan.svg";
import electricity from "../assets/electricity.svg";
import AnimeGreen from "../assets/animeGreen.svg";
import TvSubGreen from "../assets/tvSubGreen.svg";
import dataPlanGreen from "../assets/dataPlanGreen.svg";
import electricityGreen from "../assets/electricityGreen.svg";
import TransactionTable from "../components/TransactionTable";
import arrows from "../assets/arrows.svg";
import bitcoin from "../assets/bitcoin.svg";
import tron from "../assets/tron.svg";
import tv from "../assets/tv.svg";
import upArrow from "../assets/upArrow.svg";
import downArrow from "../assets/downArrow.svg";
import ethereum from "../assets/ethereum.svg";
import ActionButton from "../components/ActionButton";
import upArrowWhite from "../assets/upArrowWhite.svg";
import downArrowWhite from "../assets/downArrowWhite.svg";
import upArrowDark from "../assets/upArrowDark.svg";
import downArrowDark from "../assets/downArrowDark.svg";
import Swap from "../components/Swap";
import CustomModal from "../components/CustomModal";
import AuthCardHeader from "../components/AuthCardHeader";
import verifyIcon from "../assets/verified.svg";
import { useNavigate } from "react-router-dom";
import YellowPipeDark from "../assets/yellowPipeDark.svg";
import {
  useGetNairaBalance,
  useGetSwapRates,
  useGetWalletTransactions,
  useGetWalletWithBalance,
} from "../utils/wallet.api";
import { useGetLoggedInUser } from "../utils/auth.api";
import nairaIcon from "../assets/icons/light/naira-light.svg";
import SwapCardExp from "../components/SwapCardExp";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: user, isLoading: userLoading } = useGetLoggedInUser();
  const onClose = () => {
    setIsOpen(!isOpen);
  };
  const navigate = useNavigate();

  const [selected, setSelected] = useState("NGN");

  const paraColor = useColorModeValue("#5A5A5A", "#C7C7C7");
  const viewColor = useColorModeValue("#909090", "#00FFE0");
  const borderColor = useColorModeValue("#FFF", "#333");
  const bgColor = useColorModeValue("#F9F9F9", "#121B18");
  const airtimeIcon = useColorModeValue(Anime, AnimeGreen);
  const tvSubIcon = useColorModeValue(TvSub, TvSubGreen);
  const dataPlanIcon = useColorModeValue(dataPlan, dataPlanGreen);
  const electricityIcon = useColorModeValue(electricity, electricityGreen);
  const icon1 = useColorModeValue(upArrowWhite, upArrowDark);
  const icon2 = useColorModeValue(downArrowWhite, downArrowDark);

  const [tokenId, setTokenId] = useState();

  const { isLoading: transactionsLoading, data: transactions } =
    useGetWalletTransactions({ o: 0, l: 5, tokenId: "", filter: "" });

  const { data, isLoading, isFetching } = useGetWalletWithBalance();
  const { data: nairaBalance, isLoading: nairaLoading } = useGetNairaBalance();
  const {
    data: swapRates,
    isLoading: swapRateLoading,
    refetch,
  } = useGetSwapRates();

  useEffect(() => {
    const fromPage = localStorage.getItem("fromPage");
    if (fromPage === "verify") {
      onClose();
      localStorage.removeItem("fromPage");
    }
    const interval = setInterval(() => {
      refetch();
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <AdminLayout>
      <Box marginTop="72px" marginRight={"2%"} mb={"50px"} w={"95%"}>
        <Box marginBottom={"36px"}>
          <CustomHeader
            para1="Payremit Wallet"
            header={user?.fullname ? `Hello! ${user?.fullname}` : "Hello!"}
            amount="38,920.84"
            setSelected={setSelected}
            // para2={selected !== "NGN" ? "Wallet" : ""}
            // para3={selected !== "NGN" ? "/ " : ""}
            data={data}
            isLoading={isLoading}
          />
        </Box>
        <Flex
          flexDirection={["column", "column", "column", "row"]}
          gap={"24px"}
        >
          <Box w={["100%", "100%", "100%", "60%"]}>
            <Text
              mb={"16px"}
              color={paraColor}
              fontSize={"16px"}
              fontWeight={"700"}
              lineHeight={"22px"}
              letterSpacing={"0.08px"}
            >
              Services
            </Text>
            <Flex
              gap={"16px"}
              w={"full"}
              flexDirection={["column", "row", "row", "row"]}
            >
              <ServicesCard
                logo={airtimeIcon}
                text={"Airtime"}
                flex={1}
                onClick={() => {
                  navigate("/dashboard/services/airtime");
                  sessionStorage.setItem("isActive", "Services");
                  sessionStorage.setItem("isSubActive", "Airtime");
                }}
              />
              <ServicesCard
                logo={tvSubIcon}
                text={"TV Sub"}
                flex={1}
                onClick={() => {
                  navigate("/dashboard/services/tv-sbscription");
                  sessionStorage.setItem("isActive", "Services");
                  sessionStorage.setItem("isSubActive", "TV Subscription");
                }}
              />
              <ServicesCard
                logo={dataPlanIcon}
                text={"Data Plan"}
                flex={1}
                onClick={() => {
                  navigate("/dashboard/services/internet");
                  sessionStorage.setItem("isActive", "Services");
                  sessionStorage.setItem("isSubActive", "Internet");
                }}
              />
              <ServicesCard
                logo={electricityIcon}
                text="Electricity"
                flex={1}
                onClick={() => {
                  navigate("/dashboard/services/electricity-bill");
                  sessionStorage.setItem("isActive", "Services");
                  sessionStorage.setItem("isSubActive", "Electricity");
                }}
              />
            </Flex>
            <Flex
              justify={"space-between"}
              align={"center"}
              mt={"24px"}
              mb={"16px"}
            >
              <Text
                color={paraColor}
                fontSize={"16px"}
                fontWeight={"700"}
                lineHeight={"22px"}
                letterSpacing={"0.08px"}
              >
                Transaction History
              </Text>
              <Text
                color={viewColor}
                fontSize={"14px"}
                fontWeight={"400"}
                letterSpacing={"0.014px"}
                cursor={"pointer"}
                onClick={() => {
                  sessionStorage.setItem("isSubActive", "Wallet Transactions");
                  sessionStorage.setItem("isActive", "Transaction");
                  navigate("/dashboard/transactions");
                }}
              >
                View All
              </Text>
            </Flex>
            <Box
              bg={bgColor}
              border={`1px solid ${borderColor}`}
              borderRadius={"12px"}
              padding={"13px"}
            >
              {transactionsLoading ? (
                <Spinner />
              ) : transactions?.length > 0 ? (
                transactions?.map((item: any, idx: number) => (
                  <TransactionTable
                    month={new Date(item?.createdAt).toLocaleString("en-us", {
                      month: "short",
                    })}
                    date={new Date(item?.createdAt).getUTCDate().toString()}
                    icon={
                      item?.type?.includes("Withdraw")
                        ? upArrow
                        : item?.type === "Deposit" || item?.type === "Received"
                        ? downArrow
                        : item?.type?.includes("Transfer")
                        ? upArrow
                        : arrows
                    }
                    type={
                      // item?.type?.includes("Transfer") &&
                      // item?.receiverUserId === user?.uniqueId
                      //   ? "Receive"
                      //   :
                      item?.type
                    }
                    address={
                      item?.type?.includes("Transfer")
                        ? item?.receiverUserId || ""
                        : item?.type?.includes("Received")
                        ? item?.email || ""
                        : item?.toAddress
                        ? item?.toAddress?.slice(0, 6) +
                          "..." +
                          item?.toAddress?.slice(-2)
                        : item?.accountNumber
                    }
                    id={`${item?.id?.slice(0, 4)}...${item?.id?.slice(-4)}`}
                    amount={
                      item?.coin?.symbol?.includes("ETH") ||
                      item?.coin?.symbol?.includes("BTC")
                        ? item?.type === "Swap"
                          ? Number(item?.amount)?.toLocaleString(undefined, {
                              minimumFractionDigits: 5,
                              maximumFractionDigits: 5,
                            })
                          : Number(item?.amount)?.toLocaleString(undefined, {
                              minimumFractionDigits: 5,
                              maximumFractionDigits: 5,
                            }) + " "
                        : item?.type === "Swap"
                        ? Number(item?.amount)?.toLocaleString(undefined, {
                            minimumFractionDigits: 5,
                            maximumFractionDigits: 5,
                          })
                        : Number(item?.amount)?.toLocaleString(undefined, {
                            minimumFractionDigits: 5,
                            maximumFractionDigits: 5,
                          }) + " "
                    }
                    amountIcon={
                      item?.type === "Swap"
                        ? item?.isToCrypto
                          ? nairaIcon
                          : item?.coin?.icon || nairaIcon
                        : item?.coin?.icon || nairaIcon
                    }
                    borderC={
                      item?.type === "Withdraw"
                        ? "#00FFE0"
                        : item?.type === "Deposit"
                        ? "#F00C0C"
                        : "#FFC960"
                    }
                    onClick={() => {}}
                    // onClick={() => {
                    //   if (item?.type?.includes("Withdraw")) {
                    //     navigate("/dashboard/withdraw");
                    //     sessionStorage.setItem("isActive", "/withdraw");
                    //   } else if (item?.type?.includes("Deposit")) {
                    //     navigate("/dashboard/deposit");
                    //     sessionStorage.setItem("isActive", "/deposit");
                    //   } else {
                    //     navigate("/dashboard/index");
                    //     sessionStorage.setItem("isActive", "/index");
                    //   }
                    // }}
                  />
                ))
              ) : (
                <Text>No data available.</Text>
              )}
            </Box>
          </Box>
          <Box w={["100%", "100%", "100%", "40%"]}>
            <Text
              mb={"16px"}
              color={paraColor}
              fontSize={"16px"}
              fontWeight={"700"}
              lineHeight={"22px"}
              letterSpacing={"0.08px"}
            >
              Actions
            </Text>
            <Flex
              gap={"12px"}
              mb={"24px"}
              flexDirection={["column", "column", "row", "row"]}
            >
              <ActionButton
                onClick={() => {
                  navigate("/dashboard/deposit");
                  sessionStorage.setItem("isActive", "/deposit");
                }}
                text="Deposit"
                arrow={icon2}
                flex={1}
              />
              <ActionButton
                onClick={() => {
                  navigate("/dashboard/withdraw");
                  sessionStorage.setItem("isActive", "/withdraw");
                }}
                text="Withdraw"
                arrow={icon1}
                flex={1}
              />
            </Flex>
            {/* <Swap
              balances={data}
              balanceLoading={isLoading}
              nairaBalance={nairaBalance}
              nairaLoading={nairaLoading}
              swapRates={swapRates}
              swapRateLoading={swapRateLoading}
            /> */}
            <SwapCardExp
              balances={data}
              balanceLoading={isFetching}
              nairaBalance={nairaBalance}
              nairaLoading={nairaLoading}
              swapRates={swapRates}
              swapRateLoading={swapRateLoading}
            />
          </Box>
        </Flex>
      </Box>
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onClose}
        headerText="Registered"
      >
        <Box py="20px">
          <AuthCardHeader
            imgSrc={verifyIcon}
            imageW="56px"
            title="Congratulations!"
            des="You're all set! Welcome aboard."
          />
        </Box>
      </CustomModal>
    </AdminLayout>
  );
};

export default Home;
