import { ChevronDownIcon } from "@chakra-ui/icons";
import { Flex, Image, Text } from "@chakra-ui/react";
import CircularNaira from "../assets/naira_circular.svg";

const SelectSwapCrypto = ({ isMenuOpen, setIsMenuOpen, selectedCoin }: any) => {
  return (
    <Flex
      h={{ base: "23px", sm: "30px" }}
      px={["5px", "8px"]}
      borderRadius={{ sm: "6px" }}
      border="1px solid"
      borderColor="#fff"
      justify={"space-between"}
      align={"center"}
      cursor="pointer"
      onClick={() => setIsMenuOpen(!isMenuOpen)}
      bg="transparent"
      w="100%"
      maxW={{ base: "80px", sm: "100px" }}
      gap="10px"
    >
      <Text
        whiteSpace="nowrap"
        maxW="60px"
        minW={{ base: "40px", sm: "60px" }}
        overflow="hidden"
        fontSize={"14px"}
        fontWeight={600}
      >
        {selectedCoin?.symbol?.includes("USDT") ||
        selectedCoin?.symbol?.includes("USDC")
          ? selectedCoin?.symbol?.slice(0, 4)
          : selectedCoin?.symbol}
      </Text>
      <ChevronDownIcon color="#fff" fontSize={"20px"} p="0px" />
    </Flex>
  );
};

export default SelectSwapCrypto;
