import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.
const firebaseConfig = {
  apiKey: "AIzaSyD3yf1fVifK_JZRdnW7XdbNHXzaS8Higk4",
  authDomain: "payremit-28cbd.firebaseapp.com",
  projectId: "payremit-28cbd",
  storageBucket: "payremit-28cbd.appspot.com",
  messagingSenderId: "307578283561",
  appId: "1:307578283561:web:2b9a56b9efb9fd2ad01e38",
  measurementId: "G-JK6BX23H0T",
};
initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
  return new Promise((resolve, reject) => {
    getToken(messaging, {
      vapidKey:
        "BFNGDqJaktrxnvrCI06FtGX_47HTQUvvrm8yAwGiSWWmM_xIAjmq5SV0_8MfWUU6lVel2vuUDKQwl-xvvndIOJo",
    })
      .then((currentToken) => {
        if (currentToken) {
          // console.log("Current token for client:", currentToken);
          resolve(currentToken);
        } else {
          // console.log(
          //   "No registration token available. Request permission to generate one."
          // );
          reject(new Error("No registration token available"));
        }
      })
      .catch((err) => {
        // console.log("An error occurred while retrieving token.", err);
        reject(err);
      });
  });
};

export const onMessageListener = () => {
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      // console.log("Payload", payload);
      resolve(payload);
    });
  });
};
