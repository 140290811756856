import { Box, Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import theme from "../theme/theme";
import radioLight from "../assets/icons/light/radio.svg";
import radioDark from "../assets/icons/dark/radio.svg";

const BankSelectionCard = ({ isActive, onClick, bankName, accountNumber, accountName }: any) => {
  const color = useColorModeValue(
    theme.colors.light.text.primary,
    theme.colors.dark.text.primary
  );
  const activeBorderColor = useColorModeValue("#333", "#00665A");
  const inActiveBorderColor = useColorModeValue("#C7C7C7", "#333");

  return (
    <Flex
      border={isActive ? "2px solid" : "1px solid"}
      borderRadius={"12px"}
      borderColor={isActive? activeBorderColor : inActiveBorderColor}
      px={["5px", "16px"]}
      py="10px"
      // height={"64px"}
      align={"center"}
      justify={"space-between"}
      my="20px"
      bgColor={useColorModeValue("white", "#121b18")}
      cursor={"pointer"}
      opacity={isActive ? 1 : 0.5}
      onClick={onClick}
      gap='15px'
      wrap={'wrap'}
    >
      <Flex gap={{ base: "8px", "2xl": "16px" }} align={"center"}>
        <Image src={useColorModeValue(radioLight, radioDark)} />
        <Flex
          justify={"center"}
          align={"center"}
          boxSize={"32px"}
          borderRadius={"8px"}
          bgColor={useColorModeValue("#CCFFF9", "#00665A")}
          fontSize={"16px"}
          fontWeight={700}
          color={useColorModeValue("#00CCB3", "#00FFE0")}
        >
          D
        </Flex>
        <Box color={color}>
          <Text fontWeight={700}>{accountName}</Text>
          <Text>{bankName}</Text>
        </Box>
      </Flex>
      <Flex alignSelf={"end"}>
        <Text display={{base:'block', md:'block'}}>{accountNumber}</Text>
        {/* <Text display={{base:'none', sm:'block', md:'none'}}>000 *** 000</Text>
        <Text display={{base:'block', sm:'none'}}>0 *** 0</Text> */}

      </Flex>
    </Flex>
  );
};

export default BankSelectionCard;
