import {
  Flex,
  Image,
  PinInput,
  PinInputField,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import PrimaryButton from "./PrimaryButton";
import qrCode from "../assets/qrCode.svg";
import qrCodeDark from "../assets/qrCodeDark.svg";
import copy from "../assets/copy.svg";
import copyDark from "../assets/copyDark.svg";
import {
  useEnable2FA,
  useUpdateProfile,
  useVerify2FaOtp,
} from "../utils/auth.api";
import QRCode from "react-qr-code";

const TwoFa = ({ fetch, loggedInUser }: any) => {
  const [pinInput, setPinInput] = useState("");
  const enable2FAQuery = useEnable2FA();
  // const [verifyLoading, setVerifyLoading] = useState(false);
  const [verifyAuth, setVerifyAuth] = useState(false);
  const verify2FaOtpMutation = useVerify2FaOtp();
  const updateProfileMutation = useUpdateProfile();
  const toast = useToast();
  const paraColor1 = useColorModeValue("#5A5A5A", "#C7C7C7");
  const paraColor2 = useColorModeValue("#909090", "#C7C7C7");
  const paraColor3 = useColorModeValue("#333", "#C7C7C7");
  const paraColor4 = useColorModeValue("#C7C7C7", "#909090");
  const borderC = useColorModeValue("#EEE", "#333");
  const qrCodeIcon = useColorModeValue(qrCode, qrCodeDark);
  const copyIcon = useColorModeValue(copy, copyDark);
  useEffect(() => {
    enable2FAQuery.refetch();
    const error: any = enable2FAQuery.error;
    if (error) {
      toast({
        title: error.message,
        status: "error",
      });
    }
  }, [fetch]);

  return (
    <div>
      {verifyAuth ? (
        <Flex
          mt={"65px"}
          direction={"column"}
          align={"flex-start"}
          gap={"24px"}
          alignSelf={"stretch"}
        >
          <Text
            color={paraColor1}
            fontSize={"16px"}
            fontWeight={"700"}
            lineHeight={"22px"}
            letterSpacing={"0.08px"}
          >
            Verify Authentication
          </Text>
          <Flex
            justify={"space-between"}
            w={"100%"}
            alignSelf={"stretch"}
            gap={"12px"}
            color={paraColor1}
            fontSize={"20px"}
            fontFamily={"Roboto"}
            fontWeight={"700"}
            lineHeight={"20px"}
            letterSpacing={"0.05px"}
          >
            <PinInput
              errorBorderColor="red.300"
              focusBorderColor="#33FFE6"
              // size={"lg"}
              variant={"flushed"}
              placeholder="0"
              value={pinInput}
              // isInvalid={isVerified === 0 && pinInput !== ""}
              otp={true}
              onChange={(value) => {
                setPinInput(value);
              }}
              autoFocus
            >
              <PinInputField w={"100%"} _placeholder={{ color: "#C7C7C7" }} />
              <PinInputField w={"100%"} _placeholder={{ color: paraColor4 }} />
              <PinInputField w={"100%"} _placeholder={{ color: paraColor4 }} />
              <PinInputField w={"100%"} _placeholder={{ color: paraColor4 }} />
              <PinInputField w={"100%"} _placeholder={{ color: paraColor4 }} />
              <PinInputField w={"100%"} _placeholder={{ color: paraColor4 }} />
            </PinInput>
          </Flex>
          <PrimaryButton
            text={
              verify2FaOtpMutation?.isLoading ? (
                <Spinner />
              ) : (
                "Verify Security Code"
              )
            }
            mt="0px"
            onClick={async () => {
              if (pinInput?.length === 0) {
                toast({
                  title: "Please enter Authenticatior code first.",
                  status: "error",
                });
              } else if (pinInput?.length < 6) {
                toast({
                  title: "Please enter 6 digit Authenticatior code.",
                  status: "error",
                });
              } else {
                try {
                  const res = await verify2FaOtpMutation?.mutateAsync(pinInput);
                  if (res?.isValid && loggedInUser?.is2FAEnabled) {
                    updateProfileMutation
                      ?.mutateAsync({ is2FAEnabled: false })
                      .then(() => {
                        toast({
                          title: "Two factor authentication disabled",
                          status: "success",
                        });
                        setVerifyAuth(false);
                        setPinInput("");
                      })
                      .catch((err) => {
                        toast({
                          title: err?.message,
                          status: "error",
                        });
                        setVerifyAuth(false);
                        setPinInput("");
                      });
                  } else if (res?.isValid) {
                    toast({
                      title: "Two factor authentication enabled",
                      status: "success",
                    });
                    setVerifyAuth(false);
                    setPinInput("");
                  } else {
                    toast({
                      title: "Wrong Otp",
                      status: "error",
                    });
                    return;
                  }
                } catch (error: any) {
                  toast({
                    title: error.message,
                    status: "error",
                  });
                }
              }
            }}
            disabled={
              verify2FaOtpMutation?.isLoading ||
              updateProfileMutation?.isLoading
            }
          />
        </Flex>
      ) : (
        <Flex
          mt={"65px"}
          direction={"column"}
          align={"flex-start"}
          gap={"24px"}
          alignSelf={"stretch"}
        >
          <Text
            color={paraColor1}
            fontSize={"16px"}
            fontWeight={"700"}
            lineHeight={"22px"}
            letterSpacing={"0.08px"}
          >
            Two Factor Authentication
          </Text>
          <Flex
            direction={"column"}
            align={"center"}
            justify={"center"}
            gap={"32px"}
            alignSelf={"stretch"}
            mx={["5%", "10%", "15%", "20%"]}
          >
            <Text
              color={paraColor2}
              fontSize={"14px"}
              fontWeight={"400"}
              letterSpacing={"0.014px"}
            >
              Security QR Code
            </Text>
            {/* <Image src={qrCodeIcon} alt="qr-code" /> */}
            {enable2FAQuery?.data?.authUrl && (
              <QRCode
                size={220}
                style={{
                  border: "1px solid #EEE",
                  padding: "16px",
                  borderRadius: "10px",
                }}
                value={enable2FAQuery?.data?.authUrl}
                viewBox={`0 0 256 256`}
              />
            )}
            <Text
              color={paraColor1}
              fontSize={"14px"}
              fontWeight={"400"}
              lineHeight={"20px"}
              letterSpacing={"0.035px"}
              textAlign={"center"}
            >
              For more security, enable on authenticator app
            </Text>
            <Flex
              direction={"column"}
              gap={"16px"}
              pb={"8px"}
              borderBottom={`1px solid ${borderC}`}
              align={"center"}
              justify={"center"}
              alignSelf={"stretch"}
            >
              <Text
                color={paraColor2}
                fontSize={"12px"}
                fontWeight={"400"}
                letterSpacing={"0.048px"}
              >
                Security Key
              </Text>
              <Text
                color={paraColor3}
                fontSize={["12px", "14px", "15px", "16px"]}
                fontWeight={"700"}
                lineHeight={"22px"}
                letterSpacing={"0.08px"}
                display={"flex"}
                gap={"16px"}
                alignItems={"center"}
              >
                {enable2FAQuery?.isLoading ? (
                  <Spinner />
                ) : (
                  enable2FAQuery?.data?.authSecretText
                )}{" "}
                <Image
                  src={copyIcon}
                  w={["15px", "17px", "18px", "20px"]}
                  h={["15px", "17px", "18px", "20px"]}
                  cursor={"pointer"}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      enable2FAQuery?.data?.authSecretText
                    );
                    toast({
                      title: "Key copied to clipboard",
                      status: "success",
                      duration: 3000,
                      isClosable: true,
                    });
                  }}
                />
              </Text>
            </Flex>
            <PrimaryButton
              text={loggedInUser?.is2FAEnabled ? "Disable Now" : "Enable Now"}
              mt="0px"
              onClick={() => setVerifyAuth(true)}
            />
          </Flex>
        </Flex>
      )}
    </div>
  );
};

export default TwoFa;
