import { Box } from "@chakra-ui/react";
import React from "react";
import UserLevelCard from "./UserLevelCard";
import { useGetLoggedInUser } from "../utils/auth.api";

const UpgradeAccount = () => {
  const {data} = useGetLoggedInUser();
  
  return (
    <Box>
      <UserLevelCard
        level="Level 01"
        des={[
          "When a new user signs up, the account is marked as Level 01. User can deposit and withdraw any crypto and any amount ( but can't deposit fiat)",
        ]}
        benefits={["5M USD cap"]}
        status='Completed'
      />
      <UserLevelCard
        level="Level 02"
        des={[
          "All level 1 verifications",
          "ID Verification: Submit a government-issued ID, such as a passport or driver's license",
        ]}
        benefits={["Level 01 benefits", "Deposit Fiat up to 5M Naira"]}
        status={data?.kycTier==='2' && 'Completed'}
      />
      <UserLevelCard
        level="Pro"
        des={[
          "Selfie Verification",
          "Risk Assessment",
          "All Level 2 Requirements",
          "Enhanced ID Verification",
          "Source of Funds",
        ]}
        benefits={["Unlimited & full access"]}
      />
    </Box>
  );
};

export default UpgradeAccount;