import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { MouseEventHandler } from "react";

interface ButtonProps {
  arrow: string;
  text: string;
  flex?: number;
  borderS?: string;
  bgPic?: string;
  fromWallet?: string;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}
let bgColor: any;

const ActionButton: React.FC<ButtonProps> = ({
  arrow,
  flex,
  text,
  borderS,
  bgPic,
  fromWallet,
  onClick,
}) => {
  const bgColor1 = useColorModeValue("#F00C0C", "#FF5959");
  const bgColor2 = useColorModeValue("#FFC960", "#FFC960");
  const bgColor3 = useColorModeValue("#00FFE0", "#00FFE0");
  const colorText = useColorModeValue("#FFF", "#000");

  if (text === "Deposit" && !fromWallet) {
    bgColor = bgColor3;
  } else if (text === "Withdraw" && !fromWallet) {
    bgColor = bgColor1;
  } else if (text === "Swap" && !fromWallet) {
    bgColor = bgColor2;
  }

  return (
    <div style={{ flex }}>
      <Button
        borderRadius={"60px"}
        backgroundImage={bgPic}
        // bg={bgColor}
        background={`url(${bgPic}) no-repeat, ${
          fromWallet ? fromWallet : bgColor
        }`}
        backgroundPosition={"right"}
        backgroundSize={"cover"}
        padding={"8px 8px 8px 16px"}
        h={"100%"}
        w={"full"}
        _hover={{ bg: fromWallet ? "" : bgColor }}
        border={`4px solid ${colorText}`}
        // boxShadow={
        //   borderS
        //     ? `0px 60px 10px -6px ${fromWallet ? fromWallet : bgColor}`
        //     : ""
        // }
        _active={{ bg: fromWallet ? "" : bgColor }}
        onClick={onClick}
      >
        <Flex justify={"space-between"} align={"center"} w="full">
          <Text
            color={
              text === "Deposit" && !fromWallet
                ? "#000"
                : text === "Deposit" && fromWallet
                ? "#000"
                : text === "Withdraw" && fromWallet
                ? "#FFF"
                : "#fff"
            }
            fontSize={"14px"}
            fontWeight={"400"}
            lineHeight={"20px"}
            letterSpacing={"0.035px"}
          >
            {text}
          </Text>
          <Image src={arrow} alt="arrow" w={"32px"} h={"32px"} />
        </Flex>
      </Button>
      <Box
        borderRadius={"30px"}
        background={borderS}
        padding={"8px 8px 8px 16px"}
        h={"100%"}
        w={"100%"}
        mt={"-38px"}
      >
        sdsd
      </Box>
    </div>
  );
};

export default ActionButton;
