import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import notSelected from "../assets/notSelectedTokenIcon.svg";
import selectedIcon from "../assets/selectedIcon.svg";
import notSelectedDark from "../assets/notSelectedDark.svg";
import radioSelectedDark from "../assets/radioButtonDark.svg";
import selectedDark from "../assets/selectedIconDark.svg";
import CustomModal from "./CustomModal";

interface TokenCardProps {
  coin: string;
  fullname: string;
  icon?: string;
  balance: string;
  selected: string;
  setSelected: (value: string) => void;
  isName?: boolean;
  mb?: string;
  iconTwo?: string;
  balance2?: string;
  iconThree?: string;
  radioBtn?: boolean;
  usdtTokensData?: any;
  setSelectedTokenBalance?: (value: any) => any;
  tokenData?: any;
  setSelectedTokenData?: (value: any) => any;
  setAddAddressChecked?: (value: any) => any;
  handleModal?: any;
  selectedNetwork?: any;
}

const TokenCard: React.FC<TokenCardProps> = ({
  coin,
  fullname,
  icon,
  balance,
  selected,
  setSelected,
  isName,
  mb,
  iconTwo,
  balance2,
  iconThree,
  radioBtn,
  usdtTokensData,
  setSelectedTokenBalance,
  tokenData,
  setSelectedTokenData,
  setAddAddressChecked,
  handleModal,
  selectedNetwork,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [usdtIcon, setUsdtIcon] = useState(iconTwo);
  const borderColorSelected = useColorModeValue("#00332D", "#009986");
  const borderColor = useColorModeValue("#EEE", "#333");
  const backgroundC = useColorModeValue("#FFF", "#121B18");
  const paraColor = useColorModeValue("#333", "#EEE");
  const paraColor2 = useColorModeValue("#909090", "#C7C7C7");
  const paraColor3 = useColorModeValue("#333", "#C7C7C7");
  const nameBgColor = useColorModeValue("#CCFFF9", "#00665A");
  const nameBgColor2 = useColorModeValue("#EEE", "#00665A");
  const nameTextColor = useColorModeValue("#00CCB3", "#00FFE0");
  const nameTextColor2 = useColorModeValue("#C7C7C7", "#00FFE0");
  const icon1 = useColorModeValue(notSelected, notSelectedDark);
  const icon2 = useColorModeValue(selectedIcon, selectedDark);
  const icon3 = useColorModeValue(selectedIcon, radioSelectedDark);

  // useEffect(() => {
  //   if (tokenData?.symbol?.split(" ")[0] === "USDT") {
  //     setSelectedTokenData?.(tokenData);
  //   }
  // }, [selected, tokenData]);

  return (
    <div>
      <Flex
        p={"16px"}
        justify={"space-between"}
        align={"center"}
        borderRadius={"12px"}
        border={
          selected === coin
            ? `2px solid ${borderColorSelected}`
            : `1px solid ${borderColor}`
        }
        bg={backgroundC}
        mb={mb ? mb : "16px"}
        h={"68px"}
        cursor={"pointer"}
        onClick={() => {
          setSelected(coin);
          setSelectedTokenBalance?.(balance);
          setSelectedTokenData?.(tokenData);
          setAddAddressChecked?.(false);
          if (handleModal) {
            handleModal();
          }
          // if (
          //   (coin === "erc20" || coin === "trc20") &&
          //   balance2 !== undefined
          // ) {
          //   onOpen();
          // }
        }}
      >
        <Flex gap={"8px"} align={"center"}>
          <Image
            src={
              selected === coin && radioBtn
                ? icon3
                : selected === coin
                ? icon2
                : icon1
            }
          />
          {isName && (
            <Flex
              justify={"center"}
              align={"center"}
              boxSize={"32px"}
              borderRadius={"8px"}
              bgColor={selected === coin ? nameBgColor : nameBgColor2}
              fontSize={"16px"}
              fontWeight={700}
              color={selected === coin ? nameTextColor : nameTextColor2}
            >
              D
            </Flex>
          )}
          <Flex direction={"column"} justify={"center"}>
            <Flex gap={"8px"} align={"center"}>
              <Text
                color={paraColor}
                fontSize={["12px", "14px", "16px", "16px"]}
                fontWeight={"700"}
                lineHeight={"22px"}
                letterSpacing={"1.2px"}
                display={"flex"}
                alignItems={"center"}
              >
                {coin?.includes("USDT") || coin?.includes("USDC")
                  ? coin?.slice(0, 4)
                  : coin}
                {/* {coin === "erc20" || coin === "trc20" ? (
                  <Text
                    color={"#909090"}
                    fontSize={"12px"}
                    fontWeight={"400"}
                    letterSpacing={"0.048px"}
                  >
                    {coin}
                  </Text>
                ) : (
                  ""
                )} */}
                {selectedNetwork && (
                  <Text
                    color={"#909090"}
                    fontSize={"12px"}
                    fontWeight={"400"}
                    letterSpacing={"0.048px"}
                  >
                    {selectedNetwork?.subTitle}
                  </Text>
                )}
              </Text>
              <Flex align={"center"}>
                {icon && (
                  <Image
                    mr={iconTwo ? "-3px" : ""}
                    src={icon}
                    borderRadius="50%"
                    alt="token-symbol"
                    w={["11px", "12px", "13px", "14px"]}
                    h={["11px", "12px", "13px", "14px"]}
                  />
                )}
                {iconTwo && (
                  <Image
                    src={iconThree ? usdtIcon : iconTwo}
                    alt="token-symbol"
                    w={["8px", "8px", "9px", "10px"]}
                    h={["8px", "8px", "9px", "10px"]}
                  />
                )}
              </Flex>
            </Flex>
            <Text
              color={paraColor2}
              fontSize={["10px", "12px", "13px", "14px"]}
              fontWeight={"400"}
              lineHeight={"20px"}
              letterSpacing={"0.035px"}
            >
              {fullname}
            </Text>
          </Flex>
        </Flex>
        {!isName ? (
          <Flex direction={"column"}>
            <Text
              color={paraColor2}
              fontSize={["10px", "12px", "13px", "14px"]}
              fontWeight={"400"}
              lineHeight={"20px"}
              letterSpacing={"0.035px"}
              textAlign={"end"}
            >
              Available Balance
            </Text>
            <Text
              color={paraColor}
              fontSize={["12px", "14px", "16px", "16px"]}
              fontWeight={"700"}
              lineHeight={"22px"}
              letterSpacing={"0.08px"}
              textAlign={"end"}
              display={"flex"}
              alignItems={"center"}
              gap={"8px"}
              justifyContent={"end"}
            >
              {Number(balance)?.toLocaleString(undefined, {
                minimumFractionDigits: 5,
                maximumFractionDigits: 5,
              })}
              {balance2 !== undefined && (
                <Flex
                  gap={"8px"}
                  align={"center"}
                  color={"#909090"}
                  fontSize={"12px"}
                  fontWeight={"400"}
                  letterSpacing={"0.048px"}
                >
                  <Text>/</Text>
                  <Text>
                    {Number(balance2)?.toLocaleString(undefined, {
                      minimumFractionDigits: 5,
                      maximumFractionDigits: 5,
                    })}
                  </Text>
                </Flex>
              )}
            </Text>
          </Flex>
        ) : (
          <Flex h={"100%"} align={"end"}>
            <Text
              color={paraColor2}
              fontSize={["10px", "12px", "13px", "14px"]}
              fontWeight={"400"}
              lineHeight={"20px"}
              letterSpacing={"0.035px"}
            >
              {Number(balance)?.toLocaleString(undefined, {
                minimumFractionDigits: 5,
                maximumFractionDigits: 5,
              })}
            </Text>
          </Flex>
        )}
      </Flex>

      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        headerText="Select USDT chain"
        onSubmit={() => onClose()}
        footerText="Done"
      >
        <Flex
          align={"flex-start"}
          direction={"column"}
          gap="24px"
          mb="32px"
          mt={"32px"}
        >
          <Text
            color={"#909090"}
            fontSize={"14px"}
            fontWeight={"400"}
            lineHeight={"20px"}
            letterSpacing={"0.035px"}
          >
            Select a USDT chain for withdrawal
          </Text>
          <Flex direction={"column"}>
            <Text
              color={paraColor3}
              fontSize={"16px"}
              fontWeight={"400"}
              lineHeight={"22px"}
              letterSpacing={"0.024px"}
            >
              Total USDT
            </Text>
            <Heading
              color={paraColor}
              fontSize={"34px"}
              fontWeight={"700"}
              letterSpacing={"0.085px"}
            >
              {(
                usdtTokensData?.[0]?.balance + usdtTokensData?.[1]?.balance
              )?.toLocaleString(undefined, {
                minimumFractionDigits: 5,
                maximumFractionDigits: 5,
              })}
            </Heading>
          </Flex>
          <Box w="100%">
            {usdtTokensData?.map((tokenData: any) => (
              <TokenCard
                coin={
                  tokenData?.symbol?.split("(")[1]?.trim() === "ERC 20)"
                    ? "erc20"
                    : "trc20"
                }
                fullname={tokenData?.fullname}
                icon={icon}
                iconTwo={
                  tokenData?.symbol?.split("(")[1]?.trim() === "ERC 20)"
                    ? iconTwo
                    : iconThree
                }
                balance={tokenData?.balance?.toLocaleString(undefined, {
                  minimumFractionDigits: 5,
                  maximumFractionDigits: 5,
                })}
                selected={selected}
                setSelected={() =>
                  setSelected(
                    tokenData?.symbol?.split("(")[1]?.trim() === "ERC 20)"
                      ? "erc20"
                      : "trc20"
                  )
                }
                radioBtn={true}
                setSelectedTokenBalance={setSelectedTokenBalance}
                tokenData={tokenData}
                setSelectedTokenData={setSelectedTokenData}
                setAddAddressChecked={setAddAddressChecked}
              />
            ))}
          </Box>
        </Flex>
      </CustomModal>
    </div>
  );
};

export default TokenCard;
