import React, { useState } from "react";
import AuthLayout from "../layouts/AuthLayout";
import {
  Box,
  Checkbox,
  Image,
  Input,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import theme from "../theme/theme";
import AuthCardHeader from "../components/AuthCardHeader";
import UserInfoInput from "../components/UserInfoInput";
import PrimaryButton from "../components/PrimaryButton";
import { useNavigate } from "react-router-dom";
import verifiedIcon from "../assets/verified.svg";

const PasswordUpdated = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const { name, email, password, confirmPassword } = formData;

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const linkColor = useColorModeValue(
    theme.colors.light.link.primary,
    theme.colors.dark.link.primary
  );
  const color = useColorModeValue(
    theme.colors.light.text.primary,
    theme.colors.dark.text.primary
  );
  const checkBoxScheme = useColorModeValue("gray", "teal");
  return (
    <AuthLayout>
      <AuthCardHeader
        imgSrc={verifiedIcon}
        title="Password Updated"
        des="Your password has been updated."
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          navigate("/auth/signin", {
            state: formData,
          });
        }}
      >
        <PrimaryButton type="submit" text="Continue to Login" />
      </form>
    </AuthLayout>
  );
};

export default PasswordUpdated;
