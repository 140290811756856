import React, { useEffect, useState } from "react";
import AdminLayout from "../layouts/AdminLayout";
import {
  Box,
  Divider,
  Flex,
  Heading,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import downArrow from "../assets/downArrowCircular.svg";
import downArrowDark from "../assets/downArrowCircularDark.svg";
import upArrow from "../assets/upArrowCircular.svg";
import upArrowDark from "../assets/upArrowCircularDark.svg";
import chatIcon from "../assets/chatIcon.svg";
import headset from "../assets/headsetIcon.svg";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const HelpCenter = () => {
  const payremitFAQ = [
    {
      question: "What is Payremit?",
      answer:
        "Payremit is a mobile app designed for seamless cryptocurrency to Naira (NGN) swapping and transactions. It provides a user-friendly platform for swapping Bitcoin (BTC), Ethereum (ETH), Tether (USDT), and TRON (TRX) cryptocurrencies into Naira, with no fees.",
    },
    {
      question: "Is Payremit safe?",
      answer:
        "Yes, Payremit is committed to providing a secure environment for your cryptocurrency transactions. We employ robust security measures, including encryption and authentication, to protect your assets and personal information.",
    },
    {
      question: "Is there a fee to use Payremit?",
      answer:
        "Swapping cryptocurrencies to Naira on Payremit is generally fee-free. However, for Bitcoin (BTC), Ethereum (ETH), and TRON (TRX) transactions, users are responsible for covering the associated gas fees through their wallets. Payremit covers the fees for USDT TRC20 transactions, providing a cost-free experience for users when swapping USDT TRC20 to Naira.",
    },
    {
      question: "What cryptocurrencies can I swap on your platform?",
      answer:
        "Payremit supports the swapping of the following cryptocurrencies: Bitcoin (BTC), Ethereum (ETH), Tether (USDT), and TRON (TRX). You can swap these cryptocurrencies into Naira using our mobile app.",
    },
    {
      question: "How do I contact support?",
      answer:
        "You can contact our support team through the following methods:\n- Chat with us here\n- Email: support@payremit.io\n- Live Chat: Access the live chat feature within the mobile app.",
    },
    {
      question: "How long does it take to swap cryptocurrency to Naira?",
      answer:
        "Swapping cryptocurrency to Naira on Payremit is exceptionally fast, typically taking less than a hot minute. You can enjoy the convenience of near-instantaneous swaps with no fees on our platform.",
    },
    {
      question: "Have more questions?",
      answer: "If you have more questions, please visit this",
      linkText: "link.",
    },
  ];
  const navigate = useNavigate();
  const backColor = useColorModeValue("#009986", "#00FFE0");
  const headColor = useColorModeValue("#00332D", "#EEE");
  const borderColor = useColorModeValue("#EEE", "#333");
  const paraColor2 = useColorModeValue("#333", "#EEE");
  const bgColor = useColorModeValue("#FFF", "#121B18");
  const bgColor2 = useColorModeValue("#FFF", "");
  const paraColor = useColorModeValue("#5A5A5A", "#C7C7C7");
  const paraColor3 = useColorModeValue("#909090", "#C7C7C7");
  const downArrowIcon = useColorModeValue(downArrow, downArrowDark);
  const upArrowIcon = useColorModeValue(upArrow, upArrowDark);

  useEffect(() => {
    return () => {
      const script = document.querySelector(
        'script[src="https://desk.zoho.com/portal/api/web/inapp/986193000000351491?orgId=852760906"]'
      );
      if (script) {
        script.remove();
      }
    };
  }, []);

  return (
    <AdminLayout noSidebar={true}>
      <Helmet>
        <script
          type="text/javascript"
          src="https://desk.zoho.com/portal/api/web/inapp/986193000000351491?orgId=852760906"
          defer
        ></script>
      </Helmet>
      <Flex
        mb={"72px"}
        mt={"72px"}
        direction={"column"}
        justify={"center"}
        gap={"40px"}
        alignSelf={"stretch"}
        borderRadius={"0px 0px 12px 12px"}
        pr={"3%"}
      >
        <Text
          color={backColor}
          fontSize={"12px"}
          fontWeight={"500"}
          letterSpacing={"0.15px"}
          textDecorationLine={"underline"}
          textTransform={"capitalize"}
          cursor={"pointer"}
          onClick={() => {
            navigate("/dashboard/index");
            window.location.reload();
            const script = document.querySelector(
              'script[src="https://desk.zoho.com/portal/api/web/inapp/986193000000351491?orgId=852760906"]'
            );
            if (script) {
              script.remove();
            }
          }}
          w={"fit-content"}
        >
          Go Back to Home
        </Text>
        <Flex
          align={["start", "start", "center", "center"]}
          direction={["column", "column", "row", "row"]}
          gap={"10px"}
        >
          <Heading
            color={headColor}
            fontSize={"34px"}
            fontWeight={"700"}
            letterSpacing={"0.085px"}
            w={"89%"}
          >
            Help Center
          </Heading>
        </Flex>
        <Flex
          gap={"24px"}
          alignSelf={"stretch"}
          direction={["column", "column", "row", "row"]}
        >
          <Flex
            w={["100%", "100%", "60%", "60%"]}
            direction={"column"}
            gap={"16px"}
            flex={"1 0 0"}
          >
            <Text
              color={paraColor}
              fontSize={"16px"}
              fontWeight={"400"}
              lineHeight={"22px"}
              letterSpacing={"0.08px"}
            >
              FAQs
            </Text>
            <Flex direction={"column"} gap={"15px"} alignSelf={"stretch"}>
              {payremitFAQ.map((faq, index) => (
                <Box key={index}>
                  <HelpCenterCard
                    downArrow={downArrowIcon}
                    upArrow={upArrowIcon}
                    text={faq.question}
                    text2={faq.answer}
                    borderColor={borderColor}
                    bgC={bgColor2}
                    paraC={paraColor2}
                    paraC2={paraColor3}
                    linkText={faq?.linkText}
                  />
                </Box>
              ))}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </AdminLayout>
  );
};

export default HelpCenter;

const HelpCenterCard = ({
  downArrow,
  upArrow,
  text,
  text2,
  borderColor,
  bgC,
  paraC,
  paraC2,
  linkText,
}: any) => {
  const [dropDownOpen, setDropDownOpen] = useState(false);

  return (
    <Flex
      p={"16px"}
      direction={"column"}
      justify={"center"}
      align={"start"}
      gap={"16px"}
      alignSelf={"stretch"}
      borderRadius={"12px"}
      border={`1px solid ${borderColor}`}
      bg={bgC}
      h={dropDownOpen ? "auto" : "56px"}
    >
      <Flex justify={"space-between"} align={"center"} w={"100%"}>
        <Text
          // w={["80%", "60%", "40%", "40%"]}
          color={paraC}
          fontSize={"16px"}
          fontWeight={"400"}
          lineHeight={"22px"}
          letterSpacing={"0.08px"}
        >
          {text}
        </Text>
        <Image
          src={dropDownOpen ? upArrow : downArrow}
          alt="arrow"
          cursor={"pointer"}
          onClick={() => setDropDownOpen(!dropDownOpen)}
        />
      </Flex>
      {dropDownOpen && (
        <>
          <Divider />
          <Text
            color={paraC2}
            fontSize={"16px"}
            fontWeight={"400"}
            lineHeight={"22px"}
            letterSpacing={"0.08px"}
          >
            {text2}
            {linkText && (
              <Text
                color={"#009986"}
                fontSize={"16px"}
                fontWeight={"400"}
                lineHeight={"22px"}
                letterSpacing={"0.08px"}
                as={"span"}
                ml="3px"
                textDecor={"underline"}
                onClick={() => window.open("http://help.payremit.io/")}
                cursor={"pointer"}
              >
                {linkText}
              </Text>
            )}
          </Text>
        </>
      )}
    </Flex>
  );
};
